import useNotification from "../hooks/useNotification";

const useErrorHandler = () => {
  const notification = useNotification();
  const handleError = (e: any) => {
    console.log(e);
    if (e.response.status === 400) {
      for (var key in e.response.data.errors) notification(e.response.data.errors[key][0], "error");
    }
  };
  return handleError;
};

export default useErrorHandler;
