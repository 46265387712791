import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { IExecutionPackagePart } from "@types";
import { Card } from "../Card";
import { Button } from "../Button";
import { Thumbnail } from "../Thumbnail";
import { ModalVersions } from "../ModalVersions";

const ProgramDetailsCard: React.FC<
  {
    title: string;
    imageSrc: string;
    versions: IExecutionPackagePart[];
    programName: string;
    onFileDrop: (files: File[]) => void;
    onUpload: () => void;
    onDownload: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ title, imageSrc, versions, programName, onUpload, onDownload, onFileDrop }): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [latestVersion, setLatestVersion] = useState<IExecutionPackagePart | null>(null);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    onFileDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive, isDragReject, isDragAccept } = useDropzone({ onDrop });

  const activeStyle = {
    borderColor: styleVariables.color_white,
    borderStyle: "dashed",
    borderWidth: 1.5,
    borderRadius: 1,
  };

  const rejectStyle = {
    borderColor: styleVariables.color_red,
    borderStyle: "dashed",
    borderWidth: 1.5,
    borderRadius: 1,
  };

  const style = useMemo(
    () => ({
      ...(isDragActive || isDragAccept ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (versions && versions.length > 0) {
      const latest = versions.sort((a, b) => {
        return a.lastUpdatedDate === b.lastUpdatedDate! ? 0 : a.lastUpdatedDate! > b.lastUpdatedDate! ? -1 : 1;
      })[0];
      setLatestVersion(latest);
    }
  }, [versions]);
  return (
    <>
      <Card className="program-details-card" width={445} height={312} hover={false}>
        <div className="program-details-card-header">
          <Typography type={ElementType.eyebrow}>{title}</Typography>
          <div role="button" tabIndex={0} onClick={() => setShowModal(true)} onKeyDown={() => {}}>
            <Typography color={Color.Secondary}>View History</Typography>
          </div>
        </div>
        <div className="program-details-card-image" {...getRootProps({ style })}>
          <input {...getInputProps()} onClick={(ev) => ev.preventDefault()} />
          <Thumbnail
            backgroundColor={styleVariables.color_container_secondary}
            width={130}
            height={130}
            backgroundImageUrl={imageSrc || ""}
            title={title}
          />
        </div>
        <div className="program-details-card-footer">
          {latestVersion && (
            <div>
              <Typography type={ElementType.label}>Current version</Typography>
              <div className="custom-input">
                <Typography type={ElementType.inline} color={Color.Secondary}>
                  {`v${latestVersion?.versionNumber} `}
                </Typography>
                <Typography
                  className="program-details-card-footer-title"
                  type={ElementType.inline}
                >{` ${latestVersion?.title}`}</Typography>
              </div>
            </div>
          )}

          <Button
            themeType="primary"
            size="large"
            onClick={onDownload}
            iconOnly={true}
            iconName="far fa-arrow-to-bottom"
            tooltip="Download"
          />
          <Button
            size="large"
            onClick={onUpload}
            themeType="clear"
            iconOnly={true}
            iconName="far fa-arrow-to-top"
            tooltip="Upload"
          />
        </div>
      </Card>
      {showModal && (
        <ModalVersions
          width={960}
          onCloseModal={() => setShowModal(false)}
          title={`${programName} | ${title}`}
          versions={versions}
        />
      )}
    </>
  );
};

export { ProgramDetailsCard };
