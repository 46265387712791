import React, { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { OptionType, Select } from "../Common/Select";
import { CardAddPartAttribute } from "../CardAddPartAttribute";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { Button } from "../Button";
import { SlideToggleContent } from "../../helpers/animations";
import { INewProjectDataPart, INewProjectDataFeaturesData, IProcessParameter } from "@types";
import { ElementType, FontWeight, Typography } from "../Common/Typography";
import { v4 as uuidv4 } from "uuid";
import { SelectionInput } from "../Common/SelectionInput";
import { Input } from "../Common/Input";
import Toggle from "../Common/Toggle";
import { ActionMeta, ValueType } from "react-select";

const FeatureData: FC<{
  part: INewProjectDataPart;
  currentPart: INewProjectDataPart;
  featurePart: INewProjectDataFeaturesData;
  deletePart: () => void;
  changeProcess: (process: string) => void;
  changeFeatureName: (event: FormEvent<HTMLInputElement>) => void;
  changeFeatureMeasure: (measure: OptionType) => void;
  changeFeatureAdvanced: (event: FormEvent<HTMLInputElement>) => void;
  changeFeatureParams: (event: FormEvent<HTMLInputElement>, parameterName: string) => void;
  onClickAddAnotherFeature: (part: INewProjectDataPart) => void;
  onClickDone: (event: FormEvent<HTMLButtonElement>) => void;
  copyFromPart?: (value: ValueType<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  showAdvancedNotice: boolean;
  showMultipleNotice: boolean;
  partOptions: OptionType[];
  featureSizing: OptionType[];
  features: INewProjectDataFeaturesData[];
  processes: IProcessParameter[];
  featureIndex: number;
  index: number;
  withMargin?: boolean;
  withControls?: boolean;
}> = ({
  part,
  currentPart,
  featurePart,
  deletePart,
  changeProcess,
  changeFeatureName,
  changeFeatureMeasure,
  changeFeatureAdvanced,
  changeFeatureParams,
  onClickAddAnotherFeature,
  onClickDone,
  copyFromPart,
  showAdvancedNotice,
  showMultipleNotice,
  processes,
  partOptions,
  featureSizing,
  features,
  featureIndex,
  index,
  withMargin = false,
  withControls = true,
}): ReactElement => {
  const processOptionsNew: string[] = [];
  processes.forEach((p) => {
    if (processOptionsNew.indexOf(p.processName!) < 0) {
      processOptionsNew.push(p.processName!);
    }
  });
  const [parameters, setParameters] = useState<IProcessParameter[]>([]);

  useEffect(() => {
    let filteredParams: IProcessParameter[] = [];
    if (featurePart.process) {
      filteredParams = processes.filter((p) => p.processName === featurePart.process);
    }
    setParameters(filteredParams);
  }, [featurePart.process]);

  const getUnits = (measurementType: string, measure: string) => {
    let result = "";
    switch (measurementType) {
      case "Length":
        result = measure == "mm/deg/sec" ? "mm" : "in";
        break;
      case "Angle":
        result = "deg";
        break;
      case "Time":
        result = "sec";
        break;
      default:
        break;
    }
    return result;
  };

  const getValue = (value: number, measure: string) => {
    let result = 0;
    switch (measure) {
      case "mm/deg/sec":
        result = parseFloat(value.toFixed(5));
        break;
      case "in/deg/sec":
        result = parseFloat((value / 25.4).toFixed(5));
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <>
      {currentPart === part && (
        <div
          className={`modal-new-project-section modal-new-project-form ${
            withMargin ? "modal-new-project-form-mt" : ""
          }`}
        >
          <div className="modal-new-project-features-title-select">
            <Typography type={ElementType.display} id={`feature-title-${index}-${featureIndex}`}>
              {part.name} - {featurePart.featureName}
            </Typography>
            {features.length > 0 && (
              <div className="modal-new-project-features-title-select-row">
                {copyFromPart && (
                  <Select
                    onChange={copyFromPart}
                    options={partOptions}
                    placeholder="Copy from"
                    value={undefined}
                    width={"100%"}
                  />
                )}
                <Icon
                  className="modal-new-project-features-title-select-row-icon"
                  color={styleVariables.color_gray}
                  onClick={deletePart}
                  onKeyDown={deletePart}
                  name="fal fa-trash"
                  size={18}
                  tabIndex={0}
                />
              </div>
            )}
          </div>
          <div className="modal-new-project-features-holes">
            <Input
              label={"Feature Name *"}
              placeholder="Feature Name"
              onChange={changeFeatureName}
              containerClassName="modal-new-project-features-selection"
              width="100%"
              value={featurePart.featureName!}
            />
          </div>
          <Typography className="modal-new-project-features-subtitle" type={ElementType.subtitle}>
            What process would you like to use for{" "}
            <Typography type={ElementType.inline} fontWeight={FontWeight.Bold}>
              {part.name}
            </Typography>
            ?
          </Typography>
          {processOptionsNew.map((processOption) => {
            const changeValues = () => {
              changeProcess(processOption!);
            };

            return (
              <SelectionInput
                className="modal-new-project-features-selection"
                text={processOption!}
                handleClick={changeValues}
                checked={processOption ? featurePart?.process === processOption : false}
                width="100%"
                name={processOption!}
                key={`process-${uuidv4}-${processOption}`}
              />
            );
          })}
          {featurePart?.process && (
            <React.Fragment key={`feature-${uuidv4}-${featureIndex}`}>
              <div className="modal-new-project-features-holes">
                <div className="modal-new-project-form-title-row">
                  <Typography className="modal-new-project-features-subtitle" type={ElementType.subtitle}>
                    Feature Details
                  </Typography>
                  <Select
                    className="modal-new-project-form-input"
                    // @ts-ignore
                    onChange={changeFeatureMeasure}
                    value={featureSizing.find((f) => f.value === featurePart.measure)}
                    options={featureSizing}
                    placeholder="mm/deg/sec"
                    maxWidth={"25%"}
                    width={"100%"}
                  />
                </div>
                {parameters
                  .filter((p) => p.parameterType === "Basic")
                  .map((param) => (
                    <Input
                      key={`${param.processName}=${param.parameter}`}
                      label={param.parameter}
                      placeholder={param.parameter}
                      onChange={(e) => changeFeatureParams(e, param.parameter!)}
                      containerClassName="modal-new-project-features-selection"
                      rightText={getUnits(param.measurementType!, featurePart.measure)}
                      type="number"
                      width="100%"
                      value={
                        featurePart.parameters?.find((par) => par.parameterName === param.parameter)?.parameterValue !==
                        undefined
                          ? getValue(
                              featurePart.parameters?.find((par) => par.parameterName === param.parameter)
                                ?.parameterValue!,
                              featureSizing.find((f) => f.value === featurePart.measure)?.value!
                            )
                          : null
                      }
                    />
                  ))}
              </div>
              <div className="modal-new-project-features-advanced">
                <div className="modal-new-project-features-advanced-header">
                  <Toggle
                    checked={featurePart.advancedOptions}
                    label="Advanced Options"
                    onChange={changeFeatureAdvanced}
                    identifier={`${featureIndex}-${featurePart.featureId}-advanced`}
                  />
                  <Typography type={ElementType.label} htmlFor={`${featureIndex}-${featurePart.featureId}-advanced`}>
                    Advanced Options
                  </Typography>
                  {showAdvancedNotice && featureIndex === 0 && (
                    <CardAddPartAttribute
                      className="modal-new-project-attribute-advanced"
                      title="Enter Advanced Features Details"
                      description="Toggle advanced options to view more inputs for your feature’s details."
                    />
                  )}
                </div>
                <SlideToggleContent isVisible={featurePart.advancedOptions}>
                  <div className="modal-new-project-features-advanced-cells">
                    {parameters
                      .filter((p) => p.parameterType === "Advanced")
                      .map((param) => (
                        <div
                          className="modal-new-project-features-advanced-cells-cell"
                          key={`${param.processName}=${param.parameter}`}
                        >
                          <Input
                            label={param.parameter}
                            placeholder={param.parameter}
                            onChange={(e) => changeFeatureParams(e, param.parameter!)}
                            containerClassName="modal-new-project-features-selection"
                            rightText={getUnits(param.measurementType!, featurePart.measure)}
                            type="number"
                            width="100%"
                            value={
                              featurePart.parameters?.find((par) => par.parameterName === param.parameter)
                                ?.parameterValue !== undefined
                                ? featurePart.parameters?.find((par) => par.parameterName === param.parameter)
                                    ?.parameterValue!
                                : null
                            }
                          />
                        </div>
                      ))}
                  </div>
                </SlideToggleContent>
              </div>
            </React.Fragment>
          )}

          {withControls && (
            <>
              <br />
              <div className="modal-new-project-features-add">
                <Button
                  className="modal-new-project-button"
                  iconLeftName={"fas fa-plus"}
                  onClick={() => {
                    onClickAddAnotherFeature(part);
                  }}
                  size="large"
                  themeType={"secondary"}
                >
                  Add Another Feature
                </Button>
                <Button
                  className="modal-new-project-features-add-done"
                  size="large"
                  themeType="clear"
                  onClick={onClickDone}
                >
                  Done
                </Button>
                {showMultipleNotice && (
                  <CardAddPartAttribute
                    className="modal-new-project-attribute"
                    title="Add Multiple Features to a Part"
                    description="If your part has multiple features, add them to the project to make preparing for your machine sessions easier."
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export { FeatureData };
