import * as React from "react";
import { Card } from "../Card";
import { Thumbnail } from "../Thumbnail";
import { Status } from "../../constants/enums/Status";
import { Typography, ElementType, Color } from "../Common/Typography";
import { Progress } from "../Common/Progress";
import { Button } from "../Button";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";

const ProjectSessionHeader: React.FC<
  {
    backgroundImageUrl: string;
    status: Status;
    title: string;
    imageTitle?: string;
    sessionsUsed: number;
    totalSessions: number;
    onEditPhotoClick: () => void;
    onFavoriteClick: () => void;
    isFavorite: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  backgroundImageUrl = "",
  status = Status.InProgress,
  title = "",
  imageTitle = "",
  sessionsUsed = 0,
  totalSessions = 0,
  onFavoriteClick,
  onEditPhotoClick,
  isFavorite,
}): React.ReactElement => (
  <Card className="project-session-header" width={"initial"} height={"initial"} hover={false}>
    <div className="project-session-header-thumbnail-container">
      <Thumbnail
        className={"project-session-header-thumbnail"}
        title={imageTitle}
        round={false}
        width={88}
        height={88}
        borderRadius={8}
        filter={"drop-shadow(-2px -3px 4px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #000)"}
        backgroundImageUrl={backgroundImageUrl}
      />
      <div
        role="button"
        onKeyDown={onEditPhotoClick}
        onClick={onEditPhotoClick}
        tabIndex={0}
        className="project-session-header-thumbnail-container-button"
      >
        <Icon name="fas fa-camera" size={10} />
        <Typography fontSize={14}>{backgroundImageUrl ? "Edit" : "Add"}</Typography>
      </div>
    </div>

    <div className="project-session-header-content">
      <div className="project-session-header-content-title" data-testid="project-session-header-content-title">
        <Typography type={ElementType.h2}>{title}</Typography>
        <div className="project-session-header-content-title-icon">
          <Button
            onClick={(ev) => {
              ev.stopPropagation();
              onFavoriteClick();
            }}
            themeType="simple"
            iconOnly={true}
            iconName={"fas fa-star"}
            iconSize={20}
            iconColor={isFavorite ? styleVariables.color_orange : styleVariables.color_gray}
          />
        </div>
      </div>
      <Typography
        className="project-session-header-content-subtitle"
        type={ElementType.body}
        color={Color.OffWhite}
        data-testid="project-session-header-content-subtitle"
      >
        {sessionsUsed} / {totalSessions} budgeted sessions used
      </Typography>
      <div className="project-session-header-content-bottom">
        <Progress width={300} completed={totalSessions === 0 ? 100 : (sessionsUsed / totalSessions) * 100} />
      </div>
    </div>
  </Card>
);

export { ProjectSessionHeader };
