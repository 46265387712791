import { IArcadiaFavorite, IArcadiaUserInfo } from "@types";
import axios from "axios";

const rfc6902 = require("rfc6902");

const getUser = () => {
  return axios.get("/admin/me");
};

const getUsers = (groupId: string) => {
  return axios.get(`groups/${groupId}/users`);
};

const updateUser = (updatedUser: IArcadiaUserInfo, originalUser: IArcadiaUserInfo) => {
  const patchArr = rfc6902.createPatch(originalUser, updatedUser);
  return axios.patch("/admin/me", patchArr);
};

const getUserBalances = () => {
  return axios.get("/customer/customer/me/balances");
};

const updateFavorites = (favorites: IArcadiaFavorite[]) => {
  return axios.put("/admin/me/favorites", favorites);
};

export default {
  getUser,
  getUsers,
  updateUser,
  updateFavorites,
  getUserBalances,
};
