import React from "react";

const Progress: React.FC<
  {
    className?: string;
    completed: number;
    height?: number;
    width?: number | string;
    softShadow?: boolean;
    theme?: "blue" | "orange";
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  completed = 0,
  height = 12,
  softShadow = false,
  theme = "orange",
  width = "auto",
}): React.ReactElement => {
  return (
    <div
      style={{ width: width }}
      className={`progress-line ${softShadow ? "progress-line-softshadow" : ""}`}
      data-testid="progress-container"
    >
      <div
        className={`progress-line-filler progress-line-filler-${theme} ${className} ${
          completed >= 100 ? "progress-line-filler-full" : ""
        }`}
        style={{ height: `${height}px`, width: `${Math.min(completed, 100)}%` }}
      ></div>
    </div>
  );
};

export { Progress };
