import * as React from "react";
import { default as cx } from "classnames";
import { Icon } from "../../Icon";
import styleVariables from "@styles/export.scss";
import DropdownSelection from "../../DropdownSelection";

const VideoQualityControl: React.FC<
  {
    className?: string;
    setVideoQuality: (volume: number) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", setVideoQuality }): React.ReactElement => {
  const classes = cx(
    {
      "video-quality-control-container": true,
    },
    className
  );

  return (
    <div className={classes}>
      <DropdownSelection
        size="small"
        renderedComponent={
          <Icon color={styleVariables.color_white} size={23} width={23} height={23} name={"fas fa-cog"} />
        }
        items={[
          {
            name: "Automatic",
            onClick: () => {
              setVideoQuality(0);
            },
          },
          {
            name: "1080p",
            onClick: () => {
              setVideoQuality(1080);
            },
          },
          {
            name: "720p",
            onClick: () => {
              setVideoQuality(720);
            },
          },
          {
            name: "480p",
            onClick: () => {
              setVideoQuality(480);
            },
          },
          {
            name: "360p",
            onClick: () => {
              setVideoQuality(360);
            },
          },
        ]}
      />
    </div>
  );
};

export { VideoQualityControl };
