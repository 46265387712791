import React from "react";
import { Button } from "../Button";
import { SessionTitle } from "../SessionTitle";

const SecondNavigationTop: React.FC<
  {
    activeStep: number;
    totalSteps: number;
    sessionTitle: string;
    machineName: string;
    showBack?: boolean;
    inverseTitle?: boolean;
    onClickNext: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClickSaveDraft: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClickFinish: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onSessionNameChange: (name: string) => void;
    isNextDisabled?: boolean;
    isManager?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  activeStep = 1,
  totalSteps = 1,
  sessionTitle = "",
  machineName = "",
  showBack = true,
  inverseTitle = false,
  onClickNext,
  onClickSaveDraft,
  onClickFinish,
  onSessionNameChange,
  isNextDisabled = true,
  isManager,
}): React.ReactElement => (
  <div className="second-nav-container">
    {showBack && <Button themeType={"navigation"} iconOnly={true} iconName={"fal fa-arrow-left"} />}
    <SessionTitle
      className="second-nav-container-session-title"
      sessionTitle={sessionTitle}
      machineName={machineName}
      inverse={inverseTitle}
      editable={true}
      onSessionNameChange={onSessionNameChange}
    />
    <div className="push-right">
      {!isManager && (
        <Button themeType={"secondary"} onClick={onClickSaveDraft} size={"large"}>
          Save as Draft
        </Button>
      )}
    </div>
    <Button
      className="next-button"
      themeType={"clear"}
      size={"large"}
      onClick={activeStep === totalSteps ? onClickFinish : onClickNext}
      disabled={isNextDisabled}
    >
      {activeStep === totalSteps ? (isManager ? "Confirm" : "Done") : "Next"}
    </Button>
  </div>
);

export { SecondNavigationTop };
