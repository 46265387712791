import * as React from "react";
import { default as cx } from "classnames";
import Picker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { Typography, ElementType } from "../Common/Typography";

const DatePicker: React.FC<{
  className?: string;
  value: Date | null;
  handleChange: Function;
  customInput?: React.ReactElement;
  width?: number | string;
  open?: boolean;
  placeholderText?: string;
  excludedDates?: Date[];
  minDate?: Date;
  popperPlacement?:
    | "auto"
    | "auto-left"
    | "auto-right"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left"
    | "left-end"
    | "left-start"
    | "right"
    | "right-end"
    | "right-start"
    | "top"
    | "top-end"
    | "top-start";
}> = ({
  className = "",
  value = new Date(),
  handleChange,
  width = 325,
  excludedDates = [],
  minDate,
  customInput = (
    <Input
      rightIconName="fas fa-calendar"
      className="custom-input"
      data-testid="datepicker-input"
      value={value ? value.toISOString() : null}
      onChange={() => {}}
    />
  ),
  open = undefined,
  placeholderText = "",
  popperPlacement = "auto",
  ...others
}): React.ReactElement => {
  const classes = cx(
    {
      "custom-date-picker": true,
    },
    className
  );

  const changeHandler = (date: Date) => {
    handleChange(date);
  };

  return (
    <Picker
      excludeDates={excludedDates}
      minDate={minDate ? minDate : null}
      showPopperArrow={false}
      adjustDateOnChange={true}
      calendarClassName={classes}
      useWeekdaysShort={true}
      fixedHeight={true}
      customInput={customInput}
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => {
        const onClickDecreaseMonth = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          decreaseMonth();
        };

        const onClickIncreaseMonth = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault();
          event.stopPropagation();
          increaseMonth();
        };

        return (
          <div className="custom-header">
            <Button
              className="calendar-button"
              themeType="secondary"
              size="medium"
              iconOnly={true}
              iconName={"fal fa-chevron-left"}
              onClick={onClickDecreaseMonth}
            />
            <Typography type={ElementType.subtitle}>{moment(date).format("MMMM YYYY")}</Typography>
            <Button
              className="calendar-button"
              themeType="secondary"
              size="medium"
              iconOnly={true}
              iconName={"fal fa-chevron-right"}
              onClick={onClickIncreaseMonth}
            />
          </div>
        );
      }}
      selected={value}
      onChange={changeHandler}
      open={open}
      placeholderText={placeholderText}
      popperPlacement={popperPlacement}
      {...others}
    />
  );
};

export { DatePicker };
