import { IArcadiaTask2 } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useTasks = () => {
  const [isTasksLoading, setIsLoading] = useState(false);
  const [isTasksError, setIsError] = useState(false);
  const [tasks, setTasks] = useState<IArcadiaTask2[]>([]);

  const fetchTasks = (projectId: string) => {
    const fetchData = async () => {
      if (projectId) {
        setIsError(false);
        setIsLoading(true);
        ProjectsService.getAllTasks(projectId)
          .then((response: any) => {
            const tasks: IArcadiaTask2[] = response.data as IArcadiaTask2[];
            setTasks(tasks);
            setIsError(false);
            setIsLoading(false);
          })
          .catch((e) => {
            setIsError(true);
            setIsLoading(false);
          });
      }
    };
    fetchData();
  };

  return {
    tasks,
    isTasksLoading,
    isTasksError,
    fetchTasks,
  };
};

export default useTasks;
