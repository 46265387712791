import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { default as cx } from "classnames";
import { Status } from "../../constants/enums/Status";
import { Button } from "../Button";
import { SessionTitle } from "../SessionTitle";
import { StatusIcon } from "../StatusIcon";

const MachineSessionHeader: React.FC<
  {
    sessionTitle: string;
    status: Status;
    machineName?: string;
    height?: number;
    className?: string;
    isOperator?: boolean;
    handleEndSessionClick?: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  status,
  sessionTitle,
  machineName,
  height = 100,
  className = "",
  isOperator = false,
  handleEndSessionClick,
}): React.ReactElement => {
  const classes = cx("machine-session-header", className);
  const history = useHistory();
  const [showNotImplementedFeatures] = useState<boolean>(false);

  const getText = (status: Status) => {
    let result = "";
    switch (status) {
      case Status.InProgress:
        result = "Live";
        break;
      case Status.Done:
        result = "Completed";
        break;
      case Status.InReview:
        result = "In Review";
        break;
      case Status.NotStarted:
        result = "Not Started";
        break;
      default:
        break;
    }
    return result;
  };

  return (
    <div className={classes} style={{ height: height }}>
      <div className="machine-session-header-button-back">
        <Button themeType="navigation" iconOnly={true} iconName="fal fa-arrow-left" onClick={() => history.goBack()} />
      </div>
      <div className="machine-session-header-info">
        <div className="status-container">
          <StatusIcon status={status} text={getText(status)} />
        </div>
        {sessionTitle && <SessionTitle editable={false} sessionTitle={sessionTitle} machineName={machineName} />}
      </div>
      <div className="machine-session-header-button">
        {status === Status.InProgress &&
          (isOperator ? (
            <Button size="large" themeType="destructive" onClick={handleEndSessionClick}>
              End Session
            </Button>
          ) : (
            showNotImplementedFeatures && (
              <Button size="large" themeType="secondary" iconLeftName="fal fa-external-link">
                Remote Machine Access
              </Button>
            )
          ))}
      </div>
    </div>
  );
};

export { MachineSessionHeader };
