import React, { FC, HTMLAttributes, ReactElement } from "react";
import { Status } from "../../../constants/enums/Status";

const CircleStatus: FC<
  {
    className?: string;
    height?: number;
    status: Status;
    width?: number;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", height = 16, status = Status.InProgress, width = 16, ...other }): ReactElement => {
  return <div className={`circle-status circle-status-${status} ${className}`} style={{ height, width }} />;
};

export { CircleStatus };
