import * as React from "react";
import { default as cx } from "classnames";

import { isValidHexOrRGBColor } from "../../helpers/validators";
const Icon: React.FC<
  {
    className?: string;
    name: string;
    color?: string;
    size?: number;
    flip?: "horizontal" | "vertical";
    width?: number;
    height?: number;
    withContainer?: boolean;
  } & React.HTMLAttributes<HTMLElement>
> = ({
  className = "",
  name = "fas fa-camera",
  color = null,
  size = null,
  flip = null,
  width = null,
  height = null,
  withContainer = true,
  ...other
}): React.ReactElement => {
  const iconStyle = cx({
    [`${name}`]: true,
    [`fa-flip-${flip}`]: flip && ["horizontal", "vertical"].indexOf(flip) >= 0,
  });

  if (color && !isValidHexOrRGBColor(color)) {
    throw new Error("Invalid color!");
  }

  const containerStyleObject: { fontSize?: number; width?: number; height?: number; lineHeight?: string } = {};
  if (size) {
    containerStyleObject.fontSize = size;
    containerStyleObject.lineHeight = `${size}px`;
  }
  if (width) {
    containerStyleObject.width = width;
  }
  if (height) {
    containerStyleObject.height = height;
  }

  let iconStyleObject = {};
  if (color) {
    iconStyleObject = {
      color: color,
    };
  }
  return withContainer ? (
    <div className="icon-container" style={containerStyleObject}>
      <i className={`${iconStyle} ${className}`} {...other} style={iconStyleObject}></i>
    </div>
  ) : (
    <i className={`${iconStyle} ${className}`} {...other} style={iconStyleObject}></i>
  );
};

export { Icon };
