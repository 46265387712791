import * as React from "react";
import { default as cx } from "classnames";

const ScrollableContainer: React.FC<
  {
    className?: string;
    width?: null | number | string | "auto" | "initial" | "inherit";
    height?: null | number | string | "auto" | "initial" | "inherit";
    maxHeight?: null | number | string | "auto" | "initial" | "inherit";
    centerContent?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  width = "auto",
  height = "auto",
  maxHeight = 810,
  centerContent = false,
}): React.ReactElement => {
  const classes = cx(
    {
      "container-scrollable": true,
      "center-content": centerContent,
    },
    className
  );

  const style: any = {};
  if (width !== null) {
    style.width = width;
  }
  if (height !== null) {
    style.height = height;
  }

  if (maxHeight !== null) {
    style.maxHeight = maxHeight;
  }

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export { ScrollableContainer };
