function ensure<T>(argument: T | undefined | null, message: string = "Object is missing"): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

const isRenderableImage = (extension: string) => {
  if (extension === null) {
    return false;
  }
  return extension === "png" || extension === "jpg" || extension === "jpeg";
};

export { isRenderableImage };
export default ensure;
