import * as React from "react";
import moment from "moment";
import { IArcadiaProject, IArcadiaSession } from "@types";
import { Tabs } from "../Tabs";
import { ITab } from "@types";
import { Typography, Color } from "../Common/Typography";
import { CardSession } from "../CardSession";
import { useHistory } from "react-router-dom";
import { sortingFunctionSessions, sortingFunctionSessionsReverse } from "../../helpers/sorting";

const ProjectsSidebar: React.FC<
  {
    className?: string;
    projects: IArcadiaProject[];
    isManager?: boolean | null;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", projects, isManager }): React.ReactElement => {
  const history = useHistory();
  const renderSessions = (upcoming: boolean) => {
    let hasSession = false;
    const defaultValue = (
      <Typography className="ml1-5 mt1-5" color={Color.Secondary}>
        There are no recent sessions.
      </Typography>
    );
    let sessions: IArcadiaSession[] = [];
    projects.forEach((p) => {
      if (p.sessions) {
        const newSessions = p.sessions.map((s) => ({ ...s, projectId: p.arcadiaProjectId }));
        sessions = sessions.concat(newSessions);
      }
    });
    if (upcoming) {
      sessions = sessions.filter((s) => s.status != 4); //filter sessions with completed status
      sessions.sort(sortingFunctionSessions);
      sessions = sessions.filter((s) => moment(s.plannedDate!) >= moment());
      if (isManager) {
        sessions.forEach((s) => {
          if (s.status === 1) {
            s.sessionImageUrl =
              "https://microlutionarcadia.blob.core.windows.net/images/55e07698bca8e4aa1761121600c818e0.jpg";
          }
        });
      }
    } else {
      sessions.sort(sortingFunctionSessionsReverse);
      sessions = sessions.filter((s) => moment(s.plannedDate!) < moment());
    }
    const result = (
      <div>
        {sessions.map((session) => {
          const proj = projects.find((p) => p.arcadiaProjectId! === session.projectId!);
          if (!session || !proj) {
            return "";
          } else {
            if (!hasSession) {
              hasSession = true;
            }
          }
          return (
            <CardSession
              key={session.sessionId}
              imageUrl={session.sessionImageUrl!}
              sessionName={session.sessionName!}
              projectName={proj.projectName!}
              projectId={proj.arcadiaProjectId!}
              startDate={session.plannedDate}
              onClick={
                session.status == 3
                  ? () => history.push(`/livesession/${proj.arcadiaProjectId}/${session.sessionId}`)
                  : session.status == 4
                  ? () => history.push(`/sessionreplay/${proj.arcadiaProjectId}/${session.sessionId}`)
                  : () => history.push(`/prepare/${proj.arcadiaProjectId}/${session.sessionId}`)
              }
              isLive={session.status == 3}
            />
          );
        })}
      </div>
    );
    if (hasSession) {
      return result;
    } else {
      return defaultValue;
    }
  };

  const tabs: ITab[] = [
    {
      name: "Upcoming Sessions",
      content: renderSessions(true),
    },
    {
      name: "Recent Sessions",
      content: renderSessions(false),
    },
  ];
  return (
    <aside className={`projects-sidebar ${className}`}>
      {projects && projects.length > 0 ? <Tabs tabs={tabs} small={true} /> : ""}
    </aside>
  );
};

export { ProjectsSidebar };
