import React, { FC, ReactElement } from "react";
import { ModalWithIcon } from "../ModalWithIcon";
const checkSessionSvg = require("../../resources/svg/checkSession.svg") as string;

const ModalPrepareSession: FC<{ clickHandler: Function; checked: boolean; checkHandler: Function }> = ({
  clickHandler,
  checked,
  checkHandler,
}): ReactElement => {
  return (
    <ModalWithIcon
      svg={checkSessionSvg}
      className="modal-prepare-session"
      title="Prepare for your session"
      message="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
      buttonText="Start Prep"
      clickHandler={clickHandler}
      withCheckbox={true}
      checkboxText="Don't show me this again"
      checked={checked}
      checkHandler={checkHandler}
    ></ModalWithIcon>
  );
};

export { ModalPrepareSession };
