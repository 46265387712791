import React, { FC, ReactElement, useEffect, useState } from "react";
import { INewProjectData, INewProjectDataPart, INewProjectDataFeaturesData, IProcessParameter } from "@types";
import { Color, ElementType, Typography } from "../Common/Typography";
import uniqid from "uniqid";
import { Button } from "../Button";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { Card } from "../Card";
import LineTo from "react-lineto";
import { FeatureProjectSection } from "./FeatureProjectSection";
import { v4 as uuidv4 } from "uuid";

const FeatureProject: FC<{
  formData: INewProjectData;
  processes: IProcessParameter[];
  onClickAfterPart: (values: Map<string, INewProjectDataFeaturesData[]>) => void;
  onClickBack: () => void;
}> = ({ formData, processes, onClickAfterPart, onClickBack }): ReactElement => {
  const [featureData, setFeatureData] = useState<Map<string, INewProjectDataFeaturesData[]>>(formData.feature);
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [currentPart, setCurrentPart] = useState<INewProjectDataPart | null>(null);
  const [showAdvancedNotice, setShowAdvancedNotice] = useState<boolean>(true);
  const [showMultipleNotice, setShowMultipleNotice] = useState<boolean>(true);
  const [changesTracker, setChangesTracker] = useState<number>(0);

  const defaultFeature: INewProjectDataFeaturesData = {
    advancedOptions: false,
    measure: "mm/deg/sec",
    featureName: "New Feature",
  };

  useEffect(() => {
    setCurrentPart(formData.part[0]);
  }, []);

  useEffect(() => {
    let canContinue = true;
    for (const part of formData.part) {
      const featurePart = featureData.get(part.identifier!);

      if (!featurePart) {
        canContinue = false;
        break;
      }

      const atLeastOneIncomplete = featurePart.find((feature) => !feature.process);

      if (atLeastOneIncomplete) {
        canContinue = false;
        break;
      }
    }

    setCanContinue(canContinue);
    setChangesTracker(changesTracker + 1);
  }, [featureData, formData, setCanContinue]);

  useEffect(() => {
    const newMap = new Map<string, INewProjectDataFeaturesData[]>(featureData);
    for (const part of formData.part) {
      const featurePart = featureData.get(part?.identifier!);
      if (featurePart) {
        continue;
      }
      const newFeature = { ...defaultFeature };
      newFeature.featureId = uuidv4();
      newMap.set(part?.identifier!, [newFeature]);
    }

    setFeatureData(newMap);
    setChangesTracker(changesTracker + 1);
  }, []);

  const parseFeature = (featureIndex: number, name?: string, process?: string) => {
    if (name && process) {
      return `${process}, ${name}`;
    } else if (name) {
      return name;
    } else if (process) {
      return process;
    }

    return `Feature ${featureIndex + 1}`;
  };

  const partDone = (featurePart: INewProjectDataPart) => {
    const feature = featureData.get(featurePart.identifier!);
    if (!feature) {
      return false;
    }

    return feature.length > 0 && canCreateMore(featurePart);
  };

  const canCreateMore = (featurePart: INewProjectDataPart) =>
    !featureData.get(featurePart.identifier!)?.find((feature) => !feature.process);

  const onClickAddAnotherFeature = (featurePart: INewProjectDataPart) => {
    const newMap = new Map(featureData);
    const newFeatures = newMap.get(featurePart.identifier!) || [];
    const newFeature = { ...defaultFeature };
    (newFeature.featureId = uuidv4()), newFeatures.push(newFeature);
    newMap.set(featurePart.identifier!, newFeatures);
    setFeatureData(newMap);
    setCurrentPart(featurePart);
    setShowMultipleNotice(false);
    setChangesTracker(changesTracker + 1);
  };

  return (
    <>
      <div className="modal-new-project-attribute-features">
        <div className="modal-new-project-attribute-features-title">
          <Typography type={ElementType.eyebrow} color={Color.Secondary}>
            PARTS
          </Typography>
        </div>
        <Card
          className="card-add-part-attribute-container modal-new-project-attribute-features-steps"
          width={305}
          height="auto"
          hover={false}
        >
          {formData.part.map((part, partIndex) => {
            const openPart = () => {
              setCurrentPart(part);
              setChangesTracker(changesTracker + 1);
            };

            return (
              <>
                <div
                  className="card-add-part-attribute-container-header pointer"
                  onClick={openPart}
                  onKeyDown={openPart}
                  tabIndex={0}
                  role="button"
                >
                  <div
                    className={`modal-new-project-attribute-features-steps-indicator modal-new-project-attribute-features-steps-indicator-top circle-step-top-${partIndex}`}
                  ></div>
                  <div
                    className={`modal-new-project-attribute-features-steps-indicator modal-new-project-attribute-features-steps-indicator-bottom circle-step-bottom-${partIndex}`}
                  ></div>
                  <Icon
                    name={`${partDone(part) ? "fas" : "far"} fa-circle`}
                    color={styleVariables.color_orange}
                    size={18}
                    id={`circle-step-${partIndex}`}
                  />
                  <Typography className="card-add-part-attribute-container-header-title" type={ElementType.display}>
                    {part.name}
                  </Typography>
                </div>
                {featureData.get(part?.identifier!)?.map((featurePart, featureIndex) => {
                  const goToFeature = () => {
                    setCurrentPart(part);
                    setChangesTracker(changesTracker + 1);
                    setTimeout(() => {
                      const featureElement = document.getElementById(`feature-title-${partIndex}-${featureIndex}`);
                      if (featureElement) {
                        featureElement.scrollIntoView();
                      }
                    }, 0);
                  };

                  return (
                    <div
                      className="modal-new-project-attribute-features-steps-list"
                      key={uniqid()}
                      onClick={goToFeature}
                      onKeyDown={goToFeature}
                      tabIndex={0}
                      role="button"
                    >
                      <Icon name="far fa-circle" color={styleVariables.color_orange} size={8} />
                      <Typography type={ElementType.body}>
                        {parseFeature(featureIndex, featurePart.featureName, featurePart.process)}
                      </Typography>
                    </div>
                  );
                })}
                <div
                  className="pointer modal-new-project-attribute-features-steps-list"
                  key={uniqid()}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {
                    onClickAddAnotherFeature(part);
                  }}
                  onClick={() => {
                    onClickAddAnotherFeature(part);
                  }}
                >
                  <Icon name="far fa-circle" color={styleVariables.color_orange} size={8} />
                  <Typography type={ElementType.body} color={Color.Secondary}>
                    Add Feature
                  </Typography>
                </div>
              </>
            );
          })}
        </Card>
      </div>
      {formData.part.map((part, index) => {
        const changeSelectedPart = () => {
          setCurrentPart(part);
          setChangesTracker(changesTracker + 1);
        };

        const uniq = part.identifier || uniqid();

        return (
          <div
            className={`${part !== currentPart ? "modal-new-project-section modal-new-project-form" : ""} ${
              index > 0 ? "modal-new-project-form-mt" : ""
            } ${currentPart !== part ? "modal-new-project-features-collapsed" : ""}`}
            key={`${uniq}-buttons`}
            onClick={changeSelectedPart}
            onKeyDown={changeSelectedPart}
            role="button"
            tabIndex={0}
          >
            {part !== currentPart && partDone(part) && (
              <div className="modal-new-project-features-collapsed-completed">
                <div className="modal-new-project-features-collapsed-completed-header">
                  <div className="modal-new-project-features-collapsed-completed-header-name">
                    <Icon
                      name={`${partDone(part) ? "fas" : "far"} fa-circle`}
                      color={styleVariables.color_orange}
                      size={18}
                    />
                    <Typography type={ElementType.display}>{part.name}</Typography>
                  </div>
                  <Icon color={styleVariables.color_orange} name="far fa-check" size={16} />
                </div>
                {featureData
                  .get(part?.identifier!)
                  ?.filter((featurePart) => featurePart.process)
                  .map((featurePart, featureIndex) => {
                    return (
                      <div className="modal-new-project-features-collapsed-completed-list" key={uniqid()}>
                        <Icon name="far fa-circle" color={styleVariables.color_orange} size={8} />
                        <Typography type={ElementType.body}>
                          {parseFeature(featureIndex, featurePart.featureName, featurePart.process)}
                        </Typography>
                      </div>
                    );
                  })}
                {canCreateMore(part) && (
                  <div
                    className="modal-new-project-features-collapsed-completed-list"
                    key={uniqid()}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {
                      onClickAddAnotherFeature(part);
                    }}
                    onClick={() => {
                      onClickAddAnotherFeature(part);
                    }}
                  >
                    <Icon name="far fa-circle" color={styleVariables.color_orange} size={8} />
                    <Typography type={ElementType.body} color={Color.Secondary}>
                      Add Feature
                    </Typography>
                  </div>
                )}
              </div>
            )}
            {part !== currentPart && !partDone(part) && (
              <>
                <Typography type={ElementType.display}>{part.name}</Typography>
                <Icon color={styleVariables.color_white} name="far fa-minus" size={16} />
              </>
            )}
            {part === currentPart && (
              <FeatureProjectSection
                changesTracker={changesTracker}
                currentPart={currentPart}
                defaultFeature={defaultFeature}
                featureData={featureData}
                formData={formData}
                index={index}
                onClickAddAnotherFeature={onClickAddAnotherFeature}
                part={part}
                processes={processes}
                setChangesTracker={setChangesTracker}
                setCurrentPart={setCurrentPart}
                setFeatureData={setFeatureData}
                setShowAdvancedNotice={setShowAdvancedNotice}
                showAdvancedNotice={showAdvancedNotice}
                showMultipleNotice={showMultipleNotice}
                uniq={uniq}
              />
            )}
          </div>
        );
      })}
      <div className="modal-new-project-form-row">
        <Button className="modal-new-project-button" size="large" themeType="primary" onClick={onClickBack}>
          Back
        </Button>
        <Button
          className="modal-new-project-button"
          disabled={!canContinue}
          size="large"
          themeType="clear"
          onClick={() => onClickAfterPart(featureData)}
        >
          Next
        </Button>
      </div>
      {formData.part.slice(1).map((part, partIndex) => (
        <LineTo
          borderColor={styleVariables.color_orange}
          borderStyle="dotted"
          borderWidth={2}
          from={`circle-step-bottom-${partIndex}`}
          key={`${changesTracker}-${uniqid()}`}
          to={`circle-step-top-${partIndex + 1}`}
          within="modal-new-project-attribute-features"
          zIndex={999}
        />
      ))}
    </>
  );
};

export { FeatureProject };
