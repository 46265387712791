import { IArcadiaProject, IArcadiaSession } from "@types";
import axios from "axios";

const getAllGroupProjects = () => {
  return axios.get(`/projects/project`);
};

const getAllManagerGroupProjects = () => {
  return axios.get(`/projects/admin/projects`);
};

const getProjectById = (id: string) => {
  return axios.get(`/projects/project/${id}`);
};

// const getAllGroupSessions = () => {
//   return axios.get(`/projects/session`);
// };

const createNewProject = (project: IArcadiaProject, templateId?: string) => {
  return axios.post(`/projects?templateId=${templateId}`, project);
};

const updateProject = (project: IArcadiaProject) => {
  return axios.put(`/projects/updateproject/${project.arcadiaProjectId!}`, project);
};

const createNewSession = (projectId: string, session: IArcadiaSession) => {
  return axios.post(`/sessions/session/${projectId}`, session);
};

const updateSession = (projectId: string, sessionId: string, session: IArcadiaSession) => {
  return axios.put(`/sessions/updatesession/${projectId}/${sessionId}`, session);
};

const deleteSession = (projectId: string, sessionId: string) => {
  return axios.delete(`/sessions/session/${projectId}/${sessionId}`);
};

const getVideoUploadsForSession = (projectId: string, sessionId: string) => {
  return axios.get(`/sessions/videouploads/${projectId}/${sessionId}`);
};

const getBookmarksForSession = (projectId: string, sessionId: string) => {
  return axios.get(`/sessions/bookmarks/${projectId}/${sessionId}`);
};

const createBookmark = (projectId: string, sessionId: string, bookmark: string) => {
  return axios.post(`/sessions/bookmarks/${projectId}/${sessionId}`, { bookmark: bookmark });
};

const getAllTasks = (projectId: string) => {
  return axios.get(`/projects/session/${projectId}/tasks`);
};

const getAllScheduledSessions = () => {
  return axios.get(`/sessions/publicsessions/scheduled`);
};

const getPublicSession = (sessionId: string) => {
  return axios.get(`/sessions/publicSession/${sessionId}`);
};

const startSession = (projectId: string, sessionId: string) => {
  return axios.put(`/sessions/startsession/${projectId}/${sessionId}`);
};

const stopSession = (projectId: string, sessionId: string, notes: string) => {
  return axios.put(`/sessions/stopsession/${projectId}/${sessionId}`, {
    notes: notes,
  });
};

const getZoomMeeting = (projectId: string, sessionId: string) => {
  return axios.get(`/sessions/zoom/${projectId}/${sessionId}`);
};

const editZoomMeeting = (projectId: string, sessionId: string, meetingNumber: string) => {
  return axios.put(`/sessions/zoom/${projectId}/${sessionId}`, {
    meetingNumber,
  });
};

const assignOperator = (operatorId: string, sessionId: string) => {
  return axios.put(`/sessions/assignoperator?operatorId=${operatorId}&sessionId=${sessionId}`);
};

const confirmSession = (projectId: string, sessionId: string) => {
  return axios.put(`/sessions/confirmsession?projectId=${projectId}&sessionId=${sessionId}`);
};

export default {
  getAllGroupProjects,
  getAllManagerGroupProjects,
  getProjectById,
  createNewProject,
  updateProject,
  // getAllGroupSessions,
  getVideoUploadsForSession,
  getBookmarksForSession,
  createBookmark,
  createNewSession,
  updateSession,
  deleteSession,
  getAllTasks,
  getAllScheduledSessions,
  getPublicSession,
  startSession,
  stopSession,
  editZoomMeeting,
  getZoomMeeting,
  assignOperator,
  confirmSession,
};
