import React, { FC, ReactElement, useState, useEffect } from "react";
import { TopNavigation, ModalPrepareSession, Sidebar, AccountCard } from "@namespace/components";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import "@namespace/components/src/styles/_index.scss";
import PrepareSession from "./pages/PrepareSession";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import LiveSessionPage from "./pages/LiveSession/LiveSessionPage";
import SessionReplayPage from "./pages/SessionReplay/SessionReplayPage";
import "./App.scss";
import { IArcadiaUserInfo, IFavorite } from "@types";
import useUserBalance from "./hooks/API/useUserBalance";
import AccountPage from "./pages/AccountPage/AccountPage";
import useUser from "./hooks/API/useUser";
import UserService from "./services/UserService";
import useNotification from "./hooks/useNotification";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";

const App: FC<{
  logout: () => void;
}> = ({ logout }): ReactElement => {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const [showAccountCard, setShowAccountCard] = useState<boolean>(false);
  const [favorites, setFavorites] = useState<IFavorite[]>([]);
  const location = useLocation();
  const history = useHistory();
  const { userBalances, fetchUserBalances } = useUserBalance();
  const { user, fetchUser, setUser } = useUser();

  const notification = useNotification();

  useEffect(() => {
    fetchUserBalances();
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const handleClickFavorite = (favorite: IFavorite) => {
    let favoriteOld = favorites.find((f) => f.projectId === favorite.projectId);
    if (favoriteOld) {
      const filtered = favorites.filter((f) => f.projectId !== favorite.projectId);
      setFavorites(filtered);
    } else {
      setFavorites([...favorites, favorite]);
    }
  };

  const handleUpdateUserInfo = async (updatedUser: IArcadiaUserInfo) => {
    try {
      await UserService.updateUser(updatedUser, user!);
      notification("User information updated", "neutral");
      setUser(updatedUser);
    } catch (error) {
      console.log(error);
    }
  };

  const fixedHeader = ["/prepare"].includes(location.pathname);

  return (
    <>
      {showAccountCard && user && (
        <AccountCard
          name={user?.displayName!}
          company={user?.companyName!}
          onAccountClick={() => {
            setShowAccountCard(false);
            history.push("/account");
          }}
          onLogoutClick={() => {
            logout();
            setShowAccountCard(false);
          }}
        />
      )}
      <Sidebar balances={userBalances} favorites={favorites} openSidebar={openSidebar} toggleSidebar={toggleSidebar} />
      <div className={`App ${fixedHeader ? "App-fixed-header" : ""}`}>
        <TopNavigation
          fixed={fixedHeader}
          toggleSidebar={toggleSidebar}
          showAccountCard={showAccountCard}
          onUserClicked={() => {
            setShowAccountCard((old) => !old);
          }}
        />
        <Switch>
          <Route path="/prepare/:projectId/:sessionId">
            <PrepareSession
              isManager={
                user && user.roles && user.roles.length > 0 && user.roles.indexOf("Manager.All") >= 0 ? true : false
              }
              userGroupId={user?.groupId!}
            />
          </Route>
          <Route path="/prepare/:projectId">
            <PrepareSession
              isManager={
                user && user.roles && user.roles.length > 0 && user.roles.indexOf("Manager.All") >= 0 ? true : false
              }
              userGroupId={user?.groupId!}
            />
          </Route>
          <Route path="/livesession/:projectId/:sessionId">
            <LiveSessionPage
              isOperator={
                user && user.roles && user.roles.length > 0 && user.roles.indexOf("Operator.All") >= 0 ? true : false
              }
            />
          </Route>
          <Route path="/sessionreplay/:projectId/:sessionId">
            <SessionReplayPage />
          </Route>
          <Route path="/preparesession">
            <ModalPrepareSession
              clickHandler={() => {
                history.push("/prepare");
              }}
              checked={false}
              checkHandler={() => {}}
            />
          </Route>
          <Route path="/project/:id/:onboarding?">
            <Project favorites={favorites} onFavoriteClick={handleClickFavorite} />
          </Route>
          <Route path="/account">
            <AccountPage
              initialUser={user!}
              balances={userBalances}
              onCancelClick={() => history.push("/")}
              onUpdateClick={handleUpdateUserInfo}
            />
          </Route>
          <Route>
            <Projects setFavorites={setFavorites} favorites={favorites} onFavoriteClick={handleClickFavorite} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default withAITracking(reactPlugin, App);
