import React, { FC, FormEvent, ReactElement } from "react";
import { Typography, Card, Thumbnail, Textarea, Button } from "@namespace/components";
import { Color, ElementType } from "@namespace/components/src/components/Common/Typography";
import {
  IArcadiaMaterial,
  IArcadiaSession,
  IMachineRun,
  IPrepareSessionDataEquipment,
  IPrepareSessionDataGoals,
  IPrepareSessionDataSchedule,
} from "@types";
import moment from "moment";
import uniqid from "uniqid";

const ConfirmSession: FC<{
  notes: string;
  setNotes: (notes: string) => void;
  handleCancelSession: () => void;
  runs: IMachineRun[];
  projectId: string;
  session: IArcadiaSession;
  materials: IArcadiaMaterial[];
  goals: IPrepareSessionDataGoals;
  schedule: IPrepareSessionDataSchedule;
  equipment: IPrepareSessionDataEquipment;
  planning: Map<string, IMachineRun[]>;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
}> = ({
  setCurrentStep = () => {},
  notes,
  setNotes,
  handleCancelSession,
  schedule,
  goals,
  equipment,
  planning,
  disabled,
}): ReactElement => {
  const goToGoals = () => {
    setCurrentStep(1);
  };

  const goToSchedule = () => {
    setCurrentStep(2);
  };

  const goToEquipment = () => {
    setCurrentStep(3);
  };

  const updateNotes = (event: FormEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    setNotes(target.value);
  };

  // TODO: Change
  const fieldsFirstSection = [
    {
      id: "developFeatureInFlatStock",
      value: "Develop feature in flat stock",
    },
    {
      id: "transferFeatureToPart",
      value: "Transfer feature to part",
    },
    {
      id: "optimizeProcessForProduction",
      value: "Optimize process for production",
    },
    {
      id: "productionRun",
      value: "Production run",
    },
    // @ts-ignore
  ].filter((goal) => goals[goal.id]);

  const fieldsSecondSection = [
    {
      id: "developOptimizeScanhead",
      value: "Develop / optimize scanhead job(s)",
    },
    {
      id: "developLaserParameters",
      value: "Develop / optimize laser parameters",
    },
    {
      id: "developOtherParameters",
      value: "Develop / optimize other process parameters",
    },
    // @ts-ignore
  ].filter((goal) => goals[goal.id]);

  const fieldsThirdSection = [
    {
      id: "evaluateWorkHolding",
      value: "Evaluate Work Holding",
    },
    {
      id: "provePartProgram",
      value: "Prove / debug part program",
    },
    {
      id: "developMachineMotion",
      value: "Develop / optimize machine motion",
    },
    {
      id: "improveProgramLogic",
      value: "Improve program logic",
    },
    // @ts-ignore
  ].filter((goal) => goals[goal.id]);

  return (
    <Card width={650} height={"auto"} hover={false} className="confirm-session">
      <Typography type={ElementType.subtitle}>Session Summary</Typography>
      <div className="confirm-session-date">
        <div className="confirm-session-date-col">
          {schedule.sessionDate ? (
            <>
              <Typography type={ElementType.h3}>{moment(schedule.sessionDate).format("MMM Do, YYYY")}</Typography>
              <Typography type={ElementType.bodySmall}>8am - 6pm CST</Typography>
            </>
          ) : (
            <Typography color={Color.OffWhite}>Choose a date</Typography>
          )}
        </div>
        <Typography type={ElementType.inline} color={Color.Secondary} className="pointer" onClick={goToSchedule}>
          Edit
        </Typography>
      </div>
      {schedule && schedule.machine ? (
        <div className="confirm-session-machine">
          <Thumbnail
            backgroundImageUrl={schedule.machine.machiningStation?.imageURL || ""}
            height={96}
            width={96}
            title={schedule.machine.cellType!}
          />
          <div className="confirm-session-machine-minirow">
            <div className="confirm-session-machine-col">
              <Typography type={ElementType.h5}>{schedule.machine.cellType!}</Typography>
              <Typography type={ElementType.bodySmall} color={Color.OffWhite}>
                {schedule.machine.cellDescription}
              </Typography>
            </div>
            <Typography
              className="confirm-session-machine-minirow-edit pointer"
              type={ElementType.inline}
              color={Color.Secondary}
              onClick={goToSchedule}
            >
              Edit
            </Typography>
          </div>
        </div>
      ) : (
        <div className="session-machine-not-selected">
          <Typography color={Color.OffWhite}>Choose your equipment</Typography>
          <Typography
            className="session-machine-not-selected-edit pointer"
            type={ElementType.inline}
            color={Color.Secondary}
            onClick={goToSchedule}
          >
            Edit
          </Typography>
        </div>
      )}

      {Array.from(equipment.workHoldings.entries()).map(([workHoldingId, [workHoldingData, selectedMaterials]]) => (
        <div className="confirm-session-machine-runs">
          <Thumbnail
            backgroundImageUrl={workHoldingData.imageUrls ? workHoldingData.imageUrls[0] : ""}
            height={36}
            width={36}
            title={workHoldingData.materialName!}
          />
          <div className="confirm-session-machine-runs-col">
            <Typography type={ElementType.h6} className="confirm-session-machine-runs-col-title">
              {workHoldingData.materialName}
            </Typography>
            <Typography type={ElementType.body} color={Color.OffWhite}>
              {planning.get(workHoldingId)?.length || 0} Pre-Planned Runs (more can be added during session)
            </Typography>
          </div>
          <Typography type={ElementType.inline} color={Color.Secondary} className="pointer" onClick={goToEquipment}>
            Edit
          </Typography>
        </div>
      ))}
      <div className="confirm-session-goals">
        <div className="confirm-session-goals-row">
          <Typography type={ElementType.body}>PROJECT GOALS</Typography>
          <Typography type={ElementType.inline} color={Color.Secondary} className="pointer" onClick={goToGoals}>
            Edit
          </Typography>
        </div>
        <ul className="confirm-session-goals-listing">
          {fieldsFirstSection.map((goal) => (
            <li key={uniqid()} className="confirm-session-goals-listing-element">
              {goal.value}
            </li>
          ))}
          {fieldsFirstSection.length === 0 && <li className="confirm-session-goals-listing-element">None</li>}
        </ul>

        <Typography type={ElementType.body}>FEATURE GOALS</Typography>
        <ul className="confirm-session-goals-listing">
          {fieldsSecondSection.map((goal) => (
            <li key={uniqid()} className="confirm-session-goals-listing-element">
              {goal.value}
            </li>
          ))}
          {fieldsSecondSection.length === 0 && <li className="confirm-session-goals-listing-element">None</li>}
        </ul>
        <Typography type={ElementType.body}>PART GOALS</Typography>
        <ul className="confirm-session-goals-listing">
          {fieldsThirdSection.map((goal) => (
            <li key={uniqid()} className="confirm-session-goals-listing-element">
              {goal.value}
            </li>
          ))}
          {fieldsThirdSection.length === 0 && <li className="confirm-session-goals-listing-element">None</li>}
        </ul>
      </div>
      <Typography type={ElementType.bodySmall} className="form-section-lowmargin confirm-session-notes">
        NOTES FOR OPERATORS (OPTIONAL)
      </Typography>
      <Textarea value={notes} width="100%" className="form-section-textarea" onChange={updateNotes} />
      {/* <hr className="divider confirm-session-divider" />
      <div className="confirm-session-checkbox">
        <Checkbox checked={confirmCheck} onChange={toggleCheck} id="confirm-session-checkbox-selector" />{" "}
        <Typography
          type={ElementType.body}
          htmlFor="confirm-session-checkbox-selector"
          className="confirm-session-checkbox-label"
        >
          I have carefully reviewed the session details I've provided. I understand that Microlution will make a
          best-effort to review and prepare for the session, but that no guarantee is provided.
        </Typography>
      </div> */}
      <hr className="divider confirm-session-divider" />
      <div className="cancel-session">
        <Button
          themeType="simple"
          onClick={handleCancelSession}
          size="small"
          iconSize={12}
          iconLeftName="far fa-trash"
          color={"#8f92a1"}
        >
          Cancel this Session
        </Button>
      </div>
    </Card>
  );
};

export default ConfirmSession;
export { ConfirmSession };
