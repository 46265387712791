import React, { FC, useRef } from "react";
import { useTransition, animated } from "react-spring";

const visibleStyle = { height: "auto", opacity: 1, overflow: "visible" };
const hiddenStyle = { opacity: 0, height: 0, overflow: "hidden" };

const getElementHeight = (ref: React.MutableRefObject<HTMLDivElement | null>) =>
  ref.current ? ref.current.getBoundingClientRect().height : 0;

// @ts-ignore
const SlideToggleContent: FC<{
  isVisible: boolean;
  forceSlideIn?: boolean;
}> = ({ isVisible, children, forceSlideIn = false }): (JSX.Element | null)[] => {
  const isVisibleOnMount = useRef(isVisible && !forceSlideIn);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);

  // @ts-ignore
  const transitions = useTransition(isVisible, null, {
    enter: () => async (next: Function, cancel: Function) => {
      if (innerRef !== null) {
        const height = getElementHeight(innerRef);
        cancel();
        await next({ height, opacity: 1, overflow: "hidden" });
        await next(visibleStyle);
      }
    },
    leave: () => async (next: Function, cancel: Function) => {
      const height = getElementHeight(containerRef);
      cancel();
      await next({ height, overflow: "hidden" });
      await next(hiddenStyle);

      isVisibleOnMount.current = false;
    },
    from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
    unique: true,
  });

  return transitions.map(({ item: show, props: springProps, key }) => {
    if (show) {
      return (
        <animated.div ref={containerRef} key={key} style={springProps}>
          <div className="slideToggleInner" ref={innerRef}>
            {children}
          </div>
        </animated.div>
      );
    }

    return null;
  });
};

export { SlideToggleContent };
