import React, { FC, HTMLAttributes, MouseEvent, ReactElement } from "react";
import { default as cx } from "classnames";

const Modal: FC<
  {
    above?: JSX.Element;
    below?: JSX.Element;
    className?: string;
    darkOverlay?: boolean;
    height?: number | string;
    modalInnerContainerClassName?: string;
    onCloseModal?: () => void;
    overlayClassName?: string;
    attachmentClassName?: string;
    width: number | string;
    showAttachment?: boolean;
    attachmentWidth?: number | string;
    attachmentContent?: ReactElement;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  above = null,
  below = null,
  children,
  className = "",
  darkOverlay = false,
  modalInnerContainerClassName = "",
  onCloseModal = () => {},
  overlayClassName = "",
  attachmentClassName = "",
  width = 100,
  height,
  showAttachment = false,
  attachmentWidth,
  attachmentContent,
}): ReactElement => {
  const closeModal = (event: MouseEvent<HTMLDivElement>) => {
    let overlayClicked = false;
    if (event) {
      const target = event.target as HTMLDivElement;
      overlayClicked = target.className === "overlay";
    }

    if (overlayClicked) {
      onCloseModal();
    }
  };

  const overlayClasses = cx(
    {
      overlay: true,
      "overlay-dark": darkOverlay,
    },
    overlayClassName
  );

  const modalClasses = cx(
    {
      modal: true,
    },
    className
  );

  const modalContainerClasses = cx(
    {
      "modal-container": true,
    },
    modalInnerContainerClassName
  );

  const attachmentClasses = cx(
    {
      "modal-attachment": true,
      "modal-attachment-hidden": showAttachment,
    },
    attachmentClassName
  );

  return (
    <div className={overlayClasses} aria-hidden="true" onClick={closeModal} data-testid="modal-overlay">
      <div className={modalContainerClasses}>
        {above}
        <div className="modal-attachment-container">
          {attachmentContent && (
            <div
              className={attachmentClasses}
              style={{
                width: showAttachment ? attachmentWidth : 0,
                transition: "width 0.3s ease, left 0.3s ease",
                left: attachmentWidth,
              }}
            >
              {attachmentContent}
            </div>
          )}
          <div className={modalClasses} style={{ width: width, height: height }} data-testid="modal-content">
            {children}
          </div>
        </div>
        {below}
      </div>
    </div>
  );
};

export { Modal };
