import moment from "moment";
import { IArcadiaFileUpload, IArcadiaSession } from "@types";

const sortingFunctionFileUploads = (a: IArcadiaFileUpload, b: IArcadiaFileUpload) => {
  // Turn your strings into dates, and then subtract them
  // to get a value that is either negative, positive, or zero.
  return moment(b.correlationTimestamp) === moment(a.correlationTimestamp)
    ? 0
    : moment(b.correlationTimestamp) > moment(a.correlationTimestamp)
    ? -1
    : 1;
};

const sortingFunctionSessions = (a: IArcadiaSession, b: IArcadiaSession) => {
  return moment(b.plannedDate) === moment(a.plannedDate) ? 0 : moment(b.plannedDate) > moment(a.plannedDate) ? -1 : 1;
};

const sortingFunctionSessionsReverse = (a: IArcadiaSession, b: IArcadiaSession) => {
  return moment(b.plannedDate) === moment(a.plannedDate) ? 0 : moment(b.plannedDate) > moment(a.plannedDate) ? 1 : -1;
};

export { sortingFunctionFileUploads, sortingFunctionSessions, sortingFunctionSessionsReverse };
