import React, { FC, ReactElement } from "react";
import { Typography, FormSection, SelectionInput } from "@namespace/components";
import { ElementType, FontWeight } from "@namespace/components/src/components/Common/Typography";
import { IPrepareSessionDataGoals } from "@types";
import { fieldsFirstSection, fieldsSecondSection, fieldsThirdSection } from "./goals";

const GoalsSession: FC<{
  values: IPrepareSessionDataGoals;
  setValues: (values: IPrepareSessionDataGoals) => void;
  disabled: boolean;
}> = ({ values, setValues, disabled }): ReactElement => {
  return (
    <div style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}>
      <form>
        <FormSection
          secondary={false}
          sectionTitle={
            <>
              Describe your{" "}
              <Typography type={ElementType.inline} fontWeight={FontWeight.Bold}>
                project goal
              </Typography>{" "}
              for this machine session
            </>
          }
          sectionSubTitle="Please select all that apply."
        >
          {fieldsFirstSection.map((field) => {
            const changeValues = () => {
              const vals = Object.assign({}, values);
              const c = field.id as keyof IPrepareSessionDataGoals;
              // @ts-ignore
              vals[c] = !vals[field.id as keyof IPrepareSessionDataGoals];
              setValues(vals);
            };
            return (
              <SelectionInput
                text={field.value}
                handleClick={changeValues}
                checked={values[field.id as keyof IPrepareSessionDataGoals] as boolean}
                width="100%"
                name={field.id}
                key={field.id}
              />
            );
          })}
        </FormSection>
        <FormSection
          secondary={false}
          className="form-section-separator"
          sectionTitle="What development do you expect for this session?"
          sectionSubTitle="Please select all that apply."
        >
          <Typography type={ElementType.label} className="form-section-doublemargin">
            FEATURE GOAL
          </Typography>
          <div>
            {fieldsSecondSection.map((field) => {
              const changeValues = () => {
                const vals = Object.assign({}, values);
                // @ts-ignore
                vals[field.id] = !vals[field.id as keyof IPrepareSessionDataGoals];
                setValues(vals);
              };

              return (
                <SelectionInput
                  text={field.value}
                  handleClick={changeValues}
                  checked={values[field.id as keyof IPrepareSessionDataGoals] as boolean}
                  width="100%"
                  name={field.id}
                  key={field.id}
                />
              );
            })}
          </div>
          <Typography type={ElementType.label} className="form-section-doublemargin">
            PART GOAL
          </Typography>
          <div>
            {fieldsThirdSection.map((field) => {
              const changeValues = () => {
                const vals = Object.assign({}, values);
                // @ts-ignore
                vals[field.id] = !vals[field.id as keyof IPrepareSessionDataGoals];
                setValues(vals);
              };

              return (
                <SelectionInput
                  text={field.value}
                  handleClick={changeValues}
                  checked={values[field.id as keyof IPrepareSessionDataGoals] as boolean}
                  width="100%"
                  name={field.id}
                  key={field.id}
                />
              );
            })}
          </div>
        </FormSection>
      </form>
    </div>
  );
};

export default GoalsSession;
export { GoalsSession };
