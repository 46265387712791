import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";

const ModalAddProgram: FC<
  {
    className?: string;
    title?: string;
    onCloseModal: () => void;
    onDone: (name: string) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", title = "", onCloseModal, onDone }): ReactElement => {
  const [name, setName] = useState<string>("");

  const updateName = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setName(target.value);
  };

  const isNameValid = (value: string) => {
    const regex = RegExp(/^[a-zA-Z_][a-zA-Z0-9_-]*[a-zA-Z0-9]$/);
    return regex.test(value) && value !== "master" && value.length <= 80;
  };

  const handleDoneClick = () => {
    onDone(name);
  };

  return (
    <Modal className={`modal-add-program ${className}`} width={598} onCloseModal={onCloseModal}>
      <Typography className="modal-add-program-title" type={ElementType.h4}>
        {title}
      </Typography>
      <Input
        label={"Program Name"}
        onChange={updateName}
        height={40}
        placeholder={"Program Name"}
        value={name}
        width={"100%"}
      />
      <div className="modal-add-program-buttons">
        <Button size="large" themeType="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button disabled={!isNameValid(name)} size="large" themeType="clear" onClick={handleDoneClick}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export { ModalAddProgram };
