import * as React from "react";
import { Card } from "../Card";
import { Icon } from "../Icon";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";

const CardAddPartAttribute: React.FC<
  {
    className?: string;
    description: string;
    title: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", description = "", title = "" }): React.ReactElement => (
  <Card className={`card-add-part-attribute-container ${className}`} width={305} height={"auto"} hover={false}>
    <div className="card-add-part-attribute-container-header">
      <Icon name="far fa-lightbulb" color={styleVariables.color_orange} size={24} />
      <Typography className="card-add-part-attribute-container-header-title" type={ElementType.display}>
        {title}
      </Typography>
    </div>
    <Typography type={ElementType.body} color={Color.OffWhite}>
      {description}
    </Typography>
  </Card>
);

export { CardAddPartAttribute };
