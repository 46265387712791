import React, { FC, ReactElement, useState } from "react";
import { Input, Typography, ElementType, Select, Button } from "@namespace/components";
import { IArcadiaUserInfo } from "@types";
import countries from "./countries";
import states from "./states";

import "./profileTab.scss";

const ProfileTab: FC<{
  initialUser: IArcadiaUserInfo;
  onCancelClick: () => void;
  onUpdateClick: (user: IArcadiaUserInfo) => void;
}> = ({ initialUser, onCancelClick, onUpdateClick }): ReactElement => {
  const [user, setUser] = useState<IArcadiaUserInfo>(initialUser);

  const countriesOptions = countries.map((c) => ({ label: c, value: c }));
  const statesOptions = states.map((c) => ({ label: c, value: c }));

  const changeFirstName = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.givenName = value;
    setUser(updatedUser);
  };

  const changeLastName = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.surname = value;
    setUser(updatedUser);
  };

  const changeEmail = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.mail = value;
    setUser(updatedUser);
  };

  const changeAddress = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.streetAddress = value;
    setUser(updatedUser);
  };

  const changeCountry = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.country = value;
    setUser(updatedUser);
  };

  const changeState = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.state = value;
    setUser(updatedUser);
  };

  const changeZipCode = (value: string) => {
    const updatedUser = { ...user };
    updatedUser.postalCode = value;
    setUser(updatedUser);
  };

  return (
    <>
      <div className="profile-tab">
        <Typography className="profile-tab-subtitle" type={ElementType.subtitle}>
          Basic Info
        </Typography>
        <Input
          label={"First Name"}
          value={user?.givenName || ""}
          onChange={(ev) => {
            changeFirstName((ev.target as HTMLInputElement).value);
          }}
          width={460}
        />
        <Input
          label={"Last Name"}
          value={user?.surname || ""}
          onChange={(ev) => {
            changeLastName((ev.target as HTMLInputElement).value);
          }}
          width={460}
        />
        <Input
          label={"Email"}
          value={user?.mail || ""}
          onChange={(ev) => {
            changeEmail((ev.target as HTMLInputElement).value);
          }}
          width={460}
        />
        <Typography className="profile-tab-subtitle" type={ElementType.subtitle}>
          Location
        </Typography>
        <Input
          label={"Address"}
          value={user?.streetAddress || ""}
          onChange={(ev) => {
            changeAddress((ev.target as HTMLInputElement).value);
          }}
          width={460}
        />
        <Select
          label="Country"
          // @ts-ignore
          onChange={(selected) => changeCountry(selected.value)}
          options={countriesOptions}
          // @ts-ignore
          value={user && user.country ? countriesOptions.find((c) => c.value === user.country) : null}
          width={460}
        />
        <Select
          label="State"
          // @ts-ignore
          onChange={(selected) => changeState(selected.value)}
          options={statesOptions}
          // @ts-ignore
          value={user && user.state ? statesOptions.find((c) => c.value === user.state) : null}
          width={460}
        />
        <Input
          label={"Zip Code"}
          value={user?.postalCode || ""}
          onChange={(ev) => {
            changeZipCode((ev.target as HTMLInputElement).value);
          }}
          width={460}
        />
      </div>
      <div className="profile-tab-buttons">
        <Button onClick={onCancelClick} themeType="secondary">
          Cancel
        </Button>
        <Button onClick={() => onUpdateClick(user)} className="profile-tab-buttons-update" themeType="clear">
          Update Info
        </Button>
      </div>
    </>
  );
};

export default ProfileTab;
