import * as React from "react";
import { IArcadiaMaterial } from "@types";
import { Checkbox } from "../Common/Checkbox";
import { Typography, ElementType, Color } from "../Common/Typography";
import uniqid from "uniqid";
import { Card } from "../Card";

const MaterialsChoice: React.FC<{
  materials: IArcadiaMaterial[];
  handleClick: (materialSku: string) => void;
  selectedMaterials: string[];
}> = ({ selectedMaterials = [], handleClick, materials = [] }): React.ReactElement => {
  const MaterialsChoiceTable: React.FC<{
    materials: IArcadiaMaterial[];
  }> = ({ materials = [] }): React.ReactElement => (
    <table className="materials-choice-card-table">
      <thead>
        <tr>
          <th className="materials-choice-card-table-header"></th>
          <th className="materials-choice-card-table-header">
            <Typography type={ElementType.tableHeader} color={Color.OffWhite}>
              Material
            </Typography>
          </th>
          {/* <th className="materials-choice-card-table-header">
            <Typography type={ElementType.tableHeader} color={Color.OffWhite}>
              Form
            </Typography>
          </th>
          <th className="materials-choice-card-table-header">
            <Typography type={ElementType.tableHeader} color={Color.OffWhite}>
              Thickness
            </Typography>
          </th> */}
        </tr>
      </thead>
      <tbody className="table-tbody">
        {materials.map((material) => {
          const checkboxChange = (event: React.FormEvent<HTMLDivElement>) => {
            event.stopPropagation();
            event.preventDefault();
            handleClick(material.sku!);
          };
          return (
            <tr
              onClick={checkboxChange}
              key={uniqid()}
              className={`materials-choice-card-table-row${
                selectedMaterials.includes(material.sku!) ? " checked" : ""
              }`}
            >
              <td>
                <Checkbox
                  onChange={() => {}}
                  largeMargin={true}
                  checked={selectedMaterials.includes(material.sku!)}
                  className="materials-choice-card-table-row-check"
                />
              </td>
              <td>
                <Typography>
                  {material.materialName!}
                  {"   "}
                  {material.isCustomerMaterial && !material.isCustomized && <div className="badge large">P</div>}
                </Typography>
              </td>
              {/* <td>
                <Typography>
                  {material.materialProperties?.find((p) => p.name === "MaterialThickness")?.value}
                </Typography>
              </td>
              <td>
                <Typography>
                  {material.materialProperties?.find((p) => p.name === "MaterialThickness")?.value}
                </Typography>
              </td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="materials-choice">
      <Typography type={ElementType.bodySmall} color={Color.OffWhite} className="materials-choice-text">
        Choose from materials we can provide for you, or choose from your own.
      </Typography>
      <Card className="materials-choice-card" width={"auto"} height={"auto"} hover={false}>
        <MaterialsChoiceTable materials={materials} />
      </Card>
    </div>
  );
};

export { MaterialsChoice };
