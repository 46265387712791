import React, { FC, FormEvent, ReactElement, useEffect, useState } from "react";
import { INewProjectData, INewProjectDataPart } from "@types";
import { OptionType } from "../Common/Select";
import { v4 as uuidv4 } from "uuid";
import { CardAddPartAttribute } from "../CardAddPartAttribute";
import { AddAnotherPart } from "../AddAnotherPart";
import { Button } from "../Button";
import { PartData } from "./PartData";

const PartProject: FC<{
  formData: INewProjectData;
  materialCategories: string[];
  onClickAfterPart: (values: INewProjectDataPart[]) => void;
  onClickBack: () => void;
}> = ({ formData, materialCategories, onClickAfterPart, onClickBack }): ReactElement => {
  const [partData, setPartData] = useState<INewProjectDataPart[]>(formData.part);
  const [canContinue, setCanContinue] = useState<boolean>(false);

  const materialOptions: OptionType[] = materialCategories.map((m) => ({ value: m, label: m }));

  const cycleOptions: OptionType[] = [
    { value: "sec", label: "sec" },
    { value: "min", label: "min" },
  ];

  const addAnotherPart = () => {
    const newPartData = [...partData];
    newPartData.push({
      cycleSelection: "sec",
      description: "",
      deliveryDate: null,
      desiredCycle: null,
      drawing: [],
      identifier: uuidv4(),
      material: null,
      name: "",
      number: null,
    } as INewProjectDataPart);
    setPartData(newPartData);
  };

  useEffect(() => {
    let canContinue = true;
    for (const part of partData) {
      if (part.name === "") {
        canContinue = false;
        break;
      }
    }

    setCanContinue(canContinue);
  }, [partData, setCanContinue]);

  const onClickNext = () => {
    onClickAfterPart(partData);
  };

  return (
    <>
      {partData.map((part, index) => {
        const updateField = (
          event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
          keyIndex: any
        ) => {
          event.preventDefault();
          const element = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
          const newPartData = [...partData];
          const part = newPartData[index];
          // @ts-ignore
          part[keyIndex as keyof INewProjectDataPart] = element.value;
          setPartData(newPartData);
        };

        const changePartName = (event: FormEvent<HTMLInputElement>) => {
          updateField(event, "name");
        };

        const changePartDescription = (event: FormEvent<HTMLTextAreaElement>) => {
          updateField(event, "description");
        };

        const changePartParts = (event: FormEvent<HTMLInputElement>) => {
          updateField(event, "number");
        };

        const changePartCycle = (event: FormEvent<HTMLInputElement>) => {
          updateField(event, "desiredCycle");
        };

        const changePartMaterial = (newMaterial: OptionType) => {
          const newPartData = [...partData];
          const part = newPartData[index];
          part.material = newMaterial;
          setPartData(newPartData);
        };

        const changeDeliveryDate = (newDate: Date) => {
          const newPartData = [...partData];
          const part = newPartData[index];
          part.deliveryDate = newDate;
          setPartData(newPartData);
        };

        const changePartSelection = (newPartSelection: string) => {
          const newPartData = [...partData];
          const part = newPartData[index];
          part.cycleSelection = newPartSelection;
          setPartData(newPartData);
        };

        const changePartDrawings = (files: File[]) => {
          const newPartData = [...partData];
          const newPart = newPartData.find((p) => p.identifier === part!.identifier!)!;
          newPart.drawing = files;
          setPartData(newPartData);
        };

        const deletePart = () => {
          const newPartData = [...partData];
          // @ts-ignore
          newPartData[index] = undefined;
          setPartData(newPartData.filter((part) => !!part));
        };

        const uniq = part.identifier || uuidv4();

        return (
          <div key={uniq}>
            <PartData
              part={part}
              showCardAttributes={index === 0}
              showDelete={index > 0}
              classNames={index > 0 ? "modal-new-project-form-mt" : ""}
              changePartName={changePartName}
              changePartDescription={changePartDescription}
              changePartParts={changePartParts}
              changePartCycle={changePartCycle}
              changePartMaterial={changePartMaterial}
              changeDeliveryDate={changeDeliveryDate}
              changePartSelection={changePartSelection}
              changePartDrawings={changePartDrawings}
              deletePart={deletePart}
              materialOptions={materialOptions}
              cycleOptions={cycleOptions}
            />
          </div>
        );
      })}
      <div className="relative">
        <AddAnotherPart
          className="modal-new-project-section"
          onKeyDown={addAnotherPart}
          onClick={addAnotherPart}
          tabIndex={0}
        />
        {partData.length < 2 && (
          <CardAddPartAttribute
            className="modal-new-project-attribute-bottom"
            title="Define Multiple Parts"
            description="Add another part"
          />
        )}
      </div>
      <div className="modal-new-project-form-row">
        <Button className="modal-new-project-button" size="large" themeType="primary" onClick={onClickBack}>
          Back
        </Button>
        <Button
          className="modal-new-project-button"
          disabled={!canContinue}
          size="large"
          themeType="clear"
          onClick={onClickNext}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export { PartProject };
