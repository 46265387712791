import * as React from "react";
import { default as cx } from "classnames";
import { Icon } from "../../Icon";
import styleVariables from "@styles/export.scss";

const VolumeControl: React.FC<
  {
    className?: string;
    volume: number;
    setVolume: (volume: number) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", volume, setVolume }): React.ReactElement => {
  const classes = cx(
    {
      "volume-control-container": true,
    },
    className
  );

  return (
    <div className={classes}>
      <Icon
        color={styleVariables.color_white}
        size={24}
        width={24}
        height={24}
        className="volume-control-icon"
        name={`fas${volume < 0.01 ? " fa-volume-off" : ""} ${volume > 0 && volume <= 0.33 ? " fa-volume-down" : ""} ${
          volume > 0.33 && volume < 0.66 ? " fa-volume" : ""
        } ${volume > 0.66 ? " fa-volume-up" : ""}`}
      />
      <input
        className="volume-control-input"
        value={volume}
        onInput={(ev) => setVolume(parseFloat((ev.target as HTMLInputElement).value))}
        onChange={() => {}}
        type="range"
        min={0}
        max={1}
        step={0.01}
      />
    </div>
  );
};

export { VolumeControl };
