import React, { FC, ReactElement, useState, useCallback, useRef, useEffect } from "react";
import { default as cx } from "classnames";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Typography, ElementType, Color } from "../Common/Typography";
import { FileUploader } from "../FileUploader";
import styleVariables from "@styles/export.scss";

const ModalImageUpload: FC<{
  title: string;
  className?: string;
  onSave: (blob: Blob) => void;
  onCloseModal?: () => void;
  singleFile?: boolean;
  imageUrl?: string;
}> = ({ title, className, imageUrl, onSave, onCloseModal, singleFile }): ReactElement => {
  const classes = cx(
    {
      "modal-image-upload": true,
    },
    className
  );

  const [files, setFiles] = useState<File[]>([]);
  const [upImg, setUpImg] = useState<string>();
  const [imageSrc, setImageSrc] = useState(imageUrl || "");
  const imgRef = useRef<HTMLImageElement>();
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: "%",
    width: 30,
    aspect: 1 / 1,
  });
  const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    if (canvas && image) {
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");
      const pixelRatio = window.devicePixelRatio;

      if (crop && crop.width && crop.height) {
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        if (ctx && crop.x !== null && crop.x !== undefined && crop.y !== null && crop.y !== undefined) {
          ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
          ctx.imageSmoothingQuality = "high";

          ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          );
        }
      }
    }
  }, [completedCrop]);

  useEffect(() => {
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result as string));
      reader.readAsDataURL(files[0]);
    }
  }, [files]);

  const handleSaveClick = () => {
    if (completedCrop && previewCanvasRef.current) {
      const canvas = previewCanvasRef.current;
      canvas.toBlob((blob) => {
        if (blob) {
          onSave(blob);
        }
      });
    }
  };

  return (
    <Modal className={classes} width={600} height={"auto"} onCloseModal={onCloseModal}>
      <Typography className="modal-image-upload-title" type={ElementType.h3}>
        {upImg || imageSrc ? "Edit Photo" : "Add Photo"}
      </Typography>
      <div className="modal-image-upload-image-container">
        {imageSrc ? (
          <img className="modal-image-upload-image-container-image" src={imageSrc} alt="project" />
        ) : !upImg ? (
          <div className="modal-image-upload-file-uploader">
            <FileUploader files={files} setFiles={setFiles} singleFile={singleFile} />
          </div>
        ) : (
          <div className="modal-image-upload-file-cropper">
            <ReactCrop
              src={imageSrc || upImg || ""}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
            <div style={{ display: "none" }}>
              <canvas
                ref={previewCanvasRef}
                // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className="modal-image-upload-buttons">
        <Button
          onClick={() => {
            setImageSrc("");
            setUpImg("");
            setFiles([]);
            setCompletedCrop(null);
          }}
          themeType="simple"
          iconLeftName="fal fa-trash"
          iconColor={styleVariables.color_gray}
          iconSize={12}
        >
          <Typography color={Color.Secondary}>Remove Photo</Typography>
        </Button>
        <div className="modal-image-upload-buttons-right">
          <Button onClick={onCloseModal} className="modal-button" size="large" themeType={"secondary"}>
            Cancel
          </Button>
          <Button
            disabled={!upImg && !completedCrop}
            onClick={() => handleSaveClick()}
            className="modal-button"
            size="large"
            themeType={"clear"}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ModalImageUpload };
