import * as React from "react";
import styleVariables from "@styles/export.scss";

const Oval: React.FC<
  {
    borderColor?: string;
    color?: string;
    width?: number;
    height?: number;
    position?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  borderColor = "#1D2121",
  color = styleVariables.color_green,
  width = 16,
  height = 16,
  position = "bottom-left",
}): React.ReactElement => (
  <svg
    className={"oval oval-{$position}"}
    style={{ bottom: "0", right: "0" }}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5Z"
      fill={color}
      stroke={borderColor}
      strokeWidth="3"
    />
  </svg>
);

export { Oval };
