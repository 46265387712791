import * as React from "react";
import { Thumbnail } from "../Thumbnail";
import { Card } from "../Card";
import { Button } from "../Button";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
const defaultAvatar = require("../Avatar/defaultAvatar.svg") as string;

const CardProfile: React.FC<
  {
    backgroundImageUrl?: string;
    position: string;
    name?: string;
    contact?: string;
    clickHandler: () => void;
    isReplay?: boolean;
    isOperator?: boolean;
    buttonDisabled?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  backgroundImageUrl,
  position = "",
  name = "",
  contact = "",
  isReplay = false,
  isOperator = false,
  buttonDisabled = false,
  clickHandler,
}): React.ReactElement => {
  return (
    <div className="outer-container">
      <Card className="profile-card" width={265} height={246} hover={false}>
        <div className="position-container">
          <Typography type={ElementType.eyebrow} color={Color.Secondary}>
            {position}
          </Typography>
        </div>
        <div className="name-container">
          <Typography type={ElementType.h4}>{name}</Typography>
        </div>
        <div className="contact-container">
          <Typography color={Color.Secondary}>{contact}</Typography>
        </div>
        <div className="button-container">
          {!isReplay && (
            <Button disabled={buttonDisabled} onClick={clickHandler} themeType="secondary" iconLeftName="fal fa-video">
              {isOperator ? "Edit Zoom Info" : "Call with Zoom"}
            </Button>
          )}
        </div>
      </Card>
      <Thumbnail
        className="picture-thumbnail"
        round={true}
        backgroundColor={styleVariables.color_black}
        width={107}
        height={107}
        backgroundImageUrl={backgroundImageUrl || defaultAvatar}
        title={name}
      />
    </div>
  );
};

export { CardProfile };
