import * as React from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import { IArcadiaFileUpload } from "@types";

export enum FilePaths {
  PART_DRAWINGS = "/part-drawings",
  CAM_FILES = "/cam-files",
  MEASUREMENTS = "/measurements",
  SESSION_SNAPSHOTS = "/snapshots",
  PRESENTATION_FILES = "/presentation",
}

export enum FolderNames {
  "/part-drawings" = "Part Drawings / CAD Models",
  "/cam-files" = "CAM Files",
  "/measurements" = "Measurements",
  "/snapshots" = "Session Snapshots",
  "/presentation" = "Presentation Files",
}

// Turn enum into array
const filePaths = Object.values(FilePaths);

const Folders: React.FC<
  {
    className?: string;
    height?: number | string;
    selectedFolder?: FilePaths;
    files: IArcadiaFileUpload[];
    onItemClick?: (selectedFolder?: FilePaths) => void;
    width?: number | string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  height = 745,
  selectedFolder,
  width = 330,
  files = [],
  onItemClick = () => {},
}): React.ReactElement => {
  const onAllItemClick = () => {
    onItemClick(undefined);
  };
  return (
    <aside className={`folders ${className}`} style={{ height, width }}>
      <div className="folders-header">
        <Typography
          type={ElementType.eyebrow}
          color={Color.Secondary}
          className="folders-header-title"
          data-testid="folders-header-title"
        >
          Folders
        </Typography>
      </div>
      <ul className="folders-list">
        <li>
          <div
            role="link"
            className={`folders-list-item ${selectedFolder ? "" : "folders-list-item-active"}`}
            data-testid="folders-list-item-all"
            tabIndex={0}
            onKeyUp={onAllItemClick}
            onClick={onAllItemClick}
          >
            <Typography type={ElementType.display}>All Files ({files.length})</Typography>
          </div>
        </li>
        {filePaths.map((path) => {
          const clickHandler = () => {
            onItemClick(path);
          };

          return (
            <li key={path}>
              <div
                role="link"
                className={`folders-list-item ${path === selectedFolder ? "folders-list-item-active" : ""}`}
                tabIndex={0}
                onKeyUp={clickHandler}
                onClick={clickHandler}
              >
                <Typography type={ElementType.display}>{`${FolderNames[path]} (${
                  files.filter((f) => f.userPath === path).length
                })`}</Typography>
              </div>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export { Folders };
