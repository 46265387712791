import React, { FC, ReactElement, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  SecondNavigationTop,
  SecondNavigationBottom,
  ModalConfirm,
  Checkbox,
  Typography,
  Select,
} from "@namespace/components";
import { v4 as uuidv4 } from "uuid";
import {
  IPrepareSessionDataGoals,
  IPrepareSessionDataSchedule,
  IPrepareSessionDataEquipment,
  ISteps,
  IArcadiaMaterial,
  IMachineRun,
  IArcadiaSession,
  IArcadiaSessionGoal,
  IArcadiaSessionMaterial,
} from "@types";
import pubSub from "@namespace/components/src/helpers/pubsub";
import useProject from "../../hooks/API/useProject";
import GoalsSession from "./GoalsSession";
import ScheduleSession from "./ScheduleSession";
import EquipmentSession from "./EquipmentSession";
import PlanningSession from "./PlanningSession";
import ConfirmSession from "./ConfirmSession";
import { useEffect } from "react";
import { sortingFunctionSessions } from "@namespace/components/src/helpers/sorting";
import useMachineRuns from "../../hooks/API/useMachineRuns";
import useStatus from "../../hooks/API/useStatus";
import useMaterials from "../../hooks/API/useMaterials";
import MachineRunsService from "../../services/MachineRunsService";
import useScheduledSessions from "../../hooks/API/useScheduledSessions";
import { allSettled } from "../../utils";
import ProjectsService from "../../services/ProjectsService";
import { ArcadiaSessionStatus } from "../../enums";
import { allGoals } from "./goals";
import useNotification from "../../hooks/useNotification";
import { RunStatus } from "@namespace/components/src/components/SessionRow";
import { ElementType } from "@namespace/components/src/components/Common/Typography";
import useOperators from "../../hooks/API/useOperators";
import useCustomer from "../../hooks/API/useCustomer";
import usePublicSession from "../../hooks/API/usePublicSession";
import useUsers from "../../hooks/API/useUsers";
import useSessionHubConnection from "../../hooks/API/useSessionHubConnection";
import handleError from "../../hooks/useErrorHandler";

const PrepareSession: React.FC<{ isManager: boolean; userGroupId: string } & React.HTMLAttributes<HTMLDivElement>> = ({
  isManager,
  userGroupId,
}): React.ReactElement => {
  const { projectId, sessionId } = useParams<{ projectId: string; sessionId: string }>();
  const { project, fetchProjectById } = useProject();
  const { runs, fetchMachineRuns } = useMachineRuns();
  const { machines, fetchMachines } = useStatus();
  const { materials, fixtures, fetchMaterials } = useMaterials();
  const { scheduledSessions, fetchScheduledSessions } = useScheduledSessions();
  const { sessionHubConnection } = useSessionHubConnection();
  const history = useHistory();
  const notification = useNotification();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [confirmCheck, setConfirmCheck] = useState<boolean>(false);
  const error = handleError();

  const toggleCheck = () => {
    if (confirmCheck) {
      ps.publish("nextDisabled", true);
      setConfirmCheck(false);
    } else {
      ps.publish("nextDisabled", false);
      setConfirmCheck(true);
    }
  };

  const isScheduleValid = () => {
    let result = false;
    if (schedule.sessionDate && schedule.machine) {
      result = true;
    }
    return result;
  };

  const isEquipmentValid = () => {
    let result = false;
    const fixtures = Array.from(equipment.workHoldings.keys());
    if (fixtures && fixtures.length > 0) {
      result = true;
    }
    fixtures.forEach((fixture) => {
      const entry = equipment.workHoldings.get(fixture);
      const materials = entry ? entry[1] : [];
      if (materials.length === 0) {
        result = false;
      }
    });
    return result;
  };

  const isStepValid = (step: number) => {
    let result = false;
    if (isManager) return true;
    switch (step) {
      case 1: {
        result = true;
        break;
      }
      case 2: {
        result = isScheduleValid();
        break;
      }
      case 3: {
        result = isEquipmentValid();
        break;
      }
      case 4: {
        result = true;
        break;
      }
      case 5: {
        result = isScheduleValid() && isEquipmentValid() && confirmCheck;
        break;
      }
      default:
        break;
    }
    return result;
  };

  //formData state
  const [goals, setGoals] = useState<IPrepareSessionDataGoals>({
    developFeatureInFlatStock: false,
    transferFeatureToPart: false,
    optimizeProcessForProduction: false,
    productionRun: false,
    developOptimizeScanhead: false,
    developLaserParameters: false,
    developOtherParameters: false,
    evaluateWorkHolding: false,
    provePartProgram: false,
    developMachineMotion: false,
    improveProgramLogic: false,
  });

  const [schedule, setSchedule] = useState<IPrepareSessionDataSchedule>({
    sessionDate: null,
    daysFlexibility: false,
    machine: undefined,
  });

  const [equipment, setEquipment] = useState<IPrepareSessionDataEquipment>({
    workHoldings: new Map<string, [IArcadiaMaterial, string[]]>(),
  });

  const [planning, setPlanning] = useState(new Map<string, IMachineRun[]>());

  const [notes, setNotes] = useState<string>("");

  const [sessionName, setSessionName] = useState<string>("Enter Session Title");
  const ps = pubSub();
  const steps: ISteps[] = [
    {
      stepNumber: 1,
      title: "Goals",
    },
    {
      stepNumber: 2,
      title: "Schedule",
    },
    {
      stepNumber: 3,
      title: "Equipment",
    },
    {
      stepNumber: 4,
      title: "Planning",
    },
    {
      stepNumber: 5,
      title: "Summary",
    },
  ];

  useEffect(() => {
    if (currentStep === 4) {
      const workHoldingsValues = Array.from(equipment.workHoldings.values());
      const updatedMap = new Map<string, IMachineRun[]>();
      workHoldingsValues.forEach(([workHolding]) => {
        const filteredRuns = planning.get(workHolding.sku!) || [];
        if (filteredRuns.length === 0) {
          const runId = uuidv4();
          const newRunName = "Run 1";
          const newRun: IMachineRun = {
            runId: runId,
            isNew: true,
            fixtureId: workHolding.sku!,
            sessionId: sessionId,
            runStatus: RunStatus.NotQueued,
            runName: newRunName,
          };
          updatedMap.set(workHolding.sku!, [newRun]);
        } else {
          updatedMap.set(workHolding.sku!, filteredRuns);
        }
      });
      setPlanning(updatedMap);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setIsNextDisabled(!isStepValid(currentStep));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, schedule, equipment, confirmCheck]);

  useEffect(() => {
    if (projectId) {
      fetchProjectById(projectId);
    }
    fetchMachines();
    fetchScheduledSessions();
    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (sessionId) {
      fetchMachineRuns(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (
      project &&
      project.sessions &&
      project.sessions.length > 0 &&
      runs &&
      machines &&
      fixtures &&
      fixtures.length > 0
    ) {
      const session = project.sessions.sort(sortingFunctionSessions).find((s) => s.sessionId === sessionId);
      if (session) {
        const goals = session.goals;
        const getGoalsFromSession = () => {
          return {
            developFeatureInFlatStock: goals?.find((g) => g.goal === "Develop feature in flat stock") !== undefined,
            transferFeatureToPart: goals?.find((g) => g.goal === "Transfer feature to part") !== undefined,
            optimizeProcessForProduction:
              goals?.find((g) => g.goal === "Optimize process for production") !== undefined,
            productionRun: goals?.find((g) => g.goal === "Production run") !== undefined,
            developOptimizeScanhead: goals?.find((g) => g.goal === "Develop / optimize scanhead job(s)") !== undefined,
            developLaserParameters: goals?.find((g) => g.goal === "Develop / optimize laser parameters") !== undefined,
            developOtherParameters:
              goals?.find((g) => g.goal === "Develop / optimize other process parameters") !== undefined,
            evaluateWorkHolding: goals?.find((g) => g.goal === "Evaluate Work Holding") !== undefined,
            provePartProgram: goals?.find((g) => g.goal === "Prove / debug part program") !== undefined,
            developMachineMotion: goals?.find((g) => g.goal === "Develop / optimize machine motion") !== undefined,
            improveProgramLogic: goals?.find((g) => g.goal === "Improve program logic") !== undefined,
          };
        };

        const getScheduleFromSession = () => {
          return {
            sessionDate: session.plannedDate ? new Date(session.plannedDate) : undefined,
            daysFlexibility: false,
            machine: session.cellId ? machines.find((m) => m.cellId === session.cellId) : undefined,
          };
        };

        const getEquipmentFromSession = () => {
          const workHoldings = new Map<string, [IArcadiaMaterial, string[]]>();
          session.sessionMaterials?.forEach((sm) => {
            const fixture = fixtures.find((f) => f.sku === sm.fixtureSKU)!;
            workHoldings.set(fixture.sku!, [fixture, sm.materialSKUs || []]);
          });
          return { workHoldings };
        };

        const getPlanningFromSession = (runs: IMachineRun[]) => {
          const result = new Map<string, IMachineRun[]>();
          if (runs && runs.length > 0) {
            const fixtureIds: string[] = [];
            runs.forEach((r) => {
              if (fixtureIds.indexOf(r.fixtureId!) < 0) {
                fixtureIds.push(r.fixtureId!);
              }
            });
            fixtureIds.forEach((f) => {
              const filteredRuns = runs.filter((r) => r.fixtureId! === f);
              if (filteredRuns) {
                result.set(f, filteredRuns);
              }
            });
          }

          return result;
        };

        setSessionName(session.sessionName!);
        const goalsFromSession = getGoalsFromSession() as IPrepareSessionDataGoals;
        setGoals(goalsFromSession);
        const scheduleFromSession = getScheduleFromSession() as IPrepareSessionDataSchedule;
        setSchedule(scheduleFromSession);
        const equipmentFromSession = getEquipmentFromSession() as IPrepareSessionDataEquipment;
        setEquipment(equipmentFromSession);
        const planningFromSession = getPlanningFromSession(runs) as Map<string, IMachineRun[]>;
        setPlanning(planningFromSession);
        const notesFromSession = session.preparatoryNotes;
        setNotes(notesFromSession!);
        setCurrentStep(5);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, sessionId, runs, machines, fixtures]);

  const getGoals = (allGoals: { id: string; value: string }[], goals: IPrepareSessionDataGoals) => {
    const result: IArcadiaSessionGoal[] = [];
    const entries = Object.entries(goals);
    entries.forEach((entry) => {
      if ((entry[1] as boolean) === true) {
        const value = allGoals.find((g) => g.id === entry[0]);
        result.push({ goal: value?.value });
      }
    });
    return result;
  };

  const getMaterials = (materialsFromFormData: IPrepareSessionDataEquipment) => {
    let result: IArcadiaSessionMaterial[] = [];
    const fixturesMap = materialsFromFormData.workHoldings;
    const keys = Array.from(fixturesMap.keys());
    keys.forEach((key) => {
      const entry = fixturesMap.get(key);
      if (entry && entry[0] && entry[1]) {
        const fixture = entry[0];
        const ids = entry[1];
        result.push({ fixtureSKU: fixture.sku, materialSKUs: ids });
      }
    });
    return result;
  };

  const updateMachineRuns = async (updatedRuns: IMachineRun[], projectId: string, sessionId: string) => {
    const deletedRuns = runs.filter((run) => updatedRuns.filter((u) => u.runId === run.runId).length === 0);
    const newRuns = updatedRuns.filter((run) => run.isNew);
    const edited = updatedRuns.filter((run) => !run.isNew);
    const promises = [MachineRunsService.createMultipleRuns(projectId, sessionId, newRuns)];
    deletedRuns.forEach((run) => {
      promises.push(MachineRunsService.deleteRun(run.runId!));
    });
    promises.push(MachineRunsService.updateMultipleRuns(edited));
    const runOrderUpdatedRuns = updatedRuns.map((r) => ({ runId: r.runId }));
    promises.push(MachineRunsService.reorderRuns(projectId, sessionId, [...runOrderUpdatedRuns]));
    const results = await allSettled(promises);
    if (results.filter((r) => r.status === "rejected")) {
      console.log("An error occurred");
    }
  };

  const saveSession = async () => {
    let notificationMessage = "Session created!";
    const session = project?.sessions?.find((s) => s.sessionId === sessionId)!;
    const newSession: IArcadiaSession = {
      status: ArcadiaSessionStatus.Draft,
      goals: getGoals(allGoals, goals),
      sessionMaterials: getMaterials(equipment),
      preparatoryNotes: notes,
      isArchived: false,
      sessionName: sessionName,
    };
    if (schedule.sessionDate) {
      newSession.plannedDate = schedule.sessionDate.toISOString();
    }
    if (schedule.machine) {
      newSession.cellId = schedule.machine.cellId;
    }
    if (confirmCheck) {
      if (isManager) newSession.status = session.status;
      else
        newSession.status =
          isScheduleValid() && isEquipmentValid() ? ArcadiaSessionStatus.PendingReview : ArcadiaSessionStatus.Draft;
    }
    let newSessionId = "";
    let runs: IMachineRun[] = [];
    if (session) {
      const updatedSession = {
        ...session,
        ...newSession,
      };
      newSessionId = session.sessionId!;
      try {
        await ProjectsService.updateSession(projectId, newSessionId, updatedSession);
        notificationMessage = "Session updated!";
      } catch (e) {
        error(e);
      }
    } else {
      try {
        const result = await ProjectsService.createNewSession(projectId, newSession);
        const savedSession = result.data as IArcadiaSession;
        newSessionId = savedSession.sessionId!;
      } catch (e) {
        error(e);
      }
    }
    const tempRuns = Array.from(planning.values());
    if (tempRuns) {
      tempRuns.forEach((r) => {
        runs = [...runs, ...r];
      });
      runs.forEach((r) => {
        r.sessionId = newSessionId;
      });
      await updateMachineRuns(runs, project?.arcadiaProjectId!, newSessionId);
    }
    history.push(`/project/${projectId}`);
    notification(notificationMessage, "neutral");
  };

  const nextStep = () => {
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setIsNextDisabled(!isStepValid(newStep));
  };

  const handleSessionNameChange = (name: string) => {
    setSessionName(name);
  };

  const handleCancelSession = () => {
    setShowConfirm(true);
  };

  const deleteSession = () => {
    if (sessionId) {
      const session = project?.sessions?.find((s) => s.sessionId === sessionId);
      if (session && session.status === ArcadiaSessionStatus.Draft) {
        ProjectsService.deleteSession(projectId, sessionId).catch((e) => console.log(e));
      } else {
        const updated: IArcadiaSession = { ...session, status: ArcadiaSessionStatus.Cancelled };
        ProjectsService.updateSession(projectId, sessionId, updated).catch((e) => console.log(e));
      }
    }
    fetchProjectById(projectId);
    history.push(`/project/${projectId}`);
    notification("Session Cancelled", "neutral");
  };

  const { operators, fetchOperators } = useOperators();
  const operatorOptions = operators.map((o) => ({ label: o.operatorName!, value: o.operatorId! }));
  const [sessionOperatorId, setSessionOperatorId] = useState<string | null>();
  const { customer, fetchCustomer } = useCustomer();
  const { publicSession, fetchPublicSession } = usePublicSession();
  const { users, fetchUsers } = useUsers();

  useEffect(() => {
    if (projectId) {
      fetchOperators(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (sessionId) {
      fetchPublicSession(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId]);

  useEffect(() => {
    if (publicSession) {
      fetchCustomer(publicSession.customerId!);
      fetchUsers(publicSession.customerId!);
      setSessionOperatorId(publicSession.operatorId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicSession]);

  const confirmSession = async () => {
    try {
      await ProjectsService.assignOperator(sessionOperatorId!, sessionId);
      await ProjectsService.confirmSession(projectId, sessionId);
      await saveSession();
      notification("Session Confirmed!", "neutral");
      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <main className="container-primary-background prepare-session-main">
      <SecondNavigationTop
        activeStep={currentStep}
        totalSteps={steps.length}
        sessionTitle={sessionName}
        onSessionNameChange={handleSessionNameChange}
        machineName="Machine Session Prep"
        showBack={false}
        inverseTitle={true}
        onClickNext={nextStep}
        onClickSaveDraft={saveSession}
        onClickFinish={isManager ? confirmSession : saveSession}
        isNextDisabled={isNextDisabled}
        isManager={isManager}
      ></SecondNavigationTop>
      <SecondNavigationBottom activeStep={currentStep} steps={steps} setCurrentStep={setCurrentStep} />
      {currentStep === 1 && <GoalsSession values={goals} setValues={setGoals} disabled={isManager} />}
      {currentStep === 2 && (
        <ScheduleSession
          machines={machines!}
          values={schedule}
          setValues={setSchedule}
          scheduledSessions={scheduledSessions}
          customerId={userGroupId}
        />
      )}
      {currentStep === 3 && (
        <EquipmentSession
          values={equipment}
          setValues={setEquipment}
          materials={materials}
          setRuns={setPlanning}
          runs={planning}
          fixtures={fixtures}
          disabled={isManager}
        />
      )}
      {currentStep === 4 && (
        <PlanningSession
          projectId={project?.arcadiaProjectId!}
          sessionId={sessionId}
          runs={planning}
          materials={materials}
          fixtures={fixtures}
          setRuns={setPlanning}
          workholdings={equipment.workHoldings}
          setCurrentStep={setCurrentStep}
          disabled={isManager}
        />
      )}
      {currentStep === 5 && (
        <>
          <div className="confirm-session-checkbox-container">
            <Checkbox checked={confirmCheck} onClick={toggleCheck} />
            <Typography type={ElementType.body}>
              {isManager
                ? "I have assigned the operator and confirmed that Microlution is or will be prepared to execute the session on the planned date."
                : "I have carefully reviewed the session details I've provided. I understand that Microlution will make a best-effort to review and prepare for the session, but that no guarantee is provided."}
            </Typography>
          </div>
          {isManager && (
            <div className="confirm-session-manager-information">
              <Typography>Customer Name is {customer?.groupName}</Typography>
              <Typography>
                User Name is {users?.find((u) => u.userObjectId === publicSession?.userId)?.userName}
              </Typography>
              <Select
                width={"100%"}
                label="Select Operator"
                // @ts-ignore
                onChange={(selected) => setSessionOperatorId(selected.value)}
                options={operatorOptions}
                // @ts-ignore
                value={sessionOperatorId ? operatorOptions.find((c) => c.value === sessionOperatorId) : null}
              />
            </div>
          )}
          <ConfirmSession
            notes={notes}
            setNotes={setNotes}
            setCurrentStep={setCurrentStep}
            runs={runs}
            projectId={projectId}
            session={project?.sessions?.find((s) => s.sessionId === sessionId)!}
            materials={materials}
            goals={goals}
            schedule={schedule}
            equipment={equipment}
            planning={planning}
            handleCancelSession={handleCancelSession}
            disabled={isManager}
          />
        </>
      )}
      {showConfirm && (
        <ModalConfirm
          width={607}
          height={208}
          title="Discard Changes"
          text="Are you sure you want to cancel the session?"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => setShowConfirm(false)}
          onConfirm={deleteSession}
        />
      )}
    </main>
  );
};

export default PrepareSession;
export { PrepareSession };
