import React from "react";
import { Card } from "../Card";
import { Button } from "../Button";
import { ElementType, Typography, Color } from "../Common/Typography";
import moment, { Moment } from "moment";

const NextMachineSession: React.FC<
  {
    clickHandler: Function;
    nextMachineSession?: Moment;
    showNotImplementedFeatures: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  clickHandler = () => {},
  nextMachineSession = null,
  showNotImplementedFeatures = false,
}): React.ReactElement => {
  const now = moment();
  //const [showNotImpl] = useState<boolean>(showNotImplementedFeatures);
  return (
    <Card className="next-machine-session" width={519} height={87} hover={false}>
      <Typography data-testid="next-machine-session-title" className="next-machine-session-title" type={ElementType.h5}>
        Next Machine Session
      </Typography>
      <div className="next-machine-session-date">
        <div className="next-machine-session-date-days">
          <Typography className="next-machine-session-date-days-number" type={ElementType.h1}>
            {nextMachineSession ? nextMachineSession.diff(now, "days") : "00"}
          </Typography>{" "}
          Days
        </div>
        <div className="next-machine-session-date-rest">
          <div>
            <Typography type={ElementType.inline} className="next-machine-session-date-rest-number">
              {nextMachineSession ? moment.utc(nextMachineSession.diff(now)).format("HH") : "00"}
            </Typography>
            <Typography type={ElementType.inline} color={Color.Secondary}>
              H
            </Typography>
          </div>
          <div>
            <Typography type={ElementType.inline} className="next-machine-session-date-rest-number">
              {nextMachineSession ? moment.utc(nextMachineSession.diff(now)).format("mm") : "00"}
            </Typography>
            <Typography type={ElementType.inline} color={Color.Secondary}>
              M
            </Typography>
          </div>
        </div>
      </div>
      {showNotImplementedFeatures && (
        <Button
          disabled={nextMachineSession === null}
          data-testid="next-machine-session-button"
          iconLeftName="fa fa-plus"
          themeType="secondary"
          size="large"
          onClick={() => clickHandler()}
        >
          Calendar
        </Button>
      )}
    </Card>
  );
};

export { NextMachineSession };
