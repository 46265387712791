import {
  Button,
  Card,
  DropdownMenu,
  Progress,
  ProjectsSidebar,
  Skeleton,
  Tabs,
  Typography,
} from "@namespace/components";
import { ITab } from "@types";
import { ElementType } from "@namespace/components/src/components/Common/Typography";
import React from "react";
import uniqid from "uniqid";

const getSessionRow = () => (
  <div className="machine-sessions-row">
    <div className="machine-sessions-row-date">
      <div style={{ width: 31, height: 22 }}>
        <Skeleton
          className="machine-sessions-row-date-month"
          width={31}
          height={9}
          borderRadius={15}
          backgroundColor="rgba(255, 255, 255, 0.1)"
        />
      </div>

      <Skeleton width={31} height={21} borderRadius={15} backgroundColor="rgba(255, 255, 255, 0.1)" />
    </div>
    <Skeleton width={407} height={124} borderRadius={15} backgroundColor="rgba(143, 146, 161, 0.1)" />
  </div>
);

const getActionItemRow = () => (
  <div style={{ marginBottom: 16 }}>
    <Skeleton width={737} height={35} borderRadius={8} backgroundColor="rgba(143, 146, 161, 0.05)">
      <div style={{ display: "flex", width: "100%", height: "100%" }}>
        <Skeleton
          width={174}
          height={17}
          marginTop={9}
          marginLeft={54}
          borderRadius={8}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
        <Skeleton
          width={72}
          height={17}
          marginTop={9}
          marginLeft={228}
          borderRadius={8}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
        <Skeleton
          width={72}
          height={17}
          marginTop={9}
          marginLeft={80}
          borderRadius={8}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
      </div>
    </Skeleton>
  </div>
);

const tabs: ITab[] = [
  {
    name: "Workflow",
    content: (
      <div className="project-page-row">
        <div>
          <aside className="machine-sessions" style={{ height: "auto", width: 540 }}>
            <div className="machine-sessions-header">
              <Typography type={ElementType.h4}>Machine Sessions</Typography>
              <Button iconLeftName="fa fa-plus" themeType="secondary" size="large" onClick={() => {}}>
                New Session
              </Button>
            </div>
            {getSessionRow()}
            {getSessionRow()}
            {getSessionRow()}
          </aside>
        </div>
        <aside className="action-items">
          <div className="action-items-head-row">
            <Typography type={ElementType.h4} className="action-items-head">
              Your Action Items
            </Typography>
            <DropdownMenu buttons={[]} />
          </div>
          <div style={{ margin: "35px 0 38px 54px" }}>
            <Skeleton width={145} height={21} borderRadius={15} backgroundColor="rgba(255, 255, 255, 0.1)" />
          </div>
          {getActionItemRow()}
          {getActionItemRow()}
          {getActionItemRow()}
          <div style={{ margin: "35px 0 38px 54px" }}>
            <Skeleton width={145} height={21} borderRadius={15} backgroundColor="rgba(255, 255, 255, 0.1)" />
          </div>
          {getActionItemRow()}
          {getActionItemRow()}
          {getActionItemRow()}
        </aside>
      </div>
    ),
    id: uniqid(),
  },
  {
    name: "Equipment Drawer",
    content: <div></div>,
    id: uniqid(),
  },
  {
    name: "Program Manager",
    content: <div></div>,
    id: uniqid(),
  },
  {
    name: "File Uploads",
    content: <div></div>,
    id: uniqid(),
  },
  {
    name: "Project Settings",
    content: <div></div>,
    id: uniqid(),
  },
];

const ProjectSkeletonLoader = () => (
  <>
    <aside className="project-page-favbar">
      <div className="container-scrollable center-content">
        <Skeleton
          className="project-page-favbar-fav"
          width={24}
          height={24}
          borderRadius={2}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
        <Skeleton
          className="project-page-favbar-fav"
          width={24}
          height={24}
          borderRadius={2}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
        <Skeleton
          className="project-page-favbar-fav"
          width={24}
          height={24}
          borderRadius={2}
          backgroundColor="rgba(143, 146, 161, 0.1)"
        />
      </div>
    </aside>
    <div className="project-page-container">
      <section className="project-page-container-main">
        <div className="project-page-container-main-top">
          <Card className="project-session-header" width={"initial"} height={92} hover={false}>
            <Skeleton
              className={"project-session-header-thumbnail"}
              width={88}
              height={88}
              borderRadius={8}
              backgroundColor="rgba(143, 146, 161, 0.1)"
            />
            <div className="project-session-header-content">
              <div className="project-session-header-content-title">
                <Skeleton
                  marginTop={15}
                  marginBottom={15}
                  width={267}
                  height={18}
                  borderRadius={8}
                  backgroundColor="rgba(255, 255, 255, 0.1)"
                />
                <div className="project-session-header-content-title-icon">
                  <Button
                    themeType="simple"
                    iconOnly={true}
                    iconName={"fas fa-star"}
                    iconSize={20}
                    iconColor={"rgba(255, 255, 255, 0.1)"}
                  />
                </div>
              </div>
              <Skeleton
                className="project-session-header-content-subtitle"
                width={177}
                height={10}
                marginBottom={15}
                borderRadius={8}
                backgroundColor="rgba(143, 146, 161, 0.1)"
              />
              <div className="project-session-header-content-bottom">
                <Progress width={300} completed={0} />
              </div>
            </div>
          </Card>
        </div>
        <div className="project-page-container-main-tabs">
          <Tabs className="flex fullheight" noExtraBorder={true} sticky={true} tabs={tabs} />
        </div>
      </section>
      {<ProjectsSidebar projects={[]} />}
    </div>
  </>
);

export default ProjectSkeletonLoader;
