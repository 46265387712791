import * as React from "react";
import { default as cx } from "classnames";

export enum ContainerType {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  BASE = "base",
}

const Container: React.FC<
  {
    className?: string;
    type: ContainerType;
    width?: number | string;
    height?: number;
    borderRadius?: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  type = ContainerType.PRIMARY,
  className = "",
  width,
  height,
  borderRadius = null,
}): React.ReactElement => {
  const classes = cx("container", `container-${type}`, className);
  const containerStyleObject: { width?: number | string; height?: number; borderRadius?: number } = {};
  if (width) {
    containerStyleObject.width = width;
  }
  if (height) {
    containerStyleObject.height = height;
  }
  if (borderRadius) {
    containerStyleObject.borderRadius = borderRadius;
  }
  return (
    <div className={classes} style={containerStyleObject}>
      {children}
    </div>
  );
};

export { Container };
