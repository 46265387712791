import * as React from "react";
import { Card } from "../Card";
import { Color, ElementType, Typography } from "../Common/Typography";

const stations = [
  { name: "Setup", number: 4 },
  { name: "Cleaning", number: 0 },
  { name: "Machining", number: 1 },
  { name: "Measurement", number: 2 },
  { name: "Stereoscope", number: 3 },
  { name: "Pause", number: 5 },
];

const ActiveStationSelect: React.FC<
  {
    className?: string;
    handleClick?: (stationNumber: number) => void;
    selectedStation?: number | null;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", handleClick = () => {}, selectedStation }): React.ReactElement => {
  return (
    <Card className={`active-station-select ${className}`} height={"auto"} hover={false} width={265}>
      <Typography className="active-station-select-title" type={ElementType.h5}>
        Select Active Station
      </Typography>
      {stations.map((s) => {
        const isSelected = selectedStation === s.number;
        return (
          <div
            key={s.number}
            className={`active-station-select-option${isSelected ? " selected" : ""}`}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => handleClick(s.number)}
          >
            <Typography type={ElementType.display} color={isSelected ? Color.Black : Color.Primary}>
              {s.name}
            </Typography>
          </div>
        );
      })}
    </Card>
  );
};

export { ActiveStationSelect };
