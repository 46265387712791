import { useState } from "react";
import CameraService from "../../services/CameraService";

const useSteamUrls = () => {
  const [isUrl1Loading, setIsUrl1Loading] = useState(false);
  const [isUrl1Error, setIsUrl1Error] = useState(false);

  const [isUrl2Loading, setIsUrl2Loading] = useState(false);
  const [isUrl2Error, setIsUrl2Error] = useState(false);

  const [isApplicationNameLoading, setIsApplicationNameLoading] = useState(false);
  const [isApplicationNameError, setIsApplicationNameError] = useState(false);

  const [url1, setUrl1] = useState<string>("");
  const [url2, setUrl2] = useState<string>("");
  const [applicationName, setApplicationName] = useState<string>("");

  const fetchUrls = (sessionId: string) => {
    const fetchData = async () => {
      setIsUrl1Loading(true);
      setIsUrl2Loading(true);
      setIsApplicationNameLoading(true);
      CameraService.getUrl1(sessionId)
        .then((response) => {
          const dbUrl1 = response.data;
          setUrl1(dbUrl1);
          setIsUrl1Loading(false);
        })
        .catch((e) => {
          setIsUrl1Loading(false);
          setIsUrl1Error(true);
          console.log(e);
        });
      CameraService.getUrl2(sessionId)
        .then((response) => {
          const dbUrl2 = response.data;
          setUrl2(dbUrl2);
          setIsUrl2Loading(false);
        })
        .catch((e) => {
          setIsUrl2Loading(false);
          setIsUrl2Error(true);
          console.log(e);
        });
      CameraService.getApplicationName(sessionId)
        .then((response) => {
          const dbAppName = response.data;
          setApplicationName(dbAppName);
          setIsApplicationNameLoading(false);
        })
        .catch((e) => {
          setIsApplicationNameLoading(false);
          setIsApplicationNameError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return {
    url1,
    url2,
    applicationName,
    isUrl1Loading,
    isUrl2Loading,
    isApplicationNameLoading,
    isUrl1Error,
    isUrl2Error,
    isApplicationNameError,
    fetchUrls,
  };
};

export default useSteamUrls;
