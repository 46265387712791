import { IArcadiaBalance } from "@types";
import { useState } from "react";
import UserService from "../../services/UserService";

const useUserBalance = () => {
  const [isUserBalanceLoading, setIsLoading] = useState(false);
  const [isUserBalanceError, setIsError] = useState(false);

  const [userBalances, setUserBalances] = useState<IArcadiaBalance[]>([]);

  const fetchUserBalances = () => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      UserService.getUserBalances()
        .then((response: any) => {
          let result = response.data as IArcadiaBalance[];
          setUserBalances(result);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchData();
  };

  return { userBalances, isUserBalanceLoading, isUserBalanceError, fetchUserBalances };
};

export default useUserBalance;
