import * as React from "react";
import { default as cx } from "classnames";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import moment from "moment";

import { Typography, ElementType, Color } from "../Common/Typography";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Textarea } from "../Common/Textarea";
import { Input } from "../Common/Input";
import styleVariables from "@styles/export.scss";
import { Modal } from "../Modal";
import { IArcadiaFileUpload } from "../../../../types/api";
import openFullscreen from "../../helpers/fullscreen";
import { Tags } from "../Tags";
import { Tag } from "react-tag-autocomplete";
import DropdownMenu from "../DropdownMenu";
import { sortingFunctionFileUploads } from "../../helpers/sorting";
import { ModalConfirm } from "../ModalConfirm";
import { isRenderableImage } from "../../helpers/common";
const defaultFileImage = require("../../images/fileImage.svg") as string;

export interface Screenshot {
  id: number;
  imageUrl: string;
  bookmark: string;
  title: string;
  description: string;
}

const ScreenshotsOverlay: React.FC<
  {
    className?: string;
    screenshots: IArcadiaFileUpload[];
    screenshotIndex: string;
    handleCloseClick: () => void;
    handleDoneClick: any;
    handleDelete: (fileUploadId: string) => void;
    runName: string;
    sessionName: string;
    partName: string;
    featureName: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  screenshots = [],
  runName,
  sessionName,
  partName,
  featureName,
  screenshotIndex,
  handleDoneClick,
  handleCloseClick,
  handleDelete,
}): React.ReactElement => {
  const classes = cx("screenshots-overlay-container", className);
  const wantedScreenshot = screenshots.find((s) => s.fileUploadId === screenshotIndex);
  let index = 0;
  if (wantedScreenshot) {
    index = screenshots.indexOf(wantedScreenshot);
  }

  const [currentSlide, setCurrentSlide] = React.useState(index || 0);
  const [items, setItems] = React.useState(screenshots.sort(sortingFunctionFileUploads));
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const next = () => {
    if (currentSlide + 1 < items.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prev = () => {
    if (currentSlide - 1 >= 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const element = items[currentSlide];
    const target = event.target as HTMLInputElement;
    const newElement = { ...element, fileName: target.value };
    const filtered = items.filter((i) => i.fileUploadId !== element.fileUploadId);
    filtered.splice(currentSlide, 0, newElement);
    setItems(filtered);
  };

  const handleDescriptionChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const element = items[currentSlide];
    const target = event.target as HTMLTextAreaElement;
    const newElement = { ...element, fileDescription: target.value };
    const filtered = items.filter((i) => i.fileUploadId !== element.fileUploadId);
    filtered.splice(currentSlide, 0, newElement);
    setItems(filtered);
  };

  const handleFullscreenClick = () => {
    const element = document.getElementsByClassName("slider-wrapper")[0];
    openFullscreen(element);
  };

  const handleDownloadClick = () => {
    const element = items[currentSlide];
    // window.open(element.fileUri);
    const link = document.createElement("a");
    link.href = element.fileUri!;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onSnapshotDelete = () => {
    const element = items[currentSlide];
    const updatedItems = [...items.splice(currentSlide, 1)];
    handleDelete(element.fileUploadId!);
    setItems(updatedItems);
    setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
    setShowConfirmDelete(false);
  };

  const handleDeleteClick = () => {
    setShowConfirmDelete(true);
  };

  const onDelete = (i: number) => {
    const element = items[currentSlide];
    const updated = element!.tags!.slice(0);
    updated.splice(i, 1);

    const newElement = { ...element, tags: updated };
    const filtered = items.filter((i) => i.fileUploadId !== element.fileUploadId);
    filtered.splice(currentSlide, 0, newElement);
    setItems(filtered);
  };

  const onAddition = (tag: Tag) => {
    const element = items[currentSlide];
    const newTags = element.tags || [];
    const newElement = { ...element, tags: [...newTags, tag.name] };
    const filtered = items.filter((i) => i.fileUploadId !== element.fileUploadId);
    filtered.splice(currentSlide, 0, newElement);
    setItems(filtered);
  };

  const renderDefaultImage = (fileName: string) => {
    return <img className="default-file-image" src={defaultFileImage} alt={fileName} />;
  };

  return (
    <>
      {showConfirmDelete && (
        <ModalConfirm
          width={607}
          height={208}
          title="Delete Snapshot?"
          text="Are you sure you want to delete this snapshot. This action is irreversible."
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => setShowConfirmDelete(false)}
          onConfirm={onSnapshotDelete}
        />
      )}
      <Modal className={"screenshots-overlay-modal"} width={1383} onCloseModal={handleCloseClick}>
        <div className={classes}>
          <div className="screenshots-overlay-left">
            <Typography className="screenshots-overlay-left-title" type={ElementType.h3}>
              {`${runName} Snapshots`}
            </Typography>
            <Typography className="screenshots-overlay-left-eyebrow" color={Color.Secondary} type={ElementType.eyebrow}>
              {currentSlide + 1}/{screenshots.length}
            </Typography>
            <div className="screenshots-overlay-button-row">
              <div>
                <Icon name="fal fa-bookmark" />
                <Typography className="ml05" type={ElementType.inline}>
                  {screenshots[currentSlide].correlationTimestamp
                    ? moment(screenshots[currentSlide].correlationTimestamp).format("hh:mm:ss")
                    : ""}
                </Typography>
              </div>

              <div className="screenshots-overlay-pull-right">
                <Button onClick={handleFullscreenClick} themeType="secondary" size="small" iconLeftName="far fa-expand">
                  View Fullscreen
                </Button>
                <DropdownMenu
                  buttons={[
                    {
                      icon: "fal fa-arrow-alt-to-bottom",
                      name: "Download",
                      onClick: handleDownloadClick,
                      color: styleVariables.color_white,
                    },
                    {
                      icon: "fal fa-trash",
                      name: "Delete",
                      color: styleVariables.color_red,
                      textColor: Color.Error,
                      onClick: handleDeleteClick,
                    },
                  ]}
                  withButton={true}
                  icon={"far fa-ellipsis-v"}
                />
              </div>
            </div>

            <Carousel
              renderArrowPrev={() => (
                <Button
                  className="screenshots-carousel-button screenshots-carousel-button-left"
                  themeType="secondary"
                  onClick={prev}
                  iconOnly={true}
                  iconName="fal fa-chevron-left"
                />
              )}
              renderArrowNext={() => (
                <Button
                  className="screenshots-carousel-button screenshots-carousel-button-right"
                  themeType="secondary"
                  onClick={next}
                  iconOnly={true}
                  iconName="fal fa-chevron-right"
                />
              )}
              renderThumbs={(children: React.ReactChild[]) => children.map((c, index) => <div key={index}>{c}</div>)}
              selectedItem={currentSlide}
              onChange={updateCurrentSlide}
              showStatus={false}
              showIndicators={false}
              autoPlay={false}
              thumbWidth={152}
              useKeyboardArrows={true}
            >
              {screenshots.map((screenshot) => {
                const isImage = isRenderableImage(screenshot!.fileUri!.split(".").pop() ?? "");
                return (
                  <div className="screenshots-carousel-image-container" key={screenshot.fileUploadId}>
                    {isImage ? (
                      <img src={screenshot.imageUri_Large} alt={screenshot.fileName} />
                    ) : (
                      renderDefaultImage(screenshot.fileName!)
                    )}
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="screenshots-overlay-right">
            <div className="screenshots-overlay-pull-right">
              <Icon onClick={handleCloseClick} color={styleVariables.color_white} name="fal fa-times" size={24} />
            </div>
            <Input
              className="screenshot-name"
              value={items[currentSlide]?.fileName!}
              fontSize={24}
              onChange={handleTitleChange}
            />
            <Tags
              className="screenshot-tags"
              suggestions={[
                { id: 1, name: "Best Result" },
                { id: 2, name: "Review Later" },
              ]}
              tags={
                items && items[currentSlide] && items[currentSlide].tags
                  ? //@ts-ignore
                    items[currentSlide].tags.map((tag, index) => ({ id: index, name: tag! }))
                  : []
              }
              onDelete={onDelete}
              onAddition={onAddition}
            />
            <Textarea
              height={"100%"}
              className="screenshot-description"
              placeholder="Enter description"
              value={items[currentSlide]?.fileDescription}
              onChange={handleDescriptionChange}
            />
            <div className="screenshot-additional-info">
              <div className="screenshot-additional-info-row">
                <Typography color={Color.Secondary}>Type:</Typography>
                <Typography color={Color.OffWhite}>
                  {items[currentSlide]?.fileUri?.split(".").pop()?.toUpperCase()}
                </Typography>
              </div>
              <div className="screenshot-additional-info-row">
                <Typography color={Color.Secondary}>Uploaded:</Typography>
                <Typography color={Color.OffWhite}>
                  {moment(items[currentSlide]?.uploadTimestamp).format("YYYY-MM-DD h:mm")}
                </Typography>
              </div>
              <div className="screenshot-additional-info-row">
                <Typography color={Color.Secondary}>Session:</Typography>
                <Typography color={Color.OffWhite}>{sessionName}</Typography>
              </div>
              <div className="screenshot-additional-info-row">
                <Typography color={Color.Secondary}>Part:</Typography>
                <Typography color={Color.OffWhite}>{partName}</Typography>
              </div>
              <div className="screenshot-additional-info-row">
                <Typography color={Color.Secondary}>Feature:</Typography>
                <Typography color={Color.OffWhite}>{featureName}</Typography>
              </div>
            </div>
            <div className="screenshots-overlay-pull-right screenshots-overlay-pull-down">
              <Button onClick={() => handleDoneClick(items)} themeType="clear">
                Done
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export { ScreenshotsOverlay };
