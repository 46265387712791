import { IExecutionPackage } from "@types";
import { useState } from "react";
import ExecutionPackageService from "../../services/ExecutionPackageService";

const useExecutionPackages = () => {
  const [isExecutionPackagesLoading, setIsExecutionPackagesLoading] = useState(false);
  const [isExecutionPackagesError, setIsExecutionPackagesError] = useState(false);

  const [executionPackages, setExecutionPackages] = useState<IExecutionPackage[]>([]);

  const fetchExecutionPackages = (projectId: string) => {
    if (projectId) {
      const fetchData = async () => {
        setIsExecutionPackagesLoading(true);
        ExecutionPackageService.getAvailablePackagePartsByProject(projectId)
          .then((response) => {
            const executionPackages = response.data as IExecutionPackage[];
            setExecutionPackages(executionPackages);
            setIsExecutionPackagesLoading(false);
          })
          .catch((e) => {
            setIsExecutionPackagesLoading(false);
            setIsExecutionPackagesError(true);
            console.log(e);
          });
      };
      fetchData();
    }
  };

  return {
    executionPackages,
    isExecutionPackagesLoading,
    isExecutionPackagesError,
    fetchExecutionPackages,
    setExecutionPackages,
  };
};

export default useExecutionPackages;
