import * as React from "react";
import { Card } from "../Card";
import { Color, Typography } from "../Common/Typography";

const AddAnotherPart: React.FC<
  {
    className?: string;
    onClick?: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", onClick = () => {} }): React.ReactElement => (
  <Card className={`add-another-part ${className}`} height={137} hover={false} onClick={onClick} width="100%">
    <Typography color={Color.Secondary} fontSize={20}>
      + Add another part
    </Typography>
  </Card>
);

export { AddAnotherPart };
