import React, { FC, HTMLAttributes, ReactElement } from "react";
import { default as cx } from "classnames";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { v4 as uuidv4 } from "uuid";

import { IExecutionPackagePart, IMachineRun, IArcadiaMaterial, IDropdownItem, IExecutionPackage } from "@types";
import { Color, ElementType, Typography } from "../Common/Typography";
import DropdownSelection from "../DropdownSelection";
import { FormSection } from "../FormSection";
import { Icon } from "../Icon";
import { useState } from "react";
import { RunStatus } from "../SessionRow";
import { StatusIcon } from "../StatusIcon";
import { Status } from "../../constants/enums/Status";
import { Button } from "../Button";
import { color_gray } from "@styles/export.scss";
import { Input } from "../Common/Input";
import { InfoIcon } from "../InfoIcon";

import styleVariables from "@styles/export.scss";
import LiveSessionDropdownActions from "../LiveSessionDropdownActions";
import { ScrollableContainer } from "../ScrollableContainer";

const EditRunPlanTable: React.FC<
  {
    className?: string;
    runs: IMachineRun[];
    materials: IArcadiaMaterial[];
    fixtures: IArcadiaMaterial[];
    executionPackages: IExecutionPackage[];
    fixtureId: string;
    sessionId: string;
    scrollable?: boolean;
    setIsUpdated: (value: boolean) => void;
    setRuns: (runs: IMachineRun[]) => void;
    onClickEdit?: (run: IMachineRun) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  runs,
  materials,
  fixtures,
  executionPackages,
  fixtureId,
  sessionId,
  scrollable = true,
  setIsUpdated,
  setRuns,
  onClickEdit,
}): React.ReactElement => {
  const classes = cx(
    {
      "run-plan-table": true,
      scrollable: scrollable,
    },
    className
  );
  const [runsInEditMode, setRunsInEditMode] = useState<Map<string, boolean>>(new Map<string, boolean>());

  const getNewRunName = () => {
    const number = runs.filter((r) => r.isNew === true).length + 1;
    const result = `New Run ${number}`;
    return result;
  };

  const addRun = () => {
    const runId = uuidv4();
    const newRunName = getNewRunName();
    const newRun: IMachineRun = {
      runId: runId,
      isNew: true,
      fixtureId: fixtureId,
      sessionId: sessionId,
      runStatus: RunStatus.NotQueued,
      runName: newRunName,
    };
    const updatedRuns: IMachineRun[] = [...runs, newRun];

    setRuns(updatedRuns);
    setIsUpdated(true);
  };

  const duplicateItem = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>, runId: string) => {
    ev.preventDefault();
    const run = runs.find((i: any) => i.runId === runId);
    if (!run) {
      throw new Error("Run not found");
    }
    const newRunId = uuidv4();
    const newItem = {
      ...run,
      runId: newRunId,
      isNew: true,
      runStatus: RunStatus.NotQueued,
      runName: `Copy of ${run.runName}`,
    };
    setRuns([...runs, newItem]);
    setIsUpdated(true);
  };

  const deleteItem = (runId: string) => {
    const itemIndex = runs.map((e: any) => e.runId).indexOf(runId);
    runs.splice(itemIndex, 1);
    setRuns([...runs]);
    setIsUpdated(true);
  };

  const editRun = (runId: string, propName: string, propValue: string) => {
    const run = runs.find((r) => r.runId === runId);
    if (!run) {
      throw new Error("Run not found");
    }
    const updatedRun = {
      ...run,
      [propName]: propValue,
    };
    if (propName === "programName") {
      updatedRun.ncProgramCommitId = "";
      updatedRun.parameterCommitId = "";
      updatedRun.jobCommitId = "";
    }

    const itemIndex = runs.map((e: any) => e.runId).indexOf(runId);
    runs.splice(itemIndex, 1, updatedRun);
    setRuns([...runs]);
    setIsUpdated(true);
  };

  const mapRunStatusToStatus = (runStatus: RunStatus) => {
    let result: Status = Status.Done;
    switch (runStatus) {
      case RunStatus.Aborted:
      case RunStatus.Unknown:
      case RunStatus.NotQueued:
      case RunStatus.Queued:
        result = Status.NotStarted;
        break;

      case RunStatus.Running:
        result = Status.InProgress;
        break;
      case RunStatus.Complete:
        result = Status.Done;
        break;
      default:
        break;
    }
    return result;
  };

  const handleRunNameInputBlur = (runId: string) => {
    const clone = new Map(runsInEditMode);
    clone.set(runId, false);
    setRunsInEditMode(clone);
  };

  const handleRunNameClick = (run: IMachineRun) => {
    const clone = new Map(runsInEditMode);
    clone.set(run.runId!, true);
    setRunsInEditMode(clone);
  };

  const DragHandle = SortableHandle(() => <Icon name="far fa-grip-lines" color={color_gray} />);
  //@ts-ignore
  const SortableItem = SortableElement(({ identifier, children }) => (
    <tr key={`key-${identifier}`} style={{ zIndex: 999 }}>
      {children}
    </tr>
  ));
  //@ts-ignore
  const SortableContainerComponent = SortableContainer(({ children }) => {
    return <div>{children}</div>;
  });
  //@ts-ignore
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updated = arrayMove(
      runs.filter((r) => r.runStatus === RunStatus.NotQueued),
      oldIndex,
      newIndex
    );

    setRuns([...runs.filter((r) => r.runStatus !== RunStatus.NotQueued), ...updated]);
    setIsUpdated(true);
  };

  const renderTableHeaderRow = (className: string, text: string, tooltipText?: string) => {
    return (
      <th className={className}>
        <Typography type={ElementType.tableHeader} color={Color.OffWhite}>
          {text}
          {tooltipText && (
            <InfoIcon className=" ml05" color={styleVariables.color_gray} placement="top" tooltipText={tooltipText} />
          )}
        </Typography>
      </th>
    );
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <div>
          <tr className="table-header-row">
            {renderTableHeaderRow("table-header-small", "")}
            {renderTableHeaderRow("table-header table-header-padding", "Run Name")}
            {renderTableHeaderRow("table-header", "Program", "Tooltip text")}
            {renderTableHeaderRow("table-header", "NC Code Ver", "Tooltip text")}
            {renderTableHeaderRow("table-header", "Parameters Ver", "Tooltip text")}
            {renderTableHeaderRow("table-header", " Jobs Ver", "Tooltip text")}
            {renderTableHeaderRow("table-header", " Material")}
            {renderTableHeaderRow("table-header-grayrow", "")}
            {renderTableHeaderRow("table-header-small", "")}
          </tr>
        </div>
      </thead>
    );
  };

  const renderNoProgramDropdown = (placeholderText: string) => {
    return (
      <DropdownSelection
        size="small"
        disableOptions={true}
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={Color.Secondary} type={ElementType.bodySmall}>
              {placeholderText}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={[{ name: "Select a program to view options", onClick: () => {} }]}
      />
    );
  };

  const renderDropdownPrograms = (run: IMachineRun) => {
    const isDisabled = run.runStatus !== RunStatus.NotQueued;
    let color = Color.Primary;
    let value = executionPackages.find((j) => j.packageName === run.programName)?.packageName;
    if (!value) {
      color = Color.Secondary;
      value = "Select Program";
    }
    if (isDisabled) {
      color = Color.OffWhite;
    }
    return (
      <DropdownSelection
        disabled={isDisabled}
        size="small"
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={color} type={ElementType.bodySmall}>
              {value}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={executionPackages.map((p) => {
          const callback = () => {
            editRun(run.runId!, "programName", p.packageName || "");
          };

          return {
            name: p.packageName,
            onClick: callback,
          } as IDropdownItem;
        })}
      />
    );
  };

  const renderDropdownNCCodeVer = (run: IMachineRun) => {
    const isDisabled = run.runStatus !== RunStatus.NotQueued;
    let color = Color.Primary;
    let value = executionPackages
      .find((p) => p.packageName === run.programName)
      ?.ncPrograms.find((p: IExecutionPackagePart) => p.commitId === run.ncProgramCommitId)?.title;
    if (!value) {
      color = Color.Secondary;
      value = "Select NC Code Ver";
    }
    if (isDisabled) {
      color = Color.OffWhite;
    }
    return (
      <DropdownSelection
        disabled={isDisabled}
        size="small"
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={color} type={ElementType.bodySmall}>
              {value}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={
          run.programName
            ? executionPackages
                .find((p) => p.packageName === run.programName)
                ?.ncPrograms.map((program: IExecutionPackagePart) => {
                  const callback = () => {
                    editRun(run.runId!, "ncProgramCommitId", program.commitId || "");
                  };

                  return {
                    name: program.title,
                    onClick: callback,
                  } as IDropdownItem;
                })
            : []
        }
      />
    );
  };

  const renderDropdownParametersVer = (run: IMachineRun) => {
    const isDisabled = run.runStatus !== RunStatus.NotQueued;
    let color = Color.Primary;
    let value = executionPackages
      .find((p) => p.packageName === run.programName)
      ?.parameters.find((p) => p.commitId === run.parameterCommitId)?.title;
    if (!value) {
      color = Color.Secondary;
      value = "Select Parameters Ver";
    }
    if (isDisabled) {
      color = Color.OffWhite;
    }
    return (
      <DropdownSelection
        disabled={isDisabled}
        size="small"
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={color} type={ElementType.bodySmall}>
              {value}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={
          run.programName
            ? executionPackages
                .find((p) => p.packageName === run.programName)
                ?.parameters.map((parameter) => {
                  const callback = () => {
                    editRun(run.runId!, "parameterCommitId", parameter.commitId || "");
                  };

                  return {
                    name: parameter.title,
                    onClick: callback,
                  } as IDropdownItem;
                })
            : []
        }
      />
    );
  };

  const renderDropdownJobsVer = (run: IMachineRun) => {
    const isDisabled = run.runStatus !== RunStatus.NotQueued;
    let color = Color.Primary;
    let value = executionPackages
      .find((p) => p.packageName === run.programName)
      ?.jobs.find((j) => j.commitId === run.jobCommitId)?.title;
    if (!value) {
      color = Color.Secondary;
      value = "Select Jobs Ver";
    }
    if (isDisabled) {
      color = Color.OffWhite;
    }
    return (
      <DropdownSelection
        disabled={isDisabled}
        size="small"
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={color} type={ElementType.bodySmall}>
              {value}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={
          run.programName
            ? executionPackages
                .find((p) => p.packageName === run.programName)
                ?.jobs.map((job) => {
                  const callback = () => {
                    editRun(run.runId!, "jobCommitId", job.commitId || "");
                  };

                  return {
                    name: job.title,
                    onClick: callback,
                  } as IDropdownItem;
                })
            : []
        }
      />
    );
  };

  const renderDropdownMaterial = (run: IMachineRun) => {
    const isDisabled = run.runStatus !== RunStatus.NotQueued;
    let color = Color.Primary;
    let value = materials.find((m) => m.sku === run.materialId)?.materialName;
    if (!value) {
      color = Color.Secondary;
      value = "Select Material";
    }
    if (isDisabled) {
      color = Color.OffWhite;
    }
    return (
      <DropdownSelection
        disabled={isDisabled}
        size="small"
        renderedComponent={
          <div className="run-plan-table-row-content">
            <Typography color={color} type={ElementType.bodySmall}>
              {value}
            </Typography>
            <Icon name="far fa-chevron-down" className="run-plan-table-row-content-arrow" size={12} />
          </div>
        }
        items={materials.map((material) => {
          const callback = () => {
            editRun(run.runId!, "materialId", material.sku || "");
          };

          return {
            name: material.materialName,
            onClick: callback,
          } as IDropdownItem;
        })}
      />
    );
  };

  const renderTableRow = (run: IMachineRun, machineRunStatus: Status) => (
    <>
      <td className="run-plan-table-row-small">
        {machineRunStatus === Status.NotStarted ? <DragHandle /> : <StatusIcon status={machineRunStatus} />}
      </td>
      <td className="run-plan-table-row">
        {runsInEditMode.has(run.runId!) && runsInEditMode.get(run.runId!) === true ? (
          <Input
            autoFocus
            height={30}
            width={212}
            onChange={(ev: any) => editRun(run.runId!, "runName", ev.target.value)}
            onBlur={() => handleRunNameInputBlur(run.runId!)}
            onKeyDown={(ev) => {
              if (ev.keyCode === 13) {
                handleRunNameInputBlur(run.runId!);
              }
            }}
            value={run.runName || ""}
          />
        ) : (
          <div
            tabIndex={0}
            role="button"
            className="run-plan-table-row-run-name"
            onKeyDown={() => handleRunNameClick(run)}
            onClick={() => handleRunNameClick(run)}
          >
            {run.runName}
          </div>
        )}
      </td>
      <td className="run-plan-table-row">{renderDropdownPrograms(run)}</td>
      <td className="run-plan-table-row">
        {run.programName ? renderDropdownNCCodeVer(run) : renderNoProgramDropdown("Select NC Code Ver")}
      </td>
      <td className="run-plan-table-row">
        {run.programName ? renderDropdownParametersVer(run) : renderNoProgramDropdown("Select Parameters Ver")}
      </td>
      <td className="run-plan-table-row">
        {run.programName ? renderDropdownJobsVer(run) : renderNoProgramDropdown("Select Jobs Ver")}
      </td>
      <td className="run-plan-table-row">{renderDropdownMaterial(run)}</td>
      <td className="run-plan-table-grayrow">
        <Button
          themeType="simple"
          iconOnly={true}
          iconName={"fal fa-copy"}
          tooltip="Duplicate"
          iconColor={styleVariables.color_gray}
          onClick={(ev) => duplicateItem(ev, run.runId!)}
        />
      </td>
      {run.runStatus === RunStatus.NotQueued && (
        <td className="run-plan-table-row-small">
          <div className="dropdown-actions-container">
            <LiveSessionDropdownActions
              onClickEdit={onClickEdit ? () => onClickEdit(run) : undefined}
              onClickDelete={() => deleteItem(run.runId!)}
            />
          </div>
        </td>
      )}
    </>
  );

  return (
    <form className={classes}>
      <>
        <div className="run-plan-table-add-button-container">
          <Typography type={ElementType.h5} className="run-plan-table-title">
            {fixtures.find((f) => f.sku === fixtureId)?.materialName}
          </Typography>
          <Button
            iconLeftName={"fa fa-plus"}
            onClick={(ev) => {
              ev.preventDefault();
              addRun();
            }}
            themeType="secondary"
            size="small"
          >
            Add run
          </Button>
        </div>
        <FormSection width="100%" secondary>
          <table>
            {renderTableHeader()}
            <ScrollableContainer height={550}>
              <tbody>
                <div>
                  {runs
                    .filter((run) => run.runStatus !== RunStatus.NotQueued)
                    .map((run) => {
                      const machineRunStatus = mapRunStatusToStatus(run.runStatus as RunStatus);
                      return <tr key={run.runId}>{renderTableRow(run, machineRunStatus)}</tr>;
                    })}
                </div>
                <SortableContainerComponent onSortEnd={onSortEnd} key={fixtureId} useDragHandle>
                  {runs
                    .filter((run) => run.runStatus === RunStatus.NotQueued)
                    .map((run, index) => {
                      const machineRunStatus = mapRunStatusToStatus(run.runStatus as RunStatus);
                      return (
                        <SortableItem identifier={run.runId} index={index} key={`item-${run.runId}`}>
                          {renderTableRow(run, machineRunStatus)}
                        </SortableItem>
                      );
                    })}
                </SortableContainerComponent>
                <div>
                  <tr>
                    <td className="run-plan-table-row-small"></td>
                    <td className="run-plan-table-addrun" colSpan={7}>
                      <Typography type={ElementType.inline} onClick={addRun} color={Color.Secondary}>
                        + Add Run
                      </Typography>
                    </td>
                  </tr>
                </div>
              </tbody>
            </ScrollableContainer>
          </table>
        </FormSection>
      </>
    </form>
  );
};

export { EditRunPlanTable };
