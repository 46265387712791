import axios from "axios";
import { IArcadiaFileUpload } from "@types";

const uploadFile = (formData: FormData) => {
  return axios.post(`/uploads/upload`, formData);
};

const updateUpload = (upload: IArcadiaFileUpload) => {
  return axios.put(`/uploads/uploads/edit`, upload);
};

const deleteUpload = (projectId: string, fileUploadId: string) => {
  return axios.delete(`/uploads/${projectId}/${fileUploadId}`);
};

const getSessionUploads = (projectId: string, sessionId: string) => {
  return axios.get(`/uploads/sessionuploads/${projectId}/${sessionId}`);
};

const getSasUri = (file: IArcadiaFileUpload) => {
  return axios.post(`/uploads/getSasUri/`, file);
};

export default {
  uploadFile,
  updateUpload,
  getSessionUploads,
  deleteUpload,
  getSasUri,
};
