import React, { FC, HTMLAttributes, ReactElement } from "react";
import { default as cx } from "classnames";
import moment from "moment";

import styleVariables from "@styles/export.scss";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { Thumbnail } from "../Thumbnail";
import { Icon } from "../Icon";

const ModalAddSnapshot: FC<
  {
    className?: string;
    onCloseModal: () => void;
    firstImage: string;
    secondImage: string;
    timestamp: string;
    saveSnapshot: (imageData: string, timestamp: string) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", firstImage, secondImage, saveSnapshot, onCloseModal, timestamp }): ReactElement => {
  const classes = cx(
    {
      "modal-add-snapshot": true,
    },
    className
  );

  return (
    <Modal className={classes} width="auto" onCloseModal={onCloseModal}>
      <div className="modal-add-snapshot-top">
        <Typography type={ElementType.h4}>Add Snapshot</Typography>
        <div className="modal-add-snapshot-top-timestamp">
          <Icon name="fas fa-bookmark" color={styleVariables.color_orange} />
          <Typography className="ml05" type={ElementType.bodySmall}>
            {moment(timestamp).format("hh:mm:ss")}
          </Typography>
        </div>
      </div>
      <Typography className="modal-confirm-text" type={ElementType.body} color={Color.Secondary}>
        Select the image you want to save
      </Typography>
      <div className="modal-add-snapshot-bottom">
        <Thumbnail
          className="mr2-5"
          title={"first image"}
          round={false}
          width={193}
          height={110}
          borderRadius={0}
          backgroundImageUrl={firstImage}
          onClick={() => saveSnapshot(firstImage, timestamp)}
        />
        <Thumbnail
          title={"first image"}
          round={false}
          width={193}
          height={110}
          borderRadius={0}
          backgroundImageUrl={secondImage}
          onClick={() => saveSnapshot(secondImage, timestamp)}
        />
      </div>
    </Modal>
  );
};

export { ModalAddSnapshot };
