import { IArcadiaUser } from "@types";
import { useState } from "react";
import UserService from "../../services/UserService";

const useUsers = () => {
  const [isUsersLoading, setIsLoading] = useState(false);
  const [isUsersError, setIsError] = useState(false);
  const [users, setUsers] = useState<IArcadiaUser[] | null>(null);

  const fetchUsers = (groupId: string) => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      UserService.getUsers(groupId)
        .then((response: any) => {
          let result = response.data as IArcadiaUser[];
          setUsers(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return { users, isUsersLoading, isUsersError, fetchUsers, setUsers };
};

export default useUsers;
