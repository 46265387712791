import { IArcadiaMachineSchedule } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useScheduledSessions = () => {
  const [isScheduledSessionsLoading, setIsLoading] = useState(false);
  const [isScheduledSessionsError, setIsErrorError] = useState(false);

  const [scheduledSessions, setScheduledSessions] = useState<IArcadiaMachineSchedule[]>([]);

  const fetchScheduledSessions = () => {
    const fetchData = async () => {
      setIsLoading(true);
      ProjectsService.getAllScheduledSessions()
        .then((response) => {
          const scheduledSessions = response.data as IArcadiaMachineSchedule[];
          setScheduledSessions(scheduledSessions);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsErrorError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return { scheduledSessions, isScheduledSessionsLoading, isScheduledSessionsError, fetchScheduledSessions };
};

export default useScheduledSessions;
