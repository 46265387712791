import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import {
  ActionItems,
  Button,
  EquipmentDrawer,
  FileUploads,
  Folders,
  MachineSessions,
  ModalAddProgram,
  ModalConfirm,
  ProjectSettings,
  ModalWithIcon,
  NextMachineSession,
  ProgramDetails,
  Programs,
  ProjectSessionHeader,
  ProjectsSidebar,
  ScrollableContainer,
  Tabs,
  Thumbnail,
  Typography,
  FilePaths,
  FileModal,
  ModalUploadFile,
  ModalImageUpload,
  ModalEditZoomInfo,
} from "@namespace/components";
import {
  IArcadiaFavorite,
  IArcadiaFileUpload,
  IArcadiaMaterial,
  IArcadiaProject,
  IExecutionPackage,
  IExecutionPackagePart,
  ITab,
} from "@types";
import { useHistory, useParams } from "react-router-dom";
import TooltipTrigger, { ChildrenArg, TooltipArg } from "react-popper-tooltip";

import { Status } from "@namespace/components/src/constants/enums/Status";
import { Color, ElementType } from "@namespace/components/src/components/Common/Typography";
import { CircleStatus } from "@namespace/components/src/components/Common/CircleStatus";
import uniqid from "uniqid";
import Tour from "reactour";
import moment from "moment";
import useProjects from "../../hooks/API/useProjects";
import useProject from "../../hooks/API/useProject";
import useMaterials from "../../hooks/API/useMaterials";
import useMaterialCategories from "../../hooks/API/useMaterialCategories";
import useTasks from "../../hooks/API/useTasks";
import useExecutionPackages from "../../hooks/API/useExecutionPackages";
import useNotification from "../../hooks/useNotification";
import useProcesses from "../../hooks/API/useProcesses";
import { IFavorite } from "@types";
import MaterialService from "../../services/MaterialService";
import BuilderService from "../../services/BuilderService";
import ProjectsService from "../../services/ProjectsService";
import useUser from "../../hooks/API/useUser";
import UploadsService from "../../services/UploadsService";
import UserService from "../../services/UserService";
import useUnitsOfMeasure from "../../hooks/API/useUnitsOfMeasure";
import ProjectSkeletonLoader from "./ProjectSkeletonLoader";
import { allSettled } from "../../utils";
import { AxiosResponse } from "axios";
import handleError from "../../hooks/useErrorHandler";
import useSessionHubConnection from "../../hooks/API/useSessionHubConnection";

const plus = require("@namespace/components/src/images/plus.svg") as string;
export interface RouteParams {
  id: string;
  onboarding?: string;
}

const rfc6902 = require("rfc6902");

const Project: FC<{ favorites: IFavorite[]; onFavoriteClick: (favorite: IFavorite) => void }> = ({
  favorites,
  onFavoriteClick,
}): ReactElement => {
  const [showOnboardingModal, setShowOnboardingModal] = useState<boolean>(false);
  const [showModalEditZoomInfo, setShowModalEditZoomInfo] = useState<boolean>(false);
  const [showModalImageUpload, setShowModalImageUpload] = useState<boolean>(false);
  const [showUploadModalNcCode, setShowUploadModalNcCode] = useState(false);
  const [showUploadModalJobs, setShowUploadModalJobs] = useState(false);
  const [showUploadModalParameters, setShowUploadModalParameters] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showModalCopyToNew, setShowModalCopyToNew] = useState(false);
  const [programNameForUpdate, setProgramNameForUpdate] = useState<string | null>(null);
  const [isTourOpen, setIsTourOpen] = useState<boolean>(false);
  const [filteredFolder, setfilteredFolder] = useState<FilePaths | undefined>(undefined);
  const [selectedProgram, setSelectedProgram] = useState<IExecutionPackage | undefined>(undefined);
  const [draggedFile, setDraggedFile] = useState<File | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const { id, onboarding } = useParams<RouteParams>();
  const history = useHistory();
  const notification = useNotification();
  const error = handleError();
  const tourRef = useRef<Tour>();

  const { projects, fetchProjects } = useProjects();
  const { project, fetchProjectById, setProject } = useProject();
  const { tasks, fetchTasks } = useTasks();
  const { materials, fixtures, fetchMaterials } = useMaterials();
  // const { materialTypes, fetchMaterialTypes } = useMaterialTypes();
  const { unitsOfMeasure, fetchUnitsOfMeasure } = useUnitsOfMeasure();
  const { materialCategories, fetchMaterialCategories } = useMaterialCategories();
  const { processes, fetchProcesses } = useProcesses();
  const { executionPackages, fetchExecutionPackages, setExecutionPackages } = useExecutionPackages();
  const { user, fetchUser, setUser } = useUser();
  const { sessionHubConnection } = useSessionHubConnection();

  const goToRescheduleSessions = () => {};

  const goToChangeTemplate = () => {};
  const [tempFiles, setTempFiles] = useState<IArcadiaFileUpload[]>([]);
  const [filteredFiles, setFilteredFiles] = useState<IArcadiaFileUpload[]>([]);
  const [favoriteFiles, setFavoriteFiles] = useState<IArcadiaFileUpload[]>([]);
  const [favoriteProjects, setFavoriteProjects] = useState<IArcadiaProject[]>([]);

  const [showFilesModal, setShowFilesModal] = useState(false);
  const [showModalFileUpload, setShowModalFileUpload] = useState(false);
  const [showConfirmDeleteFileModal, setShowConfirmDeleteFileModal] = useState(false);
  const [fileForEditing, setFileForEditing] = useState<IArcadiaFileUpload | null>(null);

  const handleDoneClick = async (file: IArcadiaFileUpload) => {
    updateFile(file);
    setShowFilesModal(false);
  };

  const handleNextClick = (file: IArcadiaFileUpload) => {
    updateFile(file);
    const index = filteredFiles.map((f) => f.fileUploadId!).indexOf(fileForEditing?.fileUploadId!);
    if (index < filteredFiles.length - 1) {
      setFileForEditing(filteredFiles[index + 1]);
    }
  };

  const handleDeleteClick = async () => {
    setShowFilesModal(false);
    setShowConfirmDeleteFileModal(true);
  };

  const deleteFile = async () => {
    if (project && project?.arcadiaProjectId && fileForEditing) {
      const index = tempFiles.map((f) => f.fileUploadId!).indexOf(fileForEditing?.fileUploadId!);
      tempFiles.splice(index, 1);
      setTempFiles([...tempFiles]);
      const indexFiltered = filteredFiles.map((f) => f.fileUploadId!).indexOf(fileForEditing?.fileUploadId!);
      filteredFiles.splice(indexFiltered, 1);
      setFilteredFiles([...filteredFiles]);
      UploadsService.deleteUpload(project?.arcadiaProjectId!, fileForEditing?.fileUploadId!)
        .then(() => {
          notification("File deleted", "neutral");
          setShowConfirmDeleteFileModal(false);
          if (filteredFiles && filteredFiles.length > 0) {
            if (index <= filteredFiles.length - 1) {
              setFileForEditing(filteredFiles[0]);
            } else {
              setFileForEditing(filteredFiles[0]);
            }
            setShowFilesModal(true);
          } else {
            setFileForEditing(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePreviousClick = (file: IArcadiaFileUpload) => {
    updateFile(file);
    const index = filteredFiles.map((f) => f.fileUploadId!).indexOf(fileForEditing?.fileUploadId!);
    if (index > 0) {
      setFileForEditing(filteredFiles[index - 1]);
    }
  };

  const handleCloseClick = () => {
    setShowFilesModal(false);
  };

  useEffect(() => {
    if (project && project?.fileUploads) {
      setTempFiles(project?.fileUploads);
    }
  }, [project]);

  useEffect(() => {
    const filtered = tempFiles.filter((file) => !filteredFolder || file.userPath === filteredFolder);
    setFilteredFiles(filtered);
  }, [filteredFolder, tempFiles]);

  useEffect(() => {
    if (user) {
      const favoritesFromUser = user.favorites.filter((f) => f.type === "file");
      if (favoritesFromUser && favoritesFromUser.length > 0) {
        const idsFromUser = favoritesFromUser.map((f) => f.id);
        const favoriteFilesNew = filteredFiles.filter((f) => idsFromUser.indexOf(f.fileUploadId) >= 0);
        if (favoriteFilesNew) {
          setFavoriteFiles(favoriteFilesNew);
        }
      } else {
        setFavoriteFiles([]);
      }
    }
  }, [filteredFiles, user]);

  useEffect(() => {
    if (user) {
      const favoriteProjectsFromUser = user.favorites.filter((f) => f.type === "project");
      if (favoriteProjectsFromUser && favoriteProjectsFromUser.length > 0) {
        const idsFromUser = favoriteProjectsFromUser.map((f) => f.id);
        const favoriteProjectsNew = projects.filter((p) => idsFromUser.indexOf(p.arcadiaProjectId) >= 0);
        if (favoriteProjectsNew) {
          setFavoriteProjects(favoriteProjectsNew);
        }
      } else {
        setFavoriteProjects([]);
      }
    }
  }, [projects, user]);

  const updateTempFiles = (file: IArcadiaFileUpload) => {
    const index = tempFiles.map((f) => f.fileUploadId).indexOf(file.fileUploadId);
    tempFiles.splice(index, 1, file);
    setTempFiles([...tempFiles]);
  };

  useEffect(() => {
    fetchProjects();
    fetchUser();
    fetchProcesses();
    // fetchMaterialTypes();
    fetchUnitsOfMeasure();
    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      fetchMaterialCategories(user.userId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setSelectedProgram(undefined);
    if (project) {
      const projectId = project.arcadiaProjectId!;
      fetchExecutionPackages(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.arcadiaProjectId]);

  useEffect(() => {
    if (executionPackages && executionPackages.length > 0) {
      if (!selectedProgram) {
        setSelectedProgram(executionPackages[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionPackages]);
  const stepsData = {
    style: {
      backgroundColor: "#303030",
      padding: "3rem",
      minWidth: "460px",
    },
    stepInteraction: false,
  };

  const steps = [
    {
      selector: ".machine-sessions",
      content: (
        <div>
          <Typography type={ElementType.h4}>Access to the machines</Typography>
          <br />
          <Typography type={ElementType.body}>
            We pre-booked the time that we think you'll need on the machines to get your parts prototyped, perfected,
            and produced.
          </Typography>
          <br />
          <div className="tour-buttons">
            {/* @ts-ignore */}
            <Button onClick={tourRef.current?.nextStep}>Next</Button>
          </div>
        </div>
      ),
      ...stepsData,
    },
    {
      selector: ".machine-sessions-row",
      content: (
        <div>
          <Typography type={ElementType.h4}>Schedule your sessions</Typography>
          <br />
          <Typography type={ElementType.body}>
            You can reschedule or cancel these existing reservations, or add additional sessions at any time.
          </Typography>
          <div className="tour-buttons">
            <button className="buttonAsLink" onClick={goToRescheduleSessions}>
              <Typography type={ElementType.textLink}>Reschedule Sessions</Typography>
            </button>
            {/* @ts-ignore */}
            <Button onClick={tourRef.current?.nextStep}>Next</Button>
          </div>
        </div>
      ),
      ...stepsData,
    },
    {
      selector: ".action-items",
      content: (
        <div>
          <Typography type={ElementType.h4}>Review your workflow</Typography>
          <br />
          <Typography type={ElementType.body}>
            Your action items are a to-do list of steps to successfully complete the project and stay on track. Take a
            moment to review the name, status and due dates to fit the needs of this project, or change the template for
            a list of items that are better suited for your project.
          </Typography>
          <div className="tour-buttons">
            <button className="buttonAsLink" onClick={goToChangeTemplate}>
              <Typography type={ElementType.textLink}>Change template</Typography>
            </button>
            {/* @ts-ignore */}
            <Button onClick={tourRef.current?.nextStep}>Next</Button>
          </div>
        </div>
      ),
      ...stepsData,
    },
    {
      selector: ".action-items-group-row",
      content: (
        <div>
          <Typography type={ElementType.h4}>Edit your action items</Typography>
          <br />
          <Typography type={ElementType.body}>
            Your action items are a to-do list of steps to successfully complete the project and stay on track. Take a
            moment to review the name, status and due dates to fit the needs of this project.
          </Typography>
          <div className="tour-buttons">
            <Button
              onClick={() => {
                setIsTourOpen(false);
              }}
            >
              Finish Tour
            </Button>
          </div>
        </div>
      ),
      ...stepsData,
    },
  ];

  const startTour = () => {
    setShowOnboardingModal(false);
    setIsTourOpen(true);
  };

  // const onDragEnd = async (newTime: number, subTask?: IArcadiaSubTask) => {
  //   if (!subTask) {
  //     return;
  //   }

  //   const { data } = await axios.put(
  //     `/Projects/UpdateSubTask/${project?.arcadiaProjectId}/${subTask.subtaskId}`,
  //     {
  //       ...subTask,
  //       dueDate: new Date(newTime).toISOString(),
  //     } as IArcadiaSubTask
  //   );

  //   if (data) {
  //     const { data: newTasks } = await axios.get(`/Projects/session/${id}/tasks`);
  //     setTasks(newTasks);
  //   }
  // };

  useEffect(() => {
    if (sessionHubConnection) {
      sessionHubConnection.on("startSession", () => {
        fetchProjectById(id);
      });

      sessionHubConnection.on("stopSession", () => {
        fetchProjectById(id);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHubConnection, id]);

  useEffect(() => {
    fetchProjectById(id);
    fetchTasks(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, onboarding]);

  useEffect(() => {
    if (onboarding === "onboarding") {
      setShowOnboardingModal(true);
    }
  }, [onboarding]);

  const handleFavoriteClick = async (favorite: IFavorite) => {
    onFavoriteClick(favorite);
    let userFavorites = user?.favorites || [];
    if (userFavorites && userFavorites.length > 0) {
      const projectIds = userFavorites.filter((f) => f.type === "project").map((f) => f.id);
      if (projectIds.indexOf(favorite.projectId) >= 0) {
        userFavorites = userFavorites.filter((p) => p.id !== favorite.projectId);
      } else {
        userFavorites.push({ type: "project", id: favorite.projectId });
      }
    } else {
      userFavorites.push({ type: "project", id: favorite.projectId });
    }
    const result = await UserService.updateFavorites(userFavorites);
    const updatedFavorites = result.data as IArcadiaFavorite[];
    setUser({ ...user!, favorites: updatedFavorites });
  };

  const handleFavoriteFileClick = async (fileId: string) => {
    let userFavorites = user?.favorites || [];
    if (favoriteFiles && favoriteFiles.length > 0) {
      const fileIds = favoriteFiles.map((f) => f.fileUploadId);
      if (fileIds.indexOf(fileId) >= 0) {
        const filtered = favoriteFiles.filter((p) => p.fileUploadId !== fileId);
        const filteredIds = filtered.map((f) => f.fileUploadId);
        userFavorites = userFavorites.filter(
          (p) => p.type !== "file" || (p.type === "file" && filteredIds.indexOf(p.id) >= 0)
        );
      } else {
        userFavorites.push({ type: "file", id: fileId });
      }
    } else {
      userFavorites.push({ type: "file", id: fileId });
    }
    try {
      const result = await UserService.updateFavorites(userFavorites);
      const updatedFavorites = result.data as IArcadiaFavorite[];
      setUser({ ...user!, favorites: updatedFavorites });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadFileClick = (file?: File) => {
    if (file) {
      setDraggedFile(file);
    }
    setShowModalFileUpload(true);
  };

  const handleCloseModalFileUpload = () => {
    setDraggedFile(null);
    setShowModalFileUpload(false);
  };

  const goToNewSession = () => {
    history.push(`/prepare/${project?.arcadiaProjectId}`);
  };

  const onDeleteClick = async (sessionId: string, projectId: string) => {
    try {
      await ProjectsService.deleteSession(projectId, sessionId);
      notification("Session cancelled", "neutral");
      fetchProjectById(projectId);
    } catch (error) {
      console.log(error);
    }
  };

  const onStartClick = (sessionId: string) => {
    setSessionId(sessionId);
    setShowModalEditZoomInfo(true);
  };

  const handleEditZoomInfo = async (meetingNumber: string) => {
    if (sessionId) {
      try {
        await ProjectsService.editZoomMeeting(project?.arcadiaProjectId!, sessionId, meetingNumber);
        await ProjectsService.startSession(project?.arcadiaProjectId!, sessionId);
        setShowModalEditZoomInfo(false);
        notification("Session started", "neutral");
        history.push(`/livesession/${project?.arcadiaProjectId}/${sessionId}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleStartSession = async () => {
    if (sessionId) {
      try {
        await ProjectsService.startSession(project?.arcadiaProjectId!, sessionId);
        setShowModalEditZoomInfo(false);
        notification("Session started", "neutral");
        history.push(`/livesession/${project?.arcadiaProjectId}/${sessionId}`);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // const onStopClick = async (sessionId: string, projectId: string) => {
  //   try {
  //     await ProjectsService.stopSession(projectId, sessionId, "test");
  //     notification("Session stopped", "neutral");
  //     fetchProjectById(projectId);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onFolderClick = (folder?: FilePaths) => {
    setfilteredFolder(folder);
  };

  const onProgramClick = (program?: IExecutionPackage) => {
    setSelectedProgram(program);
  };

  const createMaterial = async (material: IArcadiaMaterial, files?: File[]) => {
    try {
      material.ownerId = user?.userId!;
      delete material.imageUrls;
      const result = await MaterialService.create(material);
      const newMaterial = result.data as IArcadiaMaterial;
      const promises: Promise<void | AxiosResponse<any>>[] = [];
      if (files && files.length > 0) {
        files.forEach(async (f) => {
          const blob = new Blob([f], { type: f.type });
          const fd = new FormData();
          fd.append("UploadData", blob, f.name);
          fd.append("fileName", f.name);
          promises.push(MaterialService.uploadCustomerMaterialImage(newMaterial.sku!, fd));
        });
      }

      if (newMaterial && material.associatedSKUS && material.associatedSKUS.length > 0) {
        const recommended = material.associatedSKUS;
        const mixed = [...materials, ...fixtures];
        const materialsForUpdating = mixed.filter((m) => recommended.indexOf(m.sku!) >= 0);
        materialsForUpdating.forEach(async (m) => {
          const updatedMaterial = { ...m };
          if (updatedMaterial.associatedSKUS && updatedMaterial.associatedSKUS.length > 0) {
            if (updatedMaterial.associatedSKUS.indexOf(newMaterial.sku!) === -1) {
              updatedMaterial.associatedSKUS = [...updatedMaterial.associatedSKUS, newMaterial.sku!];
            }
          } else {
            updatedMaterial.associatedSKUS = [newMaterial.sku!];
          }
          promises.push(updateMaterial(m, updatedMaterial, [], true));
        });
      }

      const results = await allSettled(promises);
      if (results.filter((r) => r.status === "rejected")) {
        console.log("An error occurred");
      }
      notification("Material created", "neutral");
      fetchMaterials();
    } catch (error) {
      console.log(error);
    }
  };

  const updateMaterial = async (
    originalMaterial: IArcadiaMaterial,
    updatedMaterial: IArcadiaMaterial,
    files?: File[],
    skipUpdateRelated?: boolean
  ) => {
    try {
      const promises: Promise<void | AxiosResponse<any>>[] = [];
      if (updatedMaterial && updatedMaterial.imageUrls && originalMaterial && originalMaterial.imageUrls) {
        const imagesForDelete = originalMaterial.imageUrls.filter((url) => updatedMaterial.imageUrls!.indexOf(url) < 0);
        imagesForDelete.forEach(async (imageUrl) => {
          promises.push(MaterialService.deleteMaterialImage(originalMaterial.sku!, imageUrl));
        });
      }
      updatedMaterial.imageUrls = originalMaterial.imageUrls;
      const patchArr = rfc6902.createPatch(originalMaterial, updatedMaterial);

      if (originalMaterial.isCustomerMaterial) {
        await MaterialService.updateCustomerMaterial(originalMaterial.sku!, patchArr);
      } else {
        await MaterialService.updateBoostMaterial(originalMaterial.sku!, patchArr);
      }
      if (files && files.length > 0) {
        files.forEach(async (f) => {
          const blob = new Blob([f], { type: f.type });
          const fd = new FormData();
          fd.append("UploadData", blob, f.name);
          fd.append("fileName", f.name);
          promises.push(MaterialService.uploadCustomerMaterialImage(originalMaterial.sku!, fd));
        });
      }
      if (updatedMaterial && !skipUpdateRelated) {
        const recommendedNew = updatedMaterial.associatedSKUS || [];
        const recommendedOld = originalMaterial.associatedSKUS || [];

        const materialSkusToRemoveFrom = recommendedOld?.filter((ro) => recommendedNew.indexOf(ro) === -1);
        const materialSkusToAddTo = recommendedNew?.filter((ro) => recommendedOld.indexOf(ro) === -1);
        const mixed = [...materials, ...fixtures];
        const materialsToRemoveFrom = mixed.filter((m) => materialSkusToRemoveFrom.indexOf(m.sku!) >= 0);
        materialsToRemoveFrom.forEach(async (m) => {
          const updated = { ...m };
          if (updated.associatedSKUS) {
            updated.associatedSKUS = [...updated.associatedSKUS.filter((s) => s !== updatedMaterial.sku)];
          }
          promises.push(updateMaterial(m, updated, [], true));
        });
        const materialsToAddTo = mixed.filter((m) => materialSkusToAddTo.indexOf(m.sku!) >= 0);
        materialsToAddTo.forEach(async (m) => {
          const updated = { ...m };
          if (updated.associatedSKUS) {
            updated.associatedSKUS = [...updated.associatedSKUS, updatedMaterial.sku!];
          } else {
            updated.associatedSKUS = [updated.sku!];
          }
          promises.push(updateMaterial(m, updated, [], true));
        });
      }

      const results = await allSettled(promises);
      if (results.filter((r) => r.status === "rejected")) {
        console.log("An error occurred");
      }
      notification("Material updated", "neutral");
      fetchMaterials();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMaterial = async (materialSku: string) => {
    try {
      await MaterialService.remove(materialSku);
      notification("Material removed", "neutral");
      fetchMaterials();
    } catch (error) {
      console.log(error);
    }
  };

  const transferMaterial = async (materialSku: string) => {
    try {
      await MaterialService.transferMaterialToCustomer(materialSku, 0);
      notification("Material customized", "neutral");
      fetchMaterials();
    } catch (error) {
      console.log(error);
    }
  };

  const uploadAttachment = async (materialSku: string, isBoost: boolean, formData: FormData) => {
    let fileFromDb: IArcadiaFileUpload = {};
    try {
      if (isBoost) {
        const result = await MaterialService.uploadBoostMaterialFile(materialSku, formData);
        fileFromDb = result.data;
      } else {
        const result = await MaterialService.uploadCustomerMaterialFile(materialSku, formData);
        fileFromDb = result.data;
      }
    } catch (error) {
      console.log(error);
    }

    return fileFromDb;
  };

  const addProgram = async (name: string) => {
    try {
      await BuilderService.createProgram(project?.arcadiaProjectId!, name);
      notification("Program created", "neutral");
      fetchExecutionPackages(project?.arcadiaProjectId!);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadAll = async () => {
    try {
      const response = await BuilderService.downloadAll(project?.arcadiaProjectId!, selectedProgram?.packageName!);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project?.projectName} ${selectedProgram?.packageName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadNcCode = async () => {
    try {
      const response = await BuilderService.downloadNcCode(project?.arcadiaProjectId!, selectedProgram?.packageName!);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project?.projectName!} ${selectedProgram?.packageName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadParameters = async () => {
    try {
      const response = await BuilderService.downloadParameters(
        project?.arcadiaProjectId!,
        selectedProgram?.packageName!
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project?.projectName!} ${selectedProgram?.packageName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  const downloadJobs = async () => {
    try {
      const response = await BuilderService.downloadJobs(project?.arcadiaProjectId!, selectedProgram?.packageName!);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project?.projectName!} ${selectedProgram?.packageName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteProgram = (selectedProgramName: string) => {
    setShowConfirmDelete(true);
    setProgramNameForUpdate(selectedProgramName);
  };

  const deleteProgram = async () => {
    if (project && project?.arcadiaProjectId && programNameForUpdate) {
      setSelectedProgram(undefined);
      BuilderService.deleteProgram(project?.arcadiaProjectId!, programNameForUpdate!)
        .then(() => {
          notification("Program deleted", "neutral");
          setShowConfirmDelete(false);
          fetchExecutionPackages(project?.arcadiaProjectId!);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCopyToNew = (selectedProgramName: string) => {
    setShowModalCopyToNew(true);
    setProgramNameForUpdate(selectedProgramName);
  };

  const copyToNew = async (newProgramName: string) => {
    try {
      await BuilderService.copyToNew(project?.arcadiaProjectId!, programNameForUpdate!, newProgramName);
      setShowModalCopyToNew(false);
      notification("Program copied to new project", "neutral");
      fetchExecutionPackages(project?.arcadiaProjectId!);
    } catch (error) {
      console.log(error);
    }
  };

  const onUploadNcCode = async (newPart: FormData) => {
    try {
      const result = await BuilderService.uploadNcCode(project?.arcadiaProjectId!, newPart);
      const newNcCode = result.data as IExecutionPackagePart;
      const updatedProgram: IExecutionPackage = {
        ...selectedProgram!,
        ncPrograms: [...(selectedProgram?.parameters || []), newNcCode],
        ncProgramsLastUpdatedDate: newNcCode.lastUpdatedDate,
        ncProgramsNewestVersion: newNcCode.versionNumber,
        ncProgramsLastUpdatedBy: newNcCode.author,
      };
      setShowUploadModalNcCode(false);
      notification("Nc Code Uploaded", "neutral");
      const index = executionPackages.map((e) => e.packageName).indexOf(selectedProgram?.packageName);
      const updatedExecutionPackages: IExecutionPackage[] = [...executionPackages];
      updatedExecutionPackages.splice(index, 1, updatedProgram);
      setExecutionPackages(updatedExecutionPackages);
      setSelectedProgram(updatedProgram);
    } catch (error) {
      notification("An error occurred. Make sure you're not trying to upload the same files.", "error");
    }
  };

  const onUploadJobs = async (newPart: FormData) => {
    try {
      const result = await BuilderService.uploadJobs(project?.arcadiaProjectId!, newPart);
      const newJobs = result.data as IExecutionPackagePart;
      const updatedProgram: IExecutionPackage = {
        ...selectedProgram!,
        jobs: [...(selectedProgram?.parameters || []), newJobs],
        jobsLastUpdatedDate: newJobs.lastUpdatedDate,
        jobsNewestVersion: newJobs.versionNumber,
        jobsLastUpdatedBy: newJobs.author,
      };
      setShowUploadModalJobs(false);
      notification("Jobs Uploaded", "neutral");
      const index = executionPackages.map((e) => e.packageName).indexOf(selectedProgram?.packageName);
      const updatedExecutionPackages: IExecutionPackage[] = [...executionPackages];
      updatedExecutionPackages.splice(index, 1, updatedProgram);
      setExecutionPackages(updatedExecutionPackages);
      setSelectedProgram(updatedProgram);
    } catch (error) {
      notification("An error occurred. Make sure you're not trying to upload the same files.", "error");
    }
  };

  const onUploadParameters = async (newPart: FormData) => {
    try {
      const result = await BuilderService.uploadParameters(project?.arcadiaProjectId!, newPart);
      const newParameters = result.data as IExecutionPackagePart;
      const updatedProgram: IExecutionPackage = {
        ...selectedProgram!,
        parameters: [...(selectedProgram?.parameters || []), newParameters],
        parametersLastUpdatedDate: newParameters.lastUpdatedDate,
        parametersNewestVersion: newParameters.versionNumber,
        parametersLastUpdatedBy: newParameters.author,
      };
      setShowUploadModalParameters(false);
      notification("Parameters Uploaded", "neutral");
      const index = executionPackages.map((e) => e.packageName).indexOf(selectedProgram?.packageName);
      const updatedExecutionPackages: IExecutionPackage[] = [...executionPackages];
      updatedExecutionPackages.splice(index, 1, updatedProgram);
      setExecutionPackages(updatedExecutionPackages);
      setSelectedProgram(updatedProgram);
    } catch (error) {
      notification("An error occurred. Make sure you're not trying to upload the same files.", "error");
      //console.log(error);
    }
  };

  const uploadFile = async (file: FormData) => {
    UploadsService.uploadFile(file)
      .then((result) => {
        const newFile = result.data as IArcadiaFileUpload;
        setTempFiles([...tempFiles, newFile]);
        setShowModalFileUpload(false);
        notification("File uploaded", "neutral");
      })
      .catch((e) => {
        error(e);
      });
  };

  const updateFile = async (file: IArcadiaFileUpload) => {
    try {
      updateTempFiles(file);
      await UploadsService.updateUpload(file).catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileClick = (fileId: string) => {
    const fileForEditing = tempFiles.find((f) => f.fileUploadId === fileId);
    if (fileForEditing) {
      setFileForEditing(fileForEditing);
    }
    setShowFilesModal(true);
  };

  const updateProject = async (updatedProject: IArcadiaProject) => {
    try {
      await ProjectsService.updateProject(updatedProject);
      setProject(updatedProject);
      fetchProjectById(project?.arcadiaProjectId!);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadDrawings = (files: FormData[]) => {
    files.forEach((f) => UploadsService.uploadFile(f).catch((err) => console.log(err)));
  };

  const tabs: ITab[] = [
    {
      name: "Workflow",
      content: (
        <div className="project-page-row">
          <MachineSessions
            clickHandler={goToNewSession}
            height={"auto"}
            sessions={project?.sessions || []}
            projectId={project?.arcadiaProjectId!}
            onDeleteClick={onDeleteClick}
            onStartClick={onStartClick}
            isOperator={
              user && user.roles && user.roles.length > 0 && user.roles.indexOf("Operator.All") >= 0 ? true : false
            }
            // onStopClick={onStopClick}
          />
          <ActionItems projectId={project?.arcadiaProjectId} receivedGroups={tasks || []} />
        </div>
      ),
      id: uniqid(),
    },
    // {
    //   name: "Timeline",
    //   content: <Timeline onDragEnd={onDragEnd} receivedGroups={tasks || []} />,
    //   id: uniqid(),
    // },
    {
      name: "Equipment Drawer",
      content: (
        <EquipmentDrawer
          fixtures={fixtures}
          materials={materials}
          materialTypes={materialCategories}
          unitsOfMeasure={unitsOfMeasure}
          createMaterial={createMaterial}
          updateMaterial={updateMaterial}
          deleteMaterial={deleteMaterial}
          uploadAttachment={uploadAttachment}
          transferMaterial={transferMaterial}
        />
      ),
      id: uniqid(),
    },
    {
      name: "Program Manager",
      content: (
        <div className="project-page-row">
          <Programs
            selectedProgram={selectedProgram}
            programs={executionPackages}
            height="auto"
            onItemClick={onProgramClick}
            addProgram={addProgram}
          />
          {showConfirmDelete && (
            <ModalConfirm
              width={607}
              height={208}
              title="Delete Program"
              text="Are you sure you want to delete this program This action is irreversible!"
              buttonCloseText="No"
              buttonConfirmText="Yes"
              onCloseModal={() => {
                setShowConfirmDelete(false);
                setProgramNameForUpdate(null);
              }}
              onConfirm={deleteProgram}
            />
          )}
          {showModalCopyToNew && (
            <ModalAddProgram
              title="Copy to new project"
              onDone={copyToNew}
              onCloseModal={() => setShowModalCopyToNew(false)}
            />
          )}
          {selectedProgram ? (
            <ProgramDetails
              handleDownloadAllClick={downloadAll}
              handleDownloadJobs={downloadJobs}
              handleDownloadNcCode={downloadNcCode}
              handleDownloadParameters={downloadParameters}
              handleDeleteProgram={handleDeleteProgram}
              handleCopyToNew={handleCopyToNew}
              selectedProgram={selectedProgram}
              showUploadModalNcCode={showUploadModalNcCode}
              setShowUploadModalNcCode={setShowUploadModalNcCode}
              showUploadModalJobs={showUploadModalJobs}
              setShowUploadModalJobs={setShowUploadModalJobs}
              showUploadModalParameters={showUploadModalParameters}
              setShowUploadModalParameters={setShowUploadModalParameters}
              onUploadNcCode={onUploadNcCode}
              onUploadJobs={onUploadJobs}
              onUploadParameters={onUploadParameters}
            />
          ) : (
            <div className="program-details-empty-state">
              <div className="program-details-empty-state-inner">
                <img alt="plus" src={plus} />
                <Typography color={Color.Secondary}>Add a program to edid the NC Code, Jobs and Parameters</Typography>
              </div>
            </div>
          )}
        </div>
      ),
      id: uniqid(),
    },
    {
      name: "File Uploads",
      content: (
        <div className="project-page-row">
          <Folders selectedFolder={filteredFolder} files={tempFiles || []} height="auto" onItemClick={onFolderClick} />
          <ScrollableContainer width={1150} height={700}>
            <FileUploads
              handleFileClick={handleFileClick}
              handleFavoriteFileClick={handleFavoriteFileClick}
              handleUploadFileClick={handleUploadFileClick}
              favorites={favoriteFiles}
              filteredFiles={filteredFiles}
              selectedFolder={filteredFolder || ""}
            />
          </ScrollableContainer>
          {showModalFileUpload && (
            <ModalUploadFile
              projectId={project?.arcadiaProjectId!}
              parts={project?.partDetails || []}
              currentFolder={filteredFolder}
              initialFile={draggedFile || undefined}
              onDone={uploadFile}
              onCloseModal={handleCloseModalFileUpload}
            />
          )}
          {showConfirmDeleteFileModal && (
            <ModalConfirm
              width={607}
              height={208}
              title="Delete File"
              text="Are you sure you want to delete this file. This action is irreversible!"
              buttonCloseText="No"
              buttonConfirmText="Yes"
              onCloseModal={() => {
                setShowConfirmDeleteFileModal(false);
              }}
              onConfirm={deleteFile}
            />
          )}
          {showFilesModal && fileForEditing && (
            <FileModal
              file={fileForEditing}
              isFavorite={favoriteFiles.map((f) => f.fileUploadId!).indexOf(fileForEditing.fileUploadId!) >= 0}
              handleDoneClick={handleDoneClick}
              handleCloseClick={handleCloseClick}
              handlePreviousClick={handlePreviousClick}
              handleNextClick={handleNextClick}
              handleDeleteClick={handleDeleteClick}
              handleFavoriteClick={handleFavoriteFileClick}
              parts={project?.partDetails || []}
              //features={project?.featureDetails || []}
            />
          )}
        </div>
      ),
      id: uniqid(),
    },
    {
      name: "Project Settings",
      content: (
        <ProjectSettings
          updateProject={updateProject}
          uploadDrawings={uploadDrawings}
          project={project!}
          materialCategories={materialCategories}
          processes={processes}
        />
      ),
      id: uniqid(),
    },
  ];

  const getNextSession = (project?: IArcadiaProject) => {
    let result = undefined;
    let now = moment();
    if (project && project?.sessions) {
      const sessions = project?.sessions;
      sessions.sort((a, b) => {
        return moment(b.plannedDate) === moment(a.plannedDate)
          ? 0
          : moment(b.plannedDate) > moment(a.plannedDate)
          ? 1
          : -1;
      });
      const nextSession = project.sessions.find((s) => moment(s.plannedDate) > now);
      if (nextSession) {
        result = moment(nextSession.plannedDate);
      }
    }
    return result;
  };

  const uploadProjectImage = (blob: Blob) => {
    const fd = new FormData();
    fd.append("formData", blob, "project_icon.png");
    fd.append("projectId", project?.arcadiaProjectId!);
    fd.append("fileName", `project_icon.png`);
    fd.append("description", "project icon");
    fd.append("userPath", "/");

    UploadsService.uploadFile(fd)
      .then((result) => {
        const file = result.data as IArcadiaFileUpload;
        const updatedProject: IArcadiaProject = { ...project };
        updatedProject.projectIcon = file.fileUri;
        updateProject(updatedProject);
        notification("Project photo updated", "neutral");
        setShowModalImageUpload(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <main className="container-primary-background project-page">
      {showModalImageUpload && (
        <ModalImageUpload
          onSave={uploadProjectImage}
          title={"Add Photo"}
          onCloseModal={() => setShowModalImageUpload(false)}
          singleFile={true}
          imageUrl={project?.projectIcon}
        />
      )}
      {showOnboardingModal && (
        <ModalWithIcon
          buttonText="Next"
          buttonType="secondary"
          clickHandler={startTour}
          message="Let's get your project set up for success."
          showCloseIcon={false}
          svg={undefined}
          title="Your project is created"
        />
      )}
      {showModalEditZoomInfo && (
        <ModalEditZoomInfo
          onCloseModal={() => setShowModalEditZoomInfo(false)}
          onDone={handleEditZoomInfo}
          onSkip={handleStartSession}
          isOperator={
            user && user.roles && user.roles.length > 0 && user.roles.indexOf("Operator.All") >= 0 ? true : false
          }
        />
      )}
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        rounded={16}
        showButtons={false}
        showCloseButton={false}
        showNavigationNumber={false}
        showNavigation={false}
        showNumber={false}
        // @ts-ignore
        ref={tourRef}
      />
      {project && executionPackages && materials && fixtures ? (
        <>
          <aside className="project-page-favbar">
            <ScrollableContainer centerContent={true} width="60px" height="590px">
              {favoriteProjects.map((p) => {
                const tooltip = ({ tooltipRef, getTooltipProps }: TooltipArg) => (
                  <div
                    {...getTooltipProps({
                      ref: tooltipRef,
                      className: "tooltip-container tooltip-container-projects",
                    })}
                  >
                    {p.projectName}
                  </div>
                );

                const trigger = ({ getTriggerProps, triggerRef }: ChildrenArg) => (
                  <span
                    {...getTriggerProps({
                      ref: triggerRef,
                      className: `trigger`,
                    })}
                  >
                    <Thumbnail
                      {...getTriggerProps({
                        ref: triggerRef,
                        className: "trigger",
                      })}
                      className="project-page-favbar-fav-image"
                      round={false}
                      backgroundColor={"#000000"}
                      width={24}
                      height={24}
                      borderRadius={2}
                      backgroundImageUrl={project.projectIcon || ""}
                      title={""}
                    />
                  </span>
                );
                return (
                  <div
                    onClick={() => history.push(`/project/${p.arcadiaProjectId}`)}
                    onKeyDown={() => {}}
                    role="button"
                    key={p.arcadiaProjectId}
                    className="project-page-favbar-fav"
                  >
                    {project?.arcadiaProjectId === p.arcadiaProjectId && (
                      <CircleStatus
                        className="project-page-favbar-fav-circle bg-orange"
                        status={Status.InProgress}
                        height={8}
                        width={8}
                      />
                    )}

                    <TooltipTrigger placement={"right"} trigger={"hover"} tooltip={tooltip}>
                      {trigger}
                    </TooltipTrigger>
                    {/* <Thumbnail
              className="project-page-favbar-fav-image"
              round={false}
              backgroundColor={"#000000"}
              width={24}
              height={24}
              borderRadius={2}
              backgroundImageUrl={p.projectIcon || ""}
              title={""}
            /> */}
                  </div>
                );
              })}
            </ScrollableContainer>
          </aside>
          <div className="project-page-container">
            <section className="project-page-container-main">
              <div className="project-page-container-main-top">
                <ProjectSessionHeader
                  backgroundImageUrl={project?.projectIcon || ""}
                  sessionsUsed={project?.sessionsUsed || 0}
                  status={Status.InProgress}
                  totalSessions={project?.sessionsPlanned || 0}
                  title={project?.projectName || ""}
                  onEditPhotoClick={() => setShowModalImageUpload(true)}
                  onFavoriteClick={() =>
                    handleFavoriteClick({
                      projectId: project?.arcadiaProjectId!,
                      name: project?.projectName!,
                      imageUri: project?.projectIcon!,
                    })
                  }
                  isFavorite={favorites.map((f) => f.projectId).indexOf(project?.arcadiaProjectId!) >= 0}
                />
                <NextMachineSession
                  clickHandler={() => {}}
                  nextMachineSession={getNextSession(project)}
                  showNotImplementedFeatures={false}
                />
              </div>
              <div className="project-page-container-main-tabs">
                <Tabs className="flex fullheight" noExtraBorder={true} sticky={true} tabs={tabs} />
              </div>
            </section>
            {<ProjectsSidebar projects={[]} />}
          </div>
        </>
      ) : (
        <ProjectSkeletonLoader />
      )}
    </main>
  );
};

export default Project;
export { Project };
