import React, { FC, HTMLAttributes, ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import { Search } from "../Search";
import { Logo } from "@gfms/ui";

const TopNavigation: FC<
  { fixed?: boolean; toggleSidebar: () => void; onUserClicked: () => void; showAccountCard: boolean } & HTMLAttributes<
    HTMLDivElement
  >
> = ({ fixed = false, toggleSidebar = () => {}, onUserClicked, showAccountCard }): ReactElement => {
  const history = useHistory();
  const [showNotImplementedFeatures] = useState<boolean>(false);
  const goHome = () => {
    history.push("/");
  };

  return (
    <div className={`header-container ${fixed ? "header-container-fixed" : ""}`}>
      <Button themeType={"navigation"} iconOnly={true} iconName={"fal fa-bars"} onClick={toggleSidebar} />
      <div className="logo-container pointer" tabIndex={0} role="button" onClick={goHome} onKeyDown={goHome}>
        <Logo width={74} variant="gfWhite" />
      </div>
      <div className="push-right">{showNotImplementedFeatures && <Search />}</div>
      {showNotImplementedFeatures && (
        <Button
          className="nav-button"
          themeType={"simple"}
          size={"small"}
          iconOnly={true}
          navButton={true}
          iconName={"fas fa-bell"}
        />
      )}
      <Button
        className={`nav-button${showAccountCard ? " with-background" : ""}`}
        themeType={"simple"}
        size={"small"}
        iconOnly={true}
        navButton={true}
        iconName={"fas fa-user"}
        onClick={onUserClicked}
      />
    </div>
  );
};

export { TopNavigation };
