import * as React from "react";
import { default as cx } from "classnames";

const Column: React.FC<
  {
    className?: string;
    width: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", width, children }): React.ReactElement => {
  const classes = cx(
    {
      "custom-table-row-column": true,
    },
    className
  );

  return (
    <div style={{ width: width }} className={classes}>
      {children}
    </div>
  );
};

export { Column };
