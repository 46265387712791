import * as React from "react";
import styleVariables from "@styles/export.scss";
import { default as cx } from "classnames";
import { Typography } from "../Common/Typography";
import { Icon } from "../Icon";

export type NotificationType = "primary" | "success" | "error" | "alert" | "neutral";

const Notification: React.FC<
  {
    message: string;
    className?: string;
    type?: NotificationType;
    isGlobal?: boolean;
    onClose: () => void;
    isOpen: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ type = "primary", isGlobal = false, message, className, onClose, isOpen }): React.ReactElement => {
  const classes = cx(
    {
      "notification-container": true,
      [`notification-container-${type}`]: true,
      global: isGlobal,
    },
    className
  );

  return isOpen ? (
    <div className={classes}>
      <div className="notification-container-message">
        <Typography>{message}</Typography>
      </div>
      <div className="notification-container-button">
        <Icon
          className="notification-container-close-button"
          onClick={onClose}
          color={styleVariables.color_white}
          name="fal fa-times"
          size={22}
        />
      </div>
    </div>
  ) : (
    <></>
  );
};

export { Notification };
