import React from "react";
import moment from "moment";
// import uniqid from "uniqid";
import { GoBack, GoForward, AddBookmark } from "./playbackIcons";
import { ElementType, Typography, Color } from "../Common/Typography";
import { ProgressBar } from "../CameraControls/ProgressBar";
import { Button } from "../Button";
import { IArcadiaFileUpload } from "@types";
import { Icon } from "../Icon";
import { Bookmarks } from "./bookmarks";

const Playback: React.FC<
  {
    bookmarks: string[];
    snapshots: IArcadiaFileUpload[];
    onSliderInput: (value: number) => void;
    onSliderChange: () => void;
    onGoBackClick: () => void;
    onClickScreenshot: (snapshots: IArcadiaFileUpload[], index: string) => void;
    onGoForwardClick: () => void;
    goToSnapshotTime: (timestamp: string) => void;
    createBookmark: (bookmark: string) => void;
    isPlaying: boolean;
    togglePlay: () => void;
    progress: string;
    startTime: string;
    videoFrame: any;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  bookmarks,
  snapshots,
  onSliderInput,
  onSliderChange,
  onGoBackClick,
  onGoForwardClick,
  createBookmark,
  goToSnapshotTime,
  isPlaying,
  togglePlay,
  progress,
  onClickScreenshot,
  startTime,
  videoFrame,
  ...other
}): React.ReactElement => {
  bookmarks = [moment(new Date()).add(-1, "hour").add(15, "minute").toISOString()];

  const secondsToHms = (seconds: number): string => {
    return moment().startOf("day").seconds(seconds).format("HH:mm:ss");
  };

  const handleAddBookmarkClick = () => {
    createBookmark("");
  };

  const duration = videoFrame ? videoFrame.duration : 0;

  return (
    <div className="playback-container" data-testid="playback-container">
      <>
        <div className="playback-buttons">
          <GoBack onClick={() => onGoBackClick()} />
          <Button
            onClick={togglePlay}
            iconOnly
            themeType="simple"
            size="large"
            iconName={`fa-2x fas fa-${isPlaying ? "pause" : "play"}`}
          />

          <GoForward onClick={() => onGoForwardClick()} />
        </div>
        <div className="playback-line">
          <div className="playback-line-progress-container">
            <Bookmarks
              snapshots={snapshots}
              onClickScreenshot={onClickScreenshot}
              goToSnapshotTime={goToSnapshotTime}
              videoStartTime={startTime}
              duration={duration}
            />
            <ProgressBar
              progress={progress}
              showCurrentVideoTime={true}
              onSliderInput={onSliderInput}
              onSliderChange={onSliderChange}
              videoFrame={videoFrame}
            />
          </div>
        </div>

        <div className="playback-line-timers">
          <Typography type={ElementType.body}>
            {secondsToHms(videoFrame ? videoFrame.currentTime : 0)} /{" "}
            {secondsToHms(videoFrame ? videoFrame.duration : 0)}
          </Typography>
        </div>

        <div
          className="playback-add-bookmark"
          role="button"
          tabIndex={0}
          onClick={() => onClickScreenshot(snapshots, snapshots[0].fileUploadId!)}
          onKeyDown={() => {}}
        >
          <Icon name="far fa-th-large" size={20} />
          <Typography type={ElementType.body} color={Color.Secondary}>
            See all
          </Typography>
        </div>

        <div
          className="playback-add-bookmark"
          role="button"
          tabIndex={0}
          onClick={handleAddBookmarkClick}
          onKeyDown={() => {}}
        >
          <AddBookmark />
          <Typography type={ElementType.body} color={Color.Secondary}>
            Add
          </Typography>
        </div>
      </>
    </div>
  );
};

export default Playback;
export { Playback };
