import React, { FC, ReactElement } from "react";
import { FormSection, SelectionItem, MaterialsChoice, SlideToggleContent, ModalConfirm } from "@namespace/components";
import { IArcadiaMaterial, IMachineRun, IPrepareSessionDataEquipment } from "@types";
import { useState } from "react";
const EquipmentSession: FC<{
  materials: IArcadiaMaterial[];
  fixtures: IArcadiaMaterial[];
  runs: Map<string, IMachineRun[]>;
  setRuns: (runs: Map<string, IMachineRun[]>) => void;
  values: IPrepareSessionDataEquipment;
  setValues: (values: IPrepareSessionDataEquipment) => void;
  disabled: boolean;
}> = ({ materials, fixtures, values, setValues, runs, setRuns, disabled }): ReactElement => {
  const [showConfirmFixture, setShowConfirmFixture] = useState(false);
  const [showConfirmMaterial, setShowConfirmMaterial] = useState(false);
  const [materialForUpdating, setMaterialForUpdating] = useState<{
    materialSku: string;
    fixture: IArcadiaMaterial;
  } | null>(null);
  const [fixtureForUpdating, setFixtureForUpdating] = useState<IArcadiaMaterial | null>(null);

  const update = () => {
    if (materialForUpdating && !fixtureForUpdating) {
      updateMaterial(materialForUpdating.materialSku, materialForUpdating.fixture);
      const filteredRuns = runs.get(materialForUpdating.fixture.sku!);
      filteredRuns?.map((r) => {
        if (r.materialId === materialForUpdating.materialSku) {
          r.materialId = undefined;
        }
      });
      const updatedMap = new Map(runs);
      updatedMap.set(materialForUpdating.fixture.sku!, filteredRuns || []);
      setRuns(updatedMap);
      setShowConfirmMaterial(false);
    } else if (!materialForUpdating && fixtureForUpdating) {
      updateFixture(fixtureForUpdating);
      const updatedMap = new Map(runs);
      updatedMap.delete(fixtureForUpdating.sku!);
      setRuns(updatedMap);
      setShowConfirmFixture(false);
    }
  };

  const checkFixture = (workHolding: IArcadiaMaterial) => {
    const valuesCopy = new Map(values.workHoldings);
    if (valuesCopy.has(workHolding.sku!)) {
      //check runs
      if (runs.has(workHolding.sku!)) {
        setFixtureForUpdating(workHolding);
        setMaterialForUpdating(null);
        setShowConfirmFixture(true);
      } else {
        valuesCopy.delete(workHolding.sku!);
      }
    } else {
      valuesCopy.set(workHolding.sku!, [workHolding, []]);
    }
    setValues({ workHoldings: valuesCopy });
  };

  const updateFixture = (workHolding: IArcadiaMaterial) => {
    const valuesCopy = new Map(values.workHoldings);
    if (valuesCopy.has(workHolding.sku!)) {
      valuesCopy.delete(workHolding.sku!);
    } else {
      valuesCopy.set(workHolding.sku!, [workHolding, []]);
    }
    setValues({ workHoldings: valuesCopy });
  };

  const checkMaterial = (materialSku: string, workHolding: IArcadiaMaterial) => {
    const needConfirmation = false;
    const storedWorkHolding = values.workHoldings.get(workHolding.sku!);
    if (!storedWorkHolding) {
      return needConfirmation;
    }
    const [workHoldingData, selectedMaterials] = storedWorkHolding;
    const newSelectedMaterials = [...selectedMaterials];
    const location = newSelectedMaterials.indexOf(materialSku);
    if (location !== -1) {
      if (runs.has(workHolding.sku!)) {
        const filtered = runs.get(workHolding.sku!)?.filter((r) => r.materialId === materialSku);
        if (filtered && filtered.length > 0) {
          setMaterialForUpdating({ materialSku: materialSku, fixture: workHolding });
          setFixtureForUpdating(null);
          setShowConfirmMaterial(true);
        } else {
          newSelectedMaterials.splice(location, 1);
        }
      } else {
        newSelectedMaterials.splice(location, 1);
      }
    } else {
      newSelectedMaterials.push(materialSku);
    }

    const newValues = Object.assign({}, values);
    newValues.workHoldings.set(workHolding.sku!, [workHoldingData, newSelectedMaterials]);
    setValues(newValues);
  };

  const updateMaterial = (materialSku: string, workHolding: IArcadiaMaterial) => {
    const storedWorkHolding = values.workHoldings.get(workHolding.sku!);

    if (!storedWorkHolding) {
      return;
    }

    const [workHoldingData, selectedMaterials] = storedWorkHolding;
    const newSelectedMaterials = [...selectedMaterials];
    const location = newSelectedMaterials.indexOf(materialSku);
    if (location !== -1) {
      newSelectedMaterials.splice(location, 1);
    } else {
      newSelectedMaterials.push(materialSku);
    }

    const newValues = Object.assign({}, values);
    newValues.workHoldings.set(workHolding.sku!, [workHoldingData, newSelectedMaterials]);
    setValues(newValues);
  };
  return (
    <div style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}>
      {showConfirmFixture && (
        <ModalConfirm
          width={607}
          height={208}
          title="Remove fixture"
          text="Removing this fixture will cause one or more machine runs to be deleted. Are you sure you want to remove it"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => setShowConfirmFixture(false)}
          onConfirm={update}
        />
      )}
      {showConfirmMaterial && (
        <ModalConfirm
          width={607}
          height={208}
          title="Remove material"
          text="Removing this material will cause one or more machine runs to be modified. Are you sure you want to remove it"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => setShowConfirmMaterial(false)}
          onConfirm={update}
        />
      )}
      <form>
        <FormSection
          secondary={false}
          sectionTitle="Choose the workholding/fixture for this session"
          sectionSubTitle="Select from a list of workholding we can provide for you, or choose your own."
          className="section-available-workholdings"
        >
          {fixtures.map((fixture) => {
            const onCheckBoxClick = () => {
              checkFixture(fixture);
            };

            const handleMaterialClick = (materialSku: string) => {
              checkMaterial(materialSku, fixture);
            };

            const selected = values.workHoldings.get(fixture.sku!);
            const selectedMaterials = selected ? selected[1] : [];

            return (
              <div
                className={
                  !!selected ? "section-available-workholdings-border" : "section-available-workholdings-no-border"
                }
                key={fixture.sku!}
              >
                <SelectionItem
                  userOwned={fixture.isCustomerMaterial && !fixture.isCustomized}
                  checked={!!selected}
                  imageUrl={fixture.imageUrls ? fixture.imageUrls[0] : ""}
                  title={fixture.materialName!}
                  description={fixture.description!}
                  handleClick={onCheckBoxClick}
                  top
                  hideBorder={!!selected}
                />
                <SlideToggleContent isVisible={!!selected}>
                  <MaterialsChoice
                    handleClick={handleMaterialClick}
                    materials={materials.filter((m) => m.associatedSKUS && m.associatedSKUS.indexOf(fixture.sku!) >= 0)}
                    selectedMaterials={selectedMaterials}
                  />
                </SlideToggleContent>
              </div>
            );
          })}
        </FormSection>
      </form>
    </div>
  );
};

export default EquipmentSession;
export { EquipmentSession };
