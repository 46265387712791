import * as React from "react";
import { Card } from "../Card";
import { Thumbnail } from "../Thumbnail";
import { ElementType, Typography } from "../Common/Typography";
import { IArcadiaFileUpload } from "@types";

const CardBookmark: React.FC<
  {
    className?: string;
    onClickCard?: () => void;
    snapshot: IArcadiaFileUpload;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", onClickCard, snapshot }): React.ReactElement => {
  return (
    <Card className={`card-bookmark ${className}`} height={123} hover={false} onClick={onClickCard} width={396}>
      <Thumbnail
        className={"card-bookmark-thumbnail"}
        title={snapshot.fileName!}
        round={false}
        width={72}
        height={72}
        borderRadius={4}
        backgroundImageUrl={snapshot.imageUri_Small!}
      />
      <div className="card-bookmark-column">
        <Typography type={ElementType.h5}>{snapshot.fileName!}</Typography>
        <Typography type={ElementType.bodySmall}>{snapshot.fileDescription}</Typography>
      </div>
    </Card>
  );
};

export { CardBookmark };
