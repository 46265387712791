const fieldsFirstSection = [
  {
    id: "developFeatureInFlatStock",
    value: "Develop feature in flat stock",
  },
  {
    id: "transferFeatureToPart",
    value: "Transfer feature to part",
  },
  {
    id: "optimizeProcessForProduction",
    value: "Optimize process for production",
  },
  {
    id: "productionRun",
    value: "Production run",
  },
];

const fieldsSecondSection = [
  {
    id: "developOptimizeScanhead",
    value: "Develop / optimize scanhead job(s)",
  },
  {
    id: "developLaserParameters",
    value: "Develop / optimize laser parameters",
  },
  {
    id: "developOtherParameters",
    value: "Develop / optimize other process parameters",
  },
];

const fieldsThirdSection = [
  {
    id: "evaluateWorkHolding",
    value: "Evaluate Work Holding",
  },
  {
    id: "provePartProgram",
    value: "Prove / debug part program",
  },
  {
    id: "developMachineMotion",
    value: "Develop / optimize machine motion",
  },
  {
    id: "improveProgramLogic",
    value: "Improve program logic",
  },
];

const allGoals = [...fieldsFirstSection, ...fieldsSecondSection, ...fieldsThirdSection];

export { fieldsFirstSection, fieldsSecondSection, fieldsThirdSection, allGoals };
