import { IArcadiaVideoUpload } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useSessionVideoUploads = () => {
  const [isVideoUploadsLoading, setIsVideoUploadsLoading] = useState(false);
  const [isVideoUploadsError, setIsVideoUploadsError] = useState(false);

  const [videoUploads, setVideoUploads] = useState<IArcadiaVideoUpload>();

  const fetchVideoUploads = (projectId: string, sessionId: string) => {
    if (projectId && sessionId) {
      const fetchData = async () => {
        setIsVideoUploadsLoading(true);
        ProjectsService.getVideoUploadsForSession(projectId, sessionId)
          .then((response) => {
            const result = response.data as IArcadiaVideoUpload;
            setVideoUploads(result);
            setIsVideoUploadsLoading(false);
          })
          .catch((e) => {
            setIsVideoUploadsLoading(false);
            setIsVideoUploadsError(true);
            console.log(e);
          });
      };
      fetchData();
    }
  };

  return { videoUploads, isVideoUploadsLoading, isVideoUploadsError, fetchVideoUploads, setVideoUploads };
};

export default useSessionVideoUploads;
