import React, { FC, HTMLAttributes, ReactElement, useState, useEffect, useCallback, useMemo } from "react";
import { default as cx } from "classnames";
const defaultFileImage = require("../../images/fileImage.svg") as string;

import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { useDropzone } from "react-dropzone";
import { Thumbnail } from "../Thumbnail";
import styleVariables from "@styles/export.scss";

const FileUploader: FC<
  {
    className?: string;
    files: File[];
    setFiles: (files: File[]) => void;
    fileUrls?: string[];
    setFileUrls?: (fileUrls: string[]) => void;
    singleFile?: boolean;
    showFileNames?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  files,
  setFiles,
  fileUrls,
  singleFile = false,
  showFileNames = true,
  setFileUrls,
}): ReactElement => {
  const classes = cx(
    {
      "file-uploader": true,
    },
    className
  );

  useEffect(() => {
    if (files) {
      setMyFilesBase64([]);
      files.forEach(async (f: File) => {
        const base64File = await toBase64(f);
        const obj = { fileName: f.name, base64: base64File };
        setMyFilesBase64((oldBase64) => [...oldBase64, obj]);
      });
    }
  }, [files]);

  const [myFilesBase64, setMyFilesBase64] = useState<{ fileName: string; base64: string }[]>([]);

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      const imageExtensions = ["png", "jpg", "jpeg"];
      const extension = file.name.split(".").pop();
      if (extension && imageExtensions.indexOf(extension.toLowerCase()) >= 0) {
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      } else {
        resolve("");
      }
    });

  const removeImage = (fileName: string) => {
    const filtered = files.filter((f) => f.name !== fileName);
    setFiles([...filtered]);
  };

  const getImageRow = (imageUrl: string, name: string, removeImage?: () => void, showFileNames?: boolean) => {
    return (
      <div className="modal-add-material-image-row" key={name}>
        {imageUrl ? (
          <Thumbnail
            title={name}
            round={false}
            width={36}
            height={36}
            borderRadius={4}
            backgroundImageUrl={imageUrl}
            backgroundColor={styleVariables.color_layer_2}
          />
        ) : (
          <div className="default-file-image-container">
            <img className="default-file-image" src={defaultFileImage} alt={name} />
          </div>
        )}

        <div>
          {showFileNames && <Typography>{name}</Typography>}
          <Typography type={ElementType.bodySmall} color={Color.Secondary}>
            {`${name.split(".").pop()?.toUpperCase()!} File`}
          </Typography>
        </div>
        {removeImage && (
          <Button themeType="simple" onClick={removeImage}>
            Remove
          </Button>
        )}
      </div>
    );
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (singleFile) {
        const file = acceptedFiles[0];
        setFiles([file]);
      } else {
        setFiles([...files, ...acceptedFiles]);
      }
    },
    [files]
  );
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop });

  const activeStyle = {
    borderColor: styleVariables.color_white,
    borderStyle: "dashed",
  };

  const rejectStyle = {
    borderColor: styleVariables.color_red,
    borderStyle: "dashed",
  };

  const style = useMemo(
    () => ({
      ...(isDragActive || isDragAccept ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handleRemoveOldImage = (imageUrl: string) => {
    if (fileUrls && setFileUrls) {
      const filtered = fileUrls?.filter((f) => f !== imageUrl);
      setFileUrls(filtered);
    }
  };

  return (
    <div className={classes}>
      <div className="file-uploader-dropzone" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography color={Color.Link}>Select or drag file</Typography>
      </div>
      {myFilesBase64.map((f) => getImageRow(f.base64, f.fileName, () => removeImage(f.fileName), showFileNames))}
      {fileUrls &&
        fileUrls.map((f) =>
          getImageRow(f, f.split("/").pop()!, setFileUrls ? () => handleRemoveOldImage(f) : undefined, showFileNames)
        )}
    </div>
  );
};

export { FileUploader };
