import React from "react";
import uniqid from "uniqid";
import { default as cx } from "classnames";

const Checkbox: React.FC<
  {
    id?: string;
    checked: boolean;
    error?: boolean;
    onChange?: Function;
    className?: string;
    value?: string | number | string[];
    disabled?: boolean;
    largeMargin?: boolean;
    name?: string;
    radio?: boolean;
  } & React.HTMLAttributes<HTMLDivElement> & { ref?: React.Ref<HTMLInputElement> }
> = React.forwardRef(
  (
    {
      id,
      checked = false,
      disabled = false,
      error = false,
      onChange = () => {},
      className = "",
      largeMargin = false,
      value,
      name = "",
      radio = false,
      ...other
    },
    ref
  ) => {
    const classes = cx(
      {
        "checkbox-container": !radio,
        "radio-container": radio,
        "large-margin": largeMargin,
      },
      className
    );
    const customId = id || uniqid();
    const type = radio ? "radio" : "checkbox";

    return (
      <div className={classes}>
        <input
          id={customId}
          ref={ref}
          type={type}
          checked={checked}
          onChange={onChange}
          className={`${className} ${type} ${error ? `${type}-error` : ""}`}
          value={value}
          disabled={disabled}
          name={name}
          {...other}
        />
        <label htmlFor={customId} className={`${type}-icon`}>
          {type === "checkbox" && <i className="fas fa-check"></i>}
        </label>
      </div>
    );
  }
);

export { Checkbox };
