import * as React from "react";
import { default as cx } from "classnames";
import styleVariables from "@styles/export.scss";
import { Status } from "../../constants/enums/Status";
import { Button } from "../Button";
import { Typography, ElementType, Color } from "../Common/Typography";
import uniqid from "uniqid";
import moment from "moment";
import { CardToDo } from "../CardToDo";
import { IArcadiaSession } from "@types";
import { sortingFunctionSessions, sortingFunctionSessionsReverse } from "../../helpers/sorting";
import { Icon } from "../Icon";

const MachineSessions: React.FC<
  {
    className?: string;
    clickHandler: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    height?: number | string;
    sessions: IArcadiaSession[];
    projectId: string;
    width?: number;
    isOperator?: boolean;
    onDeleteClick: (sessionId: string, projectId: string) => void;
    onStartClick: (sessionId: string, projectId: string) => void;
    // onStopClick: (sessionId: string, projectId: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  clickHandler = () => {},
  height = 714,
  sessions = [],
  width = 540,
  projectId,
  isOperator,
  onDeleteClick,
  onStartClick,
  // onStopClick,
}): React.ReactElement => {
  const classes = cx("machine-sessions", className);

  const mapStatus = (status: number) => {
    let result = Status.NotStarted;
    switch (status) {
      case 0:
        result = Status.Draft;
        break;
      case 1:
        result = Status.InReview;
        break;
      case 2:
        result = Status.NotStarted;
        break;
      case 3:
        result = Status.InProgress;
        break;
      case 4:
        result = Status.Done;
        break;
      default:
        break;
    }

    return result;
  };

  const mapFunction = (session: IArcadiaSession) => {
    const mappedStatus = mapStatus(session.status);
    return (
      <div className="machine-sessions-row" key={uniqid()}>
        <div className="machine-sessions-row-date">
          {mappedStatus === Status.InReview || mappedStatus === Status.Draft ? (
            <Icon
              name={mappedStatus === Status.InReview ? "fal fa-clock" : "fal fa-edit"}
              size={24}
              color={mappedStatus === Status.InReview ? styleVariables.color_blue : styleVariables.color_gray}
            />
          ) : (
            <>
              <Typography className="machine-sessions-row-date-month" type={ElementType.h5} color={Color.Secondary}>
                {moment(new Date(session.plannedDate!)).format("MMM")}
              </Typography>
              <Typography type={ElementType.h3}>{moment(new Date(session.plannedDate!)).format("DD")}</Typography>
            </>
          )}
        </div>
        <CardToDo
          backgroundImageUrl={session.sessionImageUrl || ""}
          clickHandler={() => {}}
          status={mapStatus(session.status)}
          text={session.sessionName!}
          startTime={session.startTime!}
          plannedDate={session.plannedDate!}
          sessionId={session.sessionId!}
          projectId={projectId}
          imageTitle=""
          onDeleteClick={() => onDeleteClick(session.sessionId!, projectId)}
          onStartClick={() => onStartClick(session.sessionId!, projectId)}
          isOperator={isOperator}
          // onStopClick={() => onStopClick(session.sessionId!, projectId)}
        />
      </div>
    );
  };

  return (
    <aside className={classes} style={{ height: height, width: width }}>
      <div className="machine-sessions-header">
        <Typography type={ElementType.h4}>Machine Sessions</Typography>
        <Button
          data-testid="next-machine-session-button"
          iconLeftName="fa fa-plus"
          themeType="secondary"
          size="large"
          onClick={clickHandler}
        >
          New Session
        </Button>
      </div>
      {sessions && sessions.length > 0 ? (
        <>
          {/* live */}
          {sessions
            .filter((s) => s.status === 3)
            .sort(sortingFunctionSessions)
            .map((session) => mapFunction(session))}
          {/* draft */}
          {sessions
            .filter((s) => s.status === 0)
            .sort(sortingFunctionSessions)
            .map((session) => mapFunction(session))}
          {/* scheduled */}
          {sessions
            .filter((s) => s.status === 2)
            .sort(sortingFunctionSessions)
            .map((session) => mapFunction(session))}
          {/* pending */}
          {sessions
            .filter((s) => s.status === 1)
            .sort(sortingFunctionSessions)
            .map((session) => mapFunction(session))}
          {/* completed */}
          {sessions
            .filter((s) => s.status === 4)
            .sort(sortingFunctionSessionsReverse)
            .map((session) => mapFunction(session))}
        </>
      ) : (
        <Typography color={Color.Secondary}>There are no upcoming sessions.</Typography>
      )}
    </aside>
  );
};

export { MachineSessions };
