import * as React from "react";
import { default as cx } from "classnames";
import { Status } from "../../constants/enums/Status";

const StatusIcon: React.FC<
  {
    className?: string;
    status: Status;
    text?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, className = "", status = Status.NotStarted, text, ...other }): React.ReactElement => {
  let statusClass = "";
  switch (status) {
    case Status.Draft:
      statusClass = "draft";
      break;
    case Status.NotStarted:
      statusClass = "notStarted";
      break;
    case Status.InReview:
      statusClass = "inReview";
      break;
    case Status.InProgress:
      statusClass = "inProgress";
      break;
    case Status.Done:
      statusClass = "done";
      break;
    default:
      break;
  }
  const statusIconStyles = cx({
    statusIcon: true,
    [`statusIcon-${statusClass}`]: true,
  });
  const statusIconTextStyles = cx({
    "status-icon-text": true,
    [`status-icon-text-${statusClass}`]: true,
  });
  return (
    <div className={`status-icon-container ${className}`}>
      <div className={statusIconStyles} {...other} />
      {text && <div className={statusIconTextStyles}>{text}</div>}
    </div>
  );
};

export { StatusIcon };
