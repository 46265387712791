import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AppProviders from "./context/AppProviders";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import { BrowserRouter as Router } from "react-router-dom";
import AzureAD, { IAzureADFunctionProps, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./auth/authProvider";

// Add a request interceptor
axios.interceptors.request.use(async (req) => {
  const token = await authProvider.acquireTokenSilent({
    scopes: ["api://d43c6832-c0b2-4234-b814-45d6050c4ff7/User.All"],
  });
  req.headers.Authorization = `Bearer ${token.accessToken}`;
  return req;
});

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

ReactDOM.render(
  <React.StrictMode>
    <AzureAD provider={authProvider} forceLogin={false}>
      {(foo: IAzureADFunctionProps) => {
        switch (foo.authenticationState) {
          case AuthenticationState.InProgress:
            return <h1>Authenticating...</h1>;
          case AuthenticationState.Unauthenticated:
            return <>{foo.login()}</>;
          case AuthenticationState.Authenticated:
            // return (<App auth={authProvider.UserAgentApplication} />);
            return (
              <AppProviders>
                <Router>
                  <App logout={foo.logout} />
                </Router>
              </AppProviders>
            );
        }
      }}
    </AzureAD>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
