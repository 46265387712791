import * as React from "react";
import { default as cx } from "classnames";

const Card: React.FC<
  {
    className?: string;
    width: null | number | string | "auto" | "initial" | "inherit";
    height: null | number | string | "auto" | "initial" | "inherit";
    hover: boolean;
    isActive?: boolean;
    onClick?: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  width = 100,
  height = 100,
  hover = false,
  isActive = false,
  onClick,
}): React.ReactElement => {
  const classes = cx(
    {
      card: true,
      "card-hover": hover && !isActive,
      "card-active": isActive,
    },
    className
  );

  const style: any = {};
  if (width !== null) {
    style.width = width;
  }
  if (height !== null) {
    style.height = height;
  }

  if (onClick) {
    return (
      <div className={classes} style={style} tabIndex={0} role="button" onKeyUp={onClick} onClick={onClick}>
        {children}
      </div>
    );
  }

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export { Card };
