import React, { FC, ReactElement } from "react";

const UserContext = React.createContext<{
  data: any;
}>({ data: {} });

const UserProvider: FC<{
  children: JSX.Element;
  data: any;
}> = ({ children, data, ...other }): ReactElement => (
  <UserContext.Provider value={{ data }} {...other}>
    {children}
  </UserContext.Provider>
);

const useUser = () => React.useContext(UserContext);
export { UserProvider, useUser };
