//@ts-nocheck
const wsConnect = (applicationName: string, streamName: string, wssUrl: string, videoElement: any) => {
  const _this = window;
  const streamInfo = { applicationName, streamName };
  const userData = { iceServers: [] };
  let peerConnection = new RTCPeerConnection(userData);
  let repeaterRetryCount: number;
  let wsConnection: any;

  try {
    wsConnection = new WebSocket(wssUrl);
  } catch (e) {
    console.log(e);
  }
  wsConnection.binaryType = "arraybuffer";

  wsConnection.onopen = () => {
    peerConnection.onicecandidate = _this.gotIceCandidate;

    peerConnection.onconnectionstatechange = () => {
      if (peerConnection.connectionState === "failed") {
        peerConnection.restartIce();
      }
    };

    peerConnection.oniceconnectionstatechange = () => {
      if (peerConnection.iceconnectionState === "failed") {
        peerConnection.restartIce();
      }
    };

    peerConnection.ontrack = () => {
      try {
        videoElement.srcObject = event.streams[0];
      } catch (error) {
        videoElement.src = window.URL.createObjectURL(event.streams[0]);
      }
    };
    sendPlayGetOffer();
  };

  const sendPlayGetOffer = () => {
    wsConnection.send(
      '{"direction":"play", "command":"getOffer", "streamInfo":' +
        JSON.stringify(streamInfo) +
        ', "userData":' +
        JSON.stringify(userData) +
        "}"
    );
  };

  const stop = () => {
    if (peerConnection != null) {
      peerConnection.close();
    }
    if (wsConnection != null) {
      wsConnection.close();
    }
    peerConnection = null;
    wsConnection = null;
    videoElement.src = "";
  };

  wsConnection.onmessage = function (evt) {
    const msgJSON = JSON.parse(evt.data);
    const msgStatus = Number(msgJSON["status"]);
    const msgCommand = msgJSON["command"];

    if (msgStatus == 514) {
      // repeater stream not ready
      repeaterRetryCount++;
      if (repeaterRetryCount < 10) {
        setTimeout(sendPlayGetOffer, 500);
      } else {
        stop();
      }
    } else if (msgStatus != 200) {
      stop();
    } else {
      const streamInfoResponse = msgJSON["streamInfo"];
      if (streamInfoResponse !== undefined) {
        streamInfo.sessionId = streamInfoResponse.sessionId;
      }

      const sdpData = msgJSON["sdp"];
      if (sdpData != null) {
        if (mungeSDP != null) {
          msgJSON.sdp.sdp = mungeSDP(msgJSON.sdp.sdp);
        }

        peerConnection
          .setRemoteDescription(new RTCSessionDescription(msgJSON.sdp))
          .then(() =>
            peerConnection.createAnswer().then((description) => {
              peerConnection
                .setLocalDescription(description)
                .then(() => {
                  wsConnection.send(
                    '{"direction":"play", "command":"sendResponse", "streamInfo":' +
                      JSON.stringify(streamInfo) +
                      ', "sdp":' +
                      JSON.stringify(description) +
                      ', "userData":' +
                      JSON.stringify(userData) +
                      "}"
                  );
                })
                .catch((err) => console.log("set local description error", err));
            })
          )
          .catch((err) => console.log("set remote description error", err));
      }

      const iceCandidates = msgJSON["iceCandidates"];
      if (iceCandidates != null) {
        for (const index in iceCandidates) {
          peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidates[index]));
        }
      }
    }

    if ("sendResponse".localeCompare(msgCommand) == 0) {
      if (wsConnection != null) {
        wsConnection.close();
      }

      wsConnection = null;
    }
  };

  wsConnection.onclose = function () {
    console.log("connection closed");
  };

  wsConnection.onerror = function (evt: string) {
    console.log(evt);
  };

  return stop;
};

const mungeSDP = (sdpStr: string) => {
  // For greatest playback compatibility,
  // force H.264 playback to baseline (42e01f).
  const sdpLines = sdpStr.split(/\r\n/);
  let sdpStrRet = "";

  for (const sdpIndex in sdpLines) {
    let sdpLine = sdpLines[sdpIndex];

    if (sdpLine.length == 0) {
      continue;
    }

    if (sdpLine.includes("profile-level-id")) {
      // The profile-level-id string has three parts: XXYYZZ, where
      //   XX: 42 baseline, 4D main, 64 high
      //   YY: constraint
      //   ZZ: level ID
      // Look for codecs higher than baseline and force downward.
      const profileLevelId = sdpLine.substr(sdpLine.indexOf("profile-level-id") + 17, 6);
      let profile = Number("0x" + profileLevelId.substr(0, 2));
      let constraint = Number("0x" + profileLevelId.substr(2, 2));
      let level = Number("0x" + profileLevelId.substr(4, 2));
      if (profile > 0x42) {
        profile = 0x42;
        constraint = 0xe0;
        level = 0x1f;
      }
      const newProfileLevelId =
        ("00" + profile.toString(16)).slice(-2).toLowerCase() +
        ("00" + constraint.toString(16)).slice(-2).toLowerCase() +
        ("00" + level.toString(16)).slice(-2).toLowerCase();

      sdpLine = sdpLine.replace(profileLevelId, newProfileLevelId);
    }

    sdpStrRet += sdpLine;
    sdpStrRet += "\r\n";
  }

  return sdpStrRet;
};

export { wsConnect };
