// @ts-nocheck
import React, { useEffect, useState } from "react";
import { default as cx } from "classnames";
import { VideoElement } from "../VideoElement";
import { Card } from "../Card";
import { GoBack } from "../Playback/playbackIcons";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { Typography } from "../Common/Typography";
import { formatDate, calculateLivePlayheadPosition, formatSecondsToHHMMSS, calculateSliderValue } from "./helpers";
import { ICameraPreset } from "@types";
import { VolumeControl } from "../CameraControls/VolumeControl";
import { VideoQualityControl } from "../CameraControls/VideoQualityControl";
import { ProgressBar } from "../CameraControls/ProgressBar";

const CameraStreams: React.FC<
  {
    className?: string;
    width?: number;
    height?: number;
    channels: { text: string; url: string }[];
    cameraPresets?: ICameraPreset[];
    lastCameraPreset?: ICameraPreset;
    isReplay?: boolean;
    takeSnapshot?: (data: string, timestamp: string) => void;
    setPTR?: (command: string) => void;
    setCameraPreset?: (preset: number) => void;
    setPlayerOne: any;
    setPlayerTwo: any;
    playerOne: any;
    playerTwo: any;
    setPlayer1Loading: (value: boolean) => void;
    setPlayer2Loading: (value: boolean) => void;
    player1Loading: boolean;
    player2Loading: boolean;
    setCurrentTime: (value: string) => void;
    currentTime: string;
    isLive: boolean;
    toggleIsLive: (value: boolean) => void;
    volume: number;
    setVolume: (value: number) => void;
    rewind: boolean;
    setRewind: (value: boolean) => void;
    synchronize: any;
    setVideoQuality: any;
    onSliderInput: (value: string) => void;
    onSliderChange: () => void;
    isSliding: boolean;
    isPlaying: boolean;
    togglePlay: () => void;
    videoQuality: number;
    setProgress: (value: string) => void;
    progress: string;
    applicationName: string;
    onFullScreenClick: (streamId: string) => void;
    setVideoFrameOne: any;
    videoFrameOne: any;
    setVideoFrameTwo: any;
    videoFrameTwo: any;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  height = 690,
  width = 435,
  channels = [],
  cameraPresets,
  lastCameraPreset,
  isReplay = false,
  takeSnapshot,
  setPTR,
  setCameraPreset,
  playerOne,
  playerTwo,
  setPlayerOne,
  setPlayerTwo,
  setPlayer1Loading,
  setPlayer2Loading,
  player1Loading,
  player2Loading,
  setCurrentTime,
  currentTime,
  isLive,
  toggleIsLive,
  volume,
  setVolume,
  rewind,
  setRewind,
  synchronize,
  setVideoQuality,
  onSliderInput,
  onSliderChange,
  isPlaying,
  isSliding,
  togglePlay,
  videoQuality,
  setProgress,
  progress,
  applicationName,
  onFullScreenClick,
  setVideoFrameOne,
  videoFrameOne,
  setVideoFrameTwo,
  videoFrameTwo,
}): React.ReactElement => {
  const classes = cx(
    {
      "camera-streams": true,
    },
    className
  );

  const [isPlayerOneReady, setPlayerOneReady] = useState(false);
  const [isPlayerTwoReady, setPlayerTwoReady] = useState(false);

  const videoJsOptions1 = {
    // lookup the options in the docs for more options
    html5: {
      vhs: {
        withCredentials: false,
        fastQualityChange: true,
      },
    },
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    crossOrigin: "anonymous",
    sources: [
      {
        src: channels[1].url,
        type: "application/x-mpegurl",
      },
    ],
  };

  const videoJsOptions2 = {
    // lookup the options in the docs for more options
    html5: {
      vhs: {
        withCredentials: false,
        fastQualityChange: true,
      },
    },
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    crossOrigin: "anonymous",
    sources: [
      {
        src: channels[0].url,
        type: "application/x-mpegurl",
      },
    ],
  };

  useEffect(() => {
    if (!isLive) {
      if (playerOne) {
        playerOne.muted(false);
        playerOne.volume(volume);
      }
      if (playerTwo) {
        playerTwo.muted(false);
        playerTwo.volume(volume);
      }
    }
  }, [volume, isLive]);

  useEffect(() => {
    if (isPlayerOneReady && isPlayerTwoReady && playerOne && playerTwo && !isLive) {
      playerOne.on(["seeking", "waiting"], () => {
        setPlayer1Loading(true);
      });
      playerOne.on("ended", () => {
        setPlayer1Loading(false);
        togglePlay();
      });

      playerOne.on("playing", () => {
        setPlayer1Loading(false);
      });
      playerTwo.on("timeupdate", () => {
        if (playerOne && playerTwo) {
          const isLive = videoFrameTwo.duration === Infinity;
          if (!isLive) {
            // if VOD
            setCurrentTime(formatSecondsToHHMMSS(videoFrameTwo.currentTime));
          } else if (videoFrameTwo.currentProgramDateTime) {
            // if stream exposes PDT timestamp
            setCurrentTime(formatDate(videoFrameTwo.currentProgramDateTime));
          } else {
            // if stream doesn't expose PDT timestamp
            const res = calculateLivePlayheadPosition(
              videoFrameTwo.currentTime,
              videoFrameTwo.seekable.end(videoFrameTwo.seekable.length - 1)
            );
            setCurrentTime(formatDate(res));
          }
          if (!isLive && !isSliding) {
            // don't overwrite slider value when we're sliding
            const result = calculateSliderValue(videoFrameTwo).toString();
            setProgress(result);
          }
        }
      });
      setPlayerOne(playerOne);

      playerTwo.on(["seeking", "waiting"], () => {
        setPlayer2Loading(true);
      });
      playerTwo.on("playing", () => {
        setPlayer2Loading(false);
      });
      playerTwo.on("ended", () => {
        setPlayer2Loading(false);
      });
      setPlayerTwo(playerTwo);

      // player1.muted = true;
      // player2.muted = true;

      if (rewind) {
        //todo - test when the stream is live
        let newTime = 0;
        if (videoFrameOne.currentTime > 30) {
          newTime = videoFrameOne.currentTime - 30;
        }
        if (playerOne && playerTwo) {
          videoFrameOne.currentTime = newTime;
          videoFrameTwo.currentTime = newTime;
          setPlayerOne(playerOne);
          setPlayerTwo(playerTwo);
          synchronize(0, [playerOne, playerTwo]);
        }
        setRewind(false);
      }

      synchronize(0, [playerOne, playerTwo]);
      playerOne.play();
      playerTwo.play();

      return () => {
        if (playerOne) {
          try {
            setPlayerOneReady(false);
            playerOne.dispose();
            setPlayerOne(null);
            setVideoFrameOne(null);
          } catch (e) {
            console.log(e);
          }
        }
        if (playerTwo) {
          try {
            setPlayerTwoReady(false);
            playerTwo.dispose();
            setPlayerTwo(null);
            setVideoFrameOne(null);
          } catch (e) {
            console.log(e);
          }
        }
      };
    }
  }, [isLive, isPlayerOneReady, isPlayerTwoReady]);

  const renderLiveVideoControls = () => {
    return (
      <>
        <GoBack
          onClick={() => {
            toggleIsLive();
            setRewind(true);
          }}
          height={24}
          width={24}
        />
        <Icon
          color={styleVariables.color_white}
          size={24}
          className="camera-streams-bottom-play"
          name={"fas fa-pause"}
          onClick={toggleIsLive}
        />
        <VolumeControl volume={volume} setVolume={setVolume} />
        <div className="camera-streams-bottom-pull-right">
          <div
            onKeyDown={toggleIsLive}
            onClick={toggleIsLive}
            role="button"
            tabIndex={0}
            className="camera-streams-bottom-pull-right-button"
          >
            <Icon color={styleVariables.color_red} size={12} name="fas fa-video" />{" "}
            <Typography className="camera-streams-bottom-pull-right-live">Live</Typography>
          </div>
        </div>
      </>
    );
  };

  const renderHLSVideoControls = () => {
    return (
      <>
        <GoBack
          onClick={() => {
            let newTime = 0;
            if (videoFrameOne && videoFrameOne.currentTime > 30) {
              newTime = videoFrameOne.currentTime - 30;
            }
            if (playerOne && playerTwo) {
              videoFrameOne.currentTime = newTime;
              videoFrameTwo.currentTime = newTime;
              setPlayerOne(playerOne);
              setPlayerTwo(playerTwo);
              synchronize(0, [playerOne, playerTwo]);
            }
          }}
          height={24}
          width={24}
        />
        {isPlaying ? (
          <Icon
            color={styleVariables.color_white}
            size={24}
            className="camera-streams-bottom-play"
            name={"fas fa-pause"}
            onClick={togglePlay}
          />
        ) : (
          <Icon
            color={styleVariables.color_white}
            size={24}
            className="camera-streams-bottom-play"
            name={"fas fa-play"}
            onClick={togglePlay}
          />
        )}
        <VolumeControl volume={volume} setVolume={setVolume} />
        <label className="camera-streams-bottom-time">{currentTime}</label>
        <div className="camera-streams-bottom-pull-right">
          <VideoQualityControl setVideoQuality={setVideoQuality} />
          <div
            onKeyDown={toggleIsLive}
            onClick={toggleIsLive}
            role="button"
            className="camera-streams-bottom-pull-right-button"
            tabIndex={0}
          >
            <Icon onClick={toggleIsLive} color={styleVariables.color_gray} size={12} name="far fa-video" />{" "}
            <Typography className="camera-streams-bottom-pull-right-live">Live</Typography>
          </div>
        </div>
      </>
    );
  };

  return (
    <Card className={classes} width={width} height={height} hover={false}>
      <>
        <VideoElement
          className="camera-streams-video-element"
          text={channels[0].text}
          applicationName={applicationName}
          streamName={"channel2.stream_1080p_webrtc"}
          withControls={isLive ? true : false}
          cameraPresets={cameraPresets}
          lastCameraPreset={lastCameraPreset}
          setCameraPreset={setCameraPreset}
          takeSnapshot={takeSnapshot}
          setPTR={setPTR}
          _player={playerOne}
          loading={!isLive ? player1Loading : false}
          isLive={isLive}
          isReplay={isReplay}
          volume={volume}
          videoQuality={videoQuality}
          setVideoQuality={setVideoQuality}
          onFullScreenClick={onFullScreenClick}
          setPlayerReady={setPlayerOneReady}
          setPlayer={setPlayerOne}
          options={videoJsOptions1}
          setVideoFrame={setVideoFrameOne}
          quality1={0}
          quality2={2}
        />
        <VideoElement
          className="camera-streams-video-element"
          text={channels[1].text}
          applicationName={applicationName}
          streamName={"channel1.stream_1080p_webrtc"}
          withControls={false}
          takeSnapshot={takeSnapshot}
          setPTR={setPTR}
          _player={playerTwo}
          loading={!isLive ? player2Loading : false}
          isLive={isLive}
          isReplay={isReplay}
          volume={volume}
          videoQuality={videoQuality}
          setVideoQuality={setVideoQuality}
          onFullScreenClick={onFullScreenClick}
          setPlayerReady={setPlayerTwoReady}
          setPlayer={setPlayerTwo}
          options={videoJsOptions2}
          setVideoFrame={setVideoFrameTwo}
          quality1={1}
          quality2={3}
        />
        {isReplay && (
          <div className="camera-streams-video-element">{<VolumeControl volume={volume} setVolume={setVolume} />}</div>
        )}
      </>
      <div className="camera-streams-progress-bar-container">
        {!isReplay && !isLive && (
          <ProgressBar
            progress={progress}
            onSliderInput={onSliderInput}
            onSliderChange={onSliderChange}
            videoFrame={videoFrameOne}
            showCurrentVideoTime={false}
          />
        )}
      </div>
      {!isReplay && (
        <div className="camera-streams-bottom">{isLive ? renderLiveVideoControls() : renderHLSVideoControls()}</div>
      )}
    </Card>
  );
};

export { CameraStreams };
