import { IArcadiaUserInfo } from "@types";
import { useState } from "react";
import UserService from "../../services/UserService";

const useUser = () => {
  const [isUserLoading, setIsLoading] = useState(false);
  const [isUserError, setIsError] = useState(false);

  const [user, setUser] = useState<IArcadiaUserInfo | null>(null);

  const fetchUser = () => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      UserService.getUser()
        .then((response: any) => {
          let result = response.data as IArcadiaUserInfo;
          setUser(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return { user, isUserLoading, isUserError, fetchUser, setUser };
};

export default useUser;
