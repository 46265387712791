import { IArcadiaProject } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useProject = () => {
  const [isProjectLoading, setIsLoading] = useState(false);
  const [isProjectError, setIsError] = useState(false);
  const [project, setProject] = useState<IArcadiaProject>();

  const fetchProjectById = (projectId: string) => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      ProjectsService.getProjectById(projectId)
        .then((response: any) => {
          const project: IArcadiaProject = response.data as IArcadiaProject;
          setProject(project);
          setIsError(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return {
    project: project,
    isProjectLoading,
    isProjectError,
    setProject,
    fetchProjectById,
  };
};

export default useProject;
