import axios from "axios";

const getUrl1 = (sessionId: string) => {
  return axios.get(`/camera/url1/${sessionId}`);
};

const getUrl2 = (sessionId: string) => {
  return axios.get(`/camera/url2/${sessionId}`);
};

const getApplicationName = (sessionId: string) => {
  return axios.get(`/camera/applicationName/${sessionId}`);
};

const getCameraPresets = () => {
  return axios.get("/camera/getcamerapresets");
};

const getLastCameraPreset = () => {
  return axios.get("/camera/getlastcamerapreset");
};

const setCameraPreset = (preset: number) => {
  return axios.put(`/camera/setcamerapreset/${preset}`);
};

const setPTR = (command: string) => {
  return axios.put(`/camera/setptr/${command}`);
};

export default {
  getUrl1,
  getUrl2,
  getApplicationName,
  setPTR,
  getCameraPresets,
  setCameraPreset,
  getLastCameraPreset,
};
