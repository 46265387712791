import * as React from "react";
import { Thumbnail } from "../Thumbnail";
import { Card } from "../Card";
import { Status } from "../../constants/enums/Status";
import { Progress } from "../Common/Progress";
import { CircleStatus } from "../Common/CircleStatus";
import moment from "moment";
import { Typography, ElementType, Color, FontWeight } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { useHistory } from "react-router-dom";
import { Button } from "../Button";
import { IFavorite } from "@types";
import DropdownMenu from "../DropdownMenu";

const CardProject: React.FC<
  {
    backgroundImageUrl: string;
    backgroundColor: string;
    status: Status;
    title: string;
    subText: string;
    imageTitle?: string;
    height?: number | "auto" | "initial" | "inherit";
    width?: number | "auto" | "initial" | "inherit";
    nextSession?: Date;
    activeSession?: Date;
    sessionsUsed: number;
    totalSessions: number;
    projectId?: string;
    isFavorite?: boolean;
    isArchived?: boolean;
    onFavoriteClick: (favorite: IFavorite) => void;
    onArchiveProject: (projectId: string) => void;
    onActivateProject: (projectId: string) => void;
    onDeleteProject: (projectId: string) => void;
    onCopyProjectLinkClick: (projectId: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  backgroundImageUrl = "",
  backgroundColor = styleVariables.color_white,
  status = Status.InProgress,
  title = "",
  subText = "",
  imageTitle = "",
  height = 323,
  width = 320,
  nextSession,
  activeSession,
  sessionsUsed = 0,
  totalSessions = 0,
  projectId = "0",
  isFavorite = false,
  isArchived = false,
  onFavoriteClick,
  onArchiveProject,
  onActivateProject,
  onDeleteProject,
  onCopyProjectLinkClick,
}): React.ReactElement => {
  const history = useHistory();

  const onClickHandler = () => {
    history.push(`/project/${projectId}`);
  };

  return (
    <Card className="project-card-outer-container" width={width} height={height} hover={false} onClick={onClickHandler}>
      <div className="project-card-content">
        <div className="project-card-content-top">
          <div className="project-card-header">
            <Thumbnail
              className={"project-card-thumbnail"}
              title={imageTitle}
              round={false}
              width={42}
              height={42}
              borderRadius={4}
              filter={"drop-shadow(-2px -3px 4px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #000)"}
              backgroundImageUrl={backgroundImageUrl}
              backgroundColor={backgroundColor}
            />
            <div className="project-card-icons">
              <Button
                onClick={(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  ev.stopPropagation();
                  onFavoriteClick({
                    projectId: projectId,
                    name: title,
                    imageUri: backgroundImageUrl,
                  });
                }}
                themeType="simple"
                iconOnly={true}
                iconName={"fas fa-star"}
                iconSize={20}
                iconColor={isFavorite ? styleVariables.color_orange : styleVariables.color_gray}
              />
              <DropdownMenu
                buttons={[
                  {
                    icon: isFavorite ? "fas fa-star" : "fal fa-star",
                    name: isFavorite ? "Remove from Favorites" : "Add to favorites",
                    onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      ev.stopPropagation();
                      onFavoriteClick({
                        projectId: projectId,
                        name: title,
                        imageUri: backgroundImageUrl,
                      });
                    },
                    color: styleVariables.color_white,
                  },
                  // {
                  //   icon: "fal fa-edit",
                  //   name: "Edit Name and Description",
                  //   onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  //     ev.stopPropagation();
                  //   },
                  //   color: styleVariables.color_white,
                  // },
                  {
                    icon: "fal fa-link",
                    name: "Copy Project Link",
                    onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      ev.stopPropagation();
                      onCopyProjectLinkClick(projectId);
                    },
                    color: styleVariables.color_white,
                  },
                  {
                    icon: "fal fa-archive",
                    name: isArchived ? "Activate Project" : "Archive Project",
                    onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      ev.stopPropagation();
                      isArchived ? onActivateProject(projectId) : onArchiveProject(projectId);
                    },
                    color: styleVariables.color_white,
                  },
                  {
                    icon: "fal fa-trash",
                    name: "Delete Project",
                    color: styleVariables.color_red,
                    textColor: Color.Error,
                    onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      ev.stopPropagation();
                      onDeleteProject(projectId);
                    },
                  },
                ]}
                icon={"far fa-ellipsis-h"}
              />
            </div>
          </div>

          <Typography className="project-card-title" type={ElementType.h3} fontWeight={FontWeight.Bold}>
            {title.length > 60 ? `${title.substring(0, 57)}...` : title}
          </Typography>
        </div>
        <div className="project-card-content-bottom">
          <Typography className="project-card-content-bottom-info" color={Color.OffWhite}>
            {sessionsUsed} / {totalSessions} budgeted sessions used
          </Typography>
          <Progress completed={totalSessions === 0 ? 100 : (sessionsUsed / totalSessions) * 100} softShadow />
        </div>
      </div>
      <div className="project-card-footer project-card-status-text">
        {!activeSession && !nextSession && (
          <>
            <CircleStatus className="mr1 bg-gray" status={status} height={16} width={16} />
            <Typography color={Color.Secondary}>No machine sessions scheduled</Typography>
          </>
        )}
        {!activeSession && nextSession && (
          <>
            <CircleStatus className="mr1 bg-off-white" status={status} height={16} width={16} />
            <Typography color={Color.OffWhite}>
              Next Machine Session: {moment(nextSession).format("MMM DD, hA")}
            </Typography>
          </>
        )}
        {activeSession && (
          <>
            <CircleStatus className="mr1 bg-orange" status={status} height={16} width={16} />
            <Typography color={Color.Orange} type={ElementType.display}>
              Live Now
            </Typography>
          </>
        )}
      </div>
    </Card>
  );
};

export { CardProject };
