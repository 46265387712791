import * as React from "react";
import { default as cx } from "classnames";
import { Thumbnail } from "../Thumbnail";
import { Color, ElementType, Typography } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { INewProjectDataFeaturesData, INewProjectDataPart } from "@types";
const cogIcon = require("./cog.svg") as string;
import moment from "moment";
import { Button } from "../Button";

const PartDetails: React.FC<
  {
    className?: string;
    part: INewProjectDataPart;
    features: INewProjectDataFeaturesData[];
    onEditPart: () => void;
    onEditFeature: (part: INewProjectDataPart, feature: INewProjectDataFeaturesData) => void;
    onAddFeature: (part: INewProjectDataPart) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className, part, features, onEditPart, onAddFeature, onEditFeature }): React.ReactElement => {
  const classes = cx(
    {
      "part-details-container": true,
    },
    className
  );

  const getSubtitleText = (part: INewProjectDataPart) => {
    const result: string[] = [];
    if (part) {
      if (part.material) {
        result.push(part.material.value);
      }
      if (part.number) {
        result.push(`${part.number} Part${part.number !== 1 ? "s" : ""}`);
      }
      if (part.deliveryDate) {
        result.push(`Deliver by ${moment(part.deliveryDate).format("MM/DD/YYYY")}`);
      }
    }
    return result.join(" • ");
  };
  return (
    <div className={classes}>
      <div className="part-details">
        <div>
          <Thumbnail
            className="part-details-image"
            backgroundColor={styleVariables.color_layer_2}
            width={96}
            height={96}
            backgroundImageUrl={cogIcon}
            title={"part-default-image"}
          />
        </div>
        <div className="part-details-info">
          <div className="part-details-info-inner">
            <Typography className="part-details-name" type={ElementType.h5}>
              {part.name}
            </Typography>
            <Typography className="part-details-description">{part.description}</Typography>
            <Typography className="part-details-name" color={Color.Secondary}>
              {getSubtitleText(part)}
            </Typography>
          </div>
          <div className="part-details-info-button">
            <Button themeType="simple" onClick={onEditPart}>
              <Typography type={ElementType.bodySmall} color={Color.Secondary}>
                Edit
              </Typography>
            </Button>
          </div>
        </div>
      </div>
      <div className="part-details-features">
        {features.map((f) => (
          <div key={f.featureId} className="part-details-features-feature">
            <div>
              <Typography type={ElementType.h6}>{f.featureName}</Typography>
              <Typography color={Color.Secondary}>{`${f.process}`}</Typography>
            </div>
            <div>
              <Button themeType="simple" onClick={() => onEditFeature(part, f)}>
                <Typography type={ElementType.bodySmall} color={Color.Secondary}>
                  Edit
                </Typography>
              </Button>
            </div>
          </div>
        ))}
        <Button className="add-feature-button" size="small" themeType="simple" onClick={() => onAddFeature(part)}>
          <Typography type={ElementType.bodySmall} color={Color.Secondary}>
            + Add Feature
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export { PartDetails };
