import React from "react";
import { default as cx } from "classnames";
import ReactTags, { Tag } from "react-tag-autocomplete";
import { ElementType, Typography } from "../Common/Typography";

const Tags: React.FC<
  {
    className?: string;
    label?: string;
    tags: Tag[];
    suggestions?: Tag[];
    onDelete: (id: number) => void;
    onAddition: (tag: Tag) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", tags, suggestions, onDelete, onAddition, label }): React.ReactElement => {
  const classes = cx(
    {
      "tags-container": true,
    },
    className
  );
  const reactTags = React.createRef<ReactTags>();

  return (
    <div className={classes}>
      {label && <Typography type={ElementType.label}>{label}</Typography>}
      <ReactTags
        //@ts-ignore
        placeholderText={"+ Add Tag"}
        allowNew={true}
        ref={reactTags}
        tags={tags}
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
      />
    </div>
  );
};

export { Tags };
