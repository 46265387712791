import * as React from "react";
import { Typography, ElementType, Color } from "../Common/Typography";

const SessionRowHeader = (): React.ReactElement => {
  return (
    <div>
      <div className="session-header">
        <div className="session-header-with-border">
          <div className="session-row-container-thumbnail-runname">
            <div className="session-row-thumbnail-container"></div>
            <Typography
              className="session-row-value-column-small"
              type={ElementType.tableHeader}
              color={Color.OffWhite}
            >
              Run
            </Typography>
          </div>
          <Typography className="session-row-value-column" type={ElementType.tableHeader} color={Color.OffWhite}>
            Program
          </Typography>
          <Typography className="session-row-value-column" type={ElementType.tableHeader} color={Color.OffWhite}>
            NC Code Ver
          </Typography>
          <Typography className="session-row-value-column" type={ElementType.tableHeader} color={Color.OffWhite}>
            Parameters Ver
          </Typography>
          <Typography className="session-row-value-column" type={ElementType.tableHeader} color={Color.OffWhite}>
            Jobs Ver
          </Typography>
        </div>
      </div>
    </div>
  );
};

export { SessionRowHeader };
