import React, { FC, HTMLAttributes, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { FileUploader } from "../FileUploader";

const ModalUploadMeasurements: FC<
  {
    className?: string;
    projectId: string;
    currentFolder: string;
    machineRunId: string;
    onCloseModal: () => void;
    onDone: (files: FormData[]) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, onDone, projectId, currentFolder, machineRunId }): ReactElement => {
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const handleDoneClick = async () => {
    const result: FormData[] = [];
    myFiles.forEach((file) => {
      const blob = new Blob([file], { type: file.type });
      const fd = new FormData();
      fd.append("formData", blob, file.name);
      fd.append("projectId", projectId);
      fd.append("fileName", file.name);
      fd.append("userPath", currentFolder || "/");
      fd.append("runId", machineRunId!);
      result.push(fd);
    });
    onDone(result);
  };

  return (
    <Modal className={`modal-upload-measurements ${className}`} height={"auto"} width={598} onCloseModal={onCloseModal}>
      <Typography className="modal-upload-measurements-title" type={ElementType.h4}>
        Upload measurements
      </Typography>
      <Typography className="modal-upload-measurements-subtitle" type={ElementType.body} color={Color.Secondary}>
        Upload your measurements for the current run.
      </Typography>
      <Typography type={ElementType.label}>Upload Measurements*</Typography>
      <FileUploader files={myFiles} setFiles={setMyFiles} />
      <div className="modal-upload-measurements-buttons">
        <Button size="large" themeType="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button size="large" themeType="clear" disabled={myFiles === null} onClick={handleDoneClick}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export { ModalUploadMeasurements };
