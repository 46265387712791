/* eslint-disable tsc/config */
import React, { FC, ReactElement, useRef, useState } from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";
import { useClickOutside } from "../../helpers/hooks";

const LiveSessionDropdownActions: FC<{
  onClickCopy?: Function;
  onClickEdit?: Function;
  onClickDelete?: Function;
  onClickStart?: Function;
}> = ({ onClickCopy, onClickEdit, onClickDelete, onClickStart }): ReactElement => {
  const [shown, setShown] = useState<boolean>(false);

  const toggleDropdown = (event: any) => {
    setShown(!shown);
  };

  const closeDropdown = () => {
    setShown(false);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, closeDropdown);

  return (
    <span className="live-session-dropdown-actions" ref={wrapperRef}>
      <span
        className="dropdown-actions-opaque"
        role="button"
        onKeyUp={toggleDropdown}
        onClick={toggleDropdown}
        tabIndex={0}
      >
        <Icon
          withContainer={true}
          color={styleVariables.color_gray}
          name="far fa-ellipsis-h fa-stack-1x"
          size={20}
          width={25}
          height={25}
        />
      </span>
      {shown && (
        <ul className="live-session-dropdown-actions-list">
          {onClickCopy && (
            <li className="live-session-dropdown-actions-list-option">
              <div
                role="button"
                className="live-session-dropdown-actions-list-option-container"
                onClick={() => {
                  setShown(false);
                  onClickCopy();
                }}
                onKeyPress={() => {
                  setShown(false);
                  onClickCopy();
                }}
                tabIndex={0}
              >
                <Icon
                  className="live-session-dropdown-actions-list-option-container-icon"
                  name="fal fa-copy"
                  color={styleVariables.color_gray}
                  size={22}
                />
                <Typography type={ElementType.inline}>Duplicate</Typography>
              </div>
            </li>
          )}
          {onClickEdit && (
            <li className="live-session-dropdown-actions-list-option">
              <div
                role="button"
                className="live-session-dropdown-actions-list-option-container"
                onClick={() => {
                  setShown(false);
                  onClickEdit();
                }}
                onKeyPress={() => {
                  setShown(false);
                  onClickEdit();
                }}
                tabIndex={0}
              >
                <Icon
                  className="live-session-dropdown-actions-list-option-container-icon"
                  name="fal fa-cog"
                  color={styleVariables.color_gray}
                  size={22}
                />
                <Typography type={ElementType.inline}>Cleaning and Measurements</Typography>
              </div>
            </li>
          )}
          {onClickDelete && (
            <li className="live-session-dropdown-actions-list-option">
              <div
                role="button"
                className="live-session-dropdown-actions-list-option-container"
                onClick={() => {
                  setShown(false);
                  onClickDelete();
                }}
                onKeyPress={() => {
                  setShown(false);
                  onClickDelete();
                }}
                tabIndex={0}
              >
                <Icon
                  className="live-session-dropdown-actions-list-option-container-icon"
                  name="fal fa-trash"
                  color={styleVariables.color_red}
                  size={22}
                />
                <Typography type={ElementType.inline} color={Color.Error}>
                  Delete Run
                </Typography>
              </div>
            </li>
          )}
          {onClickStart && (
            <li className="live-session-dropdown-actions-list-option">
              <div
                role="button"
                className="live-session-dropdown-actions-list-option-container"
                onClick={() => {
                  setShown(false);
                  onClickStart();
                }}
                onKeyPress={() => {
                  setShown(false);
                  onClickStart();
                }}
                tabIndex={0}
              >
                <Icon
                  className="live-session-dropdown-actions-list-option-container-icon"
                  name="fa fa-play"
                  size={22}
                />
                <Typography type={ElementType.inline}>Start Run</Typography>
              </div>
            </li>
          )}
        </ul>
      )}
    </span>
  );
};

export default LiveSessionDropdownActions;
export { LiveSessionDropdownActions };
