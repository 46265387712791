import React from "react";
import { Card } from "../Card";
import { Icon } from "../Icon";
import { ElementType, Color, Typography, FontWeight } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { SessionRowDetails } from "../SessionRowDetails";
import { SessionRowStatus } from "../SessionRow";
import { IArcadiaFileUpload, IArcadiaMaterial } from "@types";
import { formatSecondsToHHMMSS } from "../CameraStreams/helpers";

const SessionRowInProgress: React.FC<
  {
    className?: string;
    runName: string;
    runStartTime: string;
    program: string;
    ncCode?: string;
    parameters?: string;
    jobs?: string;
    ncCodeId?: number;
    parametersId?: number;
    jobsId?: number;
    material: IArcadiaMaterial;
    snapshots: IArcadiaFileUpload[];
    onClickScreenshot: Function;
    onClickEdit?: () => void;
    onClickStop?: () => void;
    onTextareaChange: Function;
    onClickUploadMeasurements?: () => void;
    onClickDownloadProgram: Function;
    notes: string;
    duration: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  runName,
  runStartTime,
  program,
  parameters = "",
  jobs = "",
  ncCode = "",
  parametersId,
  jobsId,
  ncCodeId,
  material,
  snapshots,
  onClickScreenshot,
  onTextareaChange,
  onClickEdit,
  onClickStop,
  onClickUploadMeasurements,
  onClickDownloadProgram,
  notes,
  duration,
}): React.ReactElement => {
  return (
    <Card className="session-row-details session-row-in-progress" width={"auto"} height={455} hover={false}>
      <div className="session-row-details-info">
        <div className="session-row-details-info-header">
          <Typography className="session-row-details-status-column" type={ElementType.display} color={Color.Orange}>
            <Typography type={ElementType.inline} color={Color.Orange} fontWeight={FontWeight.Bold}>
              {runName}
            </Typography>
            <Typography
              className="session-row-details-status-column-progress"
              type={ElementType.inline}
              color={Color.Orange}
            >
              in progress
            </Typography>
          </Typography>
          <Typography
            className="session-row-details-info-column"
            type={ElementType.tableHeader}
            color={Color.Secondary}
          >
            Program
          </Typography>
          <Typography
            className="session-row-details-info-column"
            type={ElementType.tableHeader}
            color={Color.Secondary}
          >
            NC Code Ver
          </Typography>
          <Typography
            className="session-row-details-info-column"
            type={ElementType.tableHeader}
            color={Color.Secondary}
          >
            Parameters Ver
          </Typography>
          <Typography
            className="session-row-details-info-column"
            type={ElementType.tableHeader}
            color={Color.Secondary}
          >
            Jobs Ver
          </Typography>
          {onClickStop && (
            <Icon
              className="mr05"
              onClick={onClickStop}
              size={16}
              width={24}
              height={24}
              color={styleVariables.color_gray}
              name={"fa fa-stop"}
            />
          )}
          {onClickEdit && (
            <Icon
              onClick={onClickEdit}
              size={16}
              width={24}
              height={24}
              color={styleVariables.color_gray}
              name={"fal fa-cog"}
            />
          )}
        </div>
        <div className="session-row-details-info-top">
          <div className="session-row-details-info-top-run">
            <Typography className="session-row-details-status-column" type={ElementType.display}>
              <Icon size={16} width={24} height={24} color={styleVariables.color_white} name={"fal fa-clock"} />
              {duration ? formatSecondsToHHMMSS(duration) : ""}
            </Typography>
          </div>
          <Typography className="session-row-details-info-column" type={ElementType.bodySmall}>
            {program}
          </Typography>
          <Typography className="session-row-details-info-column" type={ElementType.bodySmall}>
            {ncCode}
          </Typography>
          <Typography className="session-row-details-info-column" type={ElementType.bodySmall}>
            {parameters}
          </Typography>
          <Typography className="session-row-details-info-column" type={ElementType.bodySmall}>
            {jobs}
          </Typography>
        </div>
        <SessionRowDetails
          snapshots={snapshots}
          material={material}
          status={SessionRowStatus.Running}
          onClickScreenshot={onClickScreenshot}
          onClickUploadMeasurements={onClickUploadMeasurements}
          onClickDownloadProgram={onClickDownloadProgram}
          program={program}
          jobsId={jobsId!}
          ncCodeId={ncCodeId!}
          parametersId={parametersId!}
          notes={notes}
          onTextareaChange={onTextareaChange}
        />
      </div>
    </Card>
  );
};

export { SessionRowInProgress };
