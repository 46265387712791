import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState, useEffect } from "react";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { Textarea } from "../Common/Textarea";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import JSZip from "jszip";
import { FileUploader } from "../FileUploader";
import { ScrollableContainer } from "../ScrollableContainer";

const ModalUploadProgramPart: FC<
  {
    className?: string;
    title: string;
    programName: string;
    initialFiles: File[] | null;
    onCloseModal: () => void;
    onDone: (newPart: FormData) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, onDone, title, programName, initialFiles }): ReactElement => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [myFiles, setMyFiles] = useState<File[] | []>([]);

  useEffect(() => {
    if (initialFiles) {
      setMyFiles(initialFiles);
    }
  }, [initialFiles]);

  const updateName = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setName(target.value);
  };

  const updateDescription = (e: FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setDescription(target.value);
  };

  const handleDoneClick = async () => {
    if (myFiles) {
      const archiveUploaded =
        myFiles.length === 1 &&
        (myFiles[0].type === "application/x-zip-compressed" || myFiles[0].type === "application/zip");
      if (archiveUploaded) {
        const blob = new Blob([myFiles[0]], { type: "application/zip" });
        const fd = new FormData();
        fd.append("formData", blob, `upload_${new Date().toISOString()}.zip`);
        fd.append("programName", programName);
        fd.append("shortNote", name);
        fd.append("longNote", description);
        onDone(fd);
      } else {
        const zip = new JSZip();
        myFiles.forEach((file: File) => {
          zip.file(file.name, file);
        });
        zip.generateAsync({ type: "blob" }).then(function (blob) {
          const fd = new FormData();
          fd.append("formData", blob, `upload_${new Date().toISOString()}.zip`);
          fd.append("programName", programName);
          fd.append("shortNote", name);
          fd.append("longNote", description);
          onDone(fd);
        });
      }
    }
  };

  return (
    <Modal className={`modal-upload-program-part ${className}`} height={699} width={598} onCloseModal={onCloseModal}>
      <ScrollableContainer height={667}>
        <Typography className="modal-upload-program-part-title" type={ElementType.h4}>
          {title}
        </Typography>
        <Typography className="modal-upload-program-part-subtitle" type={ElementType.body} color={Color.Secondary}>
          A guiding message on file types, version naming or size limits.
        </Typography>
        <Typography type={ElementType.label}>Upload a File or Folder</Typography>
        <FileUploader files={myFiles} setFiles={setMyFiles} />
        <Input
          label={"Version Name"}
          onChange={updateName}
          height={40}
          placeholder={"Version Name"}
          value={name}
          width={"100%"}
        />
        <Textarea
          label={"Version Description"}
          onChange={updateDescription}
          height={40}
          placeholder={"Version Description"}
          value={description}
          width={"100%"}
        >
          {description}
        </Textarea>
        <div className="modal-upload-program-part-buttons">
          <Button size="large" themeType="secondary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button
            size="large"
            themeType="clear"
            disabled={myFiles === null || !name || !description}
            onClick={handleDoneClick}
          >
            Done
          </Button>
        </div>
      </ScrollableContainer>
    </Modal>
  );
};

export { ModalUploadProgramPart };
