import * as React from "react";
import { Card } from "../Card";
const plus = require("../../images/plus.svg") as string;
import { Typography, ElementType, Color } from "../Common/Typography";

const CardNewProject: React.FC<
  {
    height?: number | "auto" | "initial" | "inherit";
    onClick: () => void;
    width?: number | "auto" | "initial" | "inherit";
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ height = "auto", onClick = () => {}, width = 320 }): React.ReactElement => (
  <Card className="new-project-card-outer-container" width={width} height={height} hover={false} onClick={onClick}>
    <img alt="plus" src={plus} />
    <Typography className="new-project-text" type={ElementType.display} color={Color.Secondary}>
      Create New Project
    </Typography>
  </Card>
);

export { CardNewProject };
