import React, { FC, ReactElement, HTMLAttributes, FormEvent, useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Typography, ElementType } from "../Common/Typography";
import axios from "axios";
import styleVariables from "@styles/export.scss";
import DropdownActions from "../DropdownActions";
import { Input } from "../Common/Input";
import { IArcadiaSubTask, IArcadiaTask2, IDropdownButton } from "@types";
import ActionGroup from "./ActionGroup";
import { usePrevious } from "../../helpers/hooks";
import DropdownMenu from "../DropdownMenu";
// import uniqid from "uniqid";
import { v4 as uuidv4 } from "uuid";
import { Button } from "../Button";

const ActionItems: FC<{ projectId?: string; receivedGroups: IArcadiaTask2[] } & HTMLAttributes<HTMLDivElement>> = ({
  projectId = "",
  receivedGroups = [],
}): ReactElement => {
  const initialCollapsed = new Map<IArcadiaTask2, boolean>();

  for (const group of receivedGroups) {
    initialCollapsed.set(group, true);
  }

  const [shown, setShown] = useState<Map<IArcadiaTask2, boolean>>(initialCollapsed);
  const [sortableGroup, setSortableGroup] = useState<IArcadiaTask2 | null>(null);
  const [inputItem, setInputItem] = useState<IArcadiaSubTask | IArcadiaTask2 | null>(null);
  const [groups, setGroups] = useState<IArcadiaTask2[]>(receivedGroups);
  const [showCompleted, setShowCompleted] = useState<Map<IArcadiaTask2, boolean>>(initialCollapsed);
  const [showActionItems, setShowActionItems] = useState<boolean>(true);
  const prevInputItem = usePrevious<IArcadiaSubTask | IArcadiaTask2 | null>(inputItem);

  useEffect(() => {
    if (!prevInputItem) {
      return;
    }

    updateSubTask(prevInputItem);
  }, [inputItem]);

  const updateSubTask = async (task: IArcadiaSubTask | IArcadiaTask2) => {
    try {
      if ((task as IArcadiaTask2).taskId !== undefined) {
        const arcadiaTask = task as IArcadiaTask2;
        await axios.put(`/Projects/UpdateTask/${projectId}/${arcadiaTask.taskId}`, {
          newHeader: arcadiaTask.taskHeader,
        });
      } else if ((task as IArcadiaSubTask).subtaskId !== undefined) {
        const arcadiaSubTask = task as IArcadiaSubTask;
        const updated = {
          ...arcadiaSubTask,
          taskHeader: arcadiaSubTask.subTaskName,
          isComplete: arcadiaSubTask.subTaskCheckbox,
          subtaskStatus: arcadiaSubTask.subtaskStatus,
        };
        await axios.put(`/Projects/UpdateSubTask/${projectId}/${arcadiaSubTask.subtaskId}`, updated);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const deleteTask = async (taskId: string, isParent: boolean): Promise<boolean> => {
    try {
      await axios.delete(`/Projects/DeleteTask/${projectId}/${taskId}?isParent=${isParent}`);
      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const addNewSection = async () => {
    try {
      const newParentTask: IArcadiaTask2 = {
        taskId: uuidv4(),
        taskHeader: "",
        subTasks: [],
      };
      const { data } = await axios.post(`/Projects/session/${projectId}/tasks`, newParentTask);
      const newGroups = [...groups];
      newGroups.push(data);
      setGroups(newGroups);
      setInputItem(data);
      document.getElementById(`input-${data.taskId}`)?.focus();

      const newShown = new Map(shown);
      newShown.set(data, true);
      setShown(newShown);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (inputItem !== null && !event.target.id.startsWith("input-")) {
        setInputItem(null);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputItem, setInputItem]);

  // @ts-ignore
  const SortableContainerComponent = SortableContainer(({ identifier, children }) => {
    return (
      <div className="action-items-group-sortable" key={`sortable-group-${identifier}`}>
        {children}
      </div>
    );
  });

  // @ts-ignore
  const SortableItem = SortableElement(({ identifier, children }) => (
    <div className="action-items-group-row-wrapper">
      <div className="action-items-group-row" key={`key-${identifier}`}>
        {children}
      </div>
    </div>
  ));

  const headerMenuButtons: IDropdownButton[] = [
    {
      icon: "fa fa-plus",
      name: "Add New Section",
      onClick: addNewSection,
    },
    {
      icon: "fa fa-chevron-right",
      name: showActionItems ? "Collapse All" : "Expand All",
      onClick: () => {
        setShowActionItems(!showActionItems);
      },
    },
  ];

  return (
    <aside className="action-items">
      <div className="action-items-head-row">
        <Typography type={ElementType.h4} className="action-items-head">
          Your Action Items
        </Typography>
        <DropdownMenu buttons={headerMenuButtons} />
      </div>
      {showActionItems &&
        groups.map((group, groupKey) => {
          const toggleCollapseState = () => {
            const newShown = new Map(shown);
            const status = newShown.get(group) || false;
            newShown.set(group, !status);
            setShown(newShown);
          };

          const onSortEnd = ({ oldIndex = 0, newIndex = 0 }): void => {
            const newItems = group.subTasks;
            if (newIndex >= newItems.length) {
              let k = newIndex - newItems.length + 1;
              while (k--) {
                // @ts-ignore
                newItems.push(undefined);
              }
            }
            newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);

            const newGroups = [...groups];
            newGroups[groupKey].subTasks = newItems;
            setGroups(newGroups);
          };

          const makeSortableGroup = () => {
            setSortableGroup(group === sortableGroup ? null : group);
          };

          const renameGroup = () => {
            setInputItem(group);
          };

          const renameGroupInputHandler = (event: FormEvent<HTMLInputElement>) => {
            event.preventDefault();
            const element = event.target as HTMLInputElement;
            const newGroups = [...groups];
            // @ts-ignore
            const task = newGroups[groupKey as keyof IArcadiaTask2];
            task.taskHeader = element.value;
            setGroups(newGroups);
          };

          const addNewTask = async () => {
            try {
              const newSubTask = {
                taskName: "",
                parentTaskId: group.taskId,
                startDate: new Date().toISOString(),
                dueDate: new Date().toISOString(),
                endDate: new Date().toISOString(),
                isCritical: true,
              };

              const { data } = await axios.post(`/Projects/session/${projectId}/tasks`, newSubTask);
              const newGroups = [...groups];
              newGroups[groupKey].subTasks.push(data.subTasks[0]);
              setGroups(newGroups);
            } catch (e) {
              console.error(e);
            }
          };

          const toggleCompleted = () => {
            const newShowCompleted = new Map(showCompleted);
            const status = newShowCompleted.get(group) || false;
            newShowCompleted.set(group, !status);
            setShowCompleted(newShowCompleted);
          };

          const deleteGroup = () => {
            deleteTask(group.taskId!, true);
            const newGroups = [...groups.filter((g) => g.taskId !== group.taskId)];
            setGroups(newGroups);
          };

          const subtaskChildrens = shown.get(group)
            ? group.subTasks.filter((item) => showCompleted.get(group) || !item.subTaskCheckbox)
            : [];
          return (
            <div className="action-items-group" key={`container-${group.taskId}`}>
              <div className="action-items-group-header">
                <div className="action-items-group-header-col">
                  <svg
                    className={`action-items-group-header-col-first action-items-opacity ${
                      shown.has(group) && shown.get(group) === true ? "" : "action-items-rotate-180"
                    }`}
                    width="12"
                    height="6"
                    viewBox="0 0 12 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={toggleCollapseState}
                  >
                    <path
                      d="M1.46484 0C0.832031 0 0.515625 0.773438 0.972656 1.23047L5.47266 5.73047C5.75391 6.01172 6.21094 6.01172 6.49219 5.73047L10.9922 1.23047C11.4492 0.773438 11.1328 0 10.5 0H1.46484Z"
                      fill={styleVariables.color_gray}
                    />
                  </svg>
                  {inputItem === group ? (
                    <Input
                      containerClassName="action-items-group-row-item"
                      height={36}
                      id={`input-${group.taskId}`}
                      value={group.taskHeader || ""}
                      onChange={renameGroupInputHandler}
                      onKeyUp={(event) => {
                        if (event.keyCode === 13) {
                          setInputItem(null);
                        }
                      }}
                    />
                  ) : (
                    <Typography type={ElementType.h5}>
                      {group.taskHeader === "" ? "Write task header" : group.taskHeader}
                    </Typography>
                  )}
                </div>
                <div className="action-items-group-header-col pointer">
                  <Button
                    className="action-items-opacity mr05"
                    onClick={toggleCompleted}
                    themeType="outline"
                    iconOnly={true}
                    size="small"
                    iconName={`fa fa-eye${showCompleted.get(group) ? "-slash" : ""}`}
                  ></Button>
                  <DropdownActions
                    reOrderStarted={group === sortableGroup}
                    onClickReorder={makeSortableGroup}
                    onClickRename={renameGroup}
                    onClickDelete={deleteGroup}
                  />
                  <Button
                    onClick={addNewTask}
                    themeType="secondary"
                    iconOnly={true}
                    size="small"
                    iconName="fa fa-plus"
                  ></Button>
                </div>
              </div>
              {group === sortableGroup && (
                <SortableContainerComponent
                  onSortEnd={onSortEnd}
                  identifier={group.taskId}
                  key={`main-sortable-group-${group.taskId}`}
                >
                  {subtaskChildrens.map((item, key) => (
                    <SortableItem
                      identifier={`${group.taskId}-${item.subtaskId}`}
                      key={`sortable-${group.taskId}-${item.subtaskId}`}
                      index={key}
                    >
                      <ActionGroup
                        deleteTask={deleteTask}
                        group={group}
                        groups={groups}
                        groupKey={groupKey}
                        itemKey={key}
                        item={item}
                        inputItem={inputItem}
                        isSortable={true}
                        setGroups={setGroups}
                        setInputItem={setInputItem}
                        updateSubTask={updateSubTask}
                      />
                    </SortableItem>
                  ))}
                </SortableContainerComponent>
              )}
              {group !== sortableGroup && (
                <div className="action-items-group-sortable" key={`non-sortable-group-${group.taskId}`}>
                  {subtaskChildrens.map((item, key) => (
                    <div className="action-items-group-row-wrapper" key={`non-sortable-item-${item.subtaskId}`}>
                      <div className="action-items-group-row">
                        <ActionGroup
                          deleteTask={deleteTask}
                          group={group}
                          groups={groups}
                          groupKey={groupKey}
                          itemKey={key}
                          item={item}
                          inputItem={inputItem}
                          isSortable={false}
                          setGroups={setGroups}
                          setInputItem={setInputItem}
                          updateSubTask={updateSubTask}
                        />
                      </div>
                      <div className="action-items-group-row-border" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      <div className="action-items-newsection">
        <Button
          onClick={addNewSection}
          themeType="simple"
          iconLeftName={"fa fa-plus"}
          color={styleVariables.color_gray}
          iconSize={14}
        >
          Add Section
        </Button>
      </div>
    </aside>
  );
};

export default ActionItems;
export { ActionItems };
