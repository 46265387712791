import moment from "moment";
import {
  IArcadiaProject,
  INewProjectData,
  IArcadiaPartDetails,
  INewProjectDataPart,
  IArcadiaFeatureDetails,
  INewProjectDataFeaturesData,
} from "@types";

const mapPartDetailsToDataPart = (part: IArcadiaPartDetails, materialCategories: string[]): INewProjectDataPart => {
  const material = materialCategories.find((m) => m === part.materialCategory)!;
  const result: INewProjectDataPart = {
    cycleSelection: "sec",
    description: part.partDescription || "",
    deliveryDate: part.dueDate ? new Date(part.dueDate!) : null,
    desiredCycle: part.desiredCycleTime ? moment.duration(part.desiredCycleTime).asSeconds() : 0,
    drawing: [],
    identifier: part.partId,
    material: { value: material, label: material },
    name: part.partName || "",
    number: parseInt(part.numberOfParts || "0"),
  };
  return result;
};

const mapFeatureDetailstoDataPart = (feature: IArcadiaFeatureDetails): INewProjectDataFeaturesData => {
  const result: INewProjectDataFeaturesData = {
    featureId: feature.featureId,
    featureName: feature.featureName,
    partId: feature.partId,
    advancedOptions: true,
    measure: feature.measuringUnit!,
    process: feature.processName!,
    parameters: feature.featureParameters,
  };
  return result;
};

const mapProjectToFormData = (project: IArcadiaProject, materialCategories: string[]): INewProjectData => {
  const featureMap: Map<string, INewProjectDataFeaturesData[]> = new Map<string, INewProjectDataFeaturesData[]>();

  project.partDetails?.forEach((p) => {
    const filteredFeatures = project.featureDetails?.filter((f) => f.partId === p.partId) || [];
    const mappedPart = mapPartDetailsToDataPart(p, materialCategories);
    featureMap.set(
      mappedPart.identifier!,
      filteredFeatures.map((f) => mapFeatureDetailstoDataPart(f))
    );
  });

  const formData: INewProjectData = {
    projectName: project.projectName || "",
    sessionsPlanned: project.sessionsPlanned || 0,
    part: project!.partDetails?.map((p) => mapPartDetailsToDataPart(p, materialCategories)) || [],
    feature: featureMap,
  };
  return formData;
};

export { mapProjectToFormData };
