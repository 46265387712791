import { useEffect, useState } from "react";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { authProvider } from "../../auth/authProvider";

const useMachineRunHubConnection = () => {
  const [machineRunHubConnection, setMachineRunHubConnection] = useState<HubConnection>();

  useEffect(() => {
    const createHubConnection = async () => {
      const token = await authProvider.acquireTokenSilent({
        scopes: ["api://d43c6832-c0b2-4234-b814-45d6050c4ff7/User.All"],
      });
      const hubConnect = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_BASE_API_URL}/hubs/MachineRunR`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => {
            return token.accessToken;
          },
        })
        .build();
      try {
        await hubConnect.start();
      } catch (e) {
        console.log(e);
      }
      setMachineRunHubConnection(hubConnect);
    };

    createHubConnection();
  }, []);

  return { machineRunHubConnection };
};

export default useMachineRunHubConnection;
