import * as React from "react";
import { default as cx } from "classnames";
import { StatusIcon } from "../StatusIcon";
import { Status } from "../../constants/enums/Status";
import { Color, ElementType, Typography } from "../Common/Typography";

const SessionRowSummary: React.FC<
  {
    status: Status;
    totalRuns: number;
    completedRuns: number;
    className?: string;
    width?: number;
    height?: number;
    borderRadius?: number;
    text: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  width,
  height,
  borderRadius = null,
  status,
  totalRuns,
  completedRuns,
  text,
}): React.ReactElement => {
  const classes = cx("session-row-summary", className);
  const styleObj: { width?: number; height?: number; borderRadius?: number } = {};
  if (width) {
    styleObj.width = width;
  }
  if (height) {
    styleObj.height = height;
  }
  if (borderRadius) {
    styleObj.borderRadius = borderRadius;
  }
  return (
    <div className={classes} style={styleObj}>
      <div className="session-row-summary-status-container">
        <StatusIcon status={status} />
        <Typography type={ElementType.display} color={status === Status.InProgress ? Color.Orange : Color.Primary}>
          {text}
        </Typography>
      </div>
      <div>{`${completedRuns}/${totalRuns} Runs Completed`}</div>
    </div>
  );
};

export { SessionRowSummary };
