import React, { FC, HTMLAttributes, ReactElement, useState } from "react";
import { default as cx } from "classnames";

import { Button } from "../Button";
import { ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { Input } from "../Common/Input";
import { IArcadiaZoomMeeting } from "@types";

const ModalEditZoomInfo: FC<
  {
    className?: string;
    onCloseModal?: () => void;
    onDone: (zoomLink: string) => void;
    onSkip?: () => void;
    isDestructive?: boolean;
    meeting?: IArcadiaZoomMeeting;
    isOperator?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, onDone, meeting, onSkip, isOperator }): ReactElement => {
  const classes = cx(
    {
      "modal-edit-zoom-info": true,
    },
    className
  );

  const [meetingLink, setMeetingLink] = useState<string>(meeting?.meetingNumber || "");

  return (
    <Modal className={classes} width={600} onCloseModal={onCloseModal}>
      <Typography className="modal-edit-zoom-info-title" type={ElementType.h4}>
        {isOperator ? "Enter Zoom Meeting" : "Get Zoom Meeting"}
      </Typography>
      <Input
        width={"100%"}
        value={meetingLink}
        onChange={(ev) => {
          setMeetingLink((ev.target as HTMLInputElement).value);
        }}
        label="Zoom Meeting Link"
        disabled={!isOperator}
      />
      <div className="modal-edit-zoom-info-buttons">
        {onSkip ? (
          <Button className="modal-edit-zoom-info-buttons-cancel" size={"large"} themeType="secondary" onClick={onSkip}>
            Skip
          </Button>
        ) : (
          <Button
            className="modal-edit-zoom-info-buttons-cancel"
            size={"large"}
            themeType="secondary"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        )}
        <Button size="large" themeType="clear" onClick={() => onDone(meetingLink)}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export { ModalEditZoomInfo };
