import { IArcadiaGroup } from "@types";
import { useState } from "react";
import CustomerService from "../../services/CustomerService";

const useCustomer = () => {
  const [isCustomerLoading, setIsLoading] = useState(false);
  const [isCustomerError, setIsError] = useState(false);

  //use arcadiaGroup instead of arcadiaCustomer - also contains customer name and easier to access
  const [customer, setCustomer] = useState<IArcadiaGroup | null>(null);

  const fetchCustomer = (groupId: string) => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      CustomerService.getCustomer(groupId)
        .then((response: any) => {
          let result = response.data as IArcadiaGroup;
          setCustomer(result);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return { customer, isCustomerLoading, isCustomerError, fetchCustomer, setCustomer };
};

export default useCustomer;
