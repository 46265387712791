import React, { FC, FormEvent, ReactElement, useState } from "react";
import { INewProjectData, INewProjectDataFeaturesData, INewProjectDataPart } from "@types";
import { ElementType, Typography } from "../Common/Typography";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { PartDetails } from "../PartDetails";
import { ScrollableContainer } from "../ScrollableContainer";

const ProjectOverview: FC<{
  formData: INewProjectData;
  setFormData: (data: INewProjectData) => void;
  addPart: () => void;
  onSave: () => void;
  handlePartClick: (part: INewProjectDataPart) => void;
  addFeature: (part: INewProjectDataPart) => void;
  handleFeatureClick: (part: INewProjectDataPart, feature: INewProjectDataFeaturesData) => void;
}> = ({ formData, onSave, setFormData, addPart, addFeature, handlePartClick, handleFeatureClick }): ReactElement => {
  const [enableSave, setEnableSave] = useState(false);

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newProjectName = (event.target as HTMLInputElement).value;
    const newFormData = Object.assign({}, formData);
    newFormData.projectName = newProjectName;
    setFormData(newFormData);
    setEnableSave(true);
  };

  const changeSession = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const val = parseInt(target.value);
    const newFormData = Object.assign({}, formData);
    newFormData.sessionsPlanned = Math.max(val, 0);
    setFormData(newFormData);
    setEnableSave(true);
  };

  const decreaseSession = () => {
    const newFormData = Object.assign({}, formData);
    const currentSessions = newFormData.sessionsPlanned || 0;
    newFormData.sessionsPlanned = Math.max(currentSessions - 1, 0);
    setFormData(newFormData);
    setEnableSave(true);
  };

  const increaseSession = () => {
    const newFormData = Object.assign({}, formData);
    const currentSessions = newFormData.sessionsPlanned || 0;
    newFormData.sessionsPlanned = currentSessions + 1;
    setFormData(newFormData);
    setEnableSave(true);
  };
  return (
    <ScrollableContainer width={1236} height={700}>
      <div className="card-project-overview">
        <Typography className="card-project-overview-subtitle" type={ElementType.subtitle}>
          Project Details
        </Typography>
        <div className="card-project-overview-top-row">
          <div>
            <Input
              width={315}
              label={"Project Name"}
              className="modal-new-project-form-input"
              placeholder="Project Name"
              onChange={handleNameChange}
              value={formData.projectName || ""}
            />
          </div>
          <div className={`card-project-overview-input-${formData.sessionsPlanned === 1 ? "singular" : "plural"}`}>
            <Input
              label={"Machine Session Budget"}
              height={48}
              min={0}
              onChange={changeSession}
              type="number"
              value={formData.sessionsPlanned || 0}
              onIncrease={increaseSession}
              onDecrease={decreaseSession}
              withButtons={true}
              width={200}
            />
          </div>
          <div className="card-project-overview-top-row-button">
            <Button size="large" disabled={!enableSave} themeType="secondary" onClick={onSave}>
              Save
            </Button>
          </div>
        </div>
        <div className="card-project-overview-parts-header">
          <Typography className="card-project-overview-eyebrow" type={ElementType.h4}>
            {`Parts (${formData.part.length})`}
          </Typography>
          <Button iconSize={14} size="xs" themeType="secondary" onClick={addPart} iconLeftName="fal fa-plus">
            Add Part
          </Button>
        </div>
        {formData.part.length > 0 &&
          formData.part.map((p) => (
            <PartDetails
              key={p.identifier}
              part={p}
              features={formData.feature.has(p?.identifier!) ? formData.feature.get(p?.identifier!)! : []}
              onEditPart={() => handlePartClick(p)}
              onEditFeature={handleFeatureClick}
              onAddFeature={() => addFeature(p)}
            />
          ))}
      </div>
    </ScrollableContainer>
  );
};

export { ProjectOverview };
