import React from "react";
import styleVariables from "@styles/export.scss";

export const BookmarkIcon: React.FC<
  {
    onClick: (value: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ onClick }): React.ReactElement => (
  <i
    role="button"
    onKeyDown={() => {}}
    tabIndex={0}
    onClick={onClick}
    className="bookmarkIcon fas fa-bookmark"
    color={styleVariables.color_orange}
  ></i>
);

// export const BookmarkContainer: React.FC<
//   {
//     time: number;
//     onClick: (value: string) => void;
//   } & React.HTMLAttributes<HTMLDivElement>
// > = ({ time, onClick }): React.ReactElement => (
//   <div key={snapshot.fileUploadId} className="bookmark-container">
//     <CardBookmark onClickCard={() => onClickScreenshot(snapshots, index)} snapshot={snapshot} />
//     <BookmarkIcon
//       onClick={() => goToSnapshotTime(snapshot.correlationTimestamp!)}
//       time={calculateBookmarkTimeOffset(snapshot.correlationTimestamp!, videoStartTime) / 100}
//     />
//   </div>
// );

export const AddBookmark: React.FC = (): React.ReactElement => (
  <span className="fa-stack">
    <i className="far fa-bookmark fa-stack-2x bookmarkOuter"></i>
    <i className="fas fa-plus fa-stack-1x fa-sm bookmarkPlus"></i>
  </span>
);

export const GoBack: React.FC<
  {
    height?: number;
    width?: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ height = 36, width = 36, ...other }): React.ReactElement => (
  //@ts-ignore
  <svg
    {...other}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${height}px`, width: `${width}px` }}
  >
    <path
      d="M3.03387 6.60556C2.57661 6.60556 2.25 6.99749 2.25 7.38943V13.4209C2.25 13.8781 2.57661 14.2048 3.03387 14.2048H9.06532C9.45726 14.2048 9.84919 13.8781 9.84919 13.4209V12.7677C9.84919 12.3757 9.45726 11.9838 9.06532 11.9838H5.97339C8.325 7.54185 13.0282 4.40636 18.45 4.40636C26.1581 4.40636 32.429 10.7427 32.429 18.3854C32.429 26.1588 26.0927 32.3644 18.45 32.3644C14.7919 32.3644 11.5258 31.058 9.04355 28.837C8.78226 28.5104 8.25968 28.5757 7.99839 28.837L7.54113 29.2943C7.21452 29.6209 7.21452 30.1435 7.54113 30.4701C10.4153 33.0177 14.2694 34.5854 18.45 34.5854C27.3339 34.5854 34.5847 27.3999 34.65 18.4507C34.65 9.56685 27.3992 2.25072 18.45 2.25072C12.5056 2.1854 7.27984 5.45152 4.47097 10.2201V7.38943C4.47097 6.99749 4.07903 6.60556 3.6871 6.60556H3.03387Z"
      fill={styleVariables.color_gray}
    />
    <path
      d="M14.0497 19.0255H14.5464C15.609 19.0255 16.1634 19.5915 16.1634 20.5385C16.1634 21.3008 15.5859 21.8783 14.8351 21.8783C13.888 21.8783 13.4491 21.3355 13.3452 20.6887H12.375C12.4558 21.8899 13.3105 22.7792 14.8351 22.7792C16.2442 22.7792 17.1567 21.7397 17.1567 20.5154C17.1567 19.4991 16.6254 18.8638 15.8515 18.552C16.5792 18.2055 17.0412 17.5587 17.0412 16.7386C17.0412 15.5143 16.0825 14.625 14.7543 14.625C13.5646 14.625 12.4789 15.3988 12.4789 16.6578H13.4491C13.4722 16.011 14.0035 15.5143 14.7543 15.5143C15.5397 15.5143 16.0479 16.0918 16.0479 16.7155C16.0479 17.5818 15.5974 18.1246 14.5464 18.1246H14.0497V19.0255Z"
      fill={styleVariables.color_gray}
    />
    <path
      d="M23.9758 16.9234C23.9758 15.4566 22.8901 14.625 21.6889 14.625C20.4877 14.625 19.402 15.4566 19.402 16.935V20.4692C19.402 21.9476 20.4877 22.7792 21.6889 22.7792C22.8901 22.7792 23.9758 21.9476 23.9758 20.4808V16.9234ZM22.9825 16.9234V20.4808C22.9825 21.3355 22.405 21.8783 21.6889 21.8783C20.9612 21.8783 20.3837 21.3355 20.3837 20.4808V16.9234C20.3837 16.0687 20.9612 15.5259 21.6889 15.5259C22.405 15.5259 22.9825 16.0687 22.9825 16.9234Z"
      fill={styleVariables.color_gray}
    />
  </svg>
);

export const GoForward: React.FC<
  {
    height?: number;
    width?: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ height = 36, width = 36, ...other }): React.ReactElement => (
  //@ts-ignore
  <svg
    {...other}
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ height: `${height}px`, width: `${width}px` }}
  >
    <path
      d="M33.2161 6.35639C33.6734 6.35639 34 6.74838 34 7.14037V13.1726C34 13.63 33.6734 13.9566 33.2161 13.9566H27.1847C26.7927 13.9566 26.4008 13.63 26.4008 13.1726V12.5193C26.4008 12.1273 26.7927 11.7353 27.1847 11.7353H30.2766C27.925 7.29281 23.2218 4.1569 17.8 4.1569C10.0919 4.1569 3.82096 10.494 3.82096 18.1378C3.82096 25.9123 10.1573 32.1187 17.8 32.1187C21.4581 32.1187 24.7242 30.8121 27.2065 28.5908C27.4677 28.2642 27.9903 28.3295 28.2516 28.5908L28.7089 29.0482C29.0355 29.3748 29.0355 29.8975 28.7089 30.2241C25.8347 32.772 21.9806 34.34 17.8 34.34C8.91613 34.34 1.66532 27.1535 1.6 18.2031C1.6 9.31808 8.8508 2.00096 17.8 2.00096C23.7444 1.93563 28.9702 5.2022 31.779 9.97139V7.14037C31.779 6.74838 32.171 6.35639 32.5629 6.35639H33.2161Z"
      fill={styleVariables.color_gray}
    />
    <path
      d="M13.6747 18.4005H14.1714C15.234 18.4005 15.7884 18.9665 15.7884 19.9135C15.7884 20.6758 15.2109 21.2533 14.4601 21.2533C13.513 21.2533 13.0741 20.7105 12.9702 20.0637H12C12.0808 21.2649 12.9355 22.1542 14.4601 22.1542C15.8692 22.1542 16.7817 21.1147 16.7817 19.8904C16.7817 18.8741 16.2504 18.2388 15.4765 17.927C16.2042 17.5805 16.6662 16.9337 16.6662 16.1136C16.6662 14.8893 15.7075 14 14.3793 14C13.1896 14 12.1039 14.7738 12.1039 16.0328H13.0741C13.0972 15.386 13.6285 14.8893 14.3793 14.8893C15.1647 14.8893 15.6729 15.4668 15.6729 16.0905C15.6729 16.9568 15.2224 17.4996 14.1714 17.4996H13.6747V18.4005Z"
      fill={styleVariables.color_gray}
    />
    <path
      d="M23.6008 16.2984C23.6008 14.8316 22.5151 14 21.3139 14C20.1127 14 19.027 14.8316 19.027 16.31V19.8442C19.027 21.3226 20.1127 22.1542 21.3139 22.1542C22.5151 22.1542 23.6008 21.3226 23.6008 19.8558V16.2984ZM22.6075 16.2984V19.8558C22.6075 20.7105 22.03 21.2533 21.3139 21.2533C20.5862 21.2533 20.0087 20.7105 20.0087 19.8558V16.2984C20.0087 15.4437 20.5862 14.9009 21.3139 14.9009C22.03 14.9009 22.6075 15.4437 22.6075 16.2984Z"
      fill={styleVariables.color_gray}
    />
  </svg>
);

export const PlayIcon: React.FC = (): React.ReactElement => <i className="playIcon white fa-2x fas fa-play"></i>;

export const PauseIcon: React.FC = (): React.ReactElement => <i className="playIcon white fa-2x fas fa-pause"></i>;
