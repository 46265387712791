export interface IPubSub {
  publish: (eventName: string, data: any) => void;
  subscribe: (eventName: string, callback: Function) => void;
}

const pubSub = (): IPubSub => {
  const subscribers: Map<string, any[]> = new Map<string, any[]>();

  function publish(eventName: string, data: any = null) {
    const subs = subscribers.get(eventName);

    if (!subs) {
      return;
    }

    subs.forEach((callback: Function) => {
      callback(data);
    });
  }

  function subscribe(eventName: string, callback: Function) {
    const subs = subscribers.get(eventName) || [];
    if (subs.indexOf(callback) < 0) {
      subs.push(callback);
    }
    subscribers.set(eventName, subs);
  }

  return {
    publish,
    subscribe,
  };
};

export default pubSub;
