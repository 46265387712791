import React, { ReactElement, useRef, useState } from "react";
import moment from "moment";
import { IArcadiaFileUpload, IArcadiaMaterial } from "@types";
import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { Carousel } from "react-responsive-carousel";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import DropdownMenu from "../DropdownMenu";
import { ScrollableContainer } from "../ScrollableContainer";
import { isRenderableImage } from "../../helpers/common";
const defaultFileImage = require("../../images/fileImage.svg") as string;
const hamburger = require("../../images/hamburger.svg") as string;

const ModalViewMaterial: React.FC<
  {
    className?: string;
    onCloseModal: () => void;
    onDeleteMaterial: (materialSku: string) => void;
    onEditMaterial: () => void;
    onAttachmentUpload: (data: FormData) => Promise<IArcadiaFileUpload>;
    material: IArcadiaMaterial;
    materials: IArcadiaMaterial[];
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  onCloseModal = () => {},
  onDeleteMaterial = () => {},
  onEditMaterial,
  onAttachmentUpload,
  material,
  materials,
}): ReactElement => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [items] = useState(material.imageUrls || []);
  const [attachments, setAttachments] = useState(material.fileUploads || []);
  const [showAttachment, setShowAttachment] = React.useState(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const renderDefaultImage = (fileName: string) => {
    return <img className="default-file-image" src={defaultFileImage} alt={fileName} />;
  };

  const next = () => {
    if (currentSlide + 1 < items.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prev = () => {
    if (currentSlide - 1 >= 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const deleteMaterial = () => {
    onDeleteMaterial(material.sku!);
  };

  const getCompatibleWith = (skus: string[]) => {
    const resultArr: string[] = [];
    skus.forEach((s) => {
      const wantedMaterial = materials.find((m) => m.sku === s);
      if (wantedMaterial) {
        resultArr.push(wantedMaterial.materialName!);
      }
    });
    return resultArr.join(", ");
  };

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach(async (file) => {
        const blob = new Blob([file], { type: file.type });
        const fd = new FormData();
        fd.append("UploadData", blob, file.name);
        fd.append("fileName", file.name);
        const result = await onAttachmentUpload(fd);
        if (result) {
          setAttachments([...attachments, result]);
        }
      });
    }
  };

  const downloadFile = (fileUri: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = fileUri;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getAttachmentElement = (attachment: IArcadiaFileUpload) => {
    return (
      <div className="modal-view-material-attachments-row">
        <Icon size={12} width={24} height={24} name="fal fa-file" color={styleVariables.color_gray} />
        <Typography className={"modal-view-material-attachments-row-name"} type={ElementType.bodySmall}>
          {attachment.fileName}
        </Typography>
        <DropdownMenu
          buttons={[
            {
              icon: "fa fa-arrow-down",
              name: "Download",
              onClick: () => downloadFile(attachment.fileUri!, attachment.fileName!),
              color: styleVariables.color_gray,
            },
            {
              icon: "fal fa-trash",
              name: "Delete",
              color: styleVariables.color_red,
              textColor: Color.Error,
              onClick: () => downloadFile(attachment.fileUri!, attachment.fileName!),
            },
          ]}
          buttonSize="xs"
          icon={"far fa-ellipsis-h"}
        />
      </div>
    );
  };

  return (
    <Modal
      className={`modal-view-material ${className}`}
      height={738}
      width={507}
      showAttachment={showAttachment}
      attachmentContent={
        <div className="modal-view-material-attachments">
          <div className="modal-view-material-attachments-header">
            <Icon size={12} width={24} height={24} name="far fa-paperclip" color={styleVariables.color_gray} />
            <Typography type={ElementType.eyebrow} color={Color.Secondary}>
              Attachments
            </Typography>
            <input
              className="modal-view-material-attachments-header-input"
              type="file"
              ref={fileInput}
              onChange={uploadFile}
            ></input>
            <Button
              size="small"
              themeType="secondary"
              iconOnly={true}
              onClick={() => {
                if (fileInput && fileInput.current) {
                  fileInput.current.click();
                }
              }}
              iconName="fa fa-plus"
            />
          </div>
          <ScrollableContainer height={580}>
            {attachments && attachments.length > 0 ? (
              attachments.map((a) => getAttachmentElement(a))
            ) : (
              <div className="modal-view-material-attachments-empty">
                <Typography type={ElementType.bodySmall} color={Color.Secondary}>
                  No attachments
                </Typography>
              </div>
            )}
          </ScrollableContainer>
        </div>
      }
      attachmentWidth={300}
      onCloseModal={onCloseModal}
    >
      <Carousel
        renderArrowPrev={() => (
          <Button
            className="modal-view-material-carousel-button modal-view-material-carousel-button-left"
            themeType="secondary"
            size="small"
            onClick={prev}
            iconOnly={true}
            iconName="fal fa-chevron-left"
          />
        )}
        renderArrowNext={() => (
          <Button
            className="modal-view-material-carousel-button modal-view-material-carousel-button-right"
            themeType="secondary"
            size="small"
            onClick={next}
            iconOnly={true}
            iconName="fal fa-chevron-right"
          />
        )}
        renderThumbs={(children: React.ReactChild[]) => children.map((c, index) => <div key={index}>{c}</div>)}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        showStatus={false}
        showIndicators={false}
        autoPlay={false}
        thumbWidth={50}
        useKeyboardArrows={true}
      >
        {items.map((screenshot) => {
          const isImage = isRenderableImage(screenshot!.split(".").pop()?.toLowerCase() ?? "");
          return (
            <div className="modal-view-material-carousel-image-container" key={screenshot}>
              {isImage ? <img src={screenshot} alt={screenshot} /> : renderDefaultImage(screenshot)}
            </div>
          );
        })}
      </Carousel>
      <div className="modal-view-material-info">
        {material.isCustomerMaterial && (
          <Typography type={ElementType.bodySmall} color={Color.Orange}>
            {material.isCustomized ? "Customized" : "Added by you"}
          </Typography>
        )}
        <Typography type={ElementType.h4} className="modal-view-material-info-name">
          {material.materialName}
        </Typography>
        <Typography type={ElementType.bodySmall} className="modal-view-material-info-text">
          {material.description}
        </Typography>
        <div className="modal-view-material-info-subtitle">
          <Typography
            type={ElementType.inline}
            fontSize={12}
            color={Color.Secondary}
            className="modal-view-material-info-subtitle"
          >
            Compatible with:{" "}
          </Typography>
          <Typography fontSize={12} type={ElementType.inline}>
            {getCompatibleWith(material.associatedSKUS || [])}
          </Typography>
        </div>
        <div className="modal-view-material-info-table">
          <div className="modal-view-material-info-column">
            <div className="modal-view-material-info-flex">
              <Typography
                type={ElementType.inline}
                fontSize={12}
                color={Color.Secondary}
                className="modal-view-material-info-half"
              >
                SKU
              </Typography>
              <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                {material.sku}
              </Typography>
            </div>
            <div className="modal-view-material-info-flex">
              <Typography
                type={ElementType.inline}
                fontSize={12}
                color={Color.Secondary}
                className="modal-view-material-info-half"
              >
                Unit of Measure
              </Typography>
              <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                {material.unitOfMeasure || "-"}
              </Typography>
            </div>
            {material.materialType === 1 && (
              <div className="modal-view-material-info-flex">
                <Typography
                  type={ElementType.inline}
                  fontSize={12}
                  color={Color.Secondary}
                  className="modal-view-material-info-half"
                >
                  Material Type
                </Typography>
                <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                  {material.materialProperties?.find((p) => p.name === "category")?.value}
                </Typography>
              </div>
            )}
          </div>
          <div className="modal-view-material-info-column">
            <div className="modal-view-material-info-flex">
              <Typography
                type={ElementType.inline}
                fontSize={12}
                color={Color.Secondary}
                className="modal-view-material-info-half"
              >
                Available
              </Typography>
              <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                {material.quantityOnHand}
              </Typography>
            </div>
            <div className="modal-view-material-info-flex">
              <Typography
                type={ElementType.inline}
                fontSize={12}
                color={Color.Secondary}
                className="modal-view-material-info-half"
              >
                Last Counted
              </Typography>
              <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                {moment(material.lastDateCounted).format("YYYY-MM-DD")}
              </Typography>
            </div>
            {material.materialType === 3 && (
              <div className="modal-view-material-info-flex">
                <Typography
                  type={ElementType.inline}
                  fontSize={12}
                  color={Color.Secondary}
                  className="modal-view-material-info-half"
                >
                  S/N
                </Typography>
                <Typography type={ElementType.inline} fontSize={12} className="modal-view-material-info-half">
                  {material.serialNumber || "-"}
                </Typography>
              </div>
            )}
          </div>
        </div>

        <div className="modal-view-material-info-buttons">
          {material && material.isCustomerMaterial && (
            <div
              className="modal-view-material-info-buttons-attachments"
              role="button"
              tabIndex={0}
              onClick={() => setShowAttachment((old) => !old)}
              onKeyDown={() => {}}
            >
              <img className={`${showAttachment ? "rotated" : ""}`} alt="Attachments" src={hamburger} />
              <Typography color={Color.Secondary}>Attachments</Typography>
            </div>
          )}

          <div className="modal-view-material-info-buttons-actions">
            {material.isCustomerMaterial && (
              <Button themeType="outline" size="small" onClick={deleteMaterial}>
                Remove
              </Button>
            )}
            <Button themeType="secondary" size="small" onClick={onEditMaterial}>
              {material.isCustomerMaterial && !material.isCustomized ? "Edit" : "Customize"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ModalViewMaterial.displayName = "ModalViewMaterial";

export { ModalViewMaterial };
