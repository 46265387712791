import * as React from "react";
import { default as cx } from "classnames";
import { Typography, ElementType } from "../Typography";
import { Icon } from "../../Icon";

const Input: React.FC<
  {
    className?: string;
    containerClassName?: string;
    label?: string;
    placeholder?: string;
    width?: number | string;
    height?: number;
    disabled?: boolean;
    min?: number;
    type?: string;
    value: string | number | string[] | null;
    onChange?: Function;
    onIncrease?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onDecrease?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onBlur?: Function;
    rightIconName?: string;
    leftIconName?: string;
    rightText?: string;
    iconSize?: number;
    autoFocus?: boolean;
    fontSize?: number;
    withButtons?: boolean;
  } & React.HTMLAttributes<HTMLInputElement>
> = ({
  className,
  containerClassName = "",
  label = "",
  placeholder = "",
  width = 325,
  height = 48,
  fontSize = 14,
  disabled = false,
  min,
  type = "text",
  value = "",
  onChange = () => {},
  onIncrease = () => {},
  onDecrease = () => {},
  iconSize = 14,
  rightIconName,
  rightText,
  leftIconName,
  autoFocus,
  onBlur,
  withButtons,
  ...others
}): React.ReactElement => {
  const classes = cx(
    {
      "custom-input": true,
      "custom-input-left-icon": leftIconName,
      "custom-input-right-icon": rightIconName,
      "custom-input-number-with-buttons": withButtons,
    },
    className
  );
  return (
    <>
      {label && <Typography type={ElementType.label}>{label}</Typography>}
      <div className={`custom-input-wrapper ${containerClassName}`}>
        {leftIconName && <Icon className={"input-icon-left"} name={leftIconName} size={iconSize} />}
        {type === "number" && withButtons && onDecrease && (
          <Icon className={"input-icon-left"} onClick={onDecrease} name="far fa-minus" size={iconSize} />
        )}
        <input
          className={classes}
          disabled={disabled}
          style={{ width: width, height: height, fontSize: fontSize }}
          type={type}
          placeholder={placeholder}
          value={value !== null ? value : ""}
          onChange={onChange}
          autoFocus={autoFocus}
          onBlur={onBlur}
          min={min}
          {...others}
        />
        {rightText && <div className="input-text-right">{rightText}</div>}
        {type === "number" && withButtons && onIncrease && (
          <Icon className={"input-icon-right"} onClick={onIncrease} name="far fa-plus" size={iconSize} />
        )}
        {rightIconName && <Icon className={"input-icon-right"} name={rightIconName} size={iconSize} />}
      </div>
    </>
  );
};

export { Input };
