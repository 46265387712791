import { IProcessParameter } from "@types";
import { useState } from "react";
import data from "./processConfig";
//import ProcessesService from "../../services/ProcessesService";

const useProcesses = () => {
  const [isProcessesLoading, setIsLoading] = useState(false);
  const [isProcessesError, setIsError] = useState(false);

  const [processes, setProcesses] = useState<IProcessParameter[]>([]);

  const fetchProcesses = () => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      //waiting for backend - will hardcode some values for now
      // ProcessesService.getAllProcesses()
      //   .then((response: any) => {
      //     let result = response.data as IProcessParameter[];
      //     result = result.filter((r) => r !== null);
      //     setProcesses(result);
      //     setIsLoading(false);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     setIsError(true);
      //     setIsLoading(false);
      //   });

      setProcesses(data);
      setIsLoading(false);
    };
    fetchData();
  };

  return { processes, isProcessesLoading, isProcessesError, fetchProcesses };
};

export default useProcesses;
