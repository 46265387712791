import axios from "axios";

const downloadAll = (projectId: string, programName: string) => {
  return axios.get(`projects/builder/download/${projectId}?programName=${programName}`, { responseType: "blob" });
};

const downloadSpecific = (
  arcadiaProjectId: string,
  programName: string,
  ncCode: string,
  parameters: string,
  jobs: string
) => {
  return axios.get(
    `projects/builder/downloadspecific/${arcadiaProjectId}/${programName}?ncCode=${ncCode}&parameters=${parameters}&jobs=${jobs}`,
    { responseType: "blob" }
  );
};

const downloadJobs = (projectId: string, programName: string) => {
  return axios.get(`projects/builder/download/${projectId}/Jobs/${programName}`, { responseType: "blob" });
};

const downloadParameters = (projectId: string, programName: string) => {
  return axios.get(`projects/builder/download/${projectId}/Parameters/${programName}`, { responseType: "blob" });
};

const downloadNcCode = (projectId: string, programName: string) => {
  return axios.get(`projects/builder/download/${projectId}/NcPrograms/${programName}`, { responseType: "blob" });
};

const createProgram = (projectId: string, name: string) => {
  return axios.put(`projects/builder/program?projectId=${projectId}&programName=${name}`);
};

const uploadNcCode = (projectId: string, newPart: FormData) => {
  return axios.post(`projects/builder/upload/ncprograms/${projectId}`, newPart);
};

const uploadJobs = (projectId: string, newPart: FormData) => {
  return axios.post(`projects/builder/upload/jobs/${projectId}`, newPart);
};

const uploadParameters = (projectId: string, newPart: FormData) => {
  return axios.post(`projects/builder/upload/parameters/${projectId}`, newPart);
};

const deleteProgram = (projectId: string, programName: string) => {
  return axios.delete(`projects/builder/program?projectId=${projectId}&programName=${programName}`);
};

const copyToNew = (projectId: string, originalProgram: string, newProgram: string) => {
  return axios.post(
    `projects/builder/copytonew?projectId=${projectId}&originalProgram=${originalProgram}&newProgram=${newProgram}`
  );
};

export default {
  downloadAll,
  downloadSpecific,
  downloadNcCode,
  downloadJobs,
  downloadParameters,
  createProgram,
  uploadNcCode,
  uploadJobs,
  uploadParameters,
  deleteProgram,
  copyToNew,
};
