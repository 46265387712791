/* eslint-disable tsc/config */
import React, { FC, ReactElement, HTMLAttributes, FormEvent } from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import { Checkbox } from "../Common/Checkbox";
import moment from "moment";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { Input } from "../Common/Input";
import { DatePicker } from "../DatePicker";
import { IArcadiaSubTask, IArcadiaTask2 } from "@types";
import StatusSelection from "../StatusSelection";

const ActionGroup: FC<
  {
    deleteTask: (taskId: string, isParent: boolean) => Promise<boolean>;
    group: IArcadiaTask2;
    groups: IArcadiaTask2[];
    groupKey: number;
    item: IArcadiaSubTask;
    itemKey: number;
    inputItem: IArcadiaSubTask | IArcadiaTask2 | null;
    isSortable: boolean;
    setGroups: React.Dispatch<React.SetStateAction<IArcadiaTask2[]>>;
    setInputItem: React.Dispatch<React.SetStateAction<IArcadiaTask2 | IArcadiaSubTask | null>>;
    updateSubTask: (subTask: IArcadiaSubTask) => Promise<void>;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  deleteTask,
  group,
  groups,
  groupKey,
  item,
  itemKey,
  inputItem,
  isSortable,
  setGroups,
  setInputItem,
  updateSubTask,
}): ReactElement => {
  const handleCheckbox = () => {
    const newGroups = [...groups];
    // @ts-ignore
    const subTask = newGroups[groupKey as keyof IArcadiaTask2]["subTasks"][itemKey];
    // @ts-ignore
    subTask.subTaskCheckbox = !subTask.subTaskCheckbox;
    updateSubTask(subTask);
    setGroups(newGroups);
  };

  const handleStatusChange = (status: number) => {
    const newGroups = [...groups];
    // @ts-ignore
    const subTask = newGroups[groupKey as keyof IArcadiaTask2]["subTasks"][itemKey];
    // @ts-ignore
    subTask.subtaskStatus = status;
    updateSubTask(subTask);
    setGroups(newGroups);
  };

  const toggleItemInput = () => {
    setInputItem(item);
    setTimeout(() => {
      document.getElementById(`input-${item.subtaskId}`)?.focus();
    }, 0);
  };

  const updateInput = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    const newGroups = [...groups];
    // @ts-ignore
    const subTask = newGroups[groupKey as keyof IArcadiaTask2]["subTasks"][itemKey];
    subTask.subTaskName = element.value;
    setGroups(newGroups);
  };

  const updateDate = (newDate: Date) => {
    const newGroups = [...groups];
    // @ts-ignore
    const subTask = newGroups[groupKey as keyof IArcadiaTask2]["subTasks"][itemKey];
    subTask.dueDate = newDate;
    updateSubTask(subTask);
    setGroups(newGroups);
  };

  const removeRow = async () => {
    const newGroups = [...groups];
    // @ts-ignore
    const toRemove = newGroups[groupKey as keyof IArcadiaTask2]["subTasks"][itemKey] as IArcadiaSubTask;
    const result = await deleteTask(toRemove.subtaskId!, false);
    if (result) {
      // @ts-ignore
      newGroups[groupKey as keyof IArcadiaTask2]["subTasks"].splice(itemKey, 1);
      setGroups(newGroups);
    }
  };
  return (
    <React.Fragment key={`sortable-child-${group.taskId}-${item.subtaskId}`}>
      <div className="action-items-group-row-first">
        <Checkbox className="pointer" checked={item.subTaskCheckbox} onChange={handleCheckbox} />
        {inputItem === item ? (
          <Input
            containerClassName="action-items-group-row-item"
            height={36}
            id={`input-${item.subtaskId}`}
            value={item.subTaskName || ""}
            onChange={updateInput}
            onKeyUp={(event) => {
              if (event.keyCode === 13) {
                setInputItem(null);
              }
            }}
          />
        ) : (
          <Typography
            type={ElementType.inline}
            className={`action-items-group-row-item ${item.subTaskCheckbox ? "line-through" : ""}`}
            onClick={toggleItemInput}
          >
            {item.subTaskName === "" ? (
              <div className="action-items-group-row-item-placeholder">Write task name</div>
            ) : (
              item.subTaskName
            )}
          </Typography>
        )}
      </div>
      <StatusSelection onChangeStatus={handleStatusChange} status={item.subtaskStatus} />
      <div className="action-items-group-row-date-container">
        <div className="action-items-group-row-date">
          <DatePicker
            value={moment(
              item.dueDate && item.dueDate !== null && item.dueDate !== "" ? item.dueDate : new Date()
            ).toDate()}
            handleChange={updateDate}
            popperPlacement="left"
            customInput={
              <Typography type={ElementType.inline} color={Color.Secondary}>
                {moment(
                  item.dueDate && item.dueDate !== null && item.dueDate !== "" ? item.dueDate : new Date()
                ).format("MMM DD")}
              </Typography>
            }
          />
        </div>
      </div>
      {isSortable ? (
        <Icon
          className="cursor-move action-items-group-row-show-on-hover"
          name="far fa-arrows"
          color={styleVariables.color_gray}
          size={20}
        />
      ) : (
        <Icon
          className="pointer action-items-group-row-show-on-hover"
          name="far fa-trash"
          color={styleVariables.color_gray}
          size={18}
          onClick={removeRow}
        />
      )}
    </React.Fragment>
  );
};

export default ActionGroup;
export { ActionGroup };
