import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { INewProjectDataFeaturesData, INewProjectDataPart, IProcessParameter } from "@types";
import { ScrollableContainer } from "../ScrollableContainer";
import { OptionType } from "../Common/Select";
import { FeatureData } from "../ModalNewProject/FeatureData";

const FeatureModal: FC<
  {
    onCloseModal?: () => void;
    onSave: (updatedFeature: INewProjectDataFeaturesData) => void;
    onDelete: () => void;
    initialFeature: INewProjectDataFeaturesData;
    part: INewProjectDataPart;
    processes: IProcessParameter[];
  } & HTMLAttributes<HTMLDivElement>
> = ({ onCloseModal, onSave, onDelete, initialFeature, part, processes }): ReactElement => {
  const [feature, setFeature] = useState<INewProjectDataFeaturesData>(initialFeature);

  const changeProcess = (process: string) => {
    const newFeaturePart = Object.assign({}, feature);

    if (newFeaturePart.process === process) {
      newFeaturePart.process = undefined;
    } else {
      newFeaturePart.process = process;
    }

    setFeature(newFeaturePart);
  };

  const changeFeatureMeasure = (measureSelection: OptionType) => {
    const newFeaturePart = Object.assign({}, feature);
    newFeaturePart.measure = measureSelection.value;
    setFeature(newFeaturePart);
  };

  const changeFeatureParams = (e: FormEvent<HTMLInputElement>, parameterName: string) => {
    const target = e.target as HTMLInputElement;
    const newFeaturePart = Object.assign({}, feature);
    let value = parseFloat(target.value);
    const parameter = processes.find((p) => p.processName === feature.process && p.parameter === parameterName);
    if (parameter?.measurementType === "Length") {
      const measure = feature.measure;
      if (measure === "in/deg/sec") {
        value = value * 25.4;
      }
    }
    // @ts-ignore
    if (!newFeaturePart.parameters) {
      newFeaturePart.parameters = [];
      newFeaturePart.parameters?.push({ parameterName: parameterName, parameterValue: value });
    } else {
      const param = newFeaturePart.parameters?.find((p) => p.parameterName === parameterName);
      if (param) {
        param.parameterValue = value;
      } else {
        newFeaturePart.parameters?.push({ parameterName: parameterName, parameterValue: value });
      }
    }

    setFeature(newFeaturePart);
  };

  const changeFeatureName = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const newFeaturePart = Object.assign({}, feature);
    newFeaturePart.featureName = target.value;
    setFeature(newFeaturePart);
  };

  const changeFeatureAdvanced = (e: FormEvent<HTMLInputElement>) => {
    const newFeaturePart = Object.assign({}, feature);
    newFeaturePart.advancedOptions = !newFeaturePart.advancedOptions;
    setFeature(newFeaturePart);
  };

  const onClickDone = (e: FormEvent<HTMLButtonElement>) => {
    onSave(feature);
  };

  const featureSizing: OptionType[] = [
    { value: "mm/deg/sec", label: "mm/deg/sec" },
    { value: "in/deg/sec", label: "in/deg/sec" },
  ];

  return (
    <Modal className={"feature-modal"} height="auto" width={682} onCloseModal={onCloseModal}>
      <ScrollableContainer width={650} height={810}>
        <FeatureData
          deletePart={onDelete}
          part={part!}
          currentPart={part!}
          featurePart={feature}
          changeProcess={changeProcess}
          changeFeatureName={changeFeatureName}
          changeFeatureMeasure={changeFeatureMeasure}
          changeFeatureAdvanced={changeFeatureAdvanced}
          changeFeatureParams={changeFeatureParams}
          onClickAddAnotherFeature={() => {}}
          showAdvancedNotice={false}
          showMultipleNotice={false}
          partOptions={[]}
          featureSizing={featureSizing}
          processes={processes}
          features={[feature]}
          featureIndex={0}
          index={0}
          onClickDone={onClickDone}
          withMargin={false}
          withControls={false}
        />
        <Button className="feature-modal-save-button" themeType="clear" onClick={() => onSave(feature)}>
          Save
        </Button>
      </ScrollableContainer>
    </Modal>
  );
};

export { FeatureModal };
