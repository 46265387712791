import React, { useEffect, useState } from "react";
import videojs from "video.js";
import * as videojsQuality from "videojs-contrib-quality-levels";

// Styles
import "video.js/dist/video-js.css";
require("videojs-contrib-quality-levels");

const VideoPlayer = (props: {
  options: any;
  onReady: any;
  width: string;
  height: string;
  videoQuality: number;
  videoRef: any;
}) => {
  const { options, onReady, width, height, videoQuality, videoRef } = props;
  const playerRef = React.useRef<any>(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) {
        return;
      }
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player, videoElement);
      }));

      const qualityLevels = player.qualityLevels();

      qualityLevels.on("addqualitylevel", (event: videojs.EventTarget.Event) => {
        const qualityLevel = event.qualityLevel;
        setTimeout(() => {
          if (qualityLevel.height == 1080) {
            qualityLevel.enabled = true;
          } else {
            qualityLevel.enabled = false;
          }
        }, 1000);
      });
    }
  }, [options, videoRef]);

  useEffect(() => {
    if (playerRef.current && playerRef.current.qualityLevels().length > 0) {
      const qualityLevels = playerRef.current.qualityLevels();

      for (let i = 0; i < qualityLevels.length; i++) {
        const qualityLevel = qualityLevels[i];
        if (qualityLevel.height == videoQuality || videoQuality == 0) {
          qualityLevel.enabled = true;
        } else {
          qualityLevel.enabled = false;
        }
      }
    }
  }, [videoQuality]);

  return (
    <div data-vjs-player id={`id-${options.sources[0].src}`}>
      <video muted playsInline width={width} height={height} ref={videoRef} className="video-js" />
    </div>
  );
};

export { VideoPlayer };
