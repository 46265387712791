import * as React from "react";
import { default as cx } from "classnames";
import uniqid from "uniqid";

const Toggle: React.FC<
  {
    className?: string;
    identifier?: string;
    label?: string;
    value?: string;
    onChange?: Function;
    checked?: boolean;
    disabled?: boolean;
  } & React.HTMLAttributes<HTMLInputElement>
> = ({ className, identifier, label, value, onChange, checked = false, disabled = false }): React.ReactElement => {
  const classes = cx(
    {
      toggle: true,
    },
    className
  );

  const uniqueIdentifier = identifier || uniqid();

  return (
    <div className={classes}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={uniqueIdentifier}
        value={value}
      />
      <label htmlFor={uniqueIdentifier}>{label || "Toggle"}</label>
    </div>
  );
};

export { Toggle };
export default Toggle;
