import * as React from "react";
import { Checkbox } from "../Common/Checkbox";
import { Thumbnail } from "../Thumbnail";
import { Typography, ElementType, Color } from "../Common/Typography";
import uniqid from "uniqid";

const SelectionItem: React.FC<{
  checked: boolean;
  imageUrl: string;
  title: string;
  description: string;
  top?: boolean;
  hideBorder?: boolean;
  radio?: boolean;
  userOwned?: boolean;
  handleClick: (event: React.FormEvent<HTMLDivElement>) => void;
}> = ({
  checked,
  imageUrl,
  title,
  description,
  handleClick = () => {},
  top = false,
  hideBorder = false,
  radio = false,
  userOwned,
}): React.ReactElement => {
  const generatedId = uniqid();
  return (
    <label
      htmlFor={generatedId}
      className={`selection-item ${hideBorder ? "selection-item-noborder" : ""} ${
        checked && radio ? "selection-item-checked" : ""
      }`}
    >
      <div className="selection-item-image">
        <Checkbox radio={radio} checked={checked} onChange={handleClick} id={generatedId} />
        <Thumbnail
          backgroundImageUrl={imageUrl}
          title={title}
          filter={"drop-shadow(-2px -3px 6px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #1A1C1F)"}
          height={96}
          width={96}
        />
      </div>
      <div className={`selection-item-data ${top ? "selection-item-top" : ""}`}>
        {userOwned && <div className="badge small">Added by you</div>}
        <Typography type={ElementType.h5}>{title}</Typography>
        <Typography type={ElementType.body} color={Color.OffWhite}>
          {description}
        </Typography>
      </div>
    </label>
  );
};

export { SelectionItem };
