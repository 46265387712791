import React, { useState } from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";
import { IExecutionPackage } from "@types";
import { Button } from "../Button";
import { ModalAddProgram } from "../ModalAddProgram";

const Programs: React.FC<
  {
    className?: string;
    height?: number | string;
    selectedProgram?: IExecutionPackage;
    programs: IExecutionPackage[];
    onItemClick?: (program?: IExecutionPackage) => void;
    addProgram: (name: string) => void;
    width?: number | string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  height = 745,
  selectedProgram,
  width = 344,
  programs = [],
  onItemClick = () => {},
  addProgram,
}): React.ReactElement => {
  const [showModalAddProgram, setShowModalAddProgram] = useState(false);

  const handleAddProgram = (name: string) => {
    addProgram(name);
  };

  return (
    <aside className={`programs ${className}`} style={{ height, width }}>
      <div className="programs-header">
        <Typography
          type={ElementType.eyebrow}
          color={Color.Secondary}
          className="programs-header-title"
          data-testid="programs-header-title"
        >
          Programs
        </Typography>
        <Button
          onClick={() => setShowModalAddProgram(true)}
          themeType="secondary"
          size={"small"}
          iconOnly={true}
          iconName={"fal fa-plus"}
        />
      </div>
      <ul className="programs-list">
        {programs && programs.length > 0 ? (
          programs.map((p) => {
            const clickHandler = () => {
              onItemClick(p);
            };

            return (
              <li key={p.packageName}>
                <div
                  role="button"
                  className={`programs-list-item ${
                    selectedProgram?.packageName === p.packageName ? "programs-list-item-active" : ""
                  }`}
                  tabIndex={0}
                  onKeyUp={clickHandler}
                  onClick={clickHandler}
                >
                  <Typography type={ElementType.h6}>{p.packageName}</Typography>
                  <Icon name="fal fa-long-arrow-right" size={20} color={styleVariables.color_gray} />
                </div>
              </li>
            );
          })
        ) : (
          <Typography color={Color.Secondary}>There are no programs available</Typography>
        )}
      </ul>
      {showModalAddProgram && (
        <ModalAddProgram
          title="Create Program"
          onDone={handleAddProgram}
          onCloseModal={() => setShowModalAddProgram(false)}
        />
      )}
    </aside>
  );
};

export { Programs };
