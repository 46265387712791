import * as React from "react";
import { IArcadiaBalance } from "../../../../types/api";
import { Button } from "../Button";
import { Card } from "../Card";
import { Color, ElementType, Typography } from "../Common/Typography";

const CardBalances: React.FC<
  {
    width?: number;
    balances: IArcadiaBalance[];
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ balances, width = 267 }): React.ReactElement => {
  return (
    <div className="card-balances-account">
      <Card height={134} hover={false} width={width}>
        <div className="vertical-flex">
          <Typography type={ElementType.h5} color={Color.Secondary}>
            Account Plan
          </Typography>
          <Button
            className="card-balances-account-btn ml-auto nav-button"
            themeType={"outline"}
            size={"small"}
            iconOnly={false}
            navButton={false}
            iconName={"fas fa-bell"}
          >
            Add
          </Button>
        </div>
        <div className="card-balances-account-bars">
          {balances &&
            balances.length > 0 &&
            balances.map((b) => (
              <div key={b.lastTransactionId} className="card-balances-account-bars-labels">
                <Typography>{`${b.transactionType} Remaining`} </Typography>
                <Typography className="card-balances-account-bars-labels-used">{b.balance}</Typography>
              </div>
            ))}
        </div>
        {/* <div className="card-balances-account-bars">
        <div className="card-balances-account-bars-labels">
          <div className="white">Service Hours Remaining</div>
          <div className="gray">14/60</div>
        </div>
        <Progress completed={(14 / 60) * 100} height={9} theme="blue" />
      </div> */}
      </Card>
    </div>
  );
};

export { CardBalances };
