import * as React from "react";
import { default as cx } from "classnames";

const Tooltip: React.FC<
  {
    className?: string;
    type?: "light" | "dark";
  } & React.HTMLAttributes<HTMLTextAreaElement>
> = ({ className, type = "dark", children }): React.ReactElement => {
  const classes = cx(
    {
      "custom-tooltip": true,
      [`custom-tooltip-${type}`]: true,
    },
    className
  );
  return <div className={classes}>{children}</div>;
};

export { Tooltip };
