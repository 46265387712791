import axios from "axios";

const getAll = (repositoryNamespaceId: string) => {
  return axios.get(`/executionpackage/${repositoryNamespaceId}`);
};

const getByProject = (repositoryNamespaceId: string, projectName: string) => {
  return axios.get(`/executionpackage/${repositoryNamespaceId}/${projectName}`);
};

const getAvailablePackagePartsByProject = (projectId: string) => {
  return axios.get(`/projects/builder/${projectId}/availablepackageparts`);
};

const getJobsByProject = (repositoryNamespaceId: string, projectName: string) => {
  return axios.get(`/executionpackage/${repositoryNamespaceId}/${projectName}/jobs`);
};

const getProgramsByProject = (repositoryNamespaceId: string, projectName: string) => {
  return axios.get(`/executionpackage/${repositoryNamespaceId}/${projectName}/ncprograms`);
};

const getParametersByProject = (repositoryNamespaceId: string, projectName: string) => {
  return axios.get(`/executionpackage/${repositoryNamespaceId}/${encodeURIComponent(projectName)}/parameters`);
};

export default {
  getAll,
  getByProject,
  getJobsByProject,
  getProgramsByProject,
  getParametersByProject,
  getAvailablePackagePartsByProject,
};
