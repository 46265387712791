// @ts-nocheck
import React, { useState, useEffect } from "react";
import moment from "moment";

import {
  Container,
  MachineSessionHeader,
  CardProfile,
  CardMachineStatus,
  CameraStreams,
  LiveSessionTable,
  ScreenshotsOverlay,
  Card,
  ModalCleaningAndMeasurement,
  Playback,
  Typography,
  ShowMore,
  ModalAddSnapshot,
  FilePaths,
  Skeleton,
  Icon,
  Progress,
} from "@namespace/components";
import { allSettled } from "../../utils";
import useNotification from "../../hooks/useNotification";
import { ContainerType } from "@namespace/components/src/components/Common/Container";
import { MachineStatus } from "@namespace/components/src/components/CardMachineStatus";
import { IMachineRun, IArcadiaFileUpload } from "@types";
import {
  calculateLivePlayheadPosition,
  calculateNewPlayHeadPosition,
  formatDate,
} from "@namespace/components/src/components/CameraStreams/helpers";

import MachineRunsService from "../../services/MachineRunsService";
import "./sessionReplayPage.scss";
import useProject from "../../hooks/API/useProject";
import useMachineRuns from "../../hooks/API/useMachineRuns";
import useMaterials from "../../hooks/API/useMaterials";
import useExecutionPackages from "../../hooks/API/useExecutionPackages";
import useStatus from "../../hooks/API/useStatus";
import { Color, ElementType } from "@namespace/components/src/components/Common/Typography";
import { mapArcadiaSessionStatusToStatus } from "../../mappers/mappers";
import { ArcadiaSessionStatus } from "../../enums";
import useSessionVideoUploads from "../../hooks/API/useSessionVideoUploads";
import { dataURItoBlob } from "../../helpers/imageHelper";
import UploadsService from "../../services/UploadsService";
import useUploadsHubConnection from "../../hooks/API/useUploadsHubConnection";
import useSessionHubConnection from "../../hooks/API/useSessionHubConnection";
import { useParams } from "react-router-dom";

const SessionReplayPage: React.FC<React.HTMLAttributes<HTMLDivElement>> = (): React.ReactElement => {
  const { projectId, sessionId } = useParams<{ projectId: string; sessionId: string }>();
  const [screenshots, setScreenshots] = useState<IArcadiaFileUpload[]>([]);
  const [screenshotIndex, setScreenshotIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModalAddSnapshot, setShowModalAddSnapshot] = useState(false);
  const [runForEditing] = useState<IMachineRun | null>(null);
  const [showModalCleaning, setShowModalCleaning] = useState(false);

  const { status } = useStatus();
  const { project, fetchProjectById } = useProject();
  const { runs, fetchMachineRuns } = useMachineRuns();
  const { materials, fixtures, fetchMaterials } = useMaterials();
  const { executionPackages, fetchExecutionPackages } = useExecutionPackages();
  const { videoUploads, isVideoUploadsLoading, fetchVideoUploads, setVideoUploads } = useSessionVideoUploads();
  const { sessionHubConnection } = useSessionHubConnection();
  const notification = useNotification();
  const { uploadsHubConnection } = useUploadsHubConnection();
  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [videoFrameOne, setVideoFrameOne] = useState(null);
  const [videoFrameTwo, setVideoFrameTwo] = useState(null);
  const [player1Loading, setPlayer1Loading] = useState(true);
  const [player2Loading, setPlayer2Loading] = useState(true);

  const [session, setSession] = useState<IArcadiaSession | null>(null);
  const [currentTime, setCurrentTime] = useState("");
  const [isSliding, setIsSliding] = useState(false);
  const [volume, setVolume] = useState(0);
  const [videoQuality, setVideoQuality] = useState(1080);
  const [isLive, setIsLive] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [rewind, setRewind] = useState<boolean>(false);
  const [progress, setProgress] = useState("0");

  const [firstImage, setFirstImage] = useState("");
  const [secondImage, setSecondImage] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [completed, setCompleted] = useState(0);

  //new
  useEffect(() => {
    if (projectId) {
      fetchProjectById(projectId);
    }
    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (project) {
      const projectId = project.arcadiaProjectId!;
      fetchExecutionPackages(projectId);
      const wantedSession = project.sessions?.find((s) => s.sessionId === sessionId);
      if (wantedSession) {
        setSession(wantedSession);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (session) {
      fetchMachineRuns(session.sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (project && session) {
      fetchVideoUploads(project.arcadiaProjectId, session.sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, session]);

  const toggleIsLive = () => {
    setIsLive(!isLive);
  };

  useEffect(() => {
    if (uploadsHubConnection) {
      uploadsHubConnection.on("fileuploaded", (file: IArcadiaFileUpload) => {
        fetchProjectById(projectId);
      });

      uploadsHubConnection.on("videouploadprogress", (file: IArcadiaFileUpload) => {
        setCompleted(file.uploadProgress);
      });

      uploadsHubConnection.on("videouploaded", (file: IArcadiaFileUpload) => {
        setVideoUploads(file);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadsHubConnection]);

  const togglePlay = () => {
    if (!isPlaying) {
      playerOne.play();
      playerTwo.play();
      setIsPlaying(true);
    } else {
      playerOne.pause();
      playerTwo.pause();
      setIsPlaying(false);
    }
  };

  const synchronize = (id: number, players: any[]) => {
    let seekCount = players.length;
    const onSeeked = () => {
      seekCount -= 1;
      // if all players are seeked
      if (seekCount <= 0) {
        for (let i = 0; i < players.length; i++) {
          players[i].playbackRate = 1; // restore video speed
          players[i].off("seeked", onSeeked); // remove listener
        }
      }
    };

    // adjust PDT for all players
    for (let i = 0; i < players.length; i++) {
      players[i].playbackRate = 0; // halt video speed
      players[i].on("seeked", onSeeked); // await seek event
    }

    videoFrameTwo.currentProgramDateTime = videoFrameOne.currentProgramDateTime; // set PDT
  };

  const onSliderInput = (value: string) => {
    if (playerOne) {
      setIsSliding(true);
      const playheadPosition = calculateNewPlayHeadPosition(value, videoFrameOne);
      if (playheadPosition) {
        setCurrentTime(
          formatDate(
            calculateLivePlayheadPosition(
              playheadPosition,
              videoFrameOne.seekable.end(videoFrameOne.seekable.length > 0 ? videoFrameOne.seekable.length - 1 : 0)
            )
          )
        );

        videoFrameOne.currentTime = playheadPosition;
        videoFrameTwo.currentTime = playheadPosition;
        synchronize(0, [playerOne, playerTwo]);
      }
      setProgress(value);
    }
  };

  const onSliderChange = () => {
    setIsSliding(false);
  };

  const onTextAreaChange = (runId: string, value: string) => {
    const run = runs.find((i: any) => i.runId === runId);
    const updatedRun: IMachineRun = { ...run, userNotes: value };
    MachineRunsService.updateRun(updatedRun)
      .then(() => {
        fetchMachineRuns(session.sessionId);
      })
      .catch((e) => console.log(e));
  };

  const handleClickScreenshot = (screenshots: IArcadiaFileUpload[], index: any) => {
    setScreenshots(screenshots);
    setScreenshotIndex(index);
    setShowOverlay(true);
  };

  const handleCloseClick = () => {
    setShowOverlay(false);
  };

  const handleDoneClick = async (uploads: IArcadiaFileUpload[]) => {
    const promises: Promise<any>[] = [];
    uploads.forEach((upload) => {
      promises.push(UploadsService.updateUpload(upload));
    });
    const results = await allSettled(promises);
    if (results.filter((r) => r.status === "rejected")) {
      console.log("An error occurred");
    }
    fetchProjectById(projectId);
    setShowOverlay(false);
  };

  const onGoBackClick = () => {
    let newTime = 0;
    if (playerOne && videoFrameOne.currentTime > 30) {
      newTime = videoFrameOne.currentTime - 30;
    }
    if (playerOne && playerTwo) {
      videoFrameOne.currentTime = newTime;
      videoFrameTwo.currentTime = newTime;
      setPlayerOne(playerOne);
      setPlayerTwo(playerTwo);
      synchronize(0, [playerOne, playerTwo]);
    }
  };

  const onGoForwardClick = () => {
    let newTime = 0;
    newTime = videoFrameOne.currentTime + 30;
    if (newTime > videoFrameOne.duration) {
      newTime = videoFrameOne.duration;
    }
    if (playerOne && playerTwo) {
      videoFrameOne.currentTime = newTime;
      videoFrameTwo.currentTime = newTime;
      setPlayerOne(playerOne);
      setPlayerTwo(playerTwo);
      synchronize(0, [playerOne, playerTwo]);
    }
  };

  const goToSnapshotTime = (timestamp: string) => {
    const startOfTheVideo = videoUploads?.startTimestamp;
    const diff = moment
      .duration(moment(timestamp).diff(moment(startOfTheVideo)))
      .add(1, "second")
      .asSeconds();
    videoFrameOne.currentTime = diff;
    videoFrameTwo.currentTime = diff;
  };

  const createBookmark = () => {
    setShowModalAddSnapshot(true);
    const timestamp = moment(videoUploads?.startTimestamp).add(videoFrameOne.currentTime, "seconds").toISOString();
    const firstImage = takeSnapshotImage(videoFrameOne);
    const secondImage = takeSnapshotImage(videoFrameTwo);
    setFirstImage(firstImage);
    setSecondImage(secondImage);
    setTimestamp(timestamp);
  };

  const takeSnapshotImage = (videoFrame: any) => {
    if (videoFrame && videoFrame.videoWidth > 0 && videoFrame.videoHeight > 0) {
      const canvas = document.createElement("canvas")!;
      canvas.width = videoFrame.videoWidth;
      canvas.height = videoFrame.videoHeight;
      canvas.getContext("2d").drawImage(videoFrame, 0, 0);
      const imageURL = canvas.toDataURL();
      return imageURL;
    }
    return "";
  };

  const handleDelete = (fileUploadId: string) => {
    UploadsService.deleteUpload(projectId, fileUploadId).catch((err) => {
      console.log(err);
    });
  };

  const takeSnapshot = (imageData: string, timestamp: string) => {
    const blob = dataURItoBlob(imageData);
    let runId = "";
    let activeRun = runs.find((r) => r.startTime <= timestamp && (!r.endTime || r.endTime >= timestamp));
    if (activeRun) {
      runId = activeRun.runId;
    }
    const fd = new FormData();
    fd.append("formData", blob, `IMG_${moment(timestamp).format("YYYY-MM-DD_THH:MM:SS")}.png`);
    fd.append("projectId", project?.arcadiaProjectId!);
    fd.append("fileName", `IMG_${moment(timestamp).format("YYYY-MM-DD_THH:MM:SS")}`);
    fd.append("description", "Snapshot");
    fd.append("userPath", `${FilePaths.SESSION_SNAPSHOTS}`);
    if (runId) {
      fd.append("runId", runId);
    }
    fd.append("sessionId", session.sessionId);
    fd.append("correlationTimestamp", timestamp);

    UploadsService.uploadFile(fd)
      .then(() => {
        setShowModalAddSnapshot(false);
        notification("Snapshot added to run uploads", "neutral");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const filterSnapshots = (snapshots: IArcadiaFileUpload[], videoStartTime: string, videoDuration: string) => {
    let result = [];
    const videoEndTime = moment(videoStartTime).add(videoDuration, "seconds").toISOString();
    result = snapshots.filter(
      (s) => s.correlationTimestamp >= videoStartTime && s.correlationTimestamp <= videoEndTime
    );
    return result;
  };
  return (
    <>
      {showModalAddSnapshot && (
        <ModalAddSnapshot
          firstImage={firstImage}
          secondImage={secondImage}
          timestamp={timestamp}
          saveSnapshot={takeSnapshot}
          onCloseModal={() => setShowModalAddSnapshot(false)}
        />
      )}
      {showOverlay && (
        <ScreenshotsOverlay
          // screenshots={filterSnapshots(
          //   project?.fileUploads,
          //   videoUploads?.startTimestamp,
          //   playerOne.duration
          // )}
          screenshots={screenshots}
          screenshotIndex={screenshotIndex}
          handleDoneClick={handleDoneClick}
          handleCloseClick={handleCloseClick}
          handleDelete={handleDelete}
          runName={runs.find((r) => r.runId === screenshots[0].runId)?.runName || ""}
          sessionName={session.sessionName!}
          partName={project.partDetails?.partName!}
          featureName={project.featureDetails?.featureName!}
        />
      )}
      {showModalCleaning && (
        <ModalCleaningAndMeasurement
          run={runForEditing!}
          onCancelClick={() => setShowModalCleaning(false)}
          onDoneClick={() => setShowModalCleaning(false)}
          fixtureName={fixtures.find((f) => f.fixtureId === runForEditing?.fixtureId)?.materialName || ""}
        />
      )}
      <div className="session-replay-page-container">
        <div className="session-replay-page-left-side">
          {session ? (
            <MachineSessionHeader
              sessionTitle={session.sessionName || ""}
              //@ts-ignore
              status={mapArcadiaSessionStatusToStatus(session.status as ArcadiaSessionStatus)}
              height={100}
            />
          ) : (
            <Skeleton className="machine-session-header" width="auto" height={100} borderRadius={0}></Skeleton>
          )}
          <Container
            className={`session-replay-page-left-side-inner ${
              videoUploads && videoUploads.channel1Uri && videoUploads.channel2Uri ? "" : " rendering"
            }`}
            type={ContainerType.PRIMARY}
          >
            {isVideoUploadsLoading ? (
              <Card width={435} height={700} hover={false}>
                <Skeleton marginTop={22} marginLeft={24} width={300} height={30}>
                  <Typography type={ElementType.h5}>Custom View</Typography>
                </Skeleton>
                <Skeleton
                  marginTop={18}
                  marginLeft={24}
                  marginBottom={24}
                  borderRadius={2}
                  width={387}
                  height={217}
                  backgroundColor="rgba(143, 146, 161, 0.1)"
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                  </div>
                </Skeleton>
                <Skeleton marginTop={43} marginLeft={24} width={300} height={30}>
                  <Typography type={ElementType.h5}>Standard View</Typography>
                </Skeleton>
                <Skeleton
                  marginTop={15}
                  marginLeft={24}
                  marginBottom={24}
                  borderRadius={2}
                  width={387}
                  height={217}
                  backgroundColor="rgba(143, 146, 161, 0.1)"
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                  </div>
                </Skeleton>
              </Card>
            ) : videoUploads && videoUploads.channel1Uri && videoUploads.channel2Uri ? (
              <CameraStreams
                height={650}
                channels={[
                  {
                    text: "Custom View",
                    url: videoUploads.channel1Uri,
                  },
                  {
                    text: "Standard View",
                    url: videoUploads.channel2Uri,
                  },
                ]}
                playerOne={playerOne}
                playerTwo={playerTwo}
                setPlayerOne={setPlayerOne}
                setPlayerTwo={setPlayerTwo}
                setPlayer1Loading={setPlayer1Loading}
                setPlayer2Loading={setPlayer2Loading}
                player1Loading={player1Loading}
                player2Loading={player2Loading}
                setCurrentTime={setCurrentTime}
                currentTime={currentTime}
                isLive={false}
                isReplay={true}
                toggleIsLive={toggleIsLive}
                volume={volume}
                setVolume={setVolume}
                rewind={rewind}
                setRewind={setRewind}
                synchronize={synchronize}
                setVideoQuality={setVideoQuality}
                onSliderInput={onSliderInput}
                onSliderChange={onSliderChange}
                isSliding={isSliding}
                isPlaying={isPlaying}
                togglePlay={togglePlay}
                videoQuality={videoQuality}
                setProgress={setProgress}
                setVideoFrameOne={setVideoFrameOne}
                videoFrameOne={videoFrameOne}
                setVideoFrameTwo={setVideoFrameTwo}
                videoFrameTwo={videoFrameTwo}
              />
            ) : (
              <Card className="card-video-uploading" width={435} height={321} hover={false}>
                <Typography className="card-video-uploading-title" type={ElementType.h5}>
                  Uploading Session Recording
                </Typography>
                <Container className="card-video-uploading-inner" type={ContainerType.SECONDARY}>
                  <Typography type={ElementType.bodySmall} color={Color.OffWhite}>
                    Thanks for joining. This machine session has ended and is uploading for future replays and review.
                  </Typography>
                  <div>
                    <Typography color={Color.OffWhite}>{`${completed}% Uploaded`}</Typography>
                    <Progress width={340} completed={completed} />
                  </div>
                </Container>
              </Card>
            )}

            <div className="table-container-div-scrollable-content">
              <div>
                <Typography type={ElementType.h4}>Operator's notes</Typography>
                <div className="table-container-div-scrollable-content-notes">
                  <ShowMore
                    text={
                      session && session.summaryNotes
                        ? session.summaryNotes
                        : "No notes are available for this session."
                    }
                    width={486}
                    characterCount={320}
                  />
                </div>
              </div>
              {session && materials && fixtures && executionPackages ? (
                <LiveSessionTable
                  onClickScreenshot={handleClickScreenshot}
                  machineRuns={runs}
                  runOrder={session ? session.runs! : []}
                  materials={materials}
                  fixtures={fixtures}
                  executionPackages={executionPackages}
                  isReplay={true}
                  jumpTo={goToSnapshotTime}
                  uploads={
                    project && project.fileUploads && project.fileUploads?.filter((u) => u.runId).length > 0
                      ? project?.fileUploads?.filter((u) => u.runId)
                      : []
                  }
                  onTextareaChange={onTextAreaChange}
                />
              ) : (
                <div className={"table-skeleton"}>
                  <Typography type={ElementType.h4}>Run Plan</Typography>
                  <Skeleton
                    className={"table-skeleton-header"}
                    width={930}
                    height={30}
                    marginTop={30}
                    marginLeft={30}
                    marginBottom={25}
                  >
                    <Icon color={"#8F92A1"} name="fal fa-chevron-down" />
                    <Skeleton
                      width={145}
                      height={21}
                      marginLeft={30}
                      borderRadius={15}
                      backgroundColor={"rgba(255, 255, 255, 0.1)"}
                    />
                  </Skeleton>
                  <Skeleton
                    className={"table-skeleton-rows"}
                    width={960}
                    height={598}
                    backgroundColor={"rgba(143, 146, 161, 0.1)"}
                  >
                    <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                  </Skeleton>
                </div>
              )}
            </div>
          </Container>
        </div>

        <Container className="session-replay-page-right-side" type={ContainerType.BASE}>
          {status && (
            <CardProfile
              position="Operator"
              backgroundImageUrl={status.operatorImageURL}
              name={status?.operatorName}
              contact={status?.operatorContact}
              clickHandler={() => {}}
              isReplay={true}
            />
          )}

          <div className="session-replay-page-session-timer-container">
            {status && (
              <CardMachineStatus
                backgroundImageUrl={status.machiningStation?.imageURL || ""}
                fileName={status.machineStatus?.currentPartProgram || ""}
                fileHref={"/"}
                status={status.machiningStation?.state! as MachineStatus}
                text={
                  status.machiningStation && status.machiningStation.description
                    ? status.machiningStation.description
                    : ""
                }
                backgroundColor={"#ffffff"}
                subText={status.machiningStation && status.machiningStation.type ? status.machiningStation.type : ""}
                stations={[status.cleaningStation, status.scopingStation, status.measuringStation]}
                isReplay={true}
              />
            )}
          </div>
        </Container>
      </div>
      {project && playerOne && videoFrameOne && videoUploads && (
        <Playback
          createBookmark={createBookmark}
          onGoBackClick={onGoBackClick}
          onGoForwardClick={onGoForwardClick}
          goToSnapshotTime={goToSnapshotTime}
          onSliderInput={onSliderInput}
          onSliderChange={() => {}}
          onClickScreenshot={handleClickScreenshot}
          currentTime={10}
          totalTime={100}
          isPlaying={isPlaying}
          togglePlay={togglePlay}
          bookmarks={videoUploads?.bookmarks}
          screenshots={[]}
          startTime={videoUploads?.startTimestamp}
          snapshots={filterSnapshots(project?.fileUploads, videoUploads?.startTimestamp, videoFrameOne.duration)}
          progress={progress}
          videoFrame={videoFrameOne}
        />
      )}
    </>
  );
};

export default SessionReplayPage;
