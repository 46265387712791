import React, { FC, FormEvent, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { EmptyTemplate } from "./Templates";
import uniqid from "uniqid";
import styleVariables from "@styles/export.scss";
import { Input } from "../Common/Input";
import { IArcadiaProject, INewProjectData, ITemplate } from "@types";
import CardTemplate from "../CardTemplate";
import { InfoIcon } from "../InfoIcon";

const TemplateProject: FC<{
  formData: INewProjectData;
  setFormData: (data: INewProjectData) => void;
  onClickAfterPart: (values: ITemplate) => void;
  projectTemplates: IArcadiaProject[];
}> = ({ formData, setFormData, onClickAfterPart, projectTemplates }): ReactElement => {
  const [template, setTemplate] = useState<ITemplate | undefined>(formData.template);

  const templates: ITemplate[] = projectTemplates.map((p) => ({
    templateId: p.arcadiaProjectId!,
    name: p.projectName!,
    shortDescription: p.projectName!,
    longDescription: p.projectName!,
    image:
      "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80",
    bigImage: <EmptyTemplate />,
  }));

  const changeSession = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const val = parseInt(target.value);
    const newFormData = Object.assign({}, formData);
    newFormData.sessionsPlanned = Math.max(val, 0);
    setFormData(newFormData);
  };

  const decreaseSession = () => {
    const newFormData = Object.assign({}, formData);
    const currentSessions = newFormData.sessionsPlanned || 0;
    newFormData.sessionsPlanned = Math.max(currentSessions - 1, 0);
    setFormData(newFormData);
  };

  const increaseSession = () => {
    const newFormData = Object.assign({}, formData);
    const currentSessions = newFormData.sessionsPlanned || 0;
    newFormData.sessionsPlanned = currentSessions + 1;
    setFormData(newFormData);
  };

  const onClickNext = () => {
    if (template) {
      onClickAfterPart(template);
    }
  };

  // const templates: ITemplate[] = [
  //   {
  //     name: "Blank Template",
  //     shortDescription: "Create tasks and sessions as you go",
  //     longDescription:
  //       "Use this template for projects designed for making parts in large quantities. You can edit your tasks and sessions after the template is selected.",
  //     image:
  //       "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80",
  //     bigImage: <EmptyTemplate />,
  //   },
  //   {
  //     name: "Proof of Concept",
  //     shortDescription: "Best for projects focused on validating new ideas.",
  //     longDescription:
  //       "Use this template for projects designed for making parts in large quantities. You can edit your tasks and sessions after the template is selected.",
  //     image:
  //       "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80",
  //     bigImage: <LongImage />,
  //     isBigImage: true,
  //   },
  //   {
  //     name: "Batch of Parts",
  //     shortDescription: "Best for parts made in large quantities",
  //     longDescription:
  //       "Use this template for projects designed for making parts in large quantities. You can edit your tasks and sessions after the template is selected.",
  //     image:
  //       "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80",
  //     bigImage: <BatchOfParts />,
  //   },
  // ];

  return (
    <div className="modal-new-project-template">
      <div className="modal-new-project-template-templates">
        <Typography type={ElementType.subtitle}>Start with a guiding project template.</Typography>
        <Typography
          type={ElementType.body}
          color={Color.Secondary}
          className="modal-new-project-template-templates-subtitle"
        >
          We’ll prepopulate your project to set you up for success. You can always edit your machine session schedule
          and tasks after you choose a template.
        </Typography>

        <div className="modal-new-project-template-templates-session">
          <Typography type={ElementType.bodySmall}>MACHINE SESSION BUDGET</Typography>
          <InfoIcon
            className="modal-new-project-template-templates-session-icon"
            color={styleVariables.color_gray}
            placement="top"
            tooltipText="Single session bookings are a full business day: 9am CST to 6pm CST."
          />
        </div>
        {/* <div className="modal-new-project-template-templates-input">
          <span
            className="modal-new-project-template-templates-input-button"
            role="button"
            tabIndex={0}
            onKeyDown={decreaseSession}
            onClick={decreaseSession}
          >
            <Icon name="far fa-minus" size={16} />
          </span>
          <div className={`modal-new-project-template-templates-input-${sessions === 1 ? "singular" : "plural"}`}>
            <Input height={48} min={0} onChange={changeSession} type="number" value={sessions} width={360} />
          </div>
          <span
            className="modal-new-project-template-templates-input-button"
            role="button"
            tabIndex={0}
            onKeyDown={increaseSession}
            onClick={increaseSession}
          >
            <Icon name="far fa-plus" size={16} />
          </span>
        </div> */}
        <div
          className={`modal-new-project-template-templates-input-${
            formData.sessionsPlanned === 1 ? "singular" : "plural"
          }`}
        >
          <Input
            height={48}
            min={0}
            onChange={changeSession}
            type="number"
            value={formData.sessionsPlanned!}
            onIncrease={increaseSession}
            onDecrease={decreaseSession}
            withButtons={true}
            width={360}
          />
        </div>

        <div className="modal-new-project-template-templates-input-below">
          <Typography
            className="modal-new-project-template-templates-input-below-badge"
            type={ElementType.bodySmall}
            color={Color.OffWhite}
          >
            Recommended: 5 Sessions
          </Typography>
          <Typography type={ElementType.bodySmall} color={Color.OffWhite}>
            20 Sessions Remaining
          </Typography>
        </div>
        <Typography className="modal-new-project-template-templates-list-title" type={ElementType.subtitle}>
          Templates
        </Typography>
        <div className="modal-new-project-template-templates-list">
          {templates.map((thisTemplate: ITemplate) => {
            const onClickCard = () => {
              if (template?.name === thisTemplate.name) {
                setTemplate(undefined);
              } else {
                setTemplate(thisTemplate);
              }
            };

            return (
              <CardTemplate
                className="modal-new-project-template-templates-list-card"
                key={uniqid()}
                isActive={thisTemplate.name === template?.name}
                onClickCard={onClickCard}
                template={thisTemplate}
              />
            );
          })}
        </div>
      </div>
      <div className="modal-new-project-template-example">
        {template ? (
          <>
            <div className="modal-new-project-template-example-header">
              <Typography type={ElementType.h4} className="modal-new-project-template-example-title">
                {template?.name}
              </Typography>
              <Button size="medium" themeType="clear" onClick={onClickNext}>
                Select Template
              </Button>
            </div>
            <Typography
              type={ElementType.body}
              color={Color.Secondary}
              className="modal-new-project-template-example-subtitle"
            >
              {template?.longDescription}
            </Typography>
            <div
              className={`modal-new-project-template-image ${
                template.isBigImage ? "modal-new-project-template-image-big" : ""
              }`}
            >
              {typeof template?.bigImage === "string" ? <img src={template.bigImage} alt="" /> : template?.bigImage}
            </div>
          </>
        ) : (
          <div className="modal-new-project-template-example-empty">
            <div className="modal-new-project-template-image">
              <EmptyTemplate />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { TemplateProject };
