import * as React from "react";
import { default as cx } from "classnames";
import { Thumbnail } from "../Thumbnail";
import { Color, ElementType, Typography } from "../Common/Typography";
import moment from "moment";
import { useHistory } from "react-router-dom";

const CardSession: React.FC<
  {
    className?: string;
    sessionName: string;
    projectName: string;
    projectId: string;
    startDate?: string;
    imageUrl: string;
    isLive?: boolean;
    onClick: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className, sessionName, projectName, startDate, imageUrl, projectId, onClick, isLive }): React.ReactElement => {
  const classes = cx(
    {
      "card-session": true,
    },
    className
  );

  const history = useHistory();

  return (
    <div className={classes} role="button" tabIndex={0} onKeyUp={() => {}} onClick={onClick}>
      <div className="thumbnail-container">
        <Thumbnail backgroundImageUrl={imageUrl} height={36} width={36} title={sessionName} borderRadius={4} />
      </div>
      <div className="info-container">
        <Typography type={ElementType.inline}>{sessionName}</Typography>
        <Typography type={ElementType.inline} color={Color.Secondary}>
          {" in "}
          <Typography
            type={ElementType.textLink}
            color={Color.Primary}
            onClick={(ev) => {
              ev.stopPropagation();
              history.push(`project/${projectId}`);
            }}
          >
            {projectName}
          </Typography>
        </Typography>
        <Typography type={ElementType.bodySmall} color={Color.Secondary}>
          {startDate ? moment(startDate).format("MMM DD, YYYY") : ""}
        </Typography>
        {isLive && <Typography color={Color.Orange}>Live</Typography>}
      </div>
    </div>
  );
};

export { CardSession };
