import React, { FC, HTMLAttributes, ReactElement, useEffect, useState } from "react";
import { default as cx } from "classnames";
import { Icon } from "../Icon";
import { ElementType, Typography } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { OptionType, Select } from "../Common/Select";
import { wsConnect } from "./wcConnectHelper";
import { Button } from "../Button";
import openFullscreen from "../../helpers/fullscreen";
import { calculateLivePlayheadPosition } from "../CameraStreams/helpers";
import { ICameraPreset } from "@types";
import { VideoPlayer } from "../VideoComponent";

const ArrowIcon: FC<
  {
    position: "top" | "left" | "right" | "bottom";
  } & HTMLAttributes<HTMLOrSVGElement>
> = ({ position, ...other }): ReactElement => (
  <svg
    {...other}
    className={`video-element-camera-arrow video-element-camera-arrow-${position}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      className="video-element-camera-arrow-ellipse"
      cx="12"
      cy="12"
      rx="12"
      ry="12"
      fill={styleVariables.color_gray}
      fillOpacity="1.0"
    />
    <path
      d="M14.75 7.5C14.75 6.79688 13.8906 6.44531 13.3828 6.95312L8.38281 11.9531C8.07031 12.2656 8.07031 12.7734 8.38281 13.0859L13.3828 18.0859C13.8906 18.5938 14.75 18.2422 14.75 17.5391V7.5Z"
      fill="white"
    />
  </svg>
);

const VideoElement: FC<
  {
    text: string;
    takeSnapshot?: (data: string, timestamp: string) => void;
    setPTR?: (command: string) => void;
    withControls?: boolean;
    className?: string;
    applicationName: string;
    streamName: string;
    volume: number;
    loading: boolean;
    isLive: boolean;
    isReplay: boolean;
    cameraPresets?: ICameraPreset[];
    lastCameraPreset?: ICameraPreset;
    setCameraPreset?: (preset: number) => void;
    videoQuality: number;
    setVideoQuality: any;
    onFullScreenClick: (streamId: string) => void;
    channels: { text: string; url: string }[];
    setPlayerReady: (ready: boolean) => void;
    setPlayer: any;
    options: any;
    setVideoFrame: any;
    videoFrame: any;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  text = "",
  className = "",
  withControls = true,
  streamName,
  applicationName,
  takeSnapshot,
  setPTR,
  loading,
  isLive,
  isReplay,
  volume,
  cameraPresets,
  lastCameraPreset,
  setCameraPreset,
  videoQuality,
  setVideoQuality,
  onFullScreenClick,
  channels,
  setPlayerReady,
  setPlayer,
  options,
  setVideoFrame,
  videoFrame,
}): ReactElement => {
  const classes = cx(
    {
      "video-element": true,
    },
    className
  );

  const videoRef = React.createRef();
  const elementLive = React.createRef<HTMLVideoElement>();
  const [preset, setPreset] = useState<ICameraPreset>();
  const stopFunction = React.useRef(() => {});

  useEffect(() => {
    if (isLive) {
      if (elementLive && elementLive.current) {
        elementLive.current.muted = false;
        elementLive.current.volume = volume;
      }
    }
  }, [volume, isLive]);

  useEffect(() => {
    if (isLive) {
      if (elementLive && elementLive.current) {
        stopFunction.current = wsConnect(
          applicationName,
          streamName,
          "wss://5f5a8800a2db8.streamlock.net/webrtc-session.json",
          elementLive.current
        );
      }
    }
    return () => {
      if (stopFunction.current) {
        stopFunction.current();
      }
    };
  }, [isLive]);

  useEffect(() => {
    if (lastCameraPreset) {
      setPreset(lastCameraPreset);
    }
    return;
  }, [lastCameraPreset, isLive]);

  const dropdownOptions: OptionType[] = cameraPresets
    ? cameraPresets.map((p) => ({ value: p.presetId!.toString(), label: p.presetName! }))
    : [];

  const onTakeSnapshotClick = () => {
    if (isLive) {
      const element = elementLive.current as any;
      if (element !== null && element.videoWidth > 0 && element.videoHeight > 0) {
        const canvas = document.createElement("canvas")!;
        canvas.width = element.videoWidth;
        canvas.height = element.videoHeight;
        const context = canvas.getContext("2d")!;
        if (context) {
          context.drawImage(element, 0, 0, canvas.width, canvas.height);
          const imageURL = canvas.toDataURL();
          if (takeSnapshot) {
            takeSnapshot(imageURL, new Date().toISOString());
          }
        }
      }
    } else {
      if (videoFrame && videoFrame.videoWidth > 0 && videoFrame.videoHeight > 0) {
        const canvas = document.createElement("canvas")!;
        canvas.width = videoFrame.videoWidth;
        canvas.height = videoFrame.videoHeight;
        canvas.getContext("2d")!.drawImage(videoFrame, 0, 0);
        const imageURL = canvas.toDataURL();
        if (takeSnapshot) {
          const timestamp = calculateLivePlayheadPosition(
            videoFrame.currentTime,
            videoFrame.seekable.end(videoFrame.seekable.length - 1)
          ).toISOString();
          takeSnapshot(imageURL, timestamp);
        }
      }
    }
  };

  const onRewindFullScreenClick = () => {
    let element = elementLive.current as any;
    if (!isLive) {
      element = videoRef.current;
    }
    openFullscreen(element);
  };

  const handleFullScreenClick = () => {
    if (isLive) {
      onFullScreenClick(streamName);
    } else {
      onRewindFullScreenClick();
    }
  };

  const handlePresetChange = (option: OptionType) => {
    if (setCameraPreset && cameraPresets !== undefined) {
      const presetId = parseInt(option.value);
      const newPreset = cameraPresets.find((p) => p.presetId! === presetId);
      setPreset(newPreset);
      setCameraPreset(presetId);
    }
  };

  const handlePlayerReady = (player: any, video: any) => {
    setPlayer(player);
    setVideoFrame(video);
    setPlayerReady(true);
  };

  return (
    <div className={classes}>
      <div className="video-element-top">
        <Typography type={ElementType.h5}>{text}</Typography>
        <div className="video-element-top-buttons">
          {takeSnapshot && (
            <Button
              className="video-element-top-icon"
              onClick={onTakeSnapshotClick}
              themeType={"icon"}
              iconOnly={true}
              iconSize={24}
              iconName="far fa-camera"
            />
          )}

          <Button
            className="video-element-top-icon"
            onClick={handleFullScreenClick}
            themeType={"icon"}
            iconOnly={true}
            iconSize={24}
            iconName="far fa-expand"
          />
        </div>
      </div>
      <div className="video-element-camera">
        {loading && (
          <div className="video-loading">
            <Icon name="fas fa-spinner fa-spin" size={40} />
          </div>
        )}
        {isLive ? (
          <video width="387" height="218" autoPlay playsInline muted ref={elementLive}></video>
        ) : (
          <VideoPlayer
            width="387"
            height="218"
            options={options}
            onReady={handlePlayerReady}
            videoQuality={videoQuality}
            videoRef={videoRef}
          />
        )}

        {withControls && setPTR && (
          <>
            {preset?.supportsPtz && (
              <>
                <ArrowIcon position="bottom" onClick={() => setPTR("DOWN")} />
                <ArrowIcon position="left" onClick={() => setPTR("LEFT")} />
                <ArrowIcon position="right" onClick={() => setPTR("RIGHT")} />
                <ArrowIcon position="top" onClick={() => setPTR("UP")} />
                <div className="video-element-camera-zoom">
                  <Icon
                    className="video-element-camera-zoom-icon"
                    onClick={setPTR ? () => setPTR("ZOOM_IN") : () => {}}
                    name="far fa-plus"
                    size={12}
                    width={24}
                    height={24}
                  />
                  <Icon
                    className="video-element-camera-zoom-icon"
                    onClick={setPTR ? () => setPTR("ZOOM_OUT") : () => {}}
                    name="far fa-minus"
                    size={12}
                    width={24}
                    height={24}
                  />
                </div>
              </>
            )}

            <div className="video-element-camera-dropdown">
              <Select
                width={137}
                height={25}
                withBorder={false}
                value={dropdownOptions[preset?.presetId!] || dropdownOptions[0]}
                defaultValue={dropdownOptions[0]}
                options={[...dropdownOptions]}
                onChange={(value) => handlePresetChange(value as OptionType)}
                transparent={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { VideoElement };
