import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Modal } from "../Modal";
import { INewProjectDataPart } from "@types";
import { ScrollableContainer } from "../ScrollableContainer";
import { PartData } from "../ModalNewProject/PartData";
import { OptionType } from "../Common/Select";

const PartModal: FC<
  {
    className?: string;
    onCloseModal?: () => void;
    onSave: (updatedPart: INewProjectDataPart) => void;
    onDelete: () => void;
    initialPart: INewProjectDataPart;
    materialCategories: string[];
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, onSave, onDelete, initialPart, materialCategories }): ReactElement => {
  const [part, setPart] = useState<INewProjectDataPart>(initialPart);

  const materialOptions: OptionType[] = materialCategories.map((m) => ({ value: m, label: m }));

  const cycleOptions: OptionType[] = [
    { value: "sec", label: "sec" },
    { value: "min", label: "min" },
  ];

  const updateField = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, keyIndex: any) => {
    event.preventDefault();
    const element = event.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
    const newPart = { ...part };
    // @ts-ignore
    newPart[keyIndex as keyof INewProjectDataPart] = element.value;
    setPart(newPart);
  };

  const changePartName = (event: FormEvent<HTMLInputElement>) => {
    updateField(event, "name");
  };

  const changePartDescription = (event: FormEvent<HTMLTextAreaElement>) => {
    updateField(event, "description");
  };

  const changePartParts = (event: FormEvent<HTMLInputElement>) => {
    updateField(event, "number");
  };

  const changePartCycle = (event: FormEvent<HTMLInputElement>) => {
    updateField(event, "desiredCycle");
  };

  const changePartMaterial = (newMaterial: OptionType) => {
    const newPart = { ...part };
    newPart.material = newMaterial;
    setPart(newPart);
  };

  const changeDeliveryDate = (newDate: Date) => {
    const newPart = { ...part };
    newPart.deliveryDate = newDate;
    setPart(newPart);
  };

  const changePartSelection = (newPartSelection: string) => {
    const newPart = { ...part };
    newPart.cycleSelection = newPartSelection;
    setPart(newPart);
  };

  const changePartDrawings = (files: File[]) => {
    const newPart = { ...part };
    newPart.drawing = files;
    setPart(newPart);
  };

  return (
    <Modal className={"part-modal modal-new-project"} height="auto" width={682} onCloseModal={onCloseModal}>
      <ScrollableContainer width={650} height={810}>
        <PartData
          part={part}
          showCardAttributes={false}
          showDelete={true}
          classNames={""}
          changePartName={changePartName}
          changePartDescription={changePartDescription}
          changePartParts={changePartParts}
          changePartCycle={changePartCycle}
          changePartMaterial={changePartMaterial}
          changeDeliveryDate={changeDeliveryDate}
          changePartSelection={changePartSelection}
          changePartDrawings={changePartDrawings}
          deletePart={onDelete}
          materialOptions={materialOptions}
          cycleOptions={cycleOptions}
        />
        <Button className="part-modal-save-button" themeType="clear" onClick={() => onSave(part)}>
          Save
        </Button>
      </ScrollableContainer>
    </Modal>
  );
};

export { PartModal };
