import { useState } from "react";
import MaterialService from "../../services/MaterialService";

const useMaterialCategories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [materialCategories, setMaterialCategoriess] = useState<string[]>([]);

  const fetchMaterialCategories = (userId: string) => {
    const fetchData = async () => {
      setIsLoading(true);
      MaterialService.getMaterialCategories(userId)
        .then((response) => {
          const materialCategories = response.data as string[];
          setMaterialCategoriess(materialCategories);

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return {
    materialCategories,
    isMaterialCategoriesLoading: isLoading,
    isMaterialCategoriesError: isError,
    fetchMaterialCategories,
  };
};

export default useMaterialCategories;
