import { ICameraPreset } from "@types";
import { useEffect, useState } from "react";
import CameraService from "../../services/CameraService";

const useCameraPresets = () => {
  const [isCameraPresetsLoading, setIsCameraPresetsLoading] = useState(false);
  const [isCameraPresetsError, setIsCameraPresetsError] = useState(false);

  const [cameraPresets, setCameraPresets] = useState<ICameraPreset[]>([]);
  const [lastCameraPreset, setLastCameraPreset] = useState<ICameraPreset>();

  useEffect(() => {
    const fetchData = async () => {
      setIsCameraPresetsLoading(true);
      CameraService.getCameraPresets()
        .then((response) => {
          const cameraPresets = response.data as ICameraPreset[];
          setCameraPresets(cameraPresets);
          CameraService.getLastCameraPreset()
            .then((response) => {
              const cameraPreset = response.data as ICameraPreset;
              setLastCameraPreset(cameraPreset);
              setIsCameraPresetsLoading(false);
            })
            .catch((e) => {
              setIsCameraPresetsLoading(false);
              setIsCameraPresetsError(true);
              console.log(e);
            });
          setIsCameraPresetsLoading(false);
        })
        .catch((e) => {
          setIsCameraPresetsLoading(false);
          setIsCameraPresetsError(true);
          console.log(e);
        });
    };
    fetchData();
  }, []);

  const fetchLastCameraPreset = () => {
    CameraService.getLastCameraPreset()
      .then((response) => {
        const cameraPreset = response.data as ICameraPreset;
        setLastCameraPreset(cameraPreset);
        setIsCameraPresetsLoading(false);
      })
      .catch((e) => {
        setIsCameraPresetsLoading(false);
        setIsCameraPresetsError(true);
        console.log(e);
      });
    setIsCameraPresetsLoading(false);
  };

  return { cameraPresets, lastCameraPreset, isCameraPresetsLoading, isCameraPresetsError, fetchLastCameraPreset };
};

export default useCameraPresets;
