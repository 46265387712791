/* eslint-disable tsc/config */
import React, { FC, ReactElement, useRef, useState } from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";
import { useClickOutside } from "../../helpers/hooks";
import { IDropdownButton } from "@types";
import uniqid from "uniqid";
import { Button } from "../Button";

const DropdownMenu: FC<{
  buttons: IDropdownButton[];
  icon?: string;
  withButton?: boolean;
  buttonSize?: "xs" | "small" | "medium" | "large";
}> = ({ buttons, icon = "fa fa-chevron-down", withButton, buttonSize }): ReactElement => {
  const [shown, setShown] = useState<boolean>(false);

  const toggleDropdown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setShown(!shown);
  };

  const closeDropdown = () => {
    setShown(false);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, closeDropdown);

  return (
    <span className="dropdown-menu" ref={wrapperRef}>
      {withButton ? (
        <Button
          onClick={toggleDropdown}
          themeType="secondary"
          size={buttonSize ? buttonSize : "small"}
          iconOnly={true}
          iconName={icon}
          iconSize={24}
        />
      ) : (
        <Button
          size={buttonSize ? buttonSize : "medium"}
          onClick={toggleDropdown}
          themeType="simple"
          iconOnly={true}
          iconName={icon}
          iconSize={18}
        />
      )}

      {shown && (
        <ul className="dropdown-menu-list">
          {buttons.map((button) => (
            <li className="dropdown-menu-list-option" key={uniqid()}>
              <div
                role="button"
                className={`dropdown-menu-list-option-container${button.selected ? " selected" : ""}`}
                onClick={(ev) => {
                  setShown(false);
                  button.onClick(ev);
                }}
                onKeyPress={(ev) => {
                  setShown(false);
                  button.onClick(ev);
                }}
                tabIndex={0}
              >
                {button.icon && (
                  <Icon
                    className="dropdown-menu-list-option-container-icon"
                    name={button.icon!}
                    color={button.color || styleVariables.color_gray}
                    size={14}
                  />
                )}

                <Typography type={ElementType.inline} color={button.textColor || Color.Primary}>
                  {button.name}
                </Typography>
              </div>
            </li>
          ))}
        </ul>
      )}
    </span>
  );
};

export default DropdownMenu;
export { DropdownMenu };
