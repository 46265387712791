import React from "react";

export enum StrokeLineOptions {
  ROUND = "round",
  BUTT = "butt",
  SQUARE = "square",
}

const CircleProgress: React.FC<
  {
    percentage: number;
    width?: number;
    strokeWidth?: number;
    fontSize?: number;
    fontColor?: string;
    fontFamily?: string;
    primaryColor?: string | string[];
    secondaryColor?: string;
    fill?: string;
    hidePercentageText?: boolean;
    strokeLinecap?: keyof StrokeLineOptions;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className,
  percentage,
  width = 200,
  strokeWidth = 5,
  fontSize = "inherit",
  fontColor = "inherit",
  fontFamily = "inherit",
  primaryColor = ["#00BBFF", "#92d7f1"],
  secondaryColor = "transparent",
  fill = "transparent",
  hidePercentageText = false,
  strokeLinecap = StrokeLineOptions.ROUND,
}): React.ReactElement => {
  const PI = 3.14;

  const R = width / 2 - strokeWidth * 2;
  const circumference = 2 * PI * R;
  const offset = circumference - (percentage / 100) * circumference;
  const gradientId = `${primaryColor[0]}${primaryColor[1]}`.replace(/#/g, "");
  return (
    <div
      className={`circleSvg ${className}`}
      style={{
        height: `${width}px`,
        width: `${width}px`,
      }}
    >
      {!hidePercentageText ? (
        <div className="circleSvgPercentage">
          <span
            className="circleSvgPercentageSpan"
            style={{
              fontSize,
              fontFamily,
              color: fontColor,
            }}
          >
            {children}
          </span>
        </div>
      ) : null}

      <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={primaryColor[0]} />
          <stop offset="100%" stopColor={primaryColor[1]} />
        </linearGradient>
        <circle
          strokeWidth={strokeWidth}
          fill="transparent"
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={secondaryColor}
          strokeDasharray={`${circumference} ${circumference}`}
        />
        <circle
          className="circleSvgProgressCircleBar"
          strokeWidth={strokeWidth}
          fill={fill}
          r={R}
          cx={width / 2}
          cy={width / 2}
          stroke={`url(#${gradientId})`}
          strokeLinecap={strokeLinecap as StrokeLineOptions}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
      </svg>
    </div>
  );
};

export default CircleProgress;
