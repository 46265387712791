import * as React from "react";
import { default as cx } from "classnames";
import { Typography, ElementType } from "../Typography";

const Textarea: React.FC<
  {
    className?: string;
    value?: string;
    onChange?: Function;
    label?: string;
    placeholder?: string;
    width?: number | string;
    height?: number | string;
    disabled?: boolean;
  } & React.HTMLAttributes<HTMLTextAreaElement>
> = ({
  className,
  value,
  onChange,
  label = "",
  placeholder = "",
  width = 325,
  height = 120,
  disabled = false,
}): React.ReactElement => {
  const classes = cx(
    {
      "custom-textarea-container": true,
    },
    className
  );
  return (
    <div className={classes}>
      {label && <Typography type={ElementType.label}>{label}</Typography>}
      <textarea
        disabled={disabled}
        className="custom-textarea"
        style={{ width: width, height: height }}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export { Textarea };
