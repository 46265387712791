import * as React from "react";
import { Color, ElementType, Typography } from "../Common/Typography";
import { default as cx } from "classnames";

const FormSection: React.FC<
  {
    sectionTitle?: string | JSX.Element;
    sectionSubTitle?: string;
    className?: string;
    noBorder?: boolean;
    width?: string;
    secondary?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  sectionTitle = "",
  sectionSubTitle = "",
  className = "",
  noBorder = false,
  width = "650px",
  secondary = true,
}): React.ReactElement => {
  const classes = cx(
    {
      "form-section": true,
      "form-section-secondary": secondary,
      "form-section-noborder": noBorder,
    },
    className
  );

  return (
    <div className={classes} style={{ width: width }}>
      {sectionTitle && (
        <Typography type={ElementType.subtitle} className="form-section-title">
          {sectionTitle}
        </Typography>
      )}
      {sectionSubTitle && (
        <Typography type={ElementType.body} color={Color.Secondary} className="form-section-subtitle">
          {sectionSubTitle}
        </Typography>
      )}
      {children}
    </div>
  );
};

export { FormSection };
