import React from "react";
import { default as cx } from "classnames";
import { Typography, ElementType, Color } from "../Typography";
import ReactSelect, { ActionMeta, OptionsType, Styles, ValueType, components } from "react-select";
import styleVariables from "@styles/export.scss";
import { Checkbox } from "../Checkbox";

export type OptionType = { label: string; value: string };

const Select: React.FC<{
  className?: string;
  containerClassName?: string;
  label?: string;
  placeholder?: string;
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  value?: ValueType<OptionType>;
  onChange?: (value: ValueType<OptionType>, actionMeta: ActionMeta<OptionType>) => void;
  options: OptionsType<OptionType>;
  rightIconName?: string;
  leftIconName?: string;
  iconSize?: number;
  autoFocus?: boolean;
  withBorder?: boolean;
  transparent?: boolean;
  defaultValue?: ValueType<OptionType>;
  maxWidth?: number | string;
  multiselect?: boolean;
}> = ({
  containerClassName = "",
  label = "",
  placeholder = "",
  width = 325,
  height = 48,
  disabled = false,
  value,
  options = [],
  onChange = () => {},
  withBorder = true,
  transparent = false,
  className,
  defaultValue,
  maxWidth,
  multiselect = false,
}): React.ReactElement => {
  const classes = cx(
    {
      "custom-select": true,
    },
    className
  );

  const colourStyles: any = {
    container: (styles: Styles) => ({
      ...styles,
      maxWidth: maxWidth,
      width: width,
      height: height,
      minHeight: height,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    control: (styles: Styles) => ({
      ...styles,
      backgroundColor: "transparent",
      borderColor: withBorder ? "rgba(143, 146, 161, 0.8)" : "transparent",
      borderRadius: 8,
      width: width,
      height: height,
      minHeight: height,
      outline: "none",
      boxShadow: "none",
      ":hover": {
        borderColor: withBorder ? styleVariables.color_gray : "transparent",
      },
      ":focus": {
        borderColor: withBorder ? styleVariables.color_white : "transparent",
        filter: "drop-shadow(2px 2px 6px #000000) drop-shadow(-4px -4px 6px rgba(255, 255, 255, 0.09))",
      },
    }),
    // @ts-ignore
    option: (styles: Styles, { isSelected }) => {
      return {
        ...styles,
        width: width,
        paddingTop: transparent ? 2 : 8,
        paddingBottom: transparent ? 2 : 8,
        backgroundColor: isSelected ? "rgba(255, 255, 255, 0.2)" : "none",
        color: styleVariables.color_white,
        ":active": {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
        ":hover": {
          backgroundColor: "rgba(255, 255, 255, 0.2)",
        },
      };
    },
    dropdownIndicator: (styles: any) => ({
      ...styles,
      color: styleVariables.color_white,
      ":hover": styleVariables.color_white,
    }),
    indicatorSeparator: (styles: Styles) => ({ ...styles, display: "none" }),
    indicatorsContainer: (styles: Styles) => ({ ...styles, height: height }),
    input: (styles: Styles) => ({ ...styles, color: styleVariables.color_white, border: "none" }),
    placeholder: (styles: Styles) => ({ ...styles, color: styleVariables.color_white }),
    singleValue: (styles: Styles) => ({ ...styles, color: styleVariables.color_white }),
    menu: (styles: Styles) => ({
      ...styles,
      width: width,
      margin: 0,
      padding: 0,
      borderRadius: 4,
      boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.5)",
      backgroundColor: transparent ? styleVariables.color_dark_gray : styleVariables.color_layer_light,
    }),
    menuList: (styles: Styles) => ({
      ...styles,
      width: width,
      margin: 0,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4,
    }),
  };

  const CustomOption = (props: any) => (
    <div>
      <components.Option {...props}>
        <div className="multiselect-option">
          <Checkbox checked={props.isSelected} onChange={(e) => null} />
          {props.label}
        </div>
      </components.Option>
    </div>
  );

  const ValueContainer = (props: any) => {
    const { children, ...rest } = props;
    let [values] = children;
    const [, input] = children;

    if (Array.isArray(values)) {
      const plural = values.length === 1 ? "" : "s";
      values = `${values.length} item${plural} selected`;
    } else {
      values = <Typography color={Color.Secondary}>Select from dropdown</Typography>;
    }

    return (
      <components.ValueContainer {...rest}>
        {values}
        {input}
      </components.ValueContainer>
    );
  };

  return (
    <>
      {label && <Typography type={ElementType.label}>{label}</Typography>}
      {!multiselect ? (
        <ReactSelect
          menuPlacement="auto"
          className={classes}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          options={options}
          defaultValue={defaultValue}
          styles={colourStyles}
          isSearchable={false}
        />
      ) : (
        <ReactSelect
          menuPlacement="auto"
          isMulti={true}
          className={classes}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          options={options}
          hideSelectedOptions={false}
          defaultValue={defaultValue}
          styles={colourStyles}
          isSearchable={false}
          components={{ Option: CustomOption, ValueContainer: ValueContainer }}
        />
      )}
    </>
  );
};

export { Select };
