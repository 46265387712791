import * as React from "react";
import { Thumbnail } from "../Thumbnail";
import { Card } from "../Card";
import { Status } from "../../constants/enums/Status";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { StatusIcon } from "../StatusIcon";
import { IStationStatus } from "@types";

export enum MachineStatus {
  Active = "Active",
  Setup = "Setup",
  Paused = "Paused",
  Idle = "Idle",
}

const mapMachineStatusToStatus = (machineStatus: MachineStatus) => {
  let result: Status = Status.Done;
  switch (machineStatus) {
    case MachineStatus.Paused:
      result = Status.InReview;
      break;

    case MachineStatus.Active:
      result = Status.InProgress;
      break;
    case MachineStatus.Setup:
      result = Status.Done;
      break;
    case MachineStatus.Idle:
      result = Status.NotStarted;
      break;
    default:
      break;
  }
  return result;
};

const CardMachineStatus: React.FC<
  {
    backgroundImageUrl: string;
    backgroundColor: string;
    status: MachineStatus;
    text?: string;
    subText?: string;
    imageTitle?: string;
    fileName: string;
    fileHref: string;
    stations?: (IStationStatus | undefined)[];
    isReplay?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  backgroundImageUrl = "",
  backgroundColor = styleVariables.color_white,
  status,
  text = "",
  subText = "",
  imageTitle = "",
  fileName = "",
  fileHref = "/",
  stations = [],
  isReplay = false,
}): React.ReactElement => {
  return (
    <Card className="card-machine-status-outer-container" width={265} height={isReplay ? 105 : 305} hover={false}>
      <div className={`card-machine-status-header${isReplay ? " replay" : ""}`}>
        <div className="card-machine-status-thumbnail-container">
          <Thumbnail
            title={imageTitle}
            round={false}
            width={60}
            height={60}
            borderRadius={16}
            backgroundImageUrl={backgroundImageUrl}
            backgroundColor={backgroundColor}
            status={mapMachineStatusToStatus(status)}
          />
        </div>
        <div className="card-machine-status-info-container">
          <div className="card-machine-status-name-container">
            <Typography type={ElementType.h5}>{text}</Typography>
          </div>
          <div className="card-machine-status-controls-container">
            <Typography type={ElementType.bodySmall} color={Color.Secondary}>
              {!isReplay ? `${status} · ` : ""}
              {subText}
            </Typography>
          </div>
        </div>
      </div>
      {!isReplay && (
        <>
          <div className="card-machine-status-footer">
            <Icon
              className="card-machine-status-footer-icon"
              color={styleVariables.color_gray}
              size={16}
              name="far fa-file-code"
            />
            <div className="card-machine-status-footer-info">
              <div className="card-machine-status-footer-subtitle">Current Part Program</div>
              <Link href={fileHref} title={fileName}>
                {fileName}
              </Link>
            </div>
          </div>
          <div className="card-machine-status-jobs">
            {stations.map(
              (station) =>
                station && (
                  <div key={station.description} className="card-machine-status-jobs-row">
                    <StatusIcon
                      className={"mr1"}
                      status={station.state !== "Idle" ? Status.InProgress : Status.NotStarted}
                    />
                    <Typography type={ElementType.bodySmall}>
                      {station.description}
                      <Typography type={ElementType.inline} color={Color.Secondary}>
                        {` -${station.state}`}
                      </Typography>
                    </Typography>
                  </div>
                )
            )}
          </div>
        </>
      )}
    </Card>
  );
};

export { CardMachineStatus };
