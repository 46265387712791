import { Skeleton } from "@namespace/components";
import React from "react";

const ProjectsSkeletonLoader = () => (
  <div className="projects-skeleton">
    <Skeleton
      className="projects-skeleton-cards"
      width={320}
      height={323}
      backgroundColor="rgba(143, 146, 161, 0.05)"
      borderRadius={15}
    />
    <Skeleton
      className="projects-skeleton-cards"
      width={320}
      height={323}
      backgroundColor="rgba(143, 146, 161, 0.05)"
      borderRadius={15}
    />
    <Skeleton
      className="projects-skeleton-cards"
      width={320}
      height={323}
      backgroundColor="rgba(143, 146, 161, 0.05)"
      borderRadius={15}
    />
  </div>
);

export default ProjectsSkeletonLoader;
