import React from "react";
import { default as cx } from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import moment from "moment";

import { ElementType, Typography } from "../Common/Typography";
import { SessionRow, SessionRowStatus } from "../SessionRow";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";
import { Button } from "../Button";
import { SessionRowHeader } from "../SessionRowHeader";
import { SessionRowInProgress } from "../SessionRowInProgress";
import { IArcadiaMaterial, IExecutionPackage, IMachineRun, IArcadiaRun, IArcadiaFileUpload } from "@types";
import { SessionRowSummary } from "../SessionRowSummary";
import { Status } from "../../constants/enums/Status";

const LiveSessionTable: React.FC<
  {
    className?: string;
    onClickScreenshot: any;
    onClickUploadMeasurements: any;
    onClickDownloadProgram: any;
    machineRuns: IMachineRun[];
    runOrder: IArcadiaRun[];
    materials: IArcadiaMaterial[];
    fixtures: IArcadiaMaterial[];
    executionPackages: IExecutionPackage[];
    uploads: IArcadiaFileUpload[];
    isReplay: boolean;
    deleteItem?: Function;
    duplicateItem?: Function;
    editItem?: Function;
    stopItem?: Function;
    startItem?: Function;
    jumpTo?: Function;
    onEditPlanClick?: Function;
    onTextareaChange: (runId: string, value: string) => void;
    duration: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  onClickScreenshot,
  machineRuns,
  runOrder,
  materials,
  fixtures,
  executionPackages,
  uploads,
  isReplay,
  deleteItem,
  startItem,
  jumpTo,
  duplicateItem,
  editItem,
  stopItem,
  onEditPlanClick,
  onTextareaChange,
  onClickUploadMeasurements,
  onClickDownloadProgram,
  duration,
}): React.ReactElement => {
  const classes = cx("live-session-table", className);
  const fixtureIds: string[] = [];
  machineRuns.forEach((element: any) => {
    if (fixtureIds.indexOf(element.fixtureId) < 0) {
      fixtureIds.push(element.fixtureId);
    }
  });

  const getSummaryRowText = (machineRuns: IMachineRun[]) => {
    let result = "";
    const runInProgress = machineRuns.find((run) => run.runStatus === SessionRowStatus.Running);
    if (runInProgress) {
      result = `${runInProgress.runName} in Progress`;
    } else {
      const completed = machineRuns.filter((run) => run.runStatus === SessionRowStatus.Complete);
      if (completed.length === machineRuns.length) {
        result = "Completed";
      } else {
        result = "Not Started";
      }
    }
    return result;
  };

  const getSummaryRowStatus = (machineRuns: IMachineRun[]) => {
    let result = Status.NotStarted;
    const runInProgress = machineRuns.find((run) => run.runStatus === SessionRowStatus.Running);
    if (runInProgress) {
      result = Status.InProgress;
    } else {
      const completed = machineRuns.filter((run) => run.runStatus === SessionRowStatus.Complete);
      if (completed.length === machineRuns.length) {
        result = Status.Done;
      }
    }

    return result;
  };
  return (
    <div className={classes}>
      <Typography type={ElementType.h4}>Run Plan</Typography>
      {fixtureIds && fixtureIds.length > 0 && (
        <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={fixtureIds}>
          {fixtureIds.map((fixtureId: any) => (
            <AccordionItem key={fixtureId} uuid={fixtureId}>
              <div className={"accordion-header-add-button-container"}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className={"accordion-header-expand-button-container"}>
                      <Icon
                        className="accordion-button-open"
                        color={styleVariables.color_gray}
                        name="fa fa-caret-down"
                        size={20}
                      />

                      <Icon
                        className="accordion-button-close"
                        color={styleVariables.color_gray}
                        name="fa fa-caret-right"
                        size={20}
                      />
                    </div>

                    <Typography type={ElementType.h5}>
                      {fixtures.find((f) => f.sku === fixtureId)?.materialName}
                    </Typography>
                  </AccordionItemButton>
                </AccordionItemHeading>
                {onEditPlanClick && (
                  <Button
                    onClick={() => onEditPlanClick(fixtureId)}
                    themeType={"secondary"}
                    size={"small"}
                    iconLeftName="fal fa-edit"
                  >
                    Edit Run Plan
                  </Button>
                )}
              </div>
              <AccordionItemPanel>
                <SessionRowHeader />
                {runOrder &&
                  machineRuns
                    .filter((item: IMachineRun) => item.fixtureId === fixtureId)
                    .sort(function (a, b) {
                      const map = runOrder.map((r) => r.runId);
                      const firstIndex = map.indexOf(a.runId!);
                      const secondIndex = map.indexOf(b.runId!);
                      return firstIndex === secondIndex ? 0 : firstIndex > secondIndex ? 1 : -1;
                    })
                    .map((item: IMachineRun) =>
                      item.runStatus === SessionRowStatus.Running ? (
                        <SessionRowInProgress
                          key={item.runId}
                          onClickEdit={editItem !== undefined ? () => editItem(item.runId) : undefined}
                          onClickStop={stopItem !== undefined ? () => stopItem(item) : undefined}
                          runName={item.runName || ""}
                          runStartTime={item.startTime || ""}
                          duration={duration}
                          material={materials && materials.find((m) => m.sku === item.materialId)!}
                          program={item.programName || ""}
                          parameters={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.parameters.find((j) => j.commitId === item.parameterCommitId)?.title
                              : ""
                          }
                          parametersId={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.parameters.find((j) => j.commitId === item.parameterCommitId)?.versionNumber
                              : undefined
                          }
                          jobs={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.jobs.find((j) => j.commitId === item.jobCommitId)?.title
                              : ""
                          }
                          jobsId={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.jobs.find((j) => j.commitId === item.jobCommitId)?.versionNumber
                              : undefined
                          }
                          snapshots={uploads.filter((u) => u.runId === item.runId)}
                          onClickScreenshot={onClickScreenshot}
                          onClickUploadMeasurements={onClickUploadMeasurements}
                          onClickDownloadProgram={onClickDownloadProgram}
                          ncCode={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.ncPrograms.find((j) => j.commitId === item.ncProgramCommitId)?.title
                              : ""
                          }
                          ncCodeId={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.ncPrograms.find((j) => j.commitId === item.ncProgramCommitId)?.versionNumber
                              : undefined
                          }
                          notes={item.userNotes || ""}
                          onTextareaChange={(value: string) =>
                            onTextareaChange(item && item.runId ? item.runId : "", value)
                          }
                        />
                      ) : (
                        <SessionRow
                          key={item.runId}
                          status={item.runStatus as SessionRowStatus}
                          backgroundImageUrl={""}
                          runName={item.runName || ""}
                          onClickUploadMeasurements={onClickUploadMeasurements}
                          onClickDownloadProgram={onClickDownloadProgram}
                          duration={moment.utc(moment(item.endTime).diff(moment(item.startTime))).format("HH:mm:ss")}
                          material={materials && materials.find((m) => m.sku === item.materialId)!}
                          program={item.programName || ""}
                          parameters={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.parameters.find((j) => j.commitId === item.parameterCommitId)?.title
                              : ""
                          }
                          jobs={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.jobs.find((j) => j.commitId === item.jobCommitId)?.title
                              : ""
                          }
                          snapshots={uploads.filter((u) => u.runId === item.runId)}
                          ncCode={
                            item.programName
                              ? executionPackages
                                  .find((p) => p.packageName === item.programName)
                                  ?.ncPrograms.find((j) => j.commitId === item.ncProgramCommitId)?.title
                              : ""
                          }
                          onClickCopy={
                            duplicateItem !== undefined ? () => duplicateItem(fixtureId, item.runId) : undefined
                          }
                          onClickEdit={editItem !== undefined ? () => editItem(item.runId) : undefined}
                          onClickDelete={deleteItem !== undefined ? () => deleteItem(item) : undefined}
                          onClickStart={startItem !== undefined ? () => startItem(item) : undefined}
                          onJumpTo={isReplay && jumpTo !== undefined ? () => jumpTo(item.startTime) : undefined}
                          onClickScreenshot={onClickScreenshot}
                          notes={item.userNotes || ""}
                          onTextareaChange={(value: string) =>
                            onTextareaChange(item && item.runId ? item.runId : "", value)
                          }
                        />
                      )
                    )}
              </AccordionItemPanel>
              <SessionRowSummary
                status={getSummaryRowStatus(machineRuns.filter((item: IMachineRun) => item.fixtureId === fixtureId))}
                text={getSummaryRowText(machineRuns.filter((item: IMachineRun) => item.fixtureId === fixtureId))}
                totalRuns={machineRuns.filter((item: IMachineRun) => item.fixtureId === fixtureId).length}
                completedRuns={
                  machineRuns
                    .filter((item: IMachineRun) => item.fixtureId === fixtureId)
                    .filter((run) => run.runStatus === SessionRowStatus.Complete).length
                }
              />
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export { LiveSessionTable };
