import React from "react";
import { notificationContext } from "../context/Notification";

function useNotification() {
  const notification = React.useContext(notificationContext);

  return notification.floatingNotification;
}
export default useNotification;

function useGlobalNotification() {
  const notification = React.useContext(notificationContext);

  return notification.globalNotification;
}
export { useGlobalNotification };
