import React, { FC, MouseEvent, ReactElement, useState } from "react";
import {
  IArcadiaProject,
  INewProjectData,
  INewProjectDataFeaturesData,
  INewProjectDataPart,
  IProcessParameter,
  ISteps,
  ITemplate,
} from "@types";
import { SecondNavigationBottom } from "@namespace/components";
import styleVariables from "@styles/export.scss";
import { v4 as uuidv4 } from "uuid";
import { ElementType, Typography } from "../Common/Typography";
import { Icon } from "../Icon";
import { Modal } from "../Modal";
import { NameProject } from "./NameProject";
import { PartProject } from "./PartProject";
import { TemplateProject } from "./TemplateProject";
import { FeatureProject } from "./FeatureProject";
import { ClosePopup } from "../ClosePopup";

const ModalNewProject: FC<{
  className?: string;
  materialCategories: string[];
  processes: IProcessParameter[];
  projectNames: string[];
  projectTemplates: IArcadiaProject[];
  onCloseModal?: (event?: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onFinish: (formData: INewProjectData) => void;
}> = ({
  className = "",
  materialCategories,
  processes,
  projectNames,
  onCloseModal = () => {},
  onFinish,
  projectTemplates,
}): ReactElement => {
  const [step, setStep] = useState<number>(0);
  const [showClosePopup, setShowClosePopup] = useState<boolean>(false);
  const [formData, setFormData] = useState<INewProjectData>({
    projectName: "",
    part: [
      {
        cycleSelection: "sec",
        description: "",
        deliveryDate: null,
        desiredCycle: null,
        drawing: [],
        identifier: uuidv4(),
        material: null,
        name: "",
        number: null,
      },
    ],
    sessionsPlanned: 5,
    feature: new Map<string, INewProjectDataFeaturesData[]>(),
  });

  const steps: ISteps[] = [
    {
      stepNumber: 1,
      title: "Part",
    },
    {
      stepNumber: 2,
      title: "Features",
    },
    {
      stepNumber: 3,
      title: "Template",
    },
  ];

  const onClickAfterPart = (
    values: any | INewProjectDataPart[] | Map<INewProjectDataPart, INewProjectDataFeaturesData[]> | ITemplate
  ) => {
    const newFormData = Object.assign({}, formData);
    if ((values as INewProjectDataPart[]).filter !== undefined) {
      newFormData.part = [...values];
    } else if ((values as Map<INewProjectDataPart, INewProjectDataFeaturesData[]>).has !== undefined) {
      newFormData.feature = values;
    } else if ((values as ITemplate).name !== undefined) {
      newFormData.template = values;
    }

    setFormData(newFormData);

    if (step === 3) {
      onFinish(newFormData);
    } else {
      setStep(step + 1);
    }
  };

  const afterProjectName = (newProjectName: string) => {
    if (newProjectName !== "") {
      const newFormData = Object.assign(formData, { projectName: newProjectName });
      setFormData(newFormData);
      setStep(step + 1);
    }
  };

  const onClickBack = () => {
    setStep(step - 1);
  };

  const onClickCloseModal = () => {
    setShowClosePopup(!showClosePopup);
  };

  const above = (
    <>
      {showClosePopup && (
        <ClosePopup
          description="Your information will not be saved"
          onClickClose={onCloseModal}
          onClickStay={onClickCloseModal}
          title="Discard new project?"
        />
      )}
      {step === 0 ? (
        <Typography className="overlay-modal-title" type={ElementType.h1}>
          Name your project
        </Typography>
      ) : (
        <>
          <Typography className="overlay-modal-title" type={ElementType.h1}>
            Create New Project
          </Typography>
          <SecondNavigationBottom
            activeStep={step}
            className="modal-new-project-navigation"
            steps={steps}
            setCurrentStep={setStep}
          />
        </>
      )}
    </>
  );

  return (
    <>
      <div className="modal-new-project-close">
        <button className="buttonAsLink" onClick={onClickCloseModal}>
          <Icon name="fas fa-times" color={styleVariables.color_white} />
        </button>
      </div>
      <Modal
        above={above}
        className={`modal-new-project ${className}`}
        darkOverlay
        height={step === 3 ? 751 : "80vh"}
        modalInnerContainerClassName={step < 3 ? "modal-new-project-container" : ""}
        width={step === 3 ? 1340 : 650}
      >
        {step === 0 && (
          <NameProject
            afterProjectName={afterProjectName}
            projectNames={projectNames}
            defaultProjectName={formData.projectName}
          />
        )}
        {step === 1 && (
          <PartProject
            materialCategories={materialCategories}
            formData={formData}
            onClickAfterPart={onClickAfterPart}
            onClickBack={onClickBack}
          />
        )}
        {step === 2 && (
          <FeatureProject
            formData={formData}
            processes={processes}
            onClickAfterPart={onClickAfterPart}
            onClickBack={onClickBack}
          />
        )}
        {step === 3 && (
          <TemplateProject
            projectTemplates={projectTemplates}
            formData={formData}
            setFormData={setFormData}
            onClickAfterPart={onClickAfterPart}
          />
        )}
      </Modal>
    </>
  );
};

export { ModalNewProject };
