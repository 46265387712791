import React, { FC, ReactElement } from "react";
import uniqid from "uniqid";
import { useEffect } from "react";
import moment from "moment";

import {
  Typography,
  FormSection,
  DatePicker,
  Input,
  SelectionItem,
  SlideToggleContent,
  Checkbox,
  DropdownMenu,
} from "@namespace/components";
import { ElementType } from "@namespace/components/src/components/Common/Typography";
import { IArcadiaMachineSchedule, ICellStatus, IPrepareSessionDataSchedule } from "@types";
import { useState } from "react";

const ScheduleSession: FC<{
  values: IPrepareSessionDataSchedule;
  machines: ICellStatus[];
  scheduledSessions: IArcadiaMachineSchedule[];
  setValues: (values: IPrepareSessionDataSchedule) => void;
  customerId: string;
}> = ({ values, setValues, machines, scheduledSessions, customerId }): ReactElement => {
  const [filteredMachines, setFilteredMachines] = useState<ICellStatus[]>([]);

  const toggleCheck = () => {
    updateValues({ daysFlexibility: !values.daysFlexibility });
  };

  useEffect(() => {
    setFilteredMachines(machines);
  }, [machines]);

  useEffect(() => {
    if (values && values.machine) {
      const resultDates: string[] = [];
      const scheduled = scheduledSessions.find((s) => s.cellId === values.machine?.cellId);
      if (scheduled) {
        const dates = scheduled.dates;
        dates.forEach((d) => {
          if (resultDates.indexOf(d) < 0) {
            resultDates.push(d);
          }
        });
        setExcludedDates(resultDates.map((d) => new Date(d)));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.machine]);

  //TODO - use with the filter button
  // useEffect(() => {
  //   if (!values || (values && !values.machine)) {
  //     const resultDates: string[] = [];
  //     scheduledSessions.forEach((s) => {
  //       const dates = s.dates;
  //       dates.forEach((d) => {
  //         if (resultDates.indexOf(d) < 0) {
  //           resultDates.push(d);
  //         }
  //       });
  //     });
  //     setExcludedDates(resultDates.map((d) => new Date(d)));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [scheduledSessions]);

  useEffect(() => {
    const notAvailableCellIds: string[] = [];
    scheduledSessions.forEach((s) => {
      const dates = s.dates;
      dates.forEach((d) => {
        if (moment(d).isSame(moment(values.sessionDate), "day")) {
          notAvailableCellIds.push(s.cellId);
        }
      });
    });
    const filtered = machines.filter((m) => notAvailableCellIds.indexOf(m.cellId) === -1);
    setFilteredMachines(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.sessionDate]);

  const [excludedDates, setExcludedDates] = useState<Date[]>([]);

  const updateValues = (params: any) => {
    const valuesCopy = Object.assign({}, values, params);
    setValues(valuesCopy);
  };

  return (
    <form>
      <FormSection
        secondary={false}
        sectionTitle="Select a date and machine for this session"
        sectionSubTitle="Select a full business day for your machine session (8a CST - 6p CST)."
      >
        <Typography type={ElementType.label} className="form-section-lowmargin">
          Session Date
        </Typography>
        <div className="schedule-session-box">
          <DatePicker
            excludedDates={excludedDates}
            minDate={
              customerId === process.env.REACT_APP_MICROLUTION_APPS_ID
                ? moment().toDate()
                : moment().add(parseInt(process.env.REACT_APP_SESSION_ADVANCE_DAYS_NOTICE!), "day").toDate()
            }
            handleChange={(newDate: Date) => {
              const date = moment(newDate).hours(8).toDate();
              updateValues({ sessionDate: date });
            }}
            width="100%"
            customInput={
              <div className="schedule-datepicker-container">
                <Input
                  rightIconName="fas fa-calendar"
                  className="custom-input"
                  data-testid="datepicker-input"
                  value={values.sessionDate ? moment(values.sessionDate).format("MM/DD/YY hh:mm:ss A") : null}
                  onChange={() => {}}
                  width={450}
                />
                <DropdownMenu
                  buttons={machines.map((machine) => ({
                    name: machine.cellType!,
                    onClick: (ev: MouseEvent) => {
                      updateValues({ machine: machine });
                    },
                    selected: values.machine ? machine.cellId === values.machine.cellId : false,
                  }))}
                  icon={"far fa-ellipsis-h"}
                  withButton={true}
                  buttonSize="large"
                />
              </div>
            }
            value={values.sessionDate}
            open
            placeholderText="Select a date"
          />
          <div className="schedule-session-checkbox">
            <div
              className={`schedule-session-checkbox-inner ${
                !!values.sessionDate ? "schedule-session-checkbox-inner-expanded" : ""
              }`}
            >
              <Checkbox checked={values.daysFlexibility} onChange={toggleCheck} id="days-flexibility" />{" "}
              <Typography
                className="schedule-session-checkbox-label"
                type={ElementType.bodySmall}
                htmlFor="days-flexibility"
              >
                My date selection is flexible (+/- a few days)
              </Typography>
            </div>
          </div>
          <SlideToggleContent isVisible={!!values.sessionDate}>
            <div className="section-available-machines">
              <Typography type={ElementType.eyebrow} className="available-machines-text">
                Available machines
              </Typography>
              <div>
                <div className="section-available-machines-list">
                  {filteredMachines.map((machine) => {
                    const onCheckBoxClick = () => {
                      updateValues({ machine: machine });
                    };

                    return (
                      <SelectionItem
                        key={uniqid()}
                        checked={values.machine ? machine.cellId === values.machine.cellId : false}
                        imageUrl={machine.machiningStation?.imageURL ? machine.machiningStation?.imageURL : ""}
                        title={machine.cellType!}
                        description={machine.cellDescription!}
                        handleClick={onCheckBoxClick}
                        radio
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </SlideToggleContent>
        </div>
      </FormSection>
    </form>
  );
};

export default ScheduleSession;
export { ScheduleSession };
