import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState } from "react";
import { Tag } from "react-tag-autocomplete";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { Textarea } from "../Common/Textarea";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { FilePaths } from "../Folders";
import { OptionType, Select } from "../Common/Select";
import { Tags } from "../Tags";
import { IArcadiaPartDetails } from "@types";
import { getFolderName } from "../FileUploads";
import { FileUploader } from "../FileUploader";

const ModalUploadFile: FC<
  {
    className?: string;
    projectId: string;
    currentFolder?: string;
    onCloseModal: () => void;
    onDone: (newFile: FormData) => void;
    parts?: IArcadiaPartDetails[];
    initialFile?: File;
    showAdvanced?: boolean;
    machineRunId?: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  onCloseModal,
  onDone,
  parts,
  projectId,
  currentFolder,
  initialFile,
  showAdvanced = true,
  machineRunId,
}): ReactElement => {
  const [myFiles, setMyFiles] = useState<File[]>(initialFile ? [initialFile] : []);
  const [name, setName] = useState<string>(initialFile ? initialFile.name : "");
  const [userPath, setUserPath] = useState<string>(currentFolder || "");
  const [partId, setPartId] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");

  const partOptions: OptionType[] =
    parts && parts.length > 0 ? parts.map((p) => ({ label: p.partName!, value: p.partId! })) : [];

  const folderOptions: OptionType[] = Object.values(FilePaths).map((fp) => ({
    label: getFolderName(fp),
    value: fp,
  }));

  const handleNameChange = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setName(target.value);
  };

  const handleFolderChange = (folder: OptionType) => {
    setUserPath(folder.value);
  };

  const handlePartChange = (part: OptionType) => {
    setPartId(part.value);
  };

  const handleAddTag = (tag: Tag) => {
    const newTags = tags || [];
    setTags([...newTags, tag.name]);
  };

  const handleDeleteTag = (i: number) => {
    const updated = tags!.slice(0);
    updated.splice(i, 1);
    setTags(updated);
  };

  const handleDescriptionChange = (e: FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setDescription(target.value);
  };
  const handleDoneClick = async () => {
    if (myFiles && myFiles.length > 0) {
      const file = myFiles[0];
      const blob = new Blob([file], { type: file.type });
      const fd = new FormData();
      fd.append("formData", blob, file.name);
      fd.append("projectId", projectId);
      if (showAdvanced) {
        fd.append("fileName", name);
        fd.append("description", description);
        fd.append("partId", partId);
        fd.append("userPath", userPath || "/");
        fd.append("tags", JSON.stringify(tags));
      } else {
        if (machineRunId) {
          fd.append("runId", machineRunId);
        }
      }

      onDone(fd);
    }
  };

  return (
    <Modal className={`modal-upload-file ${className}`} height={"auto"} width={598} onCloseModal={onCloseModal}>
      <Typography className="modal-upload-file-title" type={ElementType.h4}>
        Upload File
      </Typography>
      <Typography className="modal-upload-file-subtitle" type={ElementType.body} color={Color.Secondary}>
        Guiding message...
      </Typography>
      <Typography type={ElementType.label}>Upload File*</Typography>
      <FileUploader files={myFiles} setFiles={setMyFiles} singleFile={true} />
      <Input
        label={"File Name*"}
        onChange={handleNameChange}
        height={40}
        placeholder={"File Name"}
        value={name}
        width={"100%"}
      />

      {showAdvanced && (
        <>
          <Select
            label="Part assignment"
            // @ts-ignore
            onChange={(selected) => handlePartChange(selected)}
            options={partOptions}
            placeholder={"Select Part"}
            value={partId ? partOptions.find((p) => p.value === partId) : null}
            width={"100%"}
          />
          <Select
            label="Folder"
            // @ts-ignore
            onChange={(selected) => handleFolderChange(selected)}
            options={folderOptions}
            placeholder={"Select Folder"}
            value={userPath ? folderOptions.find((p) => p.value === userPath) : null}
            width={"100%"}
          />

          <Tags
            label="Tags"
            suggestions={[
              { id: 1, name: "Best Result" },
              { id: 2, name: "Review Later" },
            ]}
            tags={tags ? tags.map((tag, index) => ({ id: index, name: tag! })) : []}
            onDelete={handleDeleteTag}
            onAddition={handleAddTag}
          />
          <Textarea
            label="File description"
            height={"100%"}
            width={"100%"}
            placeholder="Enter description"
            value={description}
            onChange={handleDescriptionChange}
          />
        </>
      )}

      <div className="modal-upload-file-buttons">
        <Button size="large" themeType="secondary" onClick={onCloseModal}>
          Cancel
        </Button>
        <Button size="large" themeType="clear" disabled={myFiles === null || !name} onClick={handleDoneClick}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export { ModalUploadFile };
