import React from "react";
import { default as cx } from "classnames";
import uniqid from "uniqid";
import { IArcadiaFileUpload, IArcadiaMaterial } from "@types";
import { Typography, ElementType, Color } from "../Common/Typography";
import { Button } from "../Button";
import { Card } from "../Card";
import { ModalAddMaterial } from "../ModalAddMaterial";
import { ModalViewMaterial } from "../ModalViewMaterial";

const plus = require("./plus2.svg") as string;
const defaultMaterial = require("../../images/defaultMaterial.svg") as string;

const EquipmentDrawer: React.FC<
  {
    className?: string;
    fixtures: IArcadiaMaterial[];
    materials: IArcadiaMaterial[];
    materialTypes: string[];
    unitsOfMeasure: string[];
    createMaterial: (newMaterial: IArcadiaMaterial, files?: File[]) => void;
    updateMaterial: (originalMaterial: IArcadiaMaterial, newMaterial: IArcadiaMaterial, files?: File[]) => void;
    deleteMaterial: (materialSku: string) => void;
    uploadAttachment: (materialSku: string, isBoost: boolean, formData: FormData) => Promise<IArcadiaFileUpload>;
    transferMaterial: (materialSku: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  fixtures = [],
  materials = [],
  materialTypes = [],
  unitsOfMeasure = [],
  createMaterial,
  updateMaterial,
  deleteMaterial,
  uploadAttachment,
  transferMaterial,
}): React.ReactElement => {
  const [addFixtureModal, setAddFixtureModal] = React.useState<boolean>(false);
  const [addMaterialModal, setAddMaterialModal] = React.useState<boolean>(false);
  const [viewFixtureModal, setViewFixtureModal] = React.useState<boolean>(false);
  const [viewMaterialModal, setViewMaterialModal] = React.useState<boolean>(false);
  const [materialForUpdating, setMaterialForUpdating] = React.useState<IArcadiaMaterial | null>(null);
  const classes = cx(
    {
      "equipment-drawer": true,
    },
    className
  );

  const onAddFixture = () => {
    setAddFixtureModal(true);
    setMaterialForUpdating(null);
  };

  const onAddMaterial = () => {
    setAddMaterialModal(true);
    setMaterialForUpdating(null);
  };

  const closeFixtureModal = () => {
    setViewFixtureModal(false);
  };

  const closeMaterialModal = () => {
    setViewMaterialModal(false);
  };

  const onDeleteFixture = (fixtureSku: string) => {
    deleteMaterial(fixtureSku);
    closeFixtureModal();
    closeMaterialModal();
  };

  const onCreateMaterial = async (material: IArcadiaMaterial, files?: File[]) => {
    if (material.sku && materialForUpdating) {
      await updateMaterial(materialForUpdating, material, files);
    } else {
      await createMaterial(material, files);
    }
    setAddFixtureModal(false);
    setAddMaterialModal(false);
    setMaterialForUpdating(null);
  };

  const onEditFixture = () => {
    setViewFixtureModal(false);
    setAddFixtureModal(true);
  };

  const onEditMaterial = () => {
    setViewMaterialModal(false);
    setAddMaterialModal(true);
  };

  const onAttachmentUpload = async (formData: FormData): Promise<IArcadiaFileUpload> => {
    let result: IArcadiaFileUpload = {};
    if (materialForUpdating) {
      const isBoost = !materialForUpdating?.isCustomerMaterial;
      result = await uploadAttachment(materialForUpdating?.sku!, isBoost, formData);
    }
    return result;
  };

  return (
    <>
      {addFixtureModal && (
        <ModalAddMaterial
          isFixture={true}
          materials={materials}
          initialMaterial={materialForUpdating || undefined}
          materialTypes={materialTypes}
          onCloseModal={() => setAddFixtureModal(false)}
          onDone={onCreateMaterial}
          unitsOfMeasure={unitsOfMeasure}
        />
      )}
      {addMaterialModal && (
        <ModalAddMaterial
          isFixture={false}
          materials={fixtures}
          initialMaterial={materialForUpdating || undefined}
          materialTypes={materialTypes}
          onCloseModal={() => setAddMaterialModal(false)}
          onDone={onCreateMaterial}
          unitsOfMeasure={unitsOfMeasure}
        />
      )}
      {viewFixtureModal && materialForUpdating && (
        <ModalViewMaterial
          onCloseModal={closeFixtureModal}
          onDeleteMaterial={onDeleteFixture}
          onEditMaterial={
            !materialForUpdating.isCustomerMaterial ? () => transferMaterial(materialForUpdating.sku!) : onEditFixture
          }
          onAttachmentUpload={onAttachmentUpload}
          material={materialForUpdating}
          materials={materials}
        />
      )}
      {viewMaterialModal && materialForUpdating && (
        <ModalViewMaterial
          onCloseModal={closeMaterialModal}
          onDeleteMaterial={onDeleteFixture}
          onEditMaterial={
            !materialForUpdating.isCustomerMaterial ? () => transferMaterial(materialForUpdating.sku!) : onEditMaterial
          }
          onAttachmentUpload={onAttachmentUpload}
          material={materialForUpdating}
          materials={fixtures}
        />
      )}
      <section className={classes}>
        <div className="equipment-drawer-title">
          <Typography type={ElementType.h4}>My Fixtures</Typography>
          <Button themeType={"secondary"} size={"medium"} onClick={onAddFixture} iconLeftName={"fal fa-plus"}>
            Add Fixture
          </Button>
        </div>
        <div className="equipment-drawer-fixtures">
          {fixtures && fixtures.length > 0 ? (
            fixtures.map((fixture) => {
              const onCardClick = () => {
                setMaterialForUpdating(fixture);
                setViewFixtureModal(true);
              };

              return (
                <Card
                  className="equipment-drawer-fixtures-item"
                  key={uniqid()}
                  height={"auto"}
                  hover={true}
                  width={null}
                  onClick={onCardClick}
                >
                  <img
                    className="equipment-drawer-fixtures-item-image"
                    src={fixture.imageUrls && fixture.imageUrls.length > 0 ? fixture.imageUrls[0] : defaultMaterial}
                    alt={fixture.materialName}
                  />
                  <div className="equipment-drawer-fixtures-item-data">
                    {fixture.isCustomerMaterial && (
                      <Typography type={ElementType.bodySmall} color={Color.Orange}>
                        {fixture.isCustomized ? " Customized" : " Added by you"}
                      </Typography>
                    )}
                    <Typography type={ElementType.h6} className="equipment-drawer-fixtures-item-data-text">
                      {fixture.materialName}
                    </Typography>
                  </div>
                </Card>
              );
            })
          ) : (
            <Typography color={Color.Secondary}>There are no fixtures in your equipment drawer.</Typography>
          )}
          <div
            className="equipment-drawer-fixtures-item"
            tabIndex={0}
            role="button"
            onKeyDown={onAddFixture}
            onClick={onAddFixture}
          >
            <img className="equipment-drawer-fixtures-item-add" alt="add equipment" src={plus} />
          </div>
        </div>

        <div className="equipment-drawer-title equipment-drawer-title-materials">
          <Typography type={ElementType.h4}>My Materials</Typography>
          <Button themeType={"secondary"} size={"medium"} onClick={onAddMaterial} iconLeftName={"fal fa-plus"}>
            Add Material
          </Button>
        </div>
        <div className="equipment-drawer-fixtures">
          {materials && materials.length > 0 ? (
            materials.map((material) => {
              const onCardClick = () => {
                setMaterialForUpdating(material);
                setViewMaterialModal(true);
              };
              return (
                <Card
                  className="equipment-drawer-fixtures-item"
                  key={uniqid()}
                  height={null}
                  hover={true}
                  width={null}
                  onClick={onCardClick}
                >
                  <img
                    className="equipment-drawer-fixtures-item-image"
                    src={
                      material.imageUrls && material.imageUrls.length > 0
                        ? material.imageUrls[0]
                        : "https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80"
                    }
                    alt={material.materialName}
                  />
                  <div className="equipment-drawer-fixtures-item-data">
                    {material.isCustomerMaterial && (
                      <Typography type={ElementType.bodySmall} color={Color.Orange}>
                        {material.isCustomized ? " Customized" : " Added by you"}
                      </Typography>
                    )}
                    <Typography type={ElementType.h6} className="equipment-drawer-fixtures-item-data-text">
                      {material.materialName}
                    </Typography>
                    {/* <Typography
                    type={ElementType.bodySmall}
                    color={Color.Secondary}
                    className="equipment-drawer-fixtures-item-data-text"
                  >
                    {material.materialName} | {material.materialThickness}mm
                  </Typography> */}
                  </div>
                </Card>
              );
            })
          ) : (
            <Typography color={Color.Secondary}>There are no materials in your equipment drawer.</Typography>
          )}
          <div
            className="equipment-drawer-fixtures-item"
            tabIndex={0}
            role="button"
            onKeyDown={onAddMaterial}
            onClick={onAddMaterial}
          >
            <img className="equipment-drawer-fixtures-item-add" alt="add equipment" src={plus} />
          </div>
        </div>
      </section>
    </>
  );
};

export { EquipmentDrawer };
