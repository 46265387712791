import React, { FC, ReactElement } from "react";
import { AuthProvider } from "./Auth";
import { UserProvider } from "./User";
import NotificationProvider from "./Notification";

const AppProviders: FC<{
  children: JSX.Element;
}> = ({ children }): ReactElement => (
  <AuthProvider data={{}}>
    <UserProvider data={{}}>
      <NotificationProvider>{children}</NotificationProvider>
    </UserProvider>
  </AuthProvider>
);

export default AppProviders;
