import * as React from "react";
import { default as cx } from "classnames";

export enum ElementType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  subtitle = "subtitle",
  display = "display",
  body = "body",
  bodySmall = "bodySmall",
  textLink = "textLink",
  inline = "inline",
  eyebrow = "eyebrow",
  label = "label",
  tableHeader = "tableHeader",
}

export enum FontWeight {
  Normal = "normal",
  Bold = "bold",
  Demi = "demi",
}

export enum Color {
  Initial = "initial",
  Inherit = "inherit",
  Primary = "primary",
  Secondary = "secondary",
  OffWhite = "off-white",
  Orange = "orange",
  Blue = "blue",
  Error = "error",
  Link = "link",
  Black = "black",
}

const Typography: React.FC<
  {
    className?: string;
    type?: ElementType;
    fontSize?: number;
    color?: Color;
    opacity?: number;
    fontWeight?: FontWeight;
    htmlFor?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  type = ElementType.body,
  fontWeight,
  fontSize,
  color,
  htmlFor,
  opacity = 1,
  ...other
}): React.ReactElement => {
  const classes = cx(
    {
      typography: true,
      [`typography-${type}`]: true,
      [`color-${color}`]: color ? true : false,
      [`font-weight-${fontWeight}`]: fontWeight ? true : false,
      pointer: type === ElementType.label,
    },
    className
  );

  const getTag = (type: ElementType) => {
    let result = "p";
    switch (type) {
      case ElementType.h1:
      case ElementType.h2:
      case ElementType.h3:
      case ElementType.h4:
      case ElementType.h5:
      case ElementType.h6:
        result = type;
        break;
      case ElementType.subtitle:
      case ElementType.display:
      case ElementType.body:
      case ElementType.bodySmall:
      case ElementType.tableHeader:
        result = "div";
        break;
      case ElementType.eyebrow:
      case ElementType.textLink:
      case ElementType.inline:
        result = "span";
        break;
      case ElementType.label:
        result = "label";
        break;
      default:
        break;
    }
    return result;
  };
  const style: any = {};
  style.fontSize = fontSize;
  if (opacity) {
    style.opacity = opacity;
  }
  return React.createElement(getTag(type), { className: classes, style, htmlFor, ...other }, children);
};

export { Typography };
