import React, { FC, ReactElement, useEffect, useState } from "react";

import { Typography, Card, EditRunPlanTable, ModalCleaningAndMeasurement } from "@namespace/components";
import { Color, ElementType } from "@namespace/components/src/components/Common/Typography";
import { IArcadiaMaterial, IMachineRun } from "@types";
import useExecutionPackages from "../../hooks/API/useExecutionPackages";

const PlanningSession: FC<{
  projectId: string;
  sessionId: string;
  runs: Map<string, IMachineRun[]>;
  setRuns: (values: Map<string, IMachineRun[]>) => void;
  setCurrentStep: (step: number) => void;
  workholdings: Map<string, [IArcadiaMaterial, string[]]>;
  materials: IArcadiaMaterial[];
  fixtures: IArcadiaMaterial[];
  disabled: boolean;
}> = ({
  projectId,
  sessionId,
  runs,
  setRuns,
  workholdings,
  materials,
  fixtures,
  setCurrentStep,
  disabled,
}): ReactElement => {
  const { executionPackages, fetchExecutionPackages } = useExecutionPackages();
  const workHoldingsValues = Array.from(workholdings.values());

  const [runForEditing, setRunForEditing] = useState<IMachineRun | null>(null);
  const [showModalCleaning, setShowModalCleaning] = useState(false);

  useEffect(() => {
    if (projectId) {
      fetchExecutionPackages(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const updateMachineRun = (updatedRun: IMachineRun) => {
    const filteredRuns = runs.get(updatedRun.fixtureId!);
    if (filteredRuns) {
      const index = filteredRuns.map((f) => f.runId).indexOf(updatedRun.runId);
      filteredRuns.splice(index, 1, updatedRun);
      const updatedMap = new Map(runs);
      updatedMap.set(updatedRun.fixtureId!, filteredRuns);
      setRuns(updatedMap);
    }
    setRunForEditing(null);
    setShowModalCleaning(false);
  };

  const editItem = (run: IMachineRun) => {
    setRunForEditing(run);
    setShowModalCleaning(true);
  };
  return (
    <div style={disabled ? { pointerEvents: "none", opacity: "0.7" } : {}}>
      {showModalCleaning && (
        <ModalCleaningAndMeasurement
          run={runForEditing!}
          onCancelClick={() => setShowModalCleaning(false)}
          onDoneClick={updateMachineRun}
          fixtureName={fixtures.find((f) => f.sku === runForEditing?.fixtureId)?.materialName || ""}
        />
      )}
      <Card width={1700} height={"auto"} hover={false} className="planning-session">
        <Typography type={ElementType.subtitle}>Plan your runs (optional)</Typography>
        <Typography type={ElementType.body} color={Color.Secondary} className="planning-session-subtitle">
          Design your sessions by detailing the materials, programs, parameters and more for your selected machine runs.
        </Typography>
        {workHoldingsValues.length > 0 ? (
          workHoldingsValues.map(([workHolding, materialIds]) => {
            const filteredRuns = runs.get(workHolding.sku!) || [];
            return (
              <EditRunPlanTable
                fixtures={fixtures}
                runs={filteredRuns}
                executionPackages={executionPackages}
                materials={materials.filter((m) => materialIds.indexOf(m.sku!) >= 0)}
                fixtureId={workHolding.sku!}
                sessionId={sessionId}
                scrollable={false}
                setIsUpdated={() => {}}
                setRuns={(newRuns) => {
                  const updatedMap = new Map(runs);
                  updatedMap.set(workHolding.sku!, newRuns);
                  setRuns(updatedMap);
                }}
                onClickEdit={editItem}
              />
            );
          })
        ) : (
          <div className="empty-state-run-plan">
            <div role="button" tabIndex={0} onClick={() => setCurrentStep(3)} onKeyDown={() => {}}>
              <Typography type={ElementType.subtitle}>
                <Typography className="underline" type={ElementType.inline}>
                  Select your equipment
                </Typography>{" "}
                <Typography type={ElementType.inline}>to view the planning options</Typography>
              </Typography>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default PlanningSession;
export { PlanningSession };
