import React, { FC, ReactElement, HTMLAttributes } from "react";
import { Card } from "../Card";
import CircleProgress from "../CircleProgress";
import moment from "moment";
import { Typography, Color, ElementType } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { formatSecondsToHHMMSS } from "../CameraStreams/helpers";

const SessionTimer: FC<
  {
    startDate: Date;
    endDate: Date;
    duration: number | null; //TODO this should be pushed from the backend via signalR
  } & HTMLAttributes<HTMLDivElement>
> = ({ startDate, endDate, duration }): ReactElement => {
  const newEndDate = moment.utc(moment(endDate));
  const totalSessionTime = moment.utc(newEndDate.diff(moment(startDate)));
  const timeRemaining = moment.utc(moment(newEndDate).diff(new Date()));
  const totalSessionTimeDuration = moment.duration(newEndDate.diff(moment(startDate))).asSeconds();
  const timeRemainingDuration = moment.duration(moment(newEndDate).diff(new Date())).asSeconds();
  const percentage = (timeRemainingDuration / totalSessionTimeDuration) * 100;

  return (
    <Card className="card-session-timer-outer-container" width={265} height={133} hover={false}>
      <div className="card-session-timer-column">
        <div className="card-session-timer-timers">
          <div className="card-session-timer-timers-info">
            <Typography
              className="card-session-timer-timers-subtitle"
              type={ElementType.bodySmall}
              color={Color.Secondary}
              data-testid="card-session-timer-column-total-run"
            >
              Total Session Time
            </Typography>
            <Typography className="card-session-timer-timers-time" type={ElementType.h6}>
              {moment(totalSessionTime).format("HH:mm:ss")}
            </Typography>
          </div>
        </div>
        <div className="card-session-timer-timers">
          <div className="card-session-timer-timers-info">
            <Typography
              className="card-session-timer-timers-subtitle"
              type={ElementType.bodySmall}
              color={Color.Secondary}
              data-testid="card-session-timer-column-cycle-time"
            >
              Current Run Time
            </Typography>
            <Typography className="card-session-timer-timers-time" type={ElementType.h6}>
              {duration ? formatSecondsToHHMMSS(duration) : ""}
            </Typography>
          </div>
        </div>
      </div>
      <CircleProgress
        className="card-session-timer-circle"
        percentage={percentage}
        strokeWidth={8}
        primaryColor={[styleVariables.color_gradient_orange_source, styleVariables.color_gradient_orange_target]}
        secondaryColor="#1E2222"
        width={120}
      >
        <Typography type={ElementType.h6}>
          {timeRemaining.format("H")}h {timeRemaining.format("m")}m
        </Typography>
        <Typography type={ElementType.bodySmall} color={Color.Secondary}>
          Remaining
        </Typography>
      </CircleProgress>
    </Card>
  );
};

export { SessionTimer };
