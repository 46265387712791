import * as React from "react";
import moment from "moment";

import { Thumbnail } from "../Thumbnail";
import { Card } from "../Card";
import { StatusIcon } from "../StatusIcon";
import { Status } from "../../constants/enums/Status";
import { Link } from "../Link";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { ModalConfirm } from "../ModalConfirm";
import { Icon } from "../Icon";

const CardToDo: React.FC<
  {
    backgroundImageUrl: string;
    backgroundColor?: string;
    clickHandler: Function;
    status: Status;
    text: string;
    imageTitle?: string;
    startTime: string;
    plannedDate: string;
    sessionId: string;
    projectId: string;
    isOperator?: boolean;
    onDeleteClick: () => void;
    onStartClick: () => void;
    // onStopClick: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  backgroundImageUrl = "",
  backgroundColor = styleVariables.color_white,
  clickHandler = () => {},
  status = Status.InProgress,
  text = "",
  imageTitle = "",
  startTime,
  plannedDate,
  sessionId,
  projectId,
  isOperator,
  onDeleteClick,
  onStartClick,
  // onStopClick,
}): React.ReactElement => {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const renderStatus = (status: Status, startTime: string, plannedDate: string) => {
    let statusText = "";
    switch (status) {
      case Status.Done:
        statusText = "completed";
        break;
      case Status.InProgress:
        statusText = `started ${moment(startTime).fromNow()}`;
        break;
      case Status.NotStarted:
        statusText = `scheduled for ${moment(plannedDate).format("MMM DD, hA")}`;
        break;
      case Status.InReview:
        statusText = "Pending Review";
        break;
      case Status.Draft:
        statusText = "Draft";
        break;
      default:
        break;
    }

    return (
      <div className="card-todo-status-container">
        <Typography type={ElementType.eyebrow} color={status === Status.InReview ? Color.Blue : Color.Secondary}>
          {status === Status.InProgress && (
            <Typography className="card-todo-live-label" type={ElementType.eyebrow} color={Color.Orange}>
              live
            </Typography>
          )}
          {statusText}
        </Typography>
        <StatusIcon status={status} />
      </div>
    );
  };

  const renderControls = (status: Status, plannedDate: string, sessionId: string, projectId: string) => {
    let linkText = "";
    let linkHref = "/";
    switch (status) {
      case Status.Done:
        linkText = "Replay Session";
        linkHref = `/sessionReplay/${projectId}/${sessionId}`;
        break;
      case Status.InProgress:
        linkText = "Go To Session";
        linkHref = `/livesession/${projectId}/${sessionId}`;
        break;
      case Status.NotStarted:
        //linkText = `Prepare by ${moment(plannedDate).add(-7, "days").format("MMM DD")}`;
        linkText = "Edit Session Prep";
        linkHref = `/prepare/${projectId}/${sessionId}`;
        break;
      case Status.InReview:
        linkText = "Edit Session Prep";
        linkHref = `/prepare/${projectId}/${sessionId}`;
        break;
      case Status.Draft:
        linkText = "Prepare";
        linkHref = `/prepare/${projectId}/${sessionId}`;
        break;
      default:
        break;
    }
    return (
      <>
        {(status === Status.NotStarted || status === Status.InReview) && isOperator && (
          // <Button
          //   className="start-button"
          //   size="xs"
          //   iconOnly={true}
          //   iconSize={14}
          //   iconName="fal fa-play-circle"
          //   themeType="simple"
          //   onClick={onStartClick}
          // />
          <div className="start-button" role="button" tabIndex={0} onClick={onStartClick} onKeyDown={() => {}}>
            <Typography type={ElementType.textLink}>
              <Icon name="far fa-play-circle" />
              Start
            </Typography>
          </div>
        )}
        {((status !== Status.InReview && isOperator) || !isOperator) && (
          <Link href={linkHref} title={linkText}>
            {linkText}
          </Link>
        )}
        {status === Status.Draft && (
          <div role="button" tabIndex={0} onClick={() => setShowConfirm(true)} onKeyDown={() => {}}>
            <Typography type={ElementType.textLink} color={Color.Error}>
              Delete
            </Typography>
          </div>
        )}
        {/* {status === Status.InProgress && (
          <Button
            className="stop-temp-button"
            size="xs"
            iconOnly={true}
            iconName="fa fa-stop"
            themeType="simple"
            onClick={onStopClick}
          />
        )} */}
      </>
    );
  };

  return (
    <Card className="card-todo-outer-container" width={407} height={124} hover={true}>
      <div className="card-todo-thumbnail-container">
        <Thumbnail
          title={imageTitle}
          round={false}
          width={76}
          height={76}
          borderRadius={4}
          filter={"drop-shadow(-2px -3px 6px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #1A1C1F)"}
          backgroundImageUrl={backgroundImageUrl}
          backgroundColor={backgroundColor}
        />
      </div>
      <div className="card-todo-info-container">
        {renderStatus(status, startTime, plannedDate)}
        <div className="card-todo-name-container">
          <Typography type={ElementType.h5}>{text}</Typography>
        </div>
        <div className="card-todo-controls-container">{renderControls(status, plannedDate, sessionId, projectId)}</div>
      </div>
      {showConfirm && (
        <ModalConfirm
          width={607}
          height={208}
          title="Discard Changes"
          text="Are you sure you want to cancel the session?"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => setShowConfirm(false)}
          onConfirm={onDeleteClick}
        />
      )}
    </Card>
  );
};

export { CardToDo };
