function isValidHexOrRGBColor(color: string): boolean {
  let result = false;
  result = /^#[0-9A-F]{6}$/i.test(color);
  if (!result) {
    result = /^#([0-9A-F]{3}){1,2}$/i.test(color);
  }
  if (!result) {
    result = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.test(color);
  }
  return result;
}

export { isValidHexOrRGBColor };
