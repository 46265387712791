import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { useDropzone } from "react-dropzone";

import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { IArcadiaFileUpload } from "@types";
import { Thumbnail } from "../Thumbnail";
import { Card } from "../Card";
import { Icon } from "../Icon";
import uniqid from "uniqid";
import { FilePaths, FolderNames } from "../Folders";
import { Button } from "../Button";
import { isRenderableImage } from "../../helpers/common";

export const getFolderName = (userPath: string) => {
  let result = "All Files";
  if (userPath) {
    // eslint-disable-next-line tsc/config
    switch (userPath) {
      case FilePaths.CAM_FILES:
        result = FolderNames[FilePaths.CAM_FILES];
        break;
      case FilePaths.MEASUREMENTS:
        result = FolderNames[FilePaths.MEASUREMENTS];
        break;
      case FilePaths.PART_DRAWINGS:
        result = FolderNames[FilePaths.PART_DRAWINGS];
        break;
      case FilePaths.PRESENTATION_FILES:
        result = FolderNames[FilePaths.PRESENTATION_FILES];
        break;
      case FilePaths.SESSION_SNAPSHOTS:
        result = FolderNames[FilePaths.SESSION_SNAPSHOTS];
        break;
      default:
        break;
    }
    return result;
  }

  return result;
};

const FileUploads: React.FC<
  {
    className?: string;
    filteredFiles: IArcadiaFileUpload[];
    favorites: IArcadiaFileUpload[];
    selectedFolder: string;
    handleFileClick: (fileId: string) => void;
    handleFavoriteFileClick: (fileId: string) => void;
    handleUploadFileClick: (file?: File) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  filteredFiles = [],
  favorites = [],
  handleFileClick,
  handleFavoriteFileClick,
  handleUploadFileClick,
  selectedFolder,
}): React.ReactElement => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    handleUploadFileClick(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const activeStyle = {
    borderColor: styleVariables.color_white,
    borderStyle: "dashed",
    borderWidth: 1.5,
    borderRadius: 1,
  };

  const rejectStyle = {
    borderColor: styleVariables.color_red,
    borderStyle: "dashed",
    borderWidth: 1.5,
    borderRadius: 1,
  };

  const style = useMemo(
    () => ({
      ...(isDragActive || isDragAccept ? activeStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <main className={`file-uploads ${className}`}>
      <div className="file-uploads-top-row">
        <div className="file-uploads-top-row-title" {...getRootProps({ style })}>
          <input
            {...getInputProps()}
            onClick={(ev) => {
              ev.preventDefault();
            }}
          />
          <Typography type={ElementType.h4} data-testid="file-uploads-title">
            {getFolderName(selectedFolder)}
          </Typography>
        </div>
        <div className="file-uploads-top-row-controls">
          <Button iconRightName="fa fa-chevron-down" themeType="simple">
            Part
          </Button>

          <Button iconRightName="fa fa-chevron-down" themeType="simple">
            Tag
          </Button>

          <Button iconLeftName="fa fa-plus" themeType="secondary" onClick={() => handleUploadFileClick()}>
            Upload File
          </Button>
        </div>
      </div>

      <>
        <Typography className="file-uploads-subtitle" type={ElementType.h6} color={Color.Secondary}>
          Starred
        </Typography>
        <div className="file-uploads-starred" data-testid="file-uploads-starred">
          {favorites && favorites.length > 0 ? (
            favorites.map((starredFile) => (
              <div className="file-uploads-starred-file" key={uniqid()}>
                <Card
                  height={85}
                  hover={true}
                  width={113}
                  onClick={() => {
                    handleFileClick(starredFile.fileUploadId!);
                  }}
                >
                  <Thumbnail
                    borderRadius={8}
                    round={false}
                    title={starredFile.fileName || ""}
                    backgroundImageUrl={starredFile.fileUri || ""}
                    height={75}
                    width={103}
                  />
                </Card>
                <div className="file-uploads-starred-file-name">
                  {/* <Icon name="fa fa-star" size={10} color={styleVariables.color_orange} />{" "} */}
                  <Button
                    className="file-uploads-starred-file-name-icon"
                    themeType="simple"
                    size="xs"
                    iconOnly={true}
                    iconSize={10}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      handleFavoriteFileClick(starredFile.fileUploadId!);
                    }}
                    iconName="fa fa-star"
                    iconColor={styleVariables.color_orange}
                  />
                  <Typography type={ElementType.inline}>
                    {starredFile.fileName!.length <= 30
                      ? starredFile.fileName
                      : `${starredFile.fileName?.substring(0, 27)}...`}
                  </Typography>
                </div>
              </div>
            ))
          ) : (
            <Typography color={Color.Secondary} opacity={0.65}>
              You haven&apos;t starred any files yet
            </Typography>
          )}
        </div>
      </>

      <>
        <Typography className="file-uploads-recent-subtitle" type={ElementType.h6} color={Color.Secondary}>
          Recent Uploads
        </Typography>
        {filteredFiles.length > 0 ? (
          <ul className="file-uploads-recent">
            {filteredFiles
              .sort((a, b) =>
                moment(b.uploadTimestamp) === moment(a.uploadTimestamp)
                  ? 0
                  : moment(b.uploadTimestamp) > moment(a.uploadTimestamp)
                  ? 1
                  : -1
              )
              .map((file) => (
                <li key={uniqid()} className="file-uploads-recent-file no-list">
                  <div
                    className="file-uploads-recent-file-container"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={() => {
                      handleFileClick(file.fileUploadId!);
                    }}
                  >
                    {isRenderableImage(file!.fileUri!.split(".").pop()?.toLowerCase() ?? "") ? (
                      <Thumbnail
                        className="file-uploads-recent-file-container-card"
                        width={50}
                        height={50}
                        borderRadius={8}
                        backgroundImageUrl={file.imageUri_Small!}
                        title={file.fileName!}
                      />
                    ) : (
                      <Card width={50} height={50} hover={false} className="file-uploads-recent-file-container-card">
                        <Icon
                          withContainer={true}
                          color={styleVariables.color_gray}
                          name="fal fa-file-alt"
                          width={29}
                          height={29}
                          size={20}
                        />
                      </Card>
                    )}
                    <div className="file-uploads-recent-file-container-data">
                      <div className="file-uploads-recent-file-container-data-row">
                        <Typography type={ElementType.h6}>{file.fileName}</Typography>
                        <Button
                          themeType="simple"
                          iconOnly={true}
                          iconSize={14}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            handleFavoriteFileClick(file.fileUploadId!);
                          }}
                          iconName="fa fa-star"
                          iconColor={
                            favorites.map((f) => f.fileUploadId).indexOf(file.fileUploadId) >= 0
                              ? styleVariables.color_orange
                              : styleVariables.color_layer_3
                          }
                        />
                      </div>
                      <Typography type={ElementType.h6} color={Color.Secondary}>
                        Uploaded {moment(file.uploadTimestamp).fromNow()} · {getFolderName(file.userPath!)}
                      </Typography>{" "}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <div className="file-uploads-recent-empty-state">
            <Typography color={Color.Secondary} opacity={0.65}>
              There are no files in this folder.
            </Typography>
          </div>
        )}
      </>
    </main>
  );
};

export { FileUploads };
