import * as React from "react";
import { Thumbnail } from "../Thumbnail";
import { UserStatus } from "../../constants/enums/UserStatus";
import styleVariables from "@styles/export.scss";
const defaultAvatar = require("./defaultAvatar.svg") as string;

const Avatar: React.FC<
  {
    backgroundImageUrl?: string;
    status: UserStatus;
    notificationsCount: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ backgroundImageUrl = "", status = UserStatus.Online, notificationsCount = 0 }): React.ReactElement => {
  return (
    <div className="avatar-outer-container">
      <Thumbnail
        className="avatar-picture-thumbnail"
        round={true}
        backgroundColor={styleVariables.color_black}
        filter={"drop-shadow(-2px -3px 4px rgba(255, 255, 255, 0.25)) drop-shadow(5px 5px 12px #000)"}
        width={56}
        height={56}
        backgroundImageUrl={backgroundImageUrl || defaultAvatar}
        title={name}
      />
      <div className={`avatar-notifications${notificationsCount === 0 ? " hidden" : ""}`}>{notificationsCount}</div>
      <div className={`avatar-status avatar-status-${status}`} />
    </div>
  );
};

export { Avatar };
