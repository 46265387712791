import React, { FC, FormEvent, MouseEvent, ReactElement, useState } from "react";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { ElementType, Typography } from "../Common/Typography";

const NameProject: FC<{
  afterProjectName: (newProjectName: string) => void;
  defaultProjectName: string;
  projectNames: string[];
}> = ({ afterProjectName, defaultProjectName, projectNames }): ReactElement => {
  const [projectName, setProjectName] = useState<string>(defaultProjectName);
  const updateNameProject = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setProjectName(target.value);
  };

  const isNameValid = (value: string) => {
    const regex = RegExp(/^[a-zA-Z_][a-zA-Z0-9_-]*[a-zA-Z0-9]$/);
    return regex.test(value) && value !== "master" && value.length <= 80;
  };

  const onClickAfterProjectName = (event: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    afterProjectName(projectName);
  };
  return (
    <div className="modal-new-project-name">
      <Typography className="modal-new-project-title" type={ElementType.subtitle}>
        What do you want to call this project?
      </Typography>
      <Typography className="modal-new-project-subtitle" type={ElementType.body}>
        Project Name
      </Typography>
      <Input autoFocus placeholder="Project Name" onChange={updateNameProject} width={"100%"} value={projectName} />
      <Button
        className="modal-new-project-name-button"
        disabled={
          projectName === "" || projectNames.indexOf(projectName.toLowerCase()) >= 0 || !isNameValid(projectName)
        }
        size="large"
        themeType="clear"
        // @ts-ignore
        onClick={onClickAfterProjectName}
      >
        Next
      </Button>
    </div>
  );
};

export { NameProject };
