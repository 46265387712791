import React, { MouseEvent } from "react";
import { Button } from "../Button";
import { Card } from "../Card";
import { Typography, ElementType, Color } from "../Common/Typography";
import { Modal } from "../Modal";

const ClosePopup: React.FC<
  {
    className?: string;
    description: string;
    onClickClose: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClickStay: (event: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    title: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className, description, onClickClose, onClickStay, title }): React.ReactElement => (
  <Modal width="100%">
    <Card className={`card-close-popup-container ${className}`} width={432} height={208} hover={false}>
      <Typography className="card-close-popup-container-title" type={ElementType.h4}>
        {title}
      </Typography>
      <Typography className="card-close-popup-container-subtitle" type={ElementType.bodySmall} color={Color.OffWhite}>
        {description}
      </Typography>
      <div className="card-close-popup-container-buttons">
        {/* @ts-ignore */}
        <Button onClick={onClickStay} themeType="secondary">
          Stay
        </Button>
        {/* @ts-ignore */}
        <Button onClick={onClickClose} themeType="clear">
          Close
        </Button>
      </div>
    </Card>
  </Modal>
);

export { ClosePopup };
