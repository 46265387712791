import React, { FC, ReactElement } from "react";
import { IArcadiaBalance } from "@types";
import { CardBalances, ElementType, Typography, Color } from "@namespace/components";

import "./accountBalanceTab.scss";

const AccountBalanceTab: FC<{ balances: IArcadiaBalance[] }> = ({ balances }): ReactElement => {
  return (
    <>
      <div className="account-balance-tab">
        <Typography className="account-balance-tab-subtitle" type={ElementType.subtitle}>
          Account Balance
        </Typography>
        <CardBalances width={500} balances={balances} />
        <Typography className="account-balance-tab-subtitle" type={ElementType.subtitle}>
          Add To Balance
        </Typography>
        <Typography color={Color.OffWhite} className="account-balance-tab-message">
          Please contact us by phone or email to add or modify your account balance
        </Typography>
        <Typography color={Color.OffWhite}>Phone: +1 (555) 555-555</Typography>
        <Typography color={Color.OffWhite}>Email: support@microlutionboost.com</Typography>
      </div>
    </>
  );
};

export default AccountBalanceTab;
