import { IArcadiaZoomMeeting } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useZoomMeeting = () => {
  const [isMeetingLoading, setIsLoading] = useState(false);
  const [isMeetingError, setIsError] = useState(false);

  const [meeting, setMeeting] = useState<IArcadiaZoomMeeting | null>(null);

  const fetchZoomMeeting = (projectId: string, sessionId: string) => {
    const fetchData = async () => {
      setIsLoading(true);
      ProjectsService.getZoomMeeting(projectId, sessionId)
        .then((response) => {
          const meeting = response.data as IArcadiaZoomMeeting;
          setMeeting(meeting);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return { meeting, isMeetingLoading, isMeetingError, fetchZoomMeeting };
};

export default useZoomMeeting;
