import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import Timeline, { DateHeader, TimelineHeaders } from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import styleVariables from "@styles/export.scss";
import { StatusIcon } from "../StatusIcon";
import { Status } from "../../constants/enums/Status";
import { Typography, ElementType } from "../Common/Typography";
import { Icon } from "../Icon";
import pubSub from "../../helpers/pubsub";
import { IArcadiaSubTask, IArcadiaTask2, ITimelineGroup, ITimelineItem } from "@types";

const latestSymbol = Symbol("latest");
const ps = pubSub();

export class ArcadiaTimeline extends Timeline {
  constructor(props: any) {
    super(props);

    const self = this;
    ps.subscribe("increaseZoom", () => {
      // @ts-ignore
      self.changeZoom(0.3);
    });
    ps.subscribe("decreaseZoom", () => {
      // @ts-ignore
      self.changeZoom(3);
    });
  }
}

const ProjectTimeline: FC<{
  className?: string;
  onDragEnd: (newTime: number, subTask?: IArcadiaSubTask) => void;
  receivedGroups: IArcadiaTask2[];
}> = ({ receivedGroups = [], onDragEnd, ...others }): ReactElement => {
  const [groupsTop, setGroupsTop] = useState(new Map<number, Map<number | typeof latestSymbol, number>>());
  const [zoomDifference, setZoomDifference] = useState<number>(0);
  const [zoomInitialDate, setZoomInitialDate] = useState<number>(0);
  const [zoomEndDate, setZoomEndDate] = useState<number>(0);
  const [subItems, setSubItems] = useState<Map<string, IArcadiaSubTask>>(new Map<string, IArcadiaSubTask>());

  useEffect(() => {
    ps.publish("decreaseZoom", null);
  }, []);

  const groups: ITimelineGroup[] = receivedGroups.map((group) => {
    return { title: group.taskHeader || "", id: group.taskId || 0, height: 250, stackItems: false, ...group };
  });

  const items: ITimelineItem[] = useMemo(() => {
    const newSubItems = new Map<string, IArcadiaSubTask>();
    const itemsResponse = receivedGroups.reduce((result, group) => {
      const taskSubItems: ITimelineItem[] = group.subTasks.map((subTask) => {
        const subItem = {
          id: subTask.subtaskId || 0,
          group: group.taskId || 0,
          title: subTask.subTaskName || "",
          start_time: moment(subTask.dueDate),
          end_time: moment(subTask.endDate),
          status: subTask.subTaskCheckbox ? Status.Done : Status.InProgress,
        };

        newSubItems.set(subTask.subtaskId || "0", subTask);
        return subItem;
      });
      return [...result, ...taskSubItems];
    }, [] as ITimelineItem[]);
    setSubItems(newSubItems);
    return itemsResponse;
  }, [receivedGroups]);

  const getGroupTop = (id: number, group: number) => {
    let groupMap = groupsTop.get(group);
    let saveNeeded = false;

    if (!groupMap) {
      groupMap = new Map<number | typeof latestSymbol, number>();
      groupMap.set(latestSymbol, 20);
      saveNeeded = true;
    }

    if (!groupMap.has(id)) {
      const newTop = (groupMap.get(latestSymbol) || 0) + (groupMap.size === 1 ? 5 : 50);
      groupMap.set(id, newTop);
      groupMap.set(latestSymbol, newTop);
      saveNeeded = true;
    }

    if (saveNeeded) {
      const newGroupsTop = new Map(groupsTop);
      newGroupsTop.set(group, groupMap);
      setGroupsTop(newGroupsTop);
    }

    return groupMap.get(id);
  };

  // @ts-ignore
  const itemRenderer = ({ item, timelineContext, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const { status } = item;
    const { dimensions } = itemContext || { dimensions: { top: 0, width: 50 } };
    const newTop = getGroupTop(item.id, item.group) + dimensions.top;

    if (zoomDifference === 0) {
      setZoomInitialDate(timelineContext.visibleTimeStart);
      setZoomEndDate(timelineContext.visibleTimeEnd);
      setZoomDifference(timelineContext.visibleTimeEnd - timelineContext.visibleTimeStart);
    }

    const newStyles = {
      ...getItemProps({
        style: {
          border: 0,
          boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.6)",
          color: styleVariables.color_white,
          backgroundColor: styleVariables.color_container_secondary,
          borderRadius: 12,
          borderLeftWidth: itemContext.selected ? 3 : 1,
          borderRightWidth: itemContext.selected ? 3 : 1,
        },
      }),
    };

    newStyles.style.top = `${newTop}px`;

    return (
      <div {...newStyles} className="project-timeline-item">
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <div className="project-timeline-item-content">
          <StatusIcon className="status-selection-data-icon" status={status} />
          <Typography type={ElementType.inline} className="project-timeline-item-content-text">
            {itemContext.title}
          </Typography>
          <div className="project-timeline-item-content-drag">
            <Icon name="far fa-ellipsis-v" color={styleVariables.color_gray} size={15} />
            <Icon name="far fa-ellipsis-v" color={styleVariables.color_gray} size={15} />
          </div>
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
    );
  };

  // @ts-ignore
  const intervalRender = ({ getIntervalProps, intervalContext }) => {
    // We need to hide the interval if the zoom is too high
    if (zoomDifference > 6157937089) {
      return null;
    }

    // At this point the months and days are redundant
    if (zoomDifference > 1092713952) {
      return (
        <div className="project-timeline-padding white" {...getIntervalProps()}>
          {moment(intervalContext.intervalText, "MMM DD").format("MMM")}
        </div>
      );
    }

    // At this point we have a lot of mess with month names
    if (zoomDifference > 292347385) {
      return null;
    }

    // Below the threshold just output what we receive
    return (
      <div className="project-timeline-padding white" {...getIntervalProps()}>
        {intervalContext.intervalText}
      </div>
    );
  };

  // @ts-ignore
  const secondIntervalRender = ({ getIntervalProps, intervalContext }) => {
    if (zoomDifference > 56434468666) {
      return (
        <div className="project-timeline-padding white" {...getIntervalProps()}>
          {intervalContext.intervalText.indexOf("/") === -1
            ? moment(intervalContext.intervalText, "MMMM")
                .set("year", intervalContext.interval.startTime.year())
                .format("MM/YY")
            : intervalContext.intervalText}
        </div>
      );
    }

    return (
      <div className="project-timeline-padding white" {...getIntervalProps()}>
        {intervalContext.intervalText}
      </div>
    );
  };

  const onItemMove = (subTaskId: string, newTime: number) => {
    const subTask = subItems.get(subTaskId);
    onDragEnd(newTime, subTask);
  };

  return (
    <main className="project-timeline">
      <div className="project-timeline-header">
        <div className="project-timeline-header-date" data-testid="project-timeline-header-date">
          <span className="fa-stack fa-2x">
            <Icon
              name="fas fa-square"
              className="fa-stack-2x"
              color={styleVariables.color_container_secondary}
              size={32}
            />
            <Icon
              name="fal fa-calendar"
              className="fa-stack-1x fa-inverse"
              color={styleVariables.color_container_secondary}
              size={14}
            />
          </span>
          <Typography className="ml1-5" type={ElementType.h4}>
            {moment(zoomInitialDate).format("MMM DD, YYYY")} - {moment(zoomEndDate).format("MMM DD, YYYY")}
          </Typography>
        </div>
        <div className="project-timeline-header-zoom" data-testid="project-timeline-header-zoom">
          <button
            className="project-timeline-header-zoom-button fa-stack fa-2x buttonAsLink"
            onClick={() => {
              ps.publish("decreaseZoom", null);
            }}
          >
            <Icon
              name="fas fa-square"
              className="fa-stack-2x"
              color={styleVariables.color_container_secondary}
              size={32}
            />
            <Icon
              name="fal fa-search-minus"
              className="fa-stack-1x fa-inverse"
              color={styleVariables.color_container_secondary}
              size={14}
            />
          </button>
          <button
            className="project-timeline-header-zoom-button fa-stack fa-2x buttonAsLink"
            onClick={() => {
              ps.publish("increaseZoom", null);
            }}
          >
            <Icon
              name="fas fa-square"
              className="fa-stack-2x"
              color={styleVariables.color_container_secondary}
              size={32}
            />
            <Icon
              name="fal fa-search-plus"
              className="fa-stack-1x fa-inverse"
              color={styleVariables.color_container_secondary}
              size={14}
            />
          </button>
        </div>
      </div>
      <ArcadiaTimeline
        groups={groups}
        // @ts-ignore
        items={items}
        defaultTimeStart={moment().add(12, "hour")}
        defaultTimeEnd={moment().add(36, "hour")}
        sidebarWidth={268}
        maxZoom={2 * 365 * 24 * 60 * 60 * 1000}
        minResizeWidth={260}
        canResize={false}
        // @ts-ignore
        itemRenderer={itemRenderer}
        itemHeightRatio={1}
        onZoom={(timelineContext) => {
          setZoomInitialDate(timelineContext.visibleTimeStart);
          setZoomEndDate(timelineContext.visibleTimeEnd);
          setZoomDifference(timelineContext.visibleTimeEnd - timelineContext.visibleTimeStart);
        }}
        onItemMove={onItemMove}
        {...others}
      >
        <TimelineHeaders>
          {/* @ts-ignore */}
          <DateHeader unit="day" labelFormat="MMM DD" style={{ height: 50 }} intervalRenderer={intervalRender} />
          {/* @ts-ignore */}
          <DateHeader intervalRenderer={secondIntervalRender} />
        </TimelineHeaders>
      </ArcadiaTimeline>
    </main>
  );
};

export default ProjectTimeline;
export { ProjectTimeline as Timeline };
