import React, { useEffect, useState } from "react";
import { Textarea } from "../Common/Textarea";
import { Thumbnail } from "../Thumbnail";
import { Color, ElementType, Typography } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { SessionRowStatus } from "../SessionRow";
import { IArcadiaFileUpload, IArcadiaMaterial } from "@types";
import useDebounce from "../../hooks/useDebounce";
import { Icon } from "../Icon";
import { sortingFunctionFileUploads } from "../../helpers/sorting";
const defaultMaterial = require("./material.svg") as string;

const SessionRowDetails: React.FC<
  {
    snapshots: IArcadiaFileUpload[];
    status: number;
    onClickScreenshot: Function;
    onTextareaChange: Function;
    onClickUploadMeasurements?: () => void;
    onClickDownloadProgram: Function;
    material: IArcadiaMaterial;
    notes: string;
    program: string;
    ncCodeId: number;
    parametersId: number;
    jobsId: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  snapshots,
  status,
  onClickScreenshot,
  onClickDownloadProgram,
  material,
  onTextareaChange,
  notes,
  onClickUploadMeasurements,
  program,
  ncCodeId,
  parametersId,
  jobsId,
}): React.ReactElement => {
  let sliced = snapshots;
  const isRunning = status === SessionRowStatus.Running;
  const maxSnapshots = isRunning ? 10 : 9;
  if (snapshots.length > maxSnapshots) {
    sliced = snapshots.slice(0, maxSnapshots - 1);
  }
  sliced.sort(sortingFunctionFileUploads);

  const [note, setNote] = useState(notes);

  const debouncedNoteValue = useDebounce(note, 1000);

  useEffect(() => {
    if (debouncedNoteValue) {
      onTextareaChange(debouncedNoteValue);
    }
  }, [debouncedNoteValue]);

  const handleTextareaChange = (value: string) => {
    setNote(value);
  };

  return (
    <div className={`session-row-details-bottom${!isRunning ? "-with-margin" : ""}`}>
      <div className="session-row-details-bottom-first-row">
        <div className="session-row-details-bottom-first-row-material">
          <Typography type={ElementType.label}>Material</Typography>
          <div className="session-row-details-bottom-first-row-material-inner">
            <Thumbnail
              title={"Material"}
              round={false}
              width={42}
              height={42}
              borderRadius={7}
              filter={"drop-shadow(0px 5px 12px rgba(0, 0, 0, 0.35))"}
              backgroundImageUrl={material && material.imageUrls![0]! ? material.imageUrls![0] : defaultMaterial}
              backgroundColor={styleVariables.color_white}
            />
            <div className="session-row-details-bottom-first-row-material-inner-text">
              <Typography>{material ? material.materialName : ""}</Typography>
              {/* <Typography type={ElementType.bodySmall} color={Color.Secondary}>
                {material && material.materialType && material.materialThickness
                  ? `${material.materialType}, ${material.materialThickness}`
                  : ""}
              </Typography> */}
            </div>
          </div>
        </div>
        <div className="session-row-details-bottom-first-row-uploads">
          <div className="session-row-details-bottom-first-row-uploads-container">
            <div>
              <Typography type={ElementType.label}>{`Uploads (${snapshots.length})`}</Typography>
            </div>
            {onClickUploadMeasurements && (
              <div role="button" onClick={onClickUploadMeasurements} onKeyDown={() => {}} tabIndex={0}>
                <Typography type={ElementType.textLink}>Upload Measurements</Typography>
              </div>
            )}
            {onClickDownloadProgram && (
              <div
                role="button"
                onClick={() => onClickDownloadProgram(program, ncCodeId, parametersId, jobsId)}
                onKeyDown={() => {}}
                tabIndex={0}
              >
                <Typography type={ElementType.textLink}>Download Program</Typography>
              </div>
            )}
          </div>
          <div className="session-row-details-bottom-first-row-uploads-snapshots">
            {sliced &&
              sliced.length > 0 &&
              sliced.map((snapshot, index) => {
                const type = snapshot.fileUri!.split(".").pop();
                if (type !== "png") {
                  return (
                    <div
                      role="button"
                      onKeyPress={() => {}}
                      tabIndex={0}
                      onClick={() => onClickScreenshot(snapshots, snapshot.fileUploadId)}
                      className="session-row-details-bottom-first-row-uploads-file session-row-details-bottom-first-row-uploads-snapshots-snap"
                    >
                      <Icon size={20} color={styleVariables.color_gray} name={"fal fa-file-alt"} />
                    </div>
                  );
                }
                return (
                  <Thumbnail
                    className="session-row-details-bottom-first-row-uploads-snapshots-snap"
                    key={snapshot.fileUploadId!}
                    title={snapshot.fileName!}
                    round={false}
                    width={42}
                    height={42}
                    borderRadius={4}
                    backgroundImageUrl={snapshot.imageUri_Small!}
                    backgroundColor={styleVariables.color_layer_2}
                    onClick={() => onClickScreenshot(snapshots, snapshot.fileUploadId)}
                  />
                );
              })}
            {snapshots.length > maxSnapshots && (
              <div className="session-row-details-bottom-first-row-uploads-snapshots-snap session-row-details-info-new">
                <Typography type={ElementType.display} color={Color.Secondary}>{`+${
                  snapshots.length - maxSnapshots + 1
                }`}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Typography type={ElementType.label}>Notes</Typography>
        <Textarea
          value={note}
          // @ts-ignore
          onChange={(ev) => handleTextareaChange(ev.target.value)}
          width={"auto"}
          height={176}
        ></Textarea>
      </div>
    </div>
  );
};

export { SessionRowDetails };
