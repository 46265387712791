import React, { FC, HTMLAttributes, ReactElement } from "react";
import { default as cx } from "classnames";
import moment from "moment";

import styleVariables from "@styles/export.scss";
import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { IExecutionPackagePart } from "@types";
import { Table } from "../Table";
import { Row } from "../Table/Row";
import { Column } from "../Table/Column";
import { ScrollableContainer } from "../ScrollableContainer";

const ModalVersions: FC<
  {
    className?: string;
    width: number;
    title: string;
    onCloseModal?: () => void;
    versions: IExecutionPackagePart[];
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, width, title, versions }): ReactElement => {
  const classes = cx(
    {
      "modal-versions": true,
    },
    className
  );

  const versionColumnNameWidth = 287;
  const noteColumnWidth = 370;
  const uploadedColumnWidth = 144;
  const downloadColumnWidth = 20;

  return (
    <Modal className={classes} width={width} onCloseModal={onCloseModal}>
      <Typography className="modal-versions-title" type={ElementType.h4}>
        {title}
      </Typography>
      <div className="modal-versions-table">
        <Table>
          <Row>
            <Column width={versionColumnNameWidth}>
              <Typography color={Color.OffWhite}>Version</Typography>
            </Column>
            <Column width={noteColumnWidth}>
              <Typography color={Color.OffWhite}>Name</Typography>
            </Column>
            <Column width={uploadedColumnWidth}>
              <Typography color={Color.OffWhite}>Uploaded</Typography>
            </Column>
            <Column width={downloadColumnWidth}></Column>
          </Row>
          <ScrollableContainer width={856} height={435}>
            {versions.map((v) => (
              <Row key={v.commitId}>
                <Column width={versionColumnNameWidth}>{v.versionNumber}</Column>
                <Column width={noteColumnWidth}>{v.title}</Column>
                <Column width={uploadedColumnWidth}>{moment(v.lastUpdatedDate).format("MM/DD/YY hh:mm:ss A")}</Column>
                <Column width={downloadColumnWidth}>
                  <Button
                    themeType="simple"
                    iconName="far fa-arrow-to-bottom"
                    iconOnly={true}
                    iconColor={styleVariables.color_gray}
                  />
                </Column>
              </Row>
            ))}
          </ScrollableContainer>
        </Table>
      </div>
      <div className="modal-versions-buttons">
        <Button onClick={onCloseModal} className="modal-button" size="large" themeType="clear">
          Close
        </Button>
      </div>
    </Modal>
  );
};

export { ModalVersions };
