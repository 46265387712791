import * as React from "react";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Icon } from "../Icon";

const AccountCard: React.FC<
  {
    className?: string;
    name: string;
    company: string;
    onLogoutClick: () => void;
    onAccountClick: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", name = "", company = "", onLogoutClick, onAccountClick }): React.ReactElement => {
  const getInitials = (name: string) => {
    let result = "";
    const names = name.split(" ");
    names.forEach((n) => {
      const firstLetter = n.substring(0, 1);
      result = `${result}${firstLetter}`;
    });
    return result;
  };

  return (
    <div className={`account-card ${className}`}>
      <div className="account-card-top-section">
        <div className="account-card-top-section-initials">{getInitials(name)}</div>
        <div className="account-card-top-section-info">
          <Typography className="account-card-top-section-info-name" type={ElementType.h4}>
            {name}
          </Typography>
          <Typography type={ElementType.subtitle} color={Color.Secondary}>
            {company}
          </Typography>
        </div>
      </div>
      <div className="account-card-controls-section">
        <div
          role="button"
          onClick={onAccountClick}
          tabIndex={0}
          onKeyDown={() => {}}
          className="account-card-controls-section-row"
        >
          <Icon className="account-card-controls-section-row-icon" name="fal fa-user" size={18} />
          <Typography type={ElementType.display}>Account Settings</Typography>
        </div>
        <div
          role="button"
          onClick={onLogoutClick}
          tabIndex={0}
          onKeyDown={() => {}}
          className="account-card-controls-section-row"
        >
          <Icon className="account-card-controls-section-row-icon" name="fal fa-sign-out" size={18} />
          <Typography type={ElementType.display}>Log Out</Typography>
        </div>
      </div>
    </div>
  );
};

export { AccountCard };
