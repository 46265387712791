import React, { FC, HTMLAttributes, ReactElement } from "react";
import { IMachineRun, ICleaningDefinition, IMeasurementDefinition } from "@types";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { useState } from "react";
import { Button } from "../Button";
import { Checkbox } from "../Common/Checkbox";
import { Textarea } from "../Common/Textarea";
import { ModalConfirm } from "../ModalConfirm";
import { RunStatus } from "../SessionRow";

const ModalCleaningAndMeasurement: FC<
  {
    run: IMachineRun;
    cleaningDefinitions?: ICleaningDefinition[];
    measurementDefinitions?: IMeasurementDefinition[];
    fixtureName: string;
    onCancelClick: () => void;
    onDoneClick: (run: IMachineRun) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({ run, onCancelClick, onDoneClick, fixtureName }): ReactElement => {
  const cleaningDefinitionOptions = [
    "Alcohol rinse",
    "Ultrasonic time: 5 min",
    "Ultrasonic time: 10 min",
    "Ultrasonic time: User defined",
    "Ultrasonic heat on",
    "Ultrasonic fluid: distilled water",
    "Ultrasonic fluid: detergent solution",
    "Ultrasonic fluid: citric acid solution",
  ];

  const measurementOptions = [
    "Stereoscope laser-entry side",
    "Stereoscope laser-exit side",
    "Optical feature measurement - laser-entry-side",
    "Optical feature measurement - laser-exit-side",
    "Positional measurement - laser-entry-side",
    "Positional measurement - laser-exit-side",
    "Measure according to drawing",
  ];
  const [cleaningDefinitions, setCleaningDefinitions] = useState<ICleaningDefinition[]>(
    run && run.cleaningDefinitions ? run.cleaningDefinitions : []
  );
  const [measurementDefinitions, setMeasurementDefinitions] = useState<IMeasurementDefinition[]>(
    run && run.measurementDefinitions ? run.measurementDefinitions : []
  );

  const [notes, setNotes] = useState(run && run.operatorNotes ? run.operatorNotes : "");
  const [showConfirm, setShowConfirm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const doneClickHandler = () => {
    const updatedRun: IMachineRun = {
      ...run,
      operatorNotes: notes,
      cleaningDefinitions: cleaningDefinitions,
      measurementDefinitions: measurementDefinitions,
    };
    onDoneClick(updatedRun);
  };

  const checkCleaningDefinition = (value: string) => {
    const item = cleaningDefinitions.find((cd) => cd.cleaningDefinition === value);
    let updated: ICleaningDefinition[] = [];
    if (!item) {
      updated = [...cleaningDefinitions, { cleaningDefinition: value }];
    } else {
      updated = cleaningDefinitions.filter((cd) => cd.cleaningDefinition !== value);
    }
    setCleaningDefinitions(updated);
    setIsUpdated(true);
  };

  const checkMeasurementDefinition = (value: string) => {
    const item = measurementDefinitions.find((cd) => cd.measurementDescription === value);
    let updated: IMeasurementDefinition[] = [];
    if (!item) {
      updated = [...measurementDefinitions, { measurementDescription: value }];
    } else {
      updated = measurementDefinitions.filter((cd) => cd.measurementDescription !== value);
    }
    setMeasurementDefinitions(updated);
    setIsUpdated(true);
  };

  const isDisabled = (runStatus: RunStatus): boolean => {
    let result = false;
    if (runStatus !== RunStatus.NotQueued && runStatus !== RunStatus.Running) {
      result = true;
    }
    return result;
  };

  return (
    <>
      <Modal
        className="modal-cleaning-and-measurement"
        width={800}
        onCloseModal={() => (isUpdated ? setShowConfirm(true) : onCancelClick())}
      >
        <Typography className="modal-cleaning-and-measurement-title" type={ElementType.h4}>
          Cleaning and Measurement Preferences
        </Typography>
        <Typography className="modal-cleaning-and-measurement-description" color={Color.Secondary}>
          Customize your preferred method for cleaning and measurement
        </Typography>
        <Typography className="modal-cleaning-and-measurement-subtitle" type={ElementType.subtitle}>{`${
          fixtureName ? fixtureName : ""
        } - ${run ? run.runName : ""}`}</Typography>
        <Typography type={ElementType.label}>measurement process options</Typography>
        <div className="modal-cleaning-and-measurement-checkbox-container">
          {measurementOptions.map((m) => (
            <div className="modal-cleaning-and-measurement-checkbox-container-inner" key={m}>
              <Checkbox
                disabled={isDisabled(run.runStatus!)}
                checked={measurementDefinitions.filter((md) => md.measurementDescription === m).length > 0}
                onChange={() => checkMeasurementDefinition(m)}
              />
              <Typography>{m}</Typography>
            </div>
          ))}
        </div>
        <Typography type={ElementType.label}>cleaning process options</Typography>
        <div className="modal-cleaning-and-measurement-checkbox-container">
          {cleaningDefinitionOptions.map((c) => (
            <div className="modal-cleaning-and-measurement-checkbox-container-inner" key={c}>
              <Checkbox
                disabled={isDisabled(run.runStatus!)}
                checked={cleaningDefinitions.filter((cd) => cd.cleaningDefinition === c).length > 0}
                onChange={() => checkCleaningDefinition(c)}
              />
              <Typography>{c}</Typography>
            </div>
          ))}
        </div>
        <Typography type={ElementType.label}>Notes for operator</Typography>
        {/* @ts-ignore */}
        <Textarea
          disabled={isDisabled(run.runStatus!)}
          value={notes}
          onChange={(ev) => setNotes((ev.target as HTMLTextAreaElement).value)}
          width={"100%"}
          height={100}
        />
        <div className="modal-cleaning-and-measurement-buttons">
          <Button
            onClick={() => (isUpdated ? setShowConfirm(true) : onCancelClick())}
            className="modal-cleaning-and-measurement-buttons-cancel"
            themeType="secondary"
            size="large"
          >
            Cancel
          </Button>
          <Button onClick={doneClickHandler} themeType="clear" size="large">
            Done
          </Button>
        </div>
      </Modal>
      {showConfirm && (
        <ModalConfirm
          width={607}
          height={208}
          title="Discard Changes"
          text="Are you sure you want to discard all changes?"
          buttonCloseText="Cancel"
          buttonConfirmText="Discard Changes"
          onCloseModal={() => setShowConfirm(false)}
          onConfirm={onCancelClick}
        />
      )}
    </>
  );
};

export { ModalCleaningAndMeasurement };
