import { IArcadiaProject, IArcadiaSession } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const useProjects = () => {
  const [isProjectsLoading, setIsLoading] = useState(false);
  const [isProjectsError, setIsError] = useState(false);

  const [projects, setProjects] = useState<IArcadiaProject[]>([]);
  const [sessions, setSessions] = useState<IArcadiaSession[]>([]);

  const fetchProjects = () => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      ProjectsService.getAllGroupProjects()
        .then((response: any) => {
          const projects: IArcadiaProject[] = response.data as IArcadiaProject[];
          setProjects(projects);
          if (projects && projects.length > 0) {
            setSessions(projects[0].sessions || []);
          }
          setIsError(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  const fetchManagerProjects = () => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      ProjectsService.getAllManagerGroupProjects()
        .then((response: any) => {
          const projects: IArcadiaProject[] = response.data as IArcadiaProject[];
          setProjects(projects);
          if (projects && projects.length > 0) {
            setSessions(projects[0].sessions || []);
          }
          setIsError(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return {
    projects,
    project: projects[0],
    sessions,
    session: sessions[0],
    isProjectsLoading,
    isProjectsError,
    setProjects,
    fetchProjects,
    fetchManagerProjects,
  };
};

export default useProjects;
