import * as React from "react";
import { default as cx } from "classnames";
import { ElementType, Typography, Color } from "../Common/Typography";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { ISteps } from "@types";

const SecondNavigationBottom: React.FC<
  {
    activeStep: number;
    className?: string;
    steps: ISteps[];
    setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  activeStep = 1,
  className = "",
  steps = [
    { stepNumber: 1, title: "Goals" },
    { stepNumber: 2, title: "Schedule" },
    { stepNumber: 3, title: "Material" },
    { stepNumber: 4, title: "Planning" },
  ],
  setCurrentStep = () => {},
}): React.ReactElement => {
  const classes = cx(
    {
      "second-nav-bottom-container": true,
    },
    className
  );

  return (
    <div className={classes}>
      {steps.map((step) => {
        const changeStep = () => {
          if (activeStep > step.stepNumber) {
            setCurrentStep(step.stepNumber);
          }
        };

        return (
          <div
            key={step.stepNumber}
            className={`second-nav-bottom-nav-step${step.stepNumber === activeStep ? " active" : ""} ${
              activeStep > step.stepNumber ? " pointer" : ""
            }`}
            onClick={changeStep}
            onKeyPress={changeStep}
            role="button"
            tabIndex={0}
          >
            <div className="second-nav-bottom-step-number">
              {step.stepNumber >= activeStep ? (
                <Typography type={ElementType.display}>{step.stepNumber}</Typography>
              ) : (
                <Icon color={styleVariables.color_white} name="fal fa-check" size={20} />
              )}
            </div>
            <div className="second-nav-bottom-step-title">
              <Typography
                color={step.stepNumber === activeStep ? Color.Primary : Color.Secondary}
                type={ElementType.display}
              >
                {step.title}
              </Typography>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { SecondNavigationBottom };
