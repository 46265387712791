import React, { FC, ReactElement, useEffect, useState } from "react";
import {
  CardProject,
  CardNewProject,
  Typography,
  ProjectsSidebar,
  ModalNewProject,
  Button,
  DropdownSelection,
  ModalConfirm,
  FilePaths,
} from "@namespace/components";
import { ElementType } from "@namespace/components/src/components/Common/Typography";
import { Status } from "@namespace/components/src/constants/enums/Status";
import {
  IArcadiaProject,
  INewProjectData,
  IFavorite,
  IArcadiaFeatureDetails,
  IArcadiaFeatureParameter,
  IArcadiaPartDetails,
} from "@types";
import { copyToClipboard } from "../../helpers/clipboardHelper";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ProjectsService from "../../services/ProjectsService";
import useMaterialCategories from "../../hooks/API/useMaterialCategories";
import useProcesses from "../../hooks/API/useProcesses";
import useProjects from "../../hooks/API/useProjects";
import useUser from "../../hooks/API/useUser";
import UploadsService from "../../services/UploadsService";
import { allSettled } from "../../utils";
import { AxiosResponse } from "axios";
import UserService from "../../services/UserService";
import ProjectsSkeletonLoader from "./ProjectsSkeletonLoader";
import useSessionHubConnection from "../../hooks/API/useSessionHubConnection";

const Projects: FC<{
  favorites: IFavorite[];
  setFavorites: (favorites: IFavorite[]) => void;
  onFavoriteClick: (favorite: IFavorite) => void;
}> = ({ favorites, setFavorites, onFavoriteClick }): ReactElement => {
  const history = useHistory();
  const [showCreateProject, setShowCreateProject] = useState<boolean>(false);
  const { materialCategories, fetchMaterialCategories, isMaterialCategoriesLoading } = useMaterialCategories();
  const { user, fetchUser, isUserLoading } = useUser();
  const { projects, setProjects, fetchProjects, fetchManagerProjects, isProjectsLoading } = useProjects();
  const { processes, fetchProcesses, isProcessesLoading } = useProcesses();
  const { sessionHubConnection } = useSessionHubConnection();

  const [showActive, setShowActive] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmActivate, setShowConfirmActivate] = useState(false);
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [projectIdForUpdate, setProjectIdForUpdate] = useState<string | null>(null);

  const [filteredProjects, setFilteredProjects] = useState(
    projects ? projects.filter((p) => [0, 1, 2].indexOf(p.projectStatus!) >= 0) : []
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingProject, setIsCreatingProject] = useState(false);

  useEffect(() => {
    if (sessionHubConnection && projects) {
      sessionHubConnection.on("startSession", () => {
        fetchProjects();
      });

      sessionHubConnection.on("stopSession", () => {
        fetchProjects();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHubConnection, projects]);

  useEffect(() => {
    let result = true;
    if (!isProjectsLoading && !isMaterialCategoriesLoading && !isUserLoading && !isProcessesLoading) {
      result = false;
    }
    setIsLoading(result);
  }, [isProjectsLoading, isMaterialCategoriesLoading, isUserLoading, isProcessesLoading]);

  useEffect(() => {
    let filtered = projects.filter((p) => [0, 1, 2].indexOf(p.projectStatus!) >= 0);
    if (showActive !== true) {
      filtered = projects.filter((p) => p.projectStatus === 3);
    }
    setFilteredProjects(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActive, projects]);

  useEffect(() => {
    fetchUser();
    fetchProcesses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      if (
        user.roles &&
        user.roles.length > 0 &&
        (user.roles.indexOf("Manager.All") >= 0 || user.roles.indexOf("Operator.All") >= 0)
      ) {
        fetchManagerProjects();
      } else {
        fetchProjects();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchMaterialCategories(user?.userId!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && projects) {
      const favoritesFromUser = user?.favorites.map((f) => f.id);
      if (favoritesFromUser && favoritesFromUser.length > 0) {
        const favoriteProjects = projects.filter((p) => favoritesFromUser?.indexOf(p.arcadiaProjectId) >= 0);
        const favorites = favoriteProjects.map((f) => ({
          name: f.projectName!,
          imageUri: f.projectIcon!,
          projectId: f.arcadiaProjectId!,
        }));
        setFavorites(favorites);
      } else {
        setFavorites([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, projects]);

  const toggleNewProject = () => {
    setShowCreateProject(!showCreateProject);
  };

  const onCopyProjectLinkClick = (projectId: string) => {
    copyToClipboard(`${window.location}project/${projectId}`);
  };

  const onFinishCreateProject = async (formData: INewProjectData) => {
    const partDetails: IArcadiaPartDetails[] = [];
    const files: FormData[] = [];
    const features: IArcadiaFeatureDetails[] = [];
    formData.part.forEach((p) => {
      const newPart: IArcadiaPartDetails = {
        partId: p.identifier,
        partName: p.name,
        partDescription: p.description,
        materialCategory: p.material ? p.material!.value : undefined,
        numberOfParts: p.number?.toString(),
        dueDate: p.deliveryDate?.toISOString()!,
        desiredCycleTime: `${Math.floor(p.desiredCycle! / 3600)}:${Math.floor(p.desiredCycle! / 60)}:${
          p.desiredCycle! % 60
        }.0000000`,
      };
      partDetails.push(newPart);
      p.drawing.forEach((d) => {
        const blob = new Blob([d], { type: d.type });
        const fd = new FormData();
        fd.append("formData", blob, d.name);
        fd.append("fileName", d.name);
        fd.append("userPath", `${FilePaths.PART_DRAWINGS}`);
        fd.append("partId", p.identifier!);
        files.push(fd);
      });
      const partFeatures = formData.feature.get(p.identifier!);
      if (partFeatures) {
        partFeatures.forEach((f) => {
          let featureParams: IArcadiaFeatureParameter[] = [];
          if (f.parameters) {
            featureParams = f.parameters!.map((param) => ({
              parameterName: param.parameterName,
              parameterValue: param.parameterValue,
            }));
          }
          const newFeature: IArcadiaFeatureDetails = {
            featureId: f.featureId,
            partId: p.identifier,
            featureName: f.featureName,
            measuringUnit: f.measure,
            processName: f.process,
            featureParameters: featureParams,
          };
          features.push(newFeature);
        });
      }
    });
    const updatedProject: IArcadiaProject = {
      projectName: formData.projectName,
      sessionsPlanned: formData.sessionsPlanned,
      partDetails: partDetails,
      featureDetails: features,
    };
    try {
      setIsCreatingProject(true);
      const response = await ProjectsService.createNewProject(updatedProject, formData.template?.templateId);
      const project = (response.data as unknown) as IArcadiaProject;
      const promises: Promise<AxiosResponse<any>>[] = [];
      files.forEach((f) => {
        f.append("projectId", project.arcadiaProjectId!);
        promises.push(UploadsService.uploadFile(f));
      });

      const results = await allSettled(promises);
      if (results.filter((r) => r.status === "rejected")) {
        console.log("An error occurred");
      }
      if (projects.length === 0) {
        history.push(`/project/${project.arcadiaProjectId!}/onboarding`);
      } else {
        history.push(`/project/${project.arcadiaProjectId!}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsCreatingProject(false);
    }
  };

  const handleArchiveProjectClick = async (projectId: string) => {
    setProjectIdForUpdate(projectId);
    setShowConfirmArchive(true);
  };

  const handleActivateProjectClick = async (projectId: string) => {
    setProjectIdForUpdate(projectId);
    setShowConfirmActivate(true);
  };

  const handleDeleteProjectClick = async (projectId: string) => {
    setProjectIdForUpdate(projectId);
    setShowConfirmDelete(true);
  };

  const deleteProject = async () => {
    const project = projects.find((p) => p.arcadiaProjectId === projectIdForUpdate);
    if (project) {
      try {
        await ProjectsService.updateProject({ ...project, projectStatus: 5 });
        setProjects([...projects.filter((p) => p.arcadiaProjectId !== projectIdForUpdate)]);
      } catch (error) {
        console.log(error);
      }
    }
    setShowConfirmDelete(false);
    setProjectIdForUpdate(null);
  };

  const archiveProject = async () => {
    const newProjects = [...projects];
    const project = newProjects.find((p) => p.arcadiaProjectId === projectIdForUpdate);
    if (project) {
      project.projectStatus = 3;
      try {
        await ProjectsService.updateProject(project);
        setProjects([...newProjects]);
      } catch (error) {
        console.log(error);
      }
    }
    setShowConfirmArchive(false);
    setProjectIdForUpdate(null);
  };

  const activateProject = async () => {
    const newProjects = [...projects];
    const project = newProjects.find((p) => p.arcadiaProjectId === projectIdForUpdate);
    if (project) {
      project.projectStatus = 1;
      try {
        await ProjectsService.updateProject(project);
        setProjects([...newProjects]);
      } catch (error) {
        console.log(error);
      }
    }
    setShowConfirmActivate(false);
    setProjectIdForUpdate(null);
  };

  const handleFavoriteClick = async (favorite: IFavorite) => {
    onFavoriteClick(favorite);
    let userFavorites = user?.favorites || [];
    if (userFavorites && userFavorites.length > 0) {
      const projectIds = userFavorites.map((f) => f.id);
      if (projectIds.indexOf(favorite.projectId) >= 0) {
        userFavorites = userFavorites.filter((p) => p.id !== favorite.projectId);
      } else {
        userFavorites.push({ type: "project", id: favorite.projectId });
      }
    } else {
      userFavorites.push({ type: "project", id: favorite.projectId });
    }
    await UserService.updateFavorites(userFavorites);
  };

  return (
    <main className="container-primary-background projects-page">
      {showConfirmDelete && (
        <ModalConfirm
          width={607}
          height={208}
          title="Delete Project"
          text="Are you sure you want to delete this project"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => {
            setShowConfirmDelete(false);
            setProjectIdForUpdate(null);
          }}
          onConfirm={deleteProject}
        />
      )}
      {showConfirmArchive && (
        <ModalConfirm
          width={607}
          height={208}
          title="Archive Project"
          text="Are you sure you want to archive this project"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => {
            setShowConfirmArchive(false);
            setProjectIdForUpdate(null);
          }}
          onConfirm={archiveProject}
        />
      )}
      {showConfirmActivate && (
        <ModalConfirm
          width={607}
          height={208}
          title="Activate Project"
          text="Are you sure you want to activate this project"
          buttonCloseText="No"
          buttonConfirmText="Yes"
          onCloseModal={() => {
            setShowConfirmActivate(false);
            setProjectIdForUpdate(null);
          }}
          onConfirm={activateProject}
        />
      )}
      <div className="projects-page-container">
        <section className="projects-page-container-main">
          <div className="projects-page-container-main-header">
            <Typography className="ml1" type={ElementType.h1}>
              Projects
            </Typography>
            <DropdownSelection
              renderedComponent={
                <Button iconRightName="fa fa-chevron-down" size="small" themeType="outline">
                  {`${showActive ? "Active" : "Archived"} Projects (${filteredProjects.length})`}
                </Button>
              }
              items={[
                {
                  name: "Active Projects",
                  onClick: () => {
                    setShowActive(true);
                  },
                },
                {
                  name: "Archived Projects",
                  onClick: () => {
                    setShowActive(false);
                  },
                },
              ]}
            />
          </div>
          <div className="projects-page-container-main-projects">
            {isLoading ? (
              <ProjectsSkeletonLoader />
            ) : (
              <>
                <CardNewProject onClick={toggleNewProject} />
                {filteredProjects.map((project) => {
                  const now = moment(new Date());
                  const activeSession = project.sessions?.find(
                    (s) => moment(s.startTime) < now && moment(s.endTime) >= now
                  );
                  const activeSessionDate = activeSession ? new Date(activeSession.startTime!) : undefined;
                  const nextSession = project.sessions?.find((s) => moment(s.plannedDate) > now);
                  const nextSessionDate =
                    nextSession && nextSession.plannedDate ? new Date(nextSession.plannedDate) : undefined;
                  const isInFavorites = favorites.find((f) => f.projectId === project.arcadiaProjectId) !== undefined;
                  return (
                    <CardProject
                      backgroundImageUrl={project.projectIcon || ""}
                      backgroundColor={"black"}
                      status={Status.InProgress}
                      title={project.projectName || ""}
                      subText={project.groupObjectId || ""}
                      projectId={project.arcadiaProjectId || ""}
                      activeSession={activeSessionDate}
                      nextSession={nextSessionDate}
                      sessionsUsed={project.sessionsUsed || 0}
                      totalSessions={project.sessionsPlanned || 0}
                      height="auto"
                      width={320}
                      isFavorite={isInFavorites}
                      isArchived={project.projectStatus === 3}
                      onArchiveProject={handleArchiveProjectClick}
                      onDeleteProject={handleDeleteProjectClick}
                      onActivateProject={handleActivateProjectClick}
                      onFavoriteClick={handleFavoriteClick}
                      onCopyProjectLinkClick={onCopyProjectLinkClick}
                    />
                  );
                })}
              </>
            )}
          </div>
        </section>
        {!isProjectsLoading && projects && (
          <ProjectsSidebar
            projects={projects}
            isManager={user && user.roles && user.roles.length > 0 && user.roles.indexOf("Manager.All") >= 0}
          />
        )}
        {showCreateProject && (
          <ModalNewProject
            projectNames={
              projects
                .filter((p) => [0, 1, 2].indexOf(p.projectStatus!) >= 0)
                .map((p) => p.projectName!.toLowerCase()) || []
            }
            projectTemplates={projects.filter((p) => p.projectStatus === 6)}
            materialCategories={materialCategories}
            processes={processes}
            onCloseModal={toggleNewProject}
            onFinish={isCreatingProject ? (formData: INewProjectData) => {} : onFinishCreateProject}
          />
        )}
      </div>
    </main>
  );
};

export default Projects;
export { Projects };
