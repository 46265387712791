const processes = [
  {
    processName: "Circular Hole",
    parameter: "Diameter",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal diameter for circular hole or minor diameter for elliptical hole",
  },
  {
    processName: "Circular Hole",
    parameter: "Thickness",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal thickness of the material to drill through",
  },
  {
    processName: "Circular Hole",
    parameter: "Size Tolerance",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription:
      "Basic size tolerance of the hole, if known.  Enter 0 if not known.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Circular Hole",
    parameter: "Major Diameter",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal major diameter, if hole is elliptical.  Leave blank for circular holes.",
  },
  {
    processName: "Circular Hole",
    parameter: "Positional Tolerance",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Required locational tolerance of the hole. Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Circular Hole",
    parameter: "Taper Angle",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription:
      "Nominal taper angle.  Enter positive value if laser entrance is larger than laser exit.  Enter negative value if laser entrance is smaller than laser exit",
  },
  {
    processName: "Circular Hole",
    parameter: "Taper Tolerance",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription: "Tolerance of the taper angle.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Circular Hole",
    parameter: "Entrance Diameter",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal entrance diameter, if different than basic diameter",
  },
  {
    processName: "Circular Hole",
    parameter: "Exit Diameter",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal exit diameter, if diferent than basic diameter",
  },
  {
    processName: "Circular Hole",
    parameter: "Max Entrance Radius",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Maximum entrance flare or radius, if known",
  },
  {
    processName: "Circular Hole",
    parameter: "Circularity",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Required circularity tolerance, if applicable.  Represent value as a bilateral tolerance",
  },
  {
    processName: "Circular Hole",
    parameter: "Cut Cycle Time",
    parameterType: "Advanced",
    measurementType: "Time",
    tooltipDescription: "Maximum allowable cut time for this feature",
  },
  {
    processName: "Square Hole",
    parameter: "Width",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal width for square hole or shorter side for rectangular hole",
  },
  {
    processName: "Square Hole",
    parameter: "Thickness",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal thickness of the material to drill through",
  },
  {
    processName: "Square Hole",
    parameter: "Size Tolerance",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription:
      "Basic size tolerance of the hole, if known.  Enter 0 if not known.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Square Hole",
    parameter: "Width 2",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal longer side of a rectangular hole.  Do not fill for square hole.",
  },
  {
    processName: "Square Hole",
    parameter: "Positional Tolerance",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Required locational tolerance of the hole. Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Square Hole",
    parameter: "Taper Angle",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription:
      "Nominal taper angle.  Enter positive value if laser entrance is larger than laser exit.  Enter negative value if laser entrance is smaller than laser exit",
  },
  {
    processName: "Square Hole",
    parameter: "Taper Tolerance",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription: "Tolerance of the taper angle.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Square Hole",
    parameter: "Entrance Major Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal entrance width of longer side, if different than basic diameter",
  },
  {
    processName: "Square Hole",
    parameter: "Exit Major Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal exit width of longer side, if different than basic diameter",
  },
  {
    processName: "Square Hole",
    parameter: "Entrance Minor Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal entrance width of shorter side, if different than basic diameter",
  },
  {
    processName: "Square Hole",
    parameter: "Exit Minor Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal exit width of shorter side, if different than basic diameter",
  },
  {
    processName: "Square Hole",
    parameter: "Max Entrance Radius",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Maximum entrance flare or radius, if known",
  },
  {
    processName: "Square Hole",
    parameter: "Max Corner Radius",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Maximum allowable corner radius",
  },
  {
    processName: "Square Hole",
    parameter: "Form Tolerance",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription:
      "Form tolerance for square/rectangular hole, if applicable.  Represent value as a bilateral tolerance.",
  },
  {
    processName: "Square Hole",
    parameter: "Cut Cycle Time",
    parameterType: "Advanced",
    measurementType: "Time",
    tooltipDescription: "Maximum allowable cut time for this feature",
  },
  {
    processName: "Milled Slot",
    parameter: "Width",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal width of slot",
  },
  {
    processName: "Milled Slot",
    parameter: "Thickness",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal thickness of the material to cut through",
  },
  {
    processName: "Milled Slot",
    parameter: "Size Tolerance",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription:
      "Basic size tolerance of the slot, if known.  Enter 0 if not known.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Milled Slot",
    parameter: "Positional Tolerance",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Required locational tolerance of the slot. Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Milled Slot",
    parameter: "Taper Angle",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription:
      "Nominal taper angle.  Enter positive value if laser entrance is larger than laser exit.  Enter negative value if laser entrance is smaller than laser exit",
  },
  {
    processName: "Milled Slot",
    parameter: "Taper Tolerance",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription: "Tolerance of the taper angle.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Milled Slot",
    parameter: "Entrance Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal entrance width, if different than basic diameter",
  },
  {
    processName: "Milled Slot",
    parameter: "Exit Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal exit width, if different than basic diameter",
  },
  {
    processName: "Milled Slot",
    parameter: "Max Entrance Radius",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Maximum entrance flare or radius, if known",
  },
  {
    processName: "Milled Slot",
    parameter: "Cut Cycle Time",
    parameterType: "Advanced",
    measurementType: "Time",
    tooltipDescription: "Maximum allowable cut time for this feature",
  },
  {
    processName: "Milled Profile",
    parameter: "Thickness",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal thickness of the material to cut through",
  },
  {
    processName: "Milled Profile",
    parameter: "Cut Width",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal width of cut (i.e. kerf width)",
  },
  {
    processName: "Milled Profile",
    parameter: "Positional Tolerance",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Required locational tolerance of the slot. Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Milled Profile",
    parameter: "Taper Angle",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription:
      "Nominal taper angle.  Enter positive value if laser entrance is larger than laser exit.  Enter negative value if laser entrance is smaller than laser exit",
  },
  {
    processName: "Milled Profile",
    parameter: "Taper Tolerance",
    parameterType: "Advanced",
    measurementType: "Angle",
    tooltipDescription: "Tolerance of the taper angle.  Represent the value as a bilateral tolerance.",
  },
  {
    processName: "Milled Profile",
    parameter: "Entrance Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal entrance width, if different than basic diameter",
  },
  {
    processName: "Milled Profile",
    parameter: "Exit Width",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Nominal exit width, if different than basic diameter",
  },
  {
    processName: "Milled Profile",
    parameter: "Max Entrance Radius",
    parameterType: "Advanced",
    measurementType: "Length",
    tooltipDescription: "Maximum entrance flare or radius, if known",
  },
  {
    processName: "Milled Profile",
    parameter: "Cut Cycle Time",
    parameterType: "Advanced",
    measurementType: "Time",
    tooltipDescription: "Maximum allowable cut time for this feature",
  },
  {
    processName: "3D Ablation",
    parameter: "Feature Size",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Rough nominal size of ablated feature (i.e. characteristic dimension)",
  },
  {
    processName: "3D Ablation",
    parameter: "Feature Depth",
    parameterType: "Basic",
    measurementType: "Length",
    tooltipDescription: "Nominal depth of deepest point of feature",
  },
  {
    processName: "3D Ablation",
    parameter: "Cut Cycle Time",
    parameterType: "Advanced",
    measurementType: "Time",
    tooltipDescription: "Maximum allowable cut time for this feature",
  },
];
export default processes;
