import * as React from "react";
import { default as cx } from "classnames";
import { Tab as ReactTab, Tabs as ReactTabs, TabList, TabPanel } from "react-tabs";
import uniqid from "uniqid";
import { ITab } from "@types";

const Tabs: React.FC<
  {
    className?: string;
    tabs: ITab[];
    noExtraBorder?: boolean;
    sticky?: boolean;
    small?: boolean;
    vertical?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  tabs,
  noExtraBorder = false,
  sticky = false,
  small = false,
  vertical = false,
}): React.ReactElement => {
  const classes = cx(
    {
      "tabs-container": true,
      "tabs-container-noextraborder": noExtraBorder,
      "tabs-small": small,
      "tabs-sticky": sticky,
      "tabs-vertical": vertical,
    },
    className
  );

  return (
    <div className={classes}>
      <ReactTabs>
        <TabList data-testid="tab-list">
          {tabs.map(({ id, name }) => (
            <ReactTab key={id || uniqid()}>
              <span className="tab-name">{name}</span>
            </ReactTab>
          ))}
        </TabList>
        {tabs.map(({ id, content }) => (
          <TabPanel key={id || uniqid()}>
            <div className="tab-content-container">{content}</div>
          </TabPanel>
        ))}
      </ReactTabs>
    </div>
  );
};

export { Tabs };
