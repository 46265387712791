import React, { FC, FormEvent, ReactElement } from "react";
import { INewProjectData, INewProjectDataPart, INewProjectDataFeaturesData, IProcessParameter } from "@types";
import { OptionType } from "../Common/Select";
import { ActionMeta, ValueType } from "react-select";
import { FeatureData } from "./FeatureData";

const FeatureProjectSection: FC<{
  changesTracker: number;
  currentPart: INewProjectDataPart | null;
  defaultFeature: INewProjectDataFeaturesData;
  featureData: Map<string, INewProjectDataFeaturesData[]>;
  formData: INewProjectData;
  index: number;
  onClickAddAnotherFeature: (featurePart: INewProjectDataPart) => void;
  part: INewProjectDataPart;
  processes: IProcessParameter[];
  setChangesTracker: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPart: React.Dispatch<React.SetStateAction<INewProjectDataPart | null>>;
  setFeatureData: React.Dispatch<React.SetStateAction<Map<string, INewProjectDataFeaturesData[]>>>;
  setShowAdvancedNotice: React.Dispatch<React.SetStateAction<boolean>>;
  showAdvancedNotice: boolean;
  showMultipleNotice: boolean;
  uniq: string;
}> = ({
  changesTracker,
  currentPart,
  defaultFeature,
  featureData,
  formData,
  index,
  part,
  processes,
  onClickAddAnotherFeature,
  setChangesTracker,
  setCurrentPart,
  setFeatureData,
  setShowAdvancedNotice,
  showAdvancedNotice,
  showMultipleNotice,
  uniq,
}): ReactElement => {
  const featureSizing: OptionType[] = [
    { value: "mm/deg/sec", label: "mm/deg/sec" },
    { value: "in/deg/sec", label: "in/deg/sec" },
  ];

  const features = featureData.get(part?.identifier!);

  return (
    <>
      {features?.map((featurePart, featureIndex) => {
        const changePart = (newFeaturePart: INewProjectDataFeaturesData) => {
          const newMap = new Map(featureData);
          const newFeatures = newMap.get(part?.identifier!) || [];
          newFeatures[featureIndex] = newFeaturePart;
          newMap.set(part?.identifier!, newFeatures);
          setFeatureData(newMap);
        };

        const deletePart = () => {
          const newMap = new Map(featureData);
          const newFeatures = newMap.get(part?.identifier!);

          if (!newFeatures) {
            return;
          }

          const newFeaturesCopy = [...newFeatures.slice(0, featureIndex), ...newFeatures.slice(featureIndex + 1)];

          if (newFeaturesCopy.length === 0) {
            newFeaturesCopy.push(defaultFeature);
          }

          newMap.set(part?.identifier!, newFeaturesCopy);
          setFeatureData(newMap);
        };

        const changeProcess = (process: string) => {
          const newFeaturePart = Object.assign({}, featurePart);

          if (newFeaturePart.process === process) {
            newFeaturePart.process = undefined;
          } else {
            newFeaturePart.process = process;
          }

          changePart(newFeaturePart);
        };

        const changeFeatureName = (e: FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          const newFeaturePart = Object.assign({}, featurePart);
          newFeaturePart.featureName = target.value;
          changePart(newFeaturePart);
        };

        const changeFeatureMeasure = (measureSelection: OptionType) => {
          const newFeaturePart = Object.assign({}, featurePart);
          newFeaturePart.measure = measureSelection.value;
          changePart(newFeaturePart);
        };

        const changeFeatureAdvanced = (e: FormEvent<HTMLInputElement>) => {
          const newFeaturePart = Object.assign({}, featurePart);
          newFeaturePart.advancedOptions = !newFeaturePart.advancedOptions;
          changePart(newFeaturePart);

          if (showAdvancedNotice) {
            setShowAdvancedNotice(false);
          }
        };

        const changeFeatureParams = (e: FormEvent<HTMLInputElement>, parameterName: string) => {
          const target = e.target as HTMLInputElement;
          const newFeaturePart = Object.assign({}, featurePart);
          let value = parseFloat(target.value);
          const parameter = processes.find(
            (p) => p.processName === featurePart.process && p.parameter === parameterName
          );
          if (parameter?.measurementType === "Length") {
            const measure = featurePart.measure;
            if (measure === "in/deg/sec") {
              value = value * 25.4;
            }
          }

          if (!newFeaturePart.parameters) {
            newFeaturePart.parameters = [];
            newFeaturePart.parameters?.push({ parameterName: parameterName, parameterValue: value });
          } else {
            const param = newFeaturePart.parameters?.find((p) => p.parameterName === parameterName);
            if (param) {
              param.parameterValue = value;
            } else {
              newFeaturePart.parameters?.push({
                parameterName: parameterName,
                parameterValue: value,
              });
            }
          }

          changePart(newFeaturePart);
        };

        const copyFromPart = (value: ValueType<OptionType>, actionMeta: ActionMeta<OptionType>) => {
          // @ts-ignore
          const copyFrom = formData.part.find((part) => part.name === value?.value);

          if (copyFrom) {
            const newFeatureData = new Map(featureData);
            const features = newFeatureData.get(copyFrom?.identifier!);

            if (features) {
              newFeatureData.set(part?.identifier!, [...features]);
              setFeatureData(newFeatureData);
            }
          }
        };

        const onClickDone = (e: FormEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          setCurrentPart(null);
          setChangesTracker(changesTracker + 1);
        };

        const partOptions: OptionType[] = formData.part
          .filter((tempPart) => part !== tempPart)
          .map((part) => {
            return {
              value: part.name,
              label: part.name,
            };
          });

        return (
          <React.Fragment key={uniq}>
            <FeatureData
              deletePart={deletePart}
              part={part}
              currentPart={currentPart!}
              featurePart={featurePart}
              changeProcess={changeProcess}
              changeFeatureName={changeFeatureName}
              changeFeatureMeasure={changeFeatureMeasure}
              changeFeatureAdvanced={changeFeatureAdvanced}
              changeFeatureParams={changeFeatureParams}
              onClickAddAnotherFeature={onClickAddAnotherFeature}
              showAdvancedNotice={showAdvancedNotice}
              showMultipleNotice={showMultipleNotice}
              partOptions={partOptions}
              featureSizing={featureSizing}
              processes={processes}
              features={features}
              featureIndex={featureIndex}
              index={index}
              copyFromPart={copyFromPart}
              onClickDone={onClickDone}
              withMargin={featureIndex > 0}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export { FeatureProjectSection };
