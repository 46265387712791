import React, { FC, FormEvent, HTMLAttributes, ReactElement, useState } from "react";
import { default as cx } from "classnames";

import { Button } from "../Button";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { Textarea } from "../Common/Textarea";

const ModalEndSession: FC<
  {
    className?: string;
    onCloseModal?: () => void;
    onDone: (notes: string) => void;
    isDestructive?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({ className = "", onCloseModal, onDone }): ReactElement => {
  const classes = cx(
    {
      "modal-end-session": true,
    },
    className
  );

  const [notes, setNotes] = useState<string>("");

  const updateNotes = (e: FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setNotes(target.value);
  };

  return (
    <Modal className={classes} width={600} onCloseModal={onCloseModal}>
      <Typography className="modal-end-session-title" type={ElementType.h4}>
        End Session
      </Typography>
      <Typography className="modal-end-session-subtitle" color={Color.Secondary}>
        Please provide any session notes in summary.
      </Typography>
      <Textarea className="modal-end-session-notes" value={notes} width="100%" onChange={updateNotes} />
      <div className="modal-end-session-buttons">
        <Button
          className="modal-end-session-buttons-cancel"
          size={"large"}
          themeType="secondary"
          onClick={onCloseModal}
        >
          Cancel
        </Button>
        <Button size="large" themeType="clear" onClick={() => onDone(notes)}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export { ModalEndSession };
