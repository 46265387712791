import * as React from "react";
import { default as cx } from "classnames";

const Row: React.FC<
  {
    className?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", children }): React.ReactElement => {
  const classes = cx(
    {
      "custom-table-row": true,
    },
    className
  );

  return <div className={classes}>{children}</div>;
};

export { Row };
