import * as React from "react";
import { default as cx } from "classnames";

const Skeleton: React.FC<
  {
    className?: string;
    width: null | number | string | "auto" | "initial" | "inherit";
    height: null | number | string | "auto" | "initial" | "inherit";
    marginLeft?: null | number | string | "auto" | "initial" | "inherit";
    marginRight?: null | number | string | "auto" | "initial" | "inherit";
    marginTop?: null | number | string | "auto" | "initial" | "inherit";
    marginBottom?: null | number | string | "auto" | "initial" | "inherit";
    backgroundColor?: string;
    borderRadius?: number;
    flex?: boolean;
    flexDirection?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  width = 100,
  height = 100,
  marginLeft = "",
  marginRight = "",
  marginTop = "",
  marginBottom = "",
  borderRadius = 15,
  backgroundColor = "",
  ...props
}): React.ReactElement => {
  const classes = cx(
    {
      skeleton: true,
    },
    className
  );

  const style: any = {};
  if (width !== null) {
    style.width = width;
  }
  if (height !== null) {
    style.height = height;
  }

  if (borderRadius !== null) {
    style.borderRadius = borderRadius;
  }

  if (backgroundColor !== null) {
    style.backgroundColor = backgroundColor;
  }
  if (marginLeft !== null) {
    style.marginLeft = marginLeft;
  }
  if (marginRight !== null) {
    style.marginRight = marginRight;
  }
  if (marginTop !== null) {
    style.marginTop = marginTop;
  }
  if (marginBottom !== null) {
    style.marginBottom = marginBottom;
  }

  return (
    <div className={classes} style={style}>
      {children}
    </div>
  );
};

export { Skeleton };
