import React, { FC, HTMLAttributes, ReactElement, useEffect, useState } from "react";
import { default as cx } from "classnames";
import styleVariables from "@styles/export.scss";
import { Button } from "../Button";
import { Typography, ElementType, Color } from "../Common/Typography";
import { ICameraPreset } from "@types";
import { Icon } from "../Icon";
import { wsConnect } from "../VideoElement/wcConnectHelper";
import { OptionType, Select } from "../Common/Select";
import {
  calculateLivePlayheadPosition,
  calculateSliderValue,
  formatDate,
  formatSecondsToHHMMSS,
} from "../CameraStreams/helpers";
import { GoBack } from "../Playback/playbackIcons";
import { VolumeControl } from "../CameraControls/VolumeControl";
import { ProgressBar } from "../CameraControls/ProgressBar";
import { VideoQualityControl } from "../CameraControls/VideoQualityControl";
import { VideoPlayer } from "../VideoComponent";

const ArrowIcon: FC<
  {
    position: "top" | "left" | "right" | "bottom";
  } & HTMLAttributes<HTMLOrSVGElement>
> = ({ position, ...other }): ReactElement => (
  <svg
    {...other}
    className={`fullscreen-live-video-bar-arrow fullscreen-live-video-bar-arrow-${position}`}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      className="fullscreen-live-video-bar-arrow-ellipse"
      cx="12"
      cy="12"
      rx="12"
      ry="12"
      fill={styleVariables.color_gray}
      fillOpacity="1.0"
    />
    <path
      d="M14.75 7.5C14.75 6.79688 13.8906 6.44531 13.3828 6.95312L8.38281 11.9531C8.07031 12.2656 8.07031 12.7734 8.38281 13.0859L13.3828 18.0859C13.8906 18.5938 14.75 18.2422 14.75 17.5391V7.5Z"
      fill="white"
    />
  </svg>
);

const FullscreenVideoElement: React.FC<
  {
    className?: string;
    height: number;
    width: number;
    isLive: boolean;
    volume: number;
    _player: any;
    videoQuality: number;
    applicationName: string;
    streamName: string;
    cameraPresets?: ICameraPreset[];
    lastCameraPreset?: ICameraPreset;
    setCameraPreset?: (preset: number) => void;
    takeSnapshot?: (data: string, timestamp: string) => void;
    loading: boolean;
    toggleIsLive: () => void;
    setRewind: (value: boolean) => void;
    setVolume: (value: number) => void;
    setProgress: (value: string) => void;
    progress: string;
    onSliderInput: (value: number) => void;
    onSliderChange: () => void;
    playerOne: any;
    playerTwo: any;
    playerThree: any;
    setPlayerOne: any;
    setPlayerTwo: any;
    setPlayerThree: any;
    setPlayer3Loading: (value: boolean) => void;
    isPlaying: boolean;
    synchronize: any;
    togglePlay: () => void;
    toggleChannel: () => void;
    url1: string;
    url2: string;
    setVideoQuality: any;
    currentTime: string;
    isSliding: boolean;
    rewind: boolean;
    setCurrentTime: (value: string) => void;
    setPTR: (command: string) => void;
    videoFrameThree: any;
    setVideoFrameThree: any;
    quality1: number;
    quality2: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  height,
  width,
  isLive,
  volume,
  _player,
  videoQuality,
  applicationName,
  streamName,
  cameraPresets,
  lastCameraPreset,
  setCameraPreset,
  takeSnapshot,
  loading,
  toggleIsLive,
  setRewind,
  setVolume,
  progress,
  onSliderInput,
  onSliderChange,
  playerOne,
  playerTwo,
  playerThree,
  setPlayerOne,
  setPlayerTwo,
  setPlayerThree,
  setPlayer3Loading,
  synchronize,
  isPlaying,
  toggleChannel,
  togglePlay,
  currentTime,
  setVideoQuality,
  setProgress,
  isSliding,
  rewind,
  url1,
  url2,
  setCurrentTime,
  setPTR,
  videoFrameThree,
  setVideoFrameThree,
  quality1,
  quality2,
}): React.ReactElement => {
  const classes = cx("fullscreen-video-element", className);

  const elementLive = React.createRef<HTMLVideoElement>();
  const [preset, setPreset] = useState<ICameraPreset>();
  const stopFunction = React.useRef(() => {});
  const fullScreenVideoElement = React.createRef<HTMLDivElement>();
  const [isPlayerThreeReady, setPlayerThreeReady] = useState(false);
  const source1 = {
    sources: [
      {
        src: url1,
        type: "application/x-mpegurl",
      },
    ],
  };

  const videoJsOptions1 = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    crossOrigin: "anonymous",
    sources: [
      {
        src: url1,
        type: "application/x-mpegurl",
      },
    ],
  };

  const videoJsOptions2 = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: false,
    responsive: true,
    fluid: true,
    crossOrigin: "anonymous",
    sources: [
      {
        src: url2,
        type: "application/x-mpegurl",
      },
    ],
  };

  const videoRef = React.createRef();

  const handlePlayerReady = (player: any, video: any) => {
    setPlayerThree(player);
    setVideoFrameThree(video);
    setPlayerThreeReady(true);
  };

  useEffect(() => {
    if (isPlayerThreeReady && playerThree) {
      playerThree.on(["seeking", "waiting"], function () {
        setPlayer3Loading(true);
      });
      playerThree.on("ended", function () {
        setPlayer3Loading(false);
        togglePlay();
      });

      playerThree.on("playing", function () {
        setPlayer3Loading(false);
      });
      playerThree.on("timeupdate", function () {
        if (playerThree) {
          const isLive = videoFrameThree.duration === Infinity;
          if (!isLive) {
            // if VOD
            setCurrentTime(formatSecondsToHHMMSS(videoFrameThree.currentTime));
          } else if (videoFrameThree.currentProgramDateTime) {
            // if stream exposes PDT timestamp
            setCurrentTime(formatDate(videoFrameThree.currentProgramDateTime));
          } else {
            // if stream doesn't expose PDT timestamp
            const res = calculateLivePlayheadPosition(
              videoFrameThree.currentTime,
              videoFrameThree.seekable.end(videoFrameThree.seekable.length - 1)
            );
            setCurrentTime(formatDate(res));
          }
          if (!isLive && !isSliding) {
            // don't overwrite slider value when we're sliding
            const result = calculateSliderValue(videoFrameThree).toString();
            setProgress(result);
          }
        }
      });
      setPlayerThree(playerThree);

      // player1.muted = true;
      // player2.muted = true;

      if (rewind) {
        //todo - test when the stream is live
        let newTime = 0;
        if (videoFrameThree.currentTime > 30) {
          newTime = videoFrameThree.currentTime - 30;
        }
        if (playerOne && playerTwo) {
          videoFrameThree.currentTime = newTime;
          setPlayerThree(playerThree);
        }
        setRewind(false);
      }

      playerThree.play();

      return () => {
        if (playerThree) {
          try {
            playerThree.destroy();
            setPlayerThree(null);
          } catch (e) {
            console.log(e);
          }
        }
      };
    }
  }, [isLive, isPlayerThreeReady]);

  useEffect(() => {
    if (isLive) {
      if (elementLive && elementLive.current) {
        elementLive.current.muted = false;
        elementLive.current.volume = volume;
      }
    } else {
      if (playerThree) {
        playerThree.muted = false;
        playerThree.volume = volume;
      }
    }
  }, [volume, isLive]);

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  /*useEffect(() => {
    if (!isLive && playerThree) {
      if (videoQuality == 0) {
        playerThree.videoTracks[0].targetQuality = null;
      } else {
        playerThree.videoTracks[0].targetQuality = playerThree.videoTracks[0].qualities.find(
          (q: any) => q.height === videoQuality
        );
      }
    }
  }, [videoQuality, isLive]);*/

  useEffect(() => {
    if (isLive) {
      if (elementLive && elementLive.current) {
        stopFunction.current = wsConnect(
          applicationName,
          streamName,
          "wss://5f5a8800a2db8.streamlock.net/webrtc-session.json",
          elementLive.current
        );
      }
    }
    return () => {
      if (stopFunction.current) {
        stopFunction.current();
      }
    };
  }, [isLive, streamName]);

  useEffect(() => {
    if (lastCameraPreset) {
      setPreset(lastCameraPreset);
    }
    return;
  }, [lastCameraPreset, isLive]);

  const dropdownOptions: OptionType[] = cameraPresets
    ? cameraPresets.map((p) => ({ value: p.presetId!.toString(), label: p.presetName! }))
    : [];

  const onTakeSnapshotClick = () => {
    if (isLive) {
      const element = elementLive.current as any;
      if (element !== null && element.videoWidth > 0 && element.videoHeight > 0) {
        const canvas = document.createElement("canvas")!;
        canvas.width = element.videoWidth;
        canvas.height = element.videoHeight;
        const context = canvas.getContext("2d")!;
        if (context) {
          context.drawImage(element, 0, 0, canvas.width, canvas.height);
          const imageURL = canvas.toDataURL();
          if (takeSnapshot) {
            takeSnapshot(imageURL, new Date().toISOString());
          }
        }
      }
    } else {
      if (_player !== null && _player.videoWidth > 0 && _player.videoHeight > 0) {
        const canvas = document.createElement("canvas")!;
        canvas.width = _player.videoWidth;
        canvas.height = _player.videoHeight;
        const context = canvas.getContext("2d");
        _player.canvas.drawImage(context, 0, 0);
        const imageURL = canvas.toDataURL();
        if (takeSnapshot) {
          const timestamp = calculateLivePlayheadPosition(
            _player.currentTime,
            _player.seekable.end(_player.seekable.length - 1)
          ).toISOString();
          takeSnapshot(imageURL, timestamp);
        }
      }
    }
  };

  const handlePresetChange = (option: OptionType) => {
    if (setCameraPreset && cameraPresets !== undefined) {
      const presetId = parseInt(option.value);
      const newPreset = cameraPresets.find((p) => p.presetId! === presetId);
      setPreset(newPreset);
      setCameraPreset(presetId);
    }
  };

  const renderLiveVideoControls = () => {
    return (
      <div className="fullscreen-live-video-bar">
        <Icon color={styleVariables.color_white} size={24} name={"fas fa-pause"} onClick={toggleIsLive} />
        <div className="fullscreen-live-video-bar">
          <VolumeControl volume={volume} setVolume={setVolume} className="fullscreen-live-video-bar-audio" />
        </div>
        <div
          onKeyDown={toggleIsLive}
          onClick={toggleIsLive}
          role="button"
          tabIndex={0}
          className="fullscreen-live-video-bar-rewind"
        >
          <Icon color={styleVariables.color_red} size={12} name="fas fa-video" />
          <Typography>LIVE</Typography>
        </div>
        <Select
          width={137}
          height={25}
          withBorder={false}
          value={dropdownOptions[preset?.presetId!] || dropdownOptions[0]}
          defaultValue={dropdownOptions[0]}
          options={[...dropdownOptions]}
          onChange={(value) => handlePresetChange(value as OptionType)}
          transparent={true}
        />
        <Button
          iconName="fas fa-repeat-alt"
          themeType={"icon"}
          iconOnly={true}
          color={styleVariables.color_white}
          iconSize={24}
          onClick={toggleChannel}
        />
        {takeSnapshot && (
          <Button
            onClick={onTakeSnapshotClick}
            themeType={"icon"}
            iconOnly={true}
            iconSize={24}
            iconName="far fa-camera"
          />
        )}
        <Button
          onClick={closeFullscreen}
          themeType={"icon"}
          iconOnly={true}
          iconSize={24}
          iconName="fas fa-compress-alt"
        />
      </div>
    );
  };

  const renderHLSVideoControls = () => {
    return (
      <div className="video-bar">
        <div className="video-bar-rest">
          <GoBack
            onClick={() => {
              let newTime = 0;
              if (playerThree && playerThree.currentTime > 30) {
                newTime = playerThree.currentTime - 30;
              }
              if (playerThree) {
                playerThree.currentTime = newTime;
                setPlayerThree(playerThree);
              }
            }}
            height={24}
            width={24}
          />
          <Icon
            color={styleVariables.color_white}
            size={24}
            name={isPlaying ? "fas fa-pause" : "fas fa-play"}
            onClick={togglePlay}
          />
          <VolumeControl volume={volume} setVolume={setVolume} />
          <label>{currentTime}</label>
          <VideoQualityControl setVideoQuality={setVideoQuality} />
        </div>
        <div className="video-bar-progress">
          {!isLive && (
            <ProgressBar
              progress={progress}
              onSliderInput={onSliderInput}
              onSliderChange={onSliderChange}
              videoFrame={videoFrameThree}
              showCurrentVideoTime={false}
              width={130}
            />
          )}
        </div>
        <div className="video-bar-rest">
          <div className="video-bar-live" onKeyDown={toggleIsLive} onClick={toggleIsLive} role="button" tabIndex={0}>
            <Icon color={styleVariables.color_white} size={12} name="fas fa-video" /> <Typography>Live</Typography>
          </div>
          <Button
            iconName="fas fa-repeat-alt"
            themeType={"icon"}
            iconOnly={true}
            color={styleVariables.color_white}
            iconSize={24}
            onClick={toggleChannel}
          />
          <div className="video-bar-snapshot">
            {takeSnapshot && (
              <Button
                onClick={onTakeSnapshotClick}
                themeType={"icon"}
                iconOnly={true}
                iconSize={24}
                iconName="far fa-camera"
              />
            )}
            <Button
              onClick={undefined}
              themeType={"icon"}
              iconOnly={true}
              iconSize={24}
              iconName="fas fa-compress-alt"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <aside className={classes} style={{ height: height, width: width }}>
      <div className="fullscreen-video-element" ref={fullScreenVideoElement}>
        <div className="fullscreen-video-element-top">
          {loading && (
            <div className="video-loading">
              <Icon name="fas fa-spinner fa-spin" size={40} />
            </div>
          )}
          {isLive ? (
            <video width="100%" height="100%" autoPlay playsInline muted ref={elementLive}></video>
          ) : (
            <VideoPlayer
              width="387"
              height="218"
              options={videoJsOptions1}
              onReady={handlePlayerReady}
              videoQuality={videoQuality}
              videoRef={videoRef}
            />
          )}
        </div>
        <div className="fullscreen-video-element-bottom">
          {isLive ? renderLiveVideoControls() : renderHLSVideoControls()}
        </div>
      </div>
    </aside>
  );
};

export { FullscreenVideoElement };
