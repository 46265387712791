import { useState } from "react";
import MaterialService from "../../services/MaterialService";

const useUnitsOfMeasure = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [unitsOfMeasure, setUnitsOfMeasure] = useState<string[]>([]);

  const fetchUnitsOfMeasure = () => {
    const fetchData = async () => {
      setIsLoading(true);
      MaterialService.getUnitsOfMeasure()
        .then((response) => {
          const units = response.data as string[];
          setUnitsOfMeasure(units);

          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return {
    unitsOfMeasure,
    isUnitsOfMeasureLoading: isLoading,
    isUnitsOfMeasureError: isError,
    fetchUnitsOfMeasure,
  };
};

export default useUnitsOfMeasure;
