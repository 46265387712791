// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import {
  Container,
  MachineSessionHeader,
  CardProfile,
  SessionTimer,
  CardMachineStatus,
  CameraStreams,
  LiveSessionTable,
  ScreenshotsOverlay,
  Card,
  ModalEditRunPlan,
  ModalCleaningAndMeasurement,
  FilePaths,
  Skeleton,
  Typography,
  Icon,
  Button,
  ContainerType,
  MachineStatus,
  RunStatus,
  SessionRowStatus,
  ActiveStationSelect,
  ModalEndSession,
  ModalEditZoomInfo,
  ModalUploadMeasurements,
  FullscreenVideoElement,
} from "@namespace/components";
import { IMachineRun, IArcadiaFileUpload, IArcadiaSession, ICellStatus } from "@types";

import CameraService from "../../services/CameraService";
import MachineRunsService from "../../services/MachineRunsService";
import BuilderService from "../../services/BuilderService";
import "./liveSessionPage.scss";
import { allSettled } from "../../utils";
import useNotification from "../../hooks/useNotification";
import useMachineRuns from "../../hooks/API/useMachineRuns";
import useMaterials from "../../hooks/API/useMaterials";
import useCameraPresets from "../../hooks/API/useCameraPresets";
import useExecutionPackages from "../../hooks/API/useExecutionPackages";
import useStatus from "../../hooks/API/useStatus";
import useStreamUrls from "../../hooks/API/useStreamUrls";
import sortRuns from "../../helpers/machineRunsHelper";
import useUploadsHubConnection from "../../hooks/API/useUploadsHubConnection";
import useSessionHubConnection from "../../hooks/API/useSessionHubConnection";
import useStatusHubConnection from "../../hooks/API/useStatusHubConnection";
import useMachineRunHubConnection from "../../hooks/API/useMachineRunHubConnection";
import useZoomMeeting from "../../hooks/API/useZoomMeeting";
import UploadsService from "../../services/UploadsService";
import { mapArcadiaSessionStatusToStatus } from "../../mappers/mappers";
import { ArcadiaSessionStatus } from "../../enums";
import {
  calculateLivePlayheadPosition,
  calculateNewPlayHeadPosition,
  formatDate,
} from "@namespace/components/src/components/CameraStreams/helpers";
import { dataURItoBlob } from "../../helpers/imageHelper";
import useProject from "../../hooks/API/useProject";
import { Color, ElementType } from "@namespace/components/src/components/Common/Typography";
import StatusService from "../../services/StatusService";
import ProjectsService from "../../services/ProjectsService";
import openFullscreen from "@namespace/components/src/helpers/fullscreen";
const checkSvg = require("@namespace/components/src/resources/svg/check.svg") as string;

const LiveSessionPage: React.FC<{ isOperator: boolean } & React.HTMLAttributes<HTMLDivElement>> = ({
  isOperator,
}): React.ReactElement => {
  const { projectId, sessionId } = useParams<{ projectId: string; sessionId: string }>();
  const [screenshots, setScreenshots] = useState<IArcadiaFileUpload[]>([]);
  const [screenshotIndex, setScreenshotIndex] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  const [runForEditing, setRunForEditing] = useState<IMachineRun | null>(null);
  const [showEditPlanModal, setShowEditPlanModal] = useState(false);
  const [showModalCleaning, setShowModalCleaning] = useState(false);
  const [showModalEndSession, setShowModalEndSession] = useState(false);
  const [showModalEditZoomInfo, setShowModalEditZoomInfo] = useState(false);
  const [showModalUploadMeasurements, setShowModalUploadMeasurements] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);

  const [selectedStation, setSelectedStation] = useState<number | null>(null);

  const [fixtureId, setFixtureId] = useState("");

  const [playerOne, setPlayerOne] = useState(null);
  const [playerTwo, setPlayerTwo] = useState(null);
  const [playerThree, setPlayerThree] = useState(null);

  const [player1Loading, setPlayer1Loading] = useState(true);
  const [player2Loading, setPlayer2Loading] = useState(true);
  const [player3Loading, setPlayer3Loading] = useState(true);

  const [currentStreamName, setCurrentStreamName] = useState("channel2.stream_1080p_webrtc");
  const [currentTime, setCurrentTime] = useState("");
  const [volume, setVolume] = useState(0);
  const [videoQuality, setVideoQuality] = useState(1);
  const [progress, setProgress] = useState("1");
  const [isLive, setIsLive] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [rewind, setRewind] = useState<boolean>(false);
  const [isSliding, setIsSliding] = useState(false);
  const [session, setSession] = useState<IArcadiaSession>(null);
  const [currentRunTime, setCurrentRunTime] = useState(0);
  const [videoFrameOne, setVideoFrameOne] = useState(null);
  const [videoFrameTwo, setVideoFrameTwo] = useState(null);
  const [videoFrameThree, setVideoFrameThree] = useState(null);
  const { uploadsHubConnection } = useUploadsHubConnection();
  const { sessionHubConnection } = useSessionHubConnection();
  const { statusHubConnection } = useStatusHubConnection();
  const { machineRunHubConnection } = useMachineRunHubConnection();

  const notification = useNotification();
  const { url1, url2, applicationName, fetchUrls } = useStreamUrls();
  const { status, fetchStatusById } = useStatus();
  const { project, fetchProjectById } = useProject();
  const { runs, fetchMachineRuns } = useMachineRuns();
  const { materials, fixtures, fetchMaterials } = useMaterials();
  const { cameraPresets, lastCameraPreset, fetchLastCameraPreset } = useCameraPresets();
  const { executionPackages, fetchExecutionPackages } = useExecutionPackages();
  const { meeting, fetchZoomMeeting } = useZoomMeeting();

  const history = useHistory();
  const fullScreenComponent = React.createRef();

  const onFullScreenClick = (streamName: string) => {
    setCurrentStreamName(streamName);
    setFullScreen(true);
    let element = fullScreenComponent.current as any;
    openFullscreen(element);
  };

  const onTextAreaChange = (runId: string, value: string) => {
    const run = runs.find((i: any) => i.runId === runId);
    const updatedRun: IMachineRun = { ...run, userNotes: value };
    MachineRunsService.updateRun(updatedRun)
      .then(() => {
        fetchMachineRuns(session.sessionId);
      })
      .catch((e) => console.log(e));
  };

  const updateMachineRuns = async (updatedRuns: IMachineRun[], fixtureId: string) => {
    //get deleted and call delete
    const filteredRuns = runs.filter((run) => run.fixtureId === fixtureId);
    const deletedRuns = filteredRuns.filter((run) => updatedRuns.filter((u) => u.runId === run.runId).length === 0);

    //get new and add them
    const newRuns = updatedRuns.filter((run) => run.isNew);

    //get edited and call edit
    const edited = updatedRuns.filter((run) => !run.isNew);

    const promises = [
      MachineRunsService.createMultipleRuns(project.arcadiaProjectId, session.sessionId, newRuns).catch((e) =>
        console.log(e)
      ),
    ];
    deletedRuns.forEach((run) => {
      promises.push(MachineRunsService.deleteRun(run.runId!));
    });
    promises.push(MachineRunsService.updateMultipleRuns(edited));
    const runOrderUpdatedRuns = updatedRuns.map((r) => ({ runId: r.runId }));
    const runOrderOldRuns = sortRuns(
      runs.filter((r) => r.fixtureId !== fixtureId),
      session.runs!
    ).map((r) => ({ runId: r.runId }));
    promises.push(
      MachineRunsService.reorderRuns(project.arcadiaProjectId, session.sessionId, [
        ...runOrderOldRuns,
        ...runOrderUpdatedRuns,
      ])
    );
    const results = await allSettled(promises);
    if (results.filter((r) => r.status === "rejected")) {
      console.log("An error occurred");
    }
    fetchProjectById(projectId);

    setShowEditPlanModal(false);
  };

  const updateMachineRun = async (updatedRun: IMachineRun) => {
    MachineRunsService.updateRun(updatedRun)
      .then(() => {
        const sessionId = session.sessionId;
        fetchMachineRuns(sessionId);
        setShowModalCleaning(false);
      })
      .catch((e) => console.log(e));
  };

  const deleteItem = (item: IMachineRun) => {
    if (item.runId) {
      MachineRunsService.deleteRun(item.runId)
        .then(() => {
          fetchMachineRuns(session.sessionId);
        })
        .catch((e) => console.log(e));
    }
  };

  const startItem = (item: IMachineRun) => {
    if (item.runId) {
      const sessionId = session.sessionId;
      MachineRunsService.startRun(sessionId, item.runId)
        .then(() => {
          notification(`Run "${item.runName}" started!`, "neutral");
          fetchMachineRuns(session.sessionId);
        })
        .catch((e) => console.log(e));
    }
  };

  const stopItem = (item: IMachineRun) => {
    if (item.runId) {
      const sessionId = session.sessionId;
      MachineRunsService.stopRun(sessionId, item.runId)
        .then(() => {
          notification(`Run "${item.runName}" stopped!`, "neutral");
          fetchMachineRuns(session.sessionId);
        })
        .catch((e) => console.log(e));
    }
  };

  const duplicateItem = (fixtureId: string, runId: string) => {
    const item = runs.find((i: any) => i.runId === runId);
    if (!item) {
      throw new Error("Run not found");
    }
    const newRunId = uuidv4();
    const newItem = {
      ...item,
      runId: newRunId,
      runStatus: SessionRowStatus.NotQueued,
      runName: `Copy of ${item.runName}`,
    };

    MachineRunsService.createRun(project.arcadiaProjectId, session.sessionId, newItem)
      .then(() => {
        const runOrderSameFixture = sortRuns(
          runs.filter((r) => r.fixtureId === fixtureId),
          session.runs!
        ).map((r) => ({
          runId: r.runId,
        }));
        const runOrderOtherFixtures = sortRuns(
          runs.filter((r) => r.fixtureId !== fixtureId),
          session.runs!
        ).map((r) => ({ runId: r.runId }));
        MachineRunsService.reorderRuns(project.arcadiaProjectId, session.sessionId, [
          ...runOrderOtherFixtures,
          ...runOrderSameFixture,
          { runId: newRunId },
        ])
          .then(() => {
            fetchProjectById(projectId);
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const editItem = (runId: string) => {
    const item = runs.find((i: any) => i.runId === runId)!;
    setRunForEditing(item);
    setShowModalCleaning(true);
  };

  const handleClickScreenshot = (screenshots: IArcadiaFileUpload[], index: any) => {
    setScreenshots(screenshots);
    setScreenshotIndex(index);
    setShowOverlay(true);
  };

  const handleCloseClick = () => {
    setShowOverlay(false);
  };

  const handleDelete = (fileUploadId: string) => {
    UploadsService.deleteUpload(projectId, fileUploadId).catch((e) => console.log(e));
  };

  const handleDoneClick = async (uploads: IArcadiaFileUpload[]) => {
    const promises: Promise<any>[] = [];
    uploads.forEach((upload) => {
      promises.push(UploadsService.updateUpload(upload));
    });
    const results = await allSettled(promises);
    if (results.filter((r) => r.status === "rejected")) {
      console.log("An error occurred");
    }
    fetchProjectById(projectId);
    setShowOverlay(false);
  };

  useEffect(() => {
    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fullScreenComponent.current.addEventListener("fullscreenchange", (event) => {
      if (document.fullscreenElement) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectId) {
      fetchProjectById(projectId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (project) {
      const projectId = project.arcadiaProjectId!;
      fetchExecutionPackages(projectId);

      const wantedSession = project.sessions?.find((s) => s.sessionId === sessionId);
      if (wantedSession) {
        setSession(wantedSession);
        fetchZoomMeeting(projectId, wantedSession.sessionId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  useEffect(() => {
    if (session) {
      fetchMachineRuns(session.sessionId);
      fetchUrls(session.sessionId);
      fetchStatusById(session.sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  useEffect(() => {
    if (uploadsHubConnection) {
      uploadsHubConnection.on("fileuploaded", (file: IArcadiaFileUpload) => {
        fetchProjectById(projectId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadsHubConnection]);

  useEffect(() => {
    if (sessionHubConnection) {
      sessionHubConnection.on("startSession", (file: IArcadiaFileUpload) => {
        fetchProjectById(projectId);
        fetchStatusById(sessionId);
      });
      sessionHubConnection.on("stopSession", (file: IArcadiaFileUpload) => {
        fetchProjectById(projectId);
        fetchStatusById(sessionId);
      });
      sessionHubConnection.on("currentMachineRunTime", (seconds: number) => {
        setCurrentRunTime(seconds);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHubConnection]);

  useEffect(() => {
    if (statusHubConnection) {
      statusHubConnection.on("update", (file: IArcadiaFileUpload) => {
        fetchProjectById(projectId);
        fetchStatusById(sessionId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusHubConnection]);

  useEffect(() => {
    if (machineRunHubConnection && session) {
      machineRunHubConnection.on("update", () => {
        const sessionId = session.sessionId;
        fetchMachineRuns(sessionId);
        fetchProjectById(projectId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineRunHubConnection, session]);

  useEffect(() => {
    if (isLive) {
      fetchLastCameraPreset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLive]);

  useEffect(() => {
    if (status) {
      const result = getSelectedStation(status);
      setSelectedStation(result);
    }
  }, [status]);

  const getSelectedStation = (status: ICellStatus) => {
    let result = 4;
    if (status.machiningStation?.state === "Active") {
      result = 1;
    }
    if (status.cleaningStation?.state === "Active") {
      result = 0;
    }
    if (status.measuringStation?.state === "Active") {
      result = 2;
    }
    if (status.scopingStation?.state === "Active") {
      result = 3;
    }

    return result;
  };

  const toggleIsLive = () => {
    setIsLive(!isLive);
    setIsPlaying(true);
  };

  const toggleChannel = () => {
    if (currentStreamName === "channel2.stream_1080p_webrtc") setCurrentStreamName("channel1.stream_1080p_webrtc");
    else setCurrentStreamName("channel2.stream_1080p_webrtc");
  };

  const togglePlay = () => {
    if (!isPlaying) {
      playerOne.play();
      playerTwo.play();
      setIsPlaying(true);
    } else {
      playerOne.pause();
      playerTwo.pause();
      setIsPlaying(false);
    }
  };

  const synchronize = (id: number, players: any[]) => {
    let seekCount = players.length;
    const onSeeked = () => {
      seekCount -= 1;
      // if all players are seeked
      if (seekCount <= 0) {
        for (let i = 0; i < players.length; i++) {
          players[i].playbackRate = 1; // restore video speed
          players[i].off("seeked", onSeeked); // remove listener
        }
      }
    };

    // adjust PDT for all players
    for (let i = 0; i < players.length; i++) {
      players[i].playbackRate = 0; // halt video speed
      players[i].on("seeked", onSeeked); // await seek event
    }

    videoFrameTwo.currentProgramDateTime = videoFrameOne.currentProgramDateTime; // set PDT
  };

  const onSliderInput = (value: string) => {
    if (playerOne) {
      //setIsSliding(true);
      const playheadPosition = calculateNewPlayHeadPosition(value, videoFrameOne);
      if (playheadPosition) {
        setCurrentTime(
          formatDate(
            calculateLivePlayheadPosition(
              playheadPosition,
              videoFrameOne.seekable.end(videoFrameOne.seekable.length > 0 ? videoFrameOne.seekable.length - 1 : 0)
            )
          )
        );

        videoFrameOne.currentTime = playheadPosition;
        videoFrameTwo.currentTime = playheadPosition;
        synchronize(0, [playerOne, playerTwo]);
      }
      setProgress(value);
    }
  };

  const onSliderChange = () => {
    setIsSliding(false);
  };

  const takeSnapshot = (imageData: string, timestamp: string) => {
    const blob = dataURItoBlob(imageData);
    let runId = "";
    let activeRun = runs.find((r) => r.startTime <= timestamp && (!r.endTime || r.endTime >= timestamp));
    if (activeRun) {
      runId = activeRun.runId;
    }
    const fd = new FormData();
    fd.append("formData", blob, `IMG_${moment(timestamp).format("YYYY-MM-DD_THH:mm:ss")}.png`);
    fd.append("projectId", project?.arcadiaProjectId!);
    fd.append("fileName", `IMG_${moment(timestamp).format("YYYY-MM-DD_THH:mm:ss")}`);
    fd.append("description", "Snapshot");
    fd.append("userPath", `${FilePaths.SESSION_SNAPSHOTS}`);
    fd.append("runId", runId);
    fd.append("sessionId", session.sessionId);
    fd.append("correlationTimestamp", timestamp);

    UploadsService.uploadFile(fd)
      .then(() => {
        notification("Snapshot added to run uploads", "neutral");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setCameraPreset = (preset: number) => {
    CameraService.setCameraPreset(preset).catch((e) => {
      console.log(e);
    });
  };

  const setPTR = (command: string) => {
    CameraService.setPTR(command).catch((e) => {
      console.log(e);
    });
  };

  const getInitialDuration = () => {
    let result = null;
    if (runs && runs.length > 0) {
      const runInProgress = runs.find((r) => r.runStatus === RunStatus.Running);
      if (runInProgress) {
        const durationTemp = moment.duration(moment(new Date()).diff(moment(runInProgress.startTime))).asSeconds();
        result = durationTemp;
      } else {
        result = 0;
      }
    }
    return result;
  };
  const getFilteredMaterials = (materials) => {
    if (materials) {
      let materialSKUs = [];
      session?.sessionMaterials?.forEach((sm) => {
        materialSKUs = materialSKUs.concat(sm.materialSKUs);
      });
      const filtered = materials.filter((m) => materialSKUs.indexOf(m.sku) >= 0);
      return filtered;
    }
    return [];
  };

  const handleActiveStationChange = async (stationNumber: number) => {
    try {
      setSelectedStation(stationNumber);
      await StatusService.setActiveStation(stationNumber, sessionId);
      notification("Active Station Changed", "neutral");
      fetchStatusById(sessionId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEndSession = async (notes: string) => {
    setShowEditPlanModal(false);
    try {
      await ProjectsService.stopSession(projectId, sessionId, notes);
      history.push(`/sessionreplay/${projectId}/${sessionId}`);
    } catch (error) {}
  };

  const handleEditZoomInfo = async (meetingNumber: string) => {
    try {
      await ProjectsService.editZoomMeeting(projectId, sessionId, meetingNumber);
      notification("Zoom meeting information updated", "neutral");
      setShowModalEditZoomInfo(false);
      fetchZoomMeeting(project?.arcadiaProjectId, session.sessionId);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadMeasurements = async (files: FormData[]) => {
    const promises: Promise<void | AxiosResponse<any>>[] = [];
    files.forEach((f) => promises.push(UploadsService.uploadFile(f)));
    const results = await allSettled(promises);
    if (results.filter((r) => r.status === "rejected")) {
      console.log("An error occurred");
    }
    setShowModalUploadMeasurements(false);
    notification("Measurements uploaded", "neutral");
  };

  const downloadSpecific = async (programName: string, ncCode: number, parameters: number, jobs: number) => {
    try {
      const response = await BuilderService.downloadSpecific(
        project?.arcadiaProjectId,
        programName,
        ncCode,
        parameters,
        jobs
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${project?.projectName} ${programName}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div ref={fullScreenComponent}>
        {isFullScreen &&
        session &&
        url1 &&
        url2 &&
        cameraPresets &&
        cameraPresets.length > 0 &&
        applicationName !== "" ? (
          <FullscreenVideoElement
            cameraPresets={cameraPresets}
            lastCameraPreset={lastCameraPreset}
            setCameraPreset={setCameraPreset}
            takeSnapshot={takeSnapshot}
            setPTR={setPTR}
            isLive={isLive}
            toggleIsLive={toggleIsLive}
            volume={volume}
            setVolume={setVolume}
            rewind={rewind}
            setRewind={setRewind}
            setVideoQuality={setVideoQuality}
            onSliderInput={onSliderInput}
            onSliderChange={onSliderChange}
            videoQuality={videoQuality}
            progress={progress}
            setProgress={setProgress}
            applicationName={applicationName}
            streamName={currentStreamName}
            url1={url1}
            url2={url2}
            toggleChannel={toggleChannel}
            playerOne={playerOne}
            playerTwo={playerTwo}
            playerThree={playerThree}
            setPlayerOne={setPlayerOne}
            setPlayerTwo={setPlayerTwo}
            setPlayerThree={setPlayerThree}
            setPlayer3Loading={setPlayer3Loading}
            currentTime={currentTime}
            isPlaying={isPlaying}
            togglePlay={togglePlay}
            synchronize={synchronize}
            isSliding={isSliding}
            _player={playerThree}
            videoFrameThree={videoFrameThree}
            setVideoFrameThree={setVideoFrameThree}
          />
        ) : undefined}
      </div>
      {showOverlay && (
        <ScreenshotsOverlay
          screenshots={screenshots}
          screenshotIndex={screenshotIndex}
          handleDoneClick={handleDoneClick}
          handleCloseClick={handleCloseClick}
          handleDelete={handleDelete}
          runName={runs.find((r) => r.runId === screenshots[0].runId)?.runName || ""}
          sessionName={session.sessionName!}
          partName={project.partDetails?.partName!}
          featureName={project.featureDetails?.featureName!}
        />
      )}
      {showModalCleaning && (
        <ModalCleaningAndMeasurement
          run={runForEditing!}
          onCancelClick={() => setShowModalCleaning(false)}
          onDoneClick={updateMachineRun}
          fixtureName={fixtures.find((f) => f.sku === runForEditing?.fixtureId)?.materialName || ""}
        />
      )}
      {showEditPlanModal && (
        <ModalEditRunPlan
          width={400}
          onCancelClick={() => setShowEditPlanModal(false)}
          onDoneClick={updateMachineRuns}
          fixtures={fixtures}
          machineRuns={
            fixtureId
              ? sortRuns(
                  runs.filter((run) => run.fixtureId === fixtureId),
                  session.runs!
                )
              : []
          }
          executionPackages={executionPackages}
          materials={getFilteredMaterials(materials)}
          fixtureId={fixtureId}
          sessionId={session.sessionId || ""}
          runOrder={session.runs || []}
        />
      )}
      {showModalEndSession && (
        <ModalEndSession onCloseModal={() => setShowModalEndSession(false)} onDone={handleEndSession} />
      )}
      {showModalEditZoomInfo && (
        <ModalEditZoomInfo
          onCloseModal={() => setShowModalEditZoomInfo(false)}
          onDone={handleEditZoomInfo}
          meeting={meeting}
          isOperator={isOperator}
        />
      )}
      {showModalUploadMeasurements && (
        <ModalUploadMeasurements
          projectId={project?.arcadiaProjectId!}
          currentFolder={FilePaths.MEASUREMENTS}
          onDone={uploadMeasurements}
          onCloseModal={() => setShowModalUploadMeasurements(false)}
          machineRunId={runs.filter((r) => r.runStatus === RunStatus.Running)[0].runId}
        />
      )}
      <div className="live-session-page-container">
        <div className="live-session-page-left-side">
          {session && status ? (
            <MachineSessionHeader
              sessionTitle={session.sessionName || ""}
              //@ts-ignore
              status={mapArcadiaSessionStatusToStatus(session.status as ArcadiaSessionStatus)}
              height={100}
              isOperator={isOperator}
              handleEndSessionClick={() => setShowModalEndSession(true)}
            />
          ) : (
            <Skeleton className="machine-session-header" width="auto" height={100} borderRadius={0}></Skeleton>
          )}
          {session && session.status !== 3 ? (
            <Container className="live-session-page-left-side-inner skeleton-container" type={ContainerType.PRIMARY}>
              <img className="skeleton-container-image" src={checkSvg} alt="icon" />
              <Typography className="skeleton-container-title" type={ElementType.h3}>
                This session has ended.
              </Typography>
              <Typography className="skeleton-container-subtitle" type={ElementType.subtitle} color={Color.Secondary}>
                Please go to the session replay to review
              </Typography>
              <Button
                size="large"
                onClick={() => {
                  history.push(`/sessionreplay/${projectId}/${sessionId}`);
                }}
              >
                View Session Replay
              </Button>
            </Container>
          ) : (
            <Container className="live-session-page-left-side-inner" type={ContainerType.PRIMARY}>
              {!isFullScreen && url1 && url2 && cameraPresets && cameraPresets.length > 0 && applicationName !== "" ? (
                <CameraStreams
                  height={710}
                  channels={[
                    {
                      text: "Custom View",
                      url: url1,
                    },
                    {
                      text: "Standard View",
                      url: url2,
                    },
                  ]}
                  cameraPresets={cameraPresets}
                  lastCameraPreset={lastCameraPreset}
                  setCameraPreset={setCameraPreset}
                  takeSnapshot={takeSnapshot}
                  setPTR={setPTR}
                  playerOne={playerOne}
                  playerTwo={playerTwo}
                  setPlayerOne={setPlayerOne}
                  setPlayerTwo={setPlayerTwo}
                  setPlayer1Loading={setPlayer1Loading}
                  setPlayer2Loading={setPlayer2Loading}
                  player1Loading={player1Loading}
                  player2Loading={player2Loading}
                  setCurrentTime={setCurrentTime}
                  currentTime={currentTime}
                  isLive={isLive}
                  toggleIsLive={toggleIsLive}
                  volume={volume}
                  setVolume={setVolume}
                  rewind={rewind}
                  setRewind={setRewind}
                  synchronize={synchronize}
                  setVideoQuality={setVideoQuality}
                  onSliderInput={onSliderInput}
                  onSliderChange={onSliderChange}
                  isSliding={isSliding}
                  isPlaying={isPlaying}
                  togglePlay={togglePlay}
                  videoQuality={videoQuality}
                  progress={progress}
                  setProgress={setProgress}
                  applicationName={applicationName}
                  onFullScreenClick={onFullScreenClick}
                  setVideoFrameOne={setVideoFrameOne}
                  videoFrameOne={videoFrameOne}
                  setVideoFrameTwo={setVideoFrameTwo}
                  videoFrameTwo={videoFrameTwo}
                />
              ) : (
                <Card width={435} height={700} hover={false}>
                  <Skeleton marginTop={22} marginLeft={24} width={300} height={30}>
                    <Typography type={ElementType.h5}>Custom View</Typography>
                  </Skeleton>
                  <Skeleton
                    marginTop={18}
                    marginLeft={24}
                    marginBottom={24}
                    borderRadius={2}
                    width={387}
                    height={217}
                    backgroundColor="rgba(143, 146, 161, 0.1)"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                    </div>
                  </Skeleton>
                  <Skeleton marginTop={43} marginLeft={24} width={300} height={30}>
                    <Typography type={ElementType.h5}>Standard View</Typography>
                  </Skeleton>
                  <Skeleton
                    marginTop={15}
                    marginLeft={24}
                    marginBottom={24}
                    borderRadius={2}
                    width={387}
                    height={217}
                    backgroundColor="rgba(143, 146, 161, 0.1)"
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                    </div>
                  </Skeleton>
                </Card>
              )}
              <div className="table-container-div-scrollable-content">
                {session ? (
                  <LiveSessionTable
                    onClickScreenshot={handleClickScreenshot}
                    onClickUploadMeasurements={isOperator ? () => setShowModalUploadMeasurements(true) : undefined}
                    onClickDownloadProgram={isOperator ? downloadSpecific : undefined}
                    machineRuns={runs}
                    runOrder={session ? session.runs! : []}
                    materials={materials}
                    fixtures={fixtures}
                    executionPackages={executionPackages}
                    isReplay={false}
                    deleteItem={deleteItem}
                    duplicateItem={duplicateItem}
                    editItem={editItem}
                    startItem={
                      isOperator && runs.filter((r) => r.runStatus === RunStatus.Running).length === 0
                        ? startItem
                        : undefined
                    }
                    stopItem={isOperator ? stopItem : undefined}
                    onEditPlanClick={(fixtureId: string) => {
                      fetchExecutionPackages(project?.arcadiaProjectId);
                      setShowEditPlanModal(true);
                      setFixtureId(fixtureId);
                    }}
                    uploads={
                      project && project.fileUploads && project?.fileUploads?.filter((u) => u.runId).length > 0
                        ? project?.fileUploads?.filter((u) => u.runId)
                        : []
                    }
                    onTextareaChange={onTextAreaChange}
                    duration={currentRunTime}
                  />
                ) : (
                  <div className={"table-skeleton"}>
                    <Typography type={ElementType.h4}>Run Plan</Typography>
                    <Skeleton
                      className={"table-skeleton-header"}
                      width={930}
                      height={30}
                      marginTop={30}
                      marginLeft={30}
                      marginBottom={25}
                    >
                      <Icon color={"#8F92A1"} name="fal fa-chevron-down" />
                      <Skeleton
                        width={145}
                        height={21}
                        marginLeft={30}
                        borderRadius={15}
                        backgroundColor={"rgba(255, 255, 255, 0.1)"}
                      />
                    </Skeleton>
                    <Skeleton
                      className={"table-skeleton-rows"}
                      width={960}
                      height={598}
                      backgroundColor={"rgba(143, 146, 161, 0.1)"}
                    >
                      <Icon color={"#8F92A1"} name="fas fa-circle-notch fa-spin" size={40} />
                    </Skeleton>
                  </div>
                )}
              </div>
            </Container>
          )}
        </div>

        <Container className="live-session-page-right-side" type={ContainerType.BASE}>
          {status && session && (
            <CardProfile
              position="Operator"
              backgroundImageUrl={status.operatorImageURL}
              name={status?.operatorName}
              contact={status?.operatorContact}
              isOperator={isOperator}
              buttonDisabled={!isOperator && !meeting}
              clickHandler={() => setShowModalEditZoomInfo(true)}
            />
          )}

          <div className="live-session-page-session-timer-container">
            {getInitialDuration() !== null && (
              <SessionTimer
                startDate={session.startTime ? new Date(session.startTime!) : new Date()}
                endDate={session.endTime ? new Date(session.endTime!) : new Date()}
                duration={currentRunTime}
              />
            )}
          </div>
          <div className="live-session-page-session-timer-container">
            {status &&
              (isOperator ? (
                <ActiveStationSelect selectedStation={selectedStation} handleClick={handleActiveStationChange} />
              ) : (
                <CardMachineStatus
                  backgroundImageUrl={status.machiningStation?.imageURL || ""}
                  fileName={status.machineStatus?.currentPartProgram || ""}
                  fileHref={"/"}
                  status={status.machiningStation?.state! as MachineStatus}
                  text={
                    status.machiningStation && status.machiningStation.description
                      ? status.machiningStation.description
                      : ""
                  }
                  backgroundColor={"#ffffff"}
                  subText={status.machiningStation && status.machiningStation.type ? status.machiningStation.type : ""}
                  stations={[status.cleaningStation, status.scopingStation, status.measuringStation]}
                />
              ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default LiveSessionPage;
