import React, { FC, HTMLAttributes, ReactElement } from "react";
import { default as cx } from "classnames";

import { Button } from "../Button";
import { ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";

const ModalConfirm: FC<
  {
    className?: string;
    width: number;
    height?: number;
    title: string;
    text: string;
    buttonCloseText: string;
    buttonConfirmText: string;
    onCloseModal?: () => void;
    onConfirm?: () => void;
    isDestructive?: boolean;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  children,
  className = "",
  onCloseModal,
  onConfirm,
  width,
  title,
  text,
  buttonCloseText,
  buttonConfirmText,
  isDestructive = false,
}): ReactElement => {
  const classes = cx(
    {
      "modal-confirm": true,
    },
    className
  );

  return (
    <Modal overlayClassName="modal-confirm-overlay" className={classes} width={width} onCloseModal={onCloseModal}>
      <Typography className="modal-confirm-title" type={ElementType.h4}>
        {title}
      </Typography>
      <Typography className="modal-confirm-text" type={ElementType.bodySmall}>
        {text}
      </Typography>
      <div className="modal-confirm-buttons">
        <Button onClick={onCloseModal} className="modal-button" size="large" themeType="secondary">
          {buttonCloseText}
        </Button>
        {isDestructive ? (
          <Button onClick={onConfirm} className="modal-button" size="large" themeType={"destructive"}>
            {buttonConfirmText}
          </Button>
        ) : (
          <Button onClick={onConfirm} className="modal-button" size="large" themeType={"clear"}>
            {buttonConfirmText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export { ModalConfirm };
