import { ICellStatus } from "@types";
import { useState } from "react";
import StatusService from "../../services/StatusService";

const useStatus = () => {
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isStatusError, setIsStatusError] = useState(false);
  const [status, setStatus] = useState<ICellStatus | null>(null);

  const [areMachinesLoading, setAreMachinesLoading] = useState(false);
  const [machinesStatusError, setMachinesStatusError] = useState(false);
  const [machines, setMachines] = useState<ICellStatus[] | null>(null);

  const fetchMachines = () => {
    const fetchData = async () => {
      setAreMachinesLoading(true);
      StatusService.get()
        .then((response) => {
          const machineList = response.data as ICellStatus[];
          setMachines(machineList);
          setAreMachinesLoading(false);
        })
        .catch((e) => {
          setAreMachinesLoading(false);
          setMachinesStatusError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  const fetchStatusById = (sessionId: string) => {
    const fetchData = async () => {
      setIsStatusLoading(true);
      StatusService.getById(sessionId)
        .then((response) => {
          const dbStatus = response.data as ICellStatus;
          setStatus(dbStatus);
          setIsStatusLoading(false);
        })
        .catch((e) => {
          setIsStatusLoading(false);
          setIsStatusError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return {
    status,
    isStatusLoading,
    isStatusError,
    fetchStatusById,
    machines,
    areMachinesLoading,
    machinesStatusError,
    fetchMachines,
  };
};

export default useStatus;
