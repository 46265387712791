/* eslint-disable tsc/config */
import React, { FC, ReactElement, useRef, useState } from "react";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { Icon } from "../Icon";
import { useClickOutside } from "../../helpers/hooks";
import { Button } from "../Button";

const DropdownActions: FC<{
  reOrderStarted: boolean;
  onClickRename?: () => void;
  onClickReorder?: () => void;
  onClickDelete?: () => void;
}> = ({
  onClickRename = () => {},
  onClickReorder = () => {},
  onClickDelete = () => {},
  reOrderStarted = false,
}): ReactElement => {
  const [shown, setShown] = useState<boolean>(false);

  const toggleDropdown = (event: any) => {
    setShown(!shown);
  };

  const closeDropdown = () => {
    setShown(false);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, closeDropdown);

  return (
    <span className="dropdown-actions" ref={wrapperRef}>
      <Button onClick={toggleDropdown} iconOnly={true} iconName={"fa fa-ellipsis-h"} themeType="outline" size="small" />
      {shown && (
        <ul className="dropdown-actions-list">
          <li className="dropdown-actions-list-option">
            <div
              role="button"
              className="dropdown-actions-list-option-container"
              onClick={() => {
                setShown(false);
                onClickRename();
              }}
              onKeyPress={() => {
                setShown(false);
                onClickRename();
              }}
              tabIndex={0}
            >
              <Icon
                className="dropdown-actions-list-option-container-icon"
                name="far fa-edit"
                color={styleVariables.color_gray}
                size={22}
              />
              <Typography type={ElementType.inline}>Rename</Typography>
            </div>
          </li>
          <li className="dropdown-actions-list-option">
            <div
              role="button"
              className="dropdown-actions-list-option-container"
              onClick={() => {
                setShown(false);
                onClickReorder();
              }}
              onKeyPress={() => {
                setShown(false);
                onClickReorder();
              }}
              tabIndex={0}
            >
              <Icon
                className="dropdown-actions-list-option-container-icon"
                name="fal fa-arrows"
                color={styleVariables.color_gray}
                size={22}
              />
              <Typography type={ElementType.inline}>{reOrderStarted ? "Stop Re-order" : "Re-order"}</Typography>
            </div>
          </li>
          <li className="dropdown-actions-list-option">
            <div
              role="button"
              className="dropdown-actions-list-option-container"
              onClick={() => {
                setShown(false);
                onClickDelete();
              }}
              onKeyPress={() => {
                setShown(false);
                onClickDelete();
              }}
              tabIndex={0}
            >
              <Icon
                className="dropdown-actions-list-option-container-icon"
                name="fal fa-trash"
                color={styleVariables.color_red}
                size={22}
              />
              <Typography type={ElementType.inline} color={Color.Error}>
                Delete
              </Typography>
            </div>
          </li>
        </ul>
      )}
    </span>
  );
};

export default DropdownActions;
export { DropdownActions };
