import React, { FC, ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  IArcadiaProject,
  INewProjectData,
  INewProjectDataPart,
  INewProjectDataFeaturesData,
  IProcessParameter,
  IArcadiaPartDetails,
  IArcadiaFeatureParameter,
  IArcadiaFeatureDetails,
} from "@types";
import { ProjectOverview } from "./ProjectOverview";
import { mapProjectToFormData } from "../../helpers/mappers";
import { PartModal } from "./PartModal";
import { FeatureModal } from "./FeatureModal";
import { ModalConfirm } from "../ModalConfirm";
import { FilePaths } from "../Folders";

const ProjectSettings: FC<{
  updateProject: (updatedProject: IArcadiaProject) => void;
  uploadDrawings: (files: FormData[]) => void;
  project: IArcadiaProject;
  materialCategories: string[];
  processes: IProcessParameter[];
}> = ({ updateProject, uploadDrawings, project, materialCategories, processes }): ReactElement => {
  const [formData, setFormData] = useState<INewProjectData>({
    projectName: "",
    part: [],
    feature: new Map<string, INewProjectDataFeaturesData[]>(),
  });

  useEffect(() => {
    if (project) {
      const newFormData = mapProjectToFormData(project, materialCategories);
      setFormData(newFormData);
    }
  }, [project]);

  const [part, setPart] = useState<INewProjectDataPart | undefined>();
  const [feature, setFeature] = useState<INewProjectDataFeaturesData | undefined>();

  const [showFeatureModal, setShowFeatureModal] = useState(false);
  const [showPartModal, setShowPartModal] = useState(false);

  const [showConfirmDeletePart, setShowConfirmDeletePart] = useState(false);
  const [showConfirmDeleteFeature, setShowConfirmDeleteFeature] = useState(false);

  const addPart = () => {
    const newPart = {
      cycleSelection: "sec",
      description: "",
      deliveryDate: null,
      desiredCycle: null,
      drawing: [],
      identifier: uuidv4(),
      material: null,
      name: "New Part",
      number: null,
    };
    const featureMap = new Map(formData.feature);
    featureMap.set(newPart.identifier!, []);
    setPart(newPart);
    setShowPartModal(true);
  };

  const defaultFeature: INewProjectDataFeaturesData = {
    featureId: uuidv4(),
    advancedOptions: false,
    measure: "mm/deg/sec",
  };

  const addFeature = (part: INewProjectDataPart) => {
    setPart(part);
    setFeature(defaultFeature);
    setShowFeatureModal(true);
  };

  const handleFeatureClick = (part: INewProjectDataPart, feature: INewProjectDataFeaturesData) => {
    setPart(part);
    setFeature(feature);
    setShowFeatureModal(true);
  };

  const handlePartClick = (part: INewProjectDataPart) => {
    setPart(part);
    setShowPartModal(true);
  };

  const deletePart = () => {
    const updatedProject = { ...project };
    if (updatedProject.partDetails && part) {
      updatedProject.partDetails = [...updatedProject.partDetails?.filter((p) => p.partId !== part.identifier)];
    }
    if (updatedProject.featureDetails && part) {
      updatedProject.featureDetails = [...updatedProject.featureDetails?.filter((f) => f.partId !== part.identifier)];
    }
    updateProject(updatedProject);
    setPart(undefined);
    setShowConfirmDeletePart(false);
  };

  const saveChanges = async () => {
    const updatedProject = { ...project };
    updatedProject.projectName = formData.projectName;
    updatedProject.sessionsPlanned = formData.sessionsPlanned;
    await updateProject(updatedProject);
  };

  const savePart = (part: INewProjectDataPart) => {
    const newPart: IArcadiaPartDetails = {
      partId: part.identifier,
      partName: part.name,
      partDescription: part.description,
      materialCategory: part.material ? part.material!.value : undefined,
      numberOfParts: part.number?.toString(),
      dueDate: part.deliveryDate?.toISOString()!,
      desiredCycleTime: `${Math.floor(part.desiredCycle! / 3600)}:${Math.floor(part.desiredCycle! / 60)}:${
        part.desiredCycle! % 60
      }.0000000`,
    };
    const updatedProject = { ...project };
    if (updatedProject.partDetails) {
      updatedProject.partDetails = [
        ...updatedProject.partDetails?.filter((p) => p.partId !== part.identifier),
        newPart,
      ];
    } else {
      updatedProject.partDetails = [newPart];
    }
    const files: FormData[] = [];
    part.drawing.forEach((d) => {
      const blob = new Blob([d], { type: d.type });
      const fd = new FormData();
      fd.append("formData", blob, d.name);
      fd.append("projectId", updatedProject?.arcadiaProjectId!);
      fd.append("partId", part.identifier!);
      fd.append("fileName", d.name);
      fd.append("userPath", `${FilePaths.PART_DRAWINGS}`);
      files.push(fd);
    });
    uploadDrawings(files);
    updateProject(updatedProject);
    setPart(undefined);
    setShowPartModal(false);
  };

  const saveFeature = (feature: INewProjectDataFeaturesData) => {
    let featureParams: IArcadiaFeatureParameter[] = [];
    if (feature.parameters) {
      featureParams = feature.parameters!.map((param) => ({
        parameterName: param.parameterName,
        parameterValue: param.parameterValue,
      }));
    }
    const newFeature: IArcadiaFeatureDetails = {
      featureId: feature.featureId,
      partId: part!.identifier,
      featureName: feature.featureName,
      measuringUnit: feature.measure,
      processName: feature.process,
      featureParameters: featureParams,
    };
    const updatedProject = { ...project };
    if (updatedProject.featureDetails) {
      updatedProject.featureDetails = [
        ...updatedProject.featureDetails?.filter((f) => f.featureId !== feature.featureId),
        newFeature,
      ];
    } else {
      updatedProject.featureDetails = [newFeature];
    }
    updateProject(updatedProject);
    setPart(undefined);
    setFeature(undefined);
    setShowFeatureModal(false);
  };

  const deleteFeature = () => {
    const updatedProject = { ...project };
    if (updatedProject.featureDetails && feature) {
      updatedProject.featureDetails = [
        ...updatedProject.featureDetails?.filter((f) => f.featureId !== feature.featureId),
      ];
    }
    updateProject(updatedProject);
    setPart(undefined);
    setFeature(undefined);
    setShowConfirmDeleteFeature(false);
  };

  return (
    <div className={"project-settings"}>
      <ProjectOverview
        setFormData={setFormData}
        formData={formData}
        onSave={saveChanges}
        addPart={addPart}
        handlePartClick={handlePartClick}
        addFeature={addFeature}
        handleFeatureClick={handleFeatureClick}
      />

      {showPartModal && part && (
        <PartModal
          onSave={savePart}
          onDelete={() => {
            setShowPartModal(false);
            setShowConfirmDeletePart(true);
          }}
          initialPart={part}
          materialCategories={materialCategories}
          onCloseModal={() => {
            setShowPartModal(false);
            setPart(undefined);
          }}
        />
      )}
      {showFeatureModal && part && feature && (
        <FeatureModal
          onSave={saveFeature}
          onDelete={() => {
            setShowFeatureModal(false);
            setShowConfirmDeleteFeature(true);
          }}
          initialFeature={feature}
          processes={processes}
          part={part}
          onCloseModal={() => {
            setShowFeatureModal(false);
            setPart(undefined);
            setFeature(undefined);
          }}
        />
      )}
      {showConfirmDeletePart && (
        <ModalConfirm
          width={607}
          height={208}
          title="Delete Part?"
          text="This will permanently delete the part and its features."
          buttonCloseText="Cancel"
          buttonConfirmText="Delete"
          isDestructive={true}
          onCloseModal={() => setShowConfirmDeletePart(false)}
          onConfirm={deletePart}
        />
      )}
      {showConfirmDeleteFeature && (
        <ModalConfirm
          width={607}
          height={208}
          title="Delete Feature?"
          text="This will permanently delete the feature."
          buttonCloseText="Cancel"
          buttonConfirmText="Delete"
          isDestructive={true}
          onCloseModal={() => setShowConfirmDeleteFeature(false)}
          onConfirm={deleteFeature}
        />
      )}
    </div>
  );
};

export { ProjectSettings };
