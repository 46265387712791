import React, { FC, ReactElement } from "react";
import { default as cx } from "classnames";
import { Modal } from "../Modal";
import { Button, ButtonThemeType } from "../Button";
import { Typography, ElementType } from "../Common/Typography";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { Checkbox } from "../Common/Checkbox";
const checkSvg = require("../../resources/svg/check.svg") as string;

const ModalWithIcon: FC<{
  svg?: string;
  title: string;
  message: string;
  buttonText: string;
  buttonType?: ButtonThemeType;
  clickHandler: Function;
  footer?: JSX.Element;
  className?: string;
  withCheckbox?: boolean;
  checkboxText?: string;
  checked?: boolean;
  checkHandler?: Function;
  showCloseIcon?: boolean;
  onCloseModal?: () => void;
}> = ({
  title,
  message,
  buttonType = "clear",
  buttonText,
  svg,
  className,
  clickHandler,
  withCheckbox = false,
  checkboxText,
  checked,
  checkHandler,
  showCloseIcon = true,
  onCloseModal,
}): ReactElement => {
  const classes = cx(
    {
      "modal-with-icon": true,
    },
    className
  );
  return (
    <Modal className={classes} width={540} onCloseModal={onCloseModal}>
      {showCloseIcon && (
        <div className="modal-with-icon-close">
          <Icon color={styleVariables.color_white} name="fal fa-times" size={20} />
        </div>
      )}
      <img className="modal-with-icon-icon" src={svg || checkSvg} alt="icon" />
      <Typography className="title" type={ElementType.h3} data-testid="modal-session-title">
        {title}
      </Typography>
      <Typography className="message" type={ElementType.bodySmall}>
        {message}
      </Typography>
      <Button onClick={() => clickHandler()} className="modal-button" size="large" themeType={buttonType}>
        {buttonText}
      </Button>
      {withCheckbox && checked !== undefined && checkHandler !== undefined && (
        <div className="modal-prepare-session-footer">
          <Checkbox checked={checked} onChange={() => checkHandler()} />
          <Typography type={ElementType.bodySmall}>{checkboxText}</Typography>
        </div>
      )}
    </Modal>
  );
};

export { ModalWithIcon };
