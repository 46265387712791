import { Status } from "@namespace/components/src/constants/enums/Status";
import { ArcadiaSessionStatus } from "../enums";

export const mapArcadiaSessionStatusToStatus = (sessionStatus: ArcadiaSessionStatus) => {
  let result: Status = Status.Done;
  switch (sessionStatus) {
    case ArcadiaSessionStatus.Draft:
      result = Status.Draft;
      break;
    case ArcadiaSessionStatus.PendingReview:
      result = Status.InReview;
      break;
    case ArcadiaSessionStatus.Live:
      result = Status.InProgress;
      break;
    case ArcadiaSessionStatus.Completed:
      result = Status.Done;
      break;
    case ArcadiaSessionStatus.Scheduled:
    case ArcadiaSessionStatus.Cancelled:
      result = Status.NotStarted;
      break;
    default:
      break;
  }
  return result;
};
