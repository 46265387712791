import axios from "axios";

const getBalances = (customerId: string) => {
  return axios.get(`customer/customer/${customerId}/balances`);
};

//const getCustomer = (customerId: string) => {
//  return axios.get(`customer/customer/${customerId}`);
//}

const getCustomer = (groupId: string) => {
  return axios.get(`groups/${groupId}`); //the group name is equivalent to the customer name
};

export default {
  getBalances,
  getCustomer,
};
