import React, { FC, HTMLAttributes, FormEvent, ReactElement, useState, useEffect } from "react";
import { Button } from "../Button";
import { Input } from "../Common/Input";
import { Textarea } from "../Common/Textarea";
import { Color, ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { IArcadiaMaterial } from "@types";
import { OptionType, Select } from "../Common/Select";
import { ScrollableContainer } from "../ScrollableContainer";
import { FileUploader } from "../FileUploader";

const ModalAddMaterial: FC<
  {
    className?: string;
    materials: IArcadiaMaterial[];
    initialMaterial?: IArcadiaMaterial;
    isFixture: boolean;
    materialTypes: string[];
    unitsOfMeasure: string[];
    onCloseModal: () => void;
    onDone: (newMaterial: IArcadiaMaterial, files: File[]) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  isFixture,
  materials,
  materialTypes = [],
  initialMaterial,
  unitsOfMeasure = [],
  onCloseModal,
  onDone,
}): ReactElement => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [materialType, setMaterialType] = useState<OptionType | null>(null);
  const [compatibleMaterials, setCompatibleMaterials] = useState<OptionType[]>([]);
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [unitOfMeasure, setUnitOfMeasure] = useState<OptionType | null>(null);
  const [myFiles, setMyFiles] = useState<File[]>([]);
  // const [myFilesBase64, setMyFilesBase64] = useState<{ fileName: string; base64: string }[]>([]);
  const [myFilesUrls, setMyFilesUrls] = useState<string[]>([]);

  const unitOptions = unitsOfMeasure.map((m) => ({ label: m, value: m }));

  useEffect(() => {
    if (initialMaterial) {
      setName(initialMaterial.materialName!);
      setDescription(initialMaterial.description || "");
      if (initialMaterial.imageUrls) {
        setMyFilesUrls(initialMaterial.imageUrls);
      }
      let materialCategory: OptionType | null = null;
      const category = initialMaterial.materialProperties
        ? initialMaterial.materialProperties.find((p) => p.name === "category")
        : null;
      if (category) {
        materialCategory = { label: category.value, value: category.value };
      }
      setMaterialType(materialCategory);
      if (initialMaterial.associatedSKUS && initialMaterial.associatedSKUS.length > 0) {
        const associatedMaterials: (OptionType | null)[] = initialMaterial.associatedSKUS.map((a) => {
          const material = materials.find((m) => m.sku == a);
          if (material) {
            return { label: material.materialName!, value: material.sku! };
          } else {
            return null;
          }
        });
        const result: OptionType[] = [];
        associatedMaterials?.map((m) => {
          if (m) {
            result.push(m);
          }
        });
        setCompatibleMaterials(result);
      }
      if (initialMaterial.serialNumber) {
        setSerialNumber(initialMaterial.serialNumber);
      }
      if (initialMaterial.unitOfMeasure) {
        const option = unitOptions.find((u) => u.value === initialMaterial.unitOfMeasure) || null;
        setUnitOfMeasure(option);
      }
    }
  }, []);

  const updateName = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setName(target.value);
  };

  const updateSerialNumber = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSerialNumber(target.value);
  };

  const updateCompatibleMaterials = (value: OptionType[]) => {
    setCompatibleMaterials(value);
  };

  const updateMaterialType = (value: OptionType) => {
    setMaterialType(value);
  };

  const updateUnitOfMeasure = (value: OptionType) => {
    setUnitOfMeasure(value);
  };

  const updateDescription = (e: FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    setDescription(target.value);
  };

  const handleDoneClick = () => {
    const newMaterial: IArcadiaMaterial = {
      ...initialMaterial,
      materialName: name,
      associatedSKUS: compatibleMaterials.map((m) => m.value),
      materialType: isFixture ? 3 : 1,
      description: description,
      quantityOnHand: 0,
      materialState: 1,
      imageUrls: myFilesUrls,
      lastDateCounted: new Date().toISOString(),
    };
    if (materialType) {
      newMaterial.materialProperties = [{ name: "category", value: materialType.value }];
    }
    if (unitOfMeasure) {
      newMaterial.unitOfMeasure = unitOfMeasure.value;
    }
    onDone(newMaterial, myFiles);
  };

  return (
    <Modal className={`modal-add-material ${className}`} height={"auto"} width={701} onCloseModal={onCloseModal}>
      <ScrollableContainer>
        {!initialMaterial || (initialMaterial.isCustomerMaterial && !initialMaterial.isCustomized) ? (
          <>
            <Typography className="modal-add-material-title" type={ElementType.h4}>
              {`Define your own ${isFixture ? "fixture" : "material"}`}
            </Typography>
            <Typography className="modal-add-material-subtitle" type={ElementType.body} color={Color.Secondary}>
              Add your own proprietary item to your equipment drawer for this project. You can also add proprietary data
              to existing Microlution Boost fixtures and materials. Contact us if you need further support.
            </Typography>
            <Typography type={ElementType.label}>Add Photo</Typography>
            <FileUploader
              showFileNames={false}
              files={myFiles}
              setFiles={setMyFiles}
              fileUrls={myFilesUrls}
              setFileUrls={setMyFilesUrls}
            />

            <div className="modal-add-material-row">
              <div>
                <Input
                  label={`${isFixture ? "Fixture" : "Material"} Name`}
                  onChange={updateName}
                  height={48}
                  placeholder={`${isFixture ? "Fixture" : "Material"} Name`}
                  value={name}
                  width={305}
                />
              </div>
              <div>
                <Select
                  multiselect={true}
                  label={`Compatible ${isFixture ? "materials" : "fixtures"}`}
                  // @ts-ignore
                  onChange={(selected) => updateCompatibleMaterials(selected)}
                  options={materials.map((m) => ({ label: m.materialName!, value: m.sku! }))}
                  // @ts-ignore
                  value={compatibleMaterials}
                  width={305}
                />
              </div>
            </div>

            {isFixture ? (
              <div className="modal-add-material-row">
                <div>
                  <Select
                    label="Unit of Measure"
                    // @ts-ignore
                    onChange={(selected) => updateUnitOfMeasure(selected)}
                    options={unitOptions}
                    // @ts-ignore
                    value={unitOfMeasure}
                    width={305}
                  />
                </div>
                <div>
                  <Input
                    label="Serial Number (Optional)"
                    onChange={updateSerialNumber}
                    height={48}
                    placeholder="Serial Number"
                    value={serialNumber}
                    width={305}
                  />
                </div>
              </div>
            ) : (
              <div className="modal-add-material-row">
                <div>
                  <Select
                    label="Material Type"
                    // @ts-ignore
                    onChange={(selected) => updateMaterialType(selected)}
                    options={materialTypes.map((m) => ({ label: m, value: m }))}
                    // @ts-ignore
                    value={materialType}
                    width={305}
                  />
                </div>
                <div>
                  <Select
                    label="Unit of Measure"
                    // @ts-ignore
                    onChange={(selected) => updateUnitOfMeasure(selected)}
                    options={unitsOfMeasure.map((m) => ({ label: m, value: m }))}
                    // @ts-ignore
                    value={unitOfMeasure}
                    width={305}
                  />
                </div>
              </div>
            )}

            <Typography type={ElementType.label}>{`${isFixture ? "Fixture" : "Material"} Description`}</Typography>
            <Textarea
              onChange={updateDescription}
              placeholder={`${isFixture ? "Fixture" : "Material"} Description`}
              value={description}
              width={"100%"}
            ></Textarea>
          </>
        ) : (
          <>
            <Typography className="modal-add-material-title" type={ElementType.h4}>
              {`Define your own ${isFixture ? "fixture" : "material"}`}
            </Typography>
            <Typography className="modal-add-material-subtitle" type={ElementType.body} color={Color.Secondary}>
              Add proprietary data to or modify an existing Microlution Boost fixtures and materials.
            </Typography>
            <Typography type={ElementType.label}>Add Photo</Typography>
            <FileUploader files={myFiles} setFiles={setMyFiles} fileUrls={myFilesUrls} setFileUrls={setMyFilesUrls} />
            <div>
              <Select
                multiselect={true}
                label={`Compatible ${isFixture ? "Materials" : "Fixtures"}`}
                // @ts-ignore
                onChange={(selected) => updateCompatibleMaterials(selected)}
                options={materials.map((m) => ({ label: m.materialName!, value: m.sku! }))}
                // @ts-ignore
                value={compatibleMaterials}
                width={"100%"}
              />
            </div>
          </>
        )}
        <div className="modal-add-material-buttons">
          <Button size="large" themeType="secondary" onClick={onCloseModal}>
            Cancel
          </Button>
          <Button size="large" themeType="clear" disabled={!name} onClick={handleDoneClick}>
            Done
          </Button>
        </div>
      </ScrollableContainer>
    </Modal>
  );
};

export { ModalAddMaterial };
