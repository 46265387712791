import * as React from "react";
import { ITemplate } from "@types";
import { Card } from "../Card";
import { Thumbnail } from "../Thumbnail";
import { Color, ElementType, Typography } from "../Common/Typography";

const CardTemplate: React.FC<
  {
    className?: string;
    isActive: boolean;
    onClickCard?: () => void;
    template: ITemplate;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ className = "", isActive, onClickCard, template }): React.ReactElement => {
  return (
    <Card
      className={`card-template ${isActive ? "card-template-active" : ""} ${className}`}
      height={90}
      hover={true}
      onClick={onClickCard}
      width={360}
    >
      <Thumbnail
        className={"card-template-thumbnail"}
        title={template.name}
        round={false}
        width={36}
        height={36}
        borderRadius={4}
        backgroundImageUrl={template.image}
      />
      <div className="card-template-column">
        <Typography type={ElementType.h6}>{template.name}</Typography>
        <Typography type={ElementType.bodySmall} color={Color.OffWhite}>
          {template.shortDescription}
        </Typography>
      </div>
    </Card>
  );
};

export default CardTemplate;
export { CardTemplate };
