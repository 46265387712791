import { MsalAuthProvider, IMsalAuthProviderConfig, LoginType } from "react-aad-msal";
import { Configuration, AuthenticationParameters } from "msal";

const config: Configuration = {
  auth: {
    clientId: "d43c6832-c0b2-4234-b814-45d6050c4ff7",
    authority: "https://login.microsoftonline.com/fbd73d04-5a24-471c-a3b1-b11be4b5306c/",
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};
const authenticationParameters: AuthenticationParameters = {
  scopes: ["api://d43c6832-c0b2-4234-b814-45d6050c4ff7/User.All"],
};

const authOptions: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, authOptions);
