import React, { FormEvent, ReactElement, useState } from "react";
import { default as cx } from "classnames";
import { Input } from "../Common/Input";
import { Button } from "../Button";

const Search: React.FC<
  {
    className?: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, className = "" }): ReactElement => {
  const [showInput, setShowInput] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const classes = cx(
    {
      search: true,
    },
    className
  );

  const handleSearchButtonClick = () => {
    setShowInput(true);
  };

  const updateSearchValue = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    const element = event.target as HTMLInputElement;
    setSearchValue(element.value);
  };

  const handleBlur = () => {
    if (!searchValue) {
      setShowInput(false);
    }
  };

  return (
    <div className={classes}>
      {showInput && (
        <Input
          containerClassName="search-bar-input-container"
          className="search-bar-input"
          autoFocus={true}
          value={searchValue}
          onChange={updateSearchValue}
          onBlur={handleBlur}
          rightIconName="fa fa-search"
          iconSize={20}
        />
      )}
      {!showInput && (
        <Button
          className="nav-button"
          themeType={"simple"}
          size={"medium"}
          iconOnly={true}
          navButton={true}
          onClick={handleSearchButtonClick}
          iconName={"fas fa-search"}
        />
      )}
      {children}
    </div>
  );
};

export { Search };
