import { IArcadiaBalance, IArcadiaUserInfo, ITab } from "@types";
import { ElementType, Tabs, Typography } from "@namespace/components";
import React, { FC, ReactElement } from "react";
import uniqid from "uniqid";
import ProfileTab from "./ProfileTab";
import AccountBalanceTab from "./AccountBalanceTab";
import "./accountPage.scss";

const AccountPage: FC<{
  initialUser: IArcadiaUserInfo;
  balances: IArcadiaBalance[];
  onCancelClick: () => void;
  onUpdateClick: (user: IArcadiaUserInfo) => void;
}> = ({ initialUser, balances, onCancelClick, onUpdateClick }): ReactElement => {
  const tabs: ITab[] = [
    {
      name: "Profile",
      content: <ProfileTab initialUser={initialUser} onCancelClick={onCancelClick} onUpdateClick={onUpdateClick} />,
      id: uniqid(),
    },
    {
      name: "Account Balance",
      content: <AccountBalanceTab balances={balances} />,
      id: uniqid(),
    },
  ];
  return (
    <>
      <div className="account-page">
        <div className="account-page-title">
          <Typography type={ElementType.h1}>Account Settings</Typography>
        </div>
        <Tabs className="account-page-tabs" vertical={true} tabs={tabs} />
      </div>
    </>
  );
};

export default AccountPage;
