import { IOperator } from "@types";
import { useState } from "react";
import OperatorService from "../../services/OperatorService";

const useOperators = () => {
  const [isOperatorsLoading, setIsLoading] = useState(false);
  const [isOperatorsError, setIsError] = useState(false);
  const [operators, setOperators] = useState<IOperator[]>([]);

  const fetchOperators = (projectId: string) => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      OperatorService.getOperators()
        .then((response: any) => {
          const operators: IOperator[] = response.data as IOperator[];
          setOperators(operators);
          setIsError(false);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsError(true);
          setIsLoading(false);
        });
    };
    fetchData();
  };

  return {
    operators,
    isOperatorsLoading,
    isOperatorsError,
    fetchOperators,
  };
};

export default useOperators;
