import * as React from "react";
import { Icon } from "../Icon";
import { Thumbnail } from "../Thumbnail";
import { Typography, ElementType, Color } from "../Common/Typography";
import styleVariables from "@styles/export.scss";
import { SessionRowDetails } from "../SessionRowDetails";
import { useState } from "react";
import LiveSessionDropdownActions from "../LiveSessionDropdownActions";
import { IArcadiaFileUpload, IArcadiaMaterial } from "@types";
import { Button } from "../Button";

export interface Snapshot {
  id: number;
  imageUrl: string;
  title: string;
}

export enum SessionRowStatus {
  Unknown = 0,
  NotQueued = 1,
  Queued = 2,
  Running = 3,
  Complete = 4,
  Aborted = 5,
}

export enum RunStatus {
  Unknown = 0,
  NotQueued = 1,
  Queued = 2,
  Running = 3,
  Complete = 4,
  Aborted = 5,
}

const SessionRow: React.FC<
  {
    className?: string;
    status: SessionRowStatus;
    backgroundImageUrl: string;
    backgroundColor?: string;
    runName: string;
    duration: string;
    material: IArcadiaMaterial;
    program: string;
    parameters?: string;
    jobs?: string;
    ncCode?: string;
    parametersId?: number;
    jobsId?: number;
    ncCodeId?: number;
    snapshots: IArcadiaFileUpload[];
    onClickScreenshot: Function;
    onClickDownloadProgram: Function;
    onClickUploadMeasurements?: () => void;
    onClickCopy?: Function;
    onClickEdit?: Function;
    onClickDelete?: Function;
    onClickStart?: Function;
    onJumpTo?: () => void;
    onTextareaChange: Function;
    notes: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  status,
  backgroundImageUrl,
  backgroundColor,
  runName,
  duration,
  material,
  program,
  parameters = "",
  jobs = "",
  ncCode = "",
  parametersId,
  jobsId,
  ncCodeId,
  snapshots,
  onClickCopy = undefined,
  onClickEdit = undefined,
  onClickDelete = undefined,
  onClickStart = undefined,
  onJumpTo = undefined,
  onTextareaChange,
  onClickScreenshot,
  onClickDownloadProgram,
  onClickUploadMeasurements,
  notes,
}): React.ReactElement => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="session-row-container">
      <div className="session-row">
        <div
          className="session-row-arrow"
          role="button"
          tabIndex={0}
          onKeyDown={() => setExpanded(!expanded)}
          onClick={() => setExpanded(!expanded)}
        >
          {status === SessionRowStatus.Complete && (
            <Icon
              name={`fas fa-caret-${expanded === true ? "down" : "right"}`}
              size={18}
              color={styleVariables.color_gray}
            />
          )}
        </div>
        <div className="session-row-with-border">
          <div className="session-row-container-thumbnail-runname">
            <div className="session-row-thumbnail-container">
              {status === SessionRowStatus.Complete && (
                <React.Fragment>
                  <Thumbnail
                    title={""}
                    backgroundImageUrl={
                      snapshots && snapshots.length > 0 ? snapshots[0].imageUri_Small! : backgroundImageUrl
                    }
                    backgroundColor={backgroundColor}
                    width={24}
                    height={24}
                    borderRadius={4}
                  />
                  {snapshots && snapshots.length > 0 && (
                    <div className="session-row-thumbnail-number">{snapshots.length}</div>
                  )}
                </React.Fragment>
              )}
              {(status === SessionRowStatus.NotQueued ||
                status === SessionRowStatus.Queued ||
                status === SessionRowStatus.Unknown) && (
                <div className="session-row-empty-thumbnail">
                  {/* <Icon name="fal fa-camera" size={8} color="rgba(143, 146, 161, 0.6)" /> */}
                </div>
              )}
            </div>
            <div className="session-row-title" data-testid="session-row-title">
              <Typography>{runName}</Typography>
              <Typography type={ElementType.bodySmall} color={Color.Secondary}>
                {status === SessionRowStatus.Complete ? duration : "Not Started"}
              </Typography>
            </div>
          </div>
          <Typography className="session-row-value-column" type={ElementType.bodySmall}>
            {program}
          </Typography>
          <Typography className="session-row-value-column" type={ElementType.bodySmall}>
            {ncCode}
          </Typography>
          <Typography className="session-row-value-column" type={ElementType.bodySmall}>
            {parameters}
          </Typography>
          <Typography className="session-row-value-column-small" type={ElementType.bodySmall}>
            {jobs}
          </Typography>
          {(onClickCopy || onClickEdit || onClickDelete || onClickStart) && (
            <div className="dropdown-actions-container">
              <LiveSessionDropdownActions
                onClickCopy={onClickCopy}
                onClickEdit={onClickEdit}
                onClickDelete={
                  status !== SessionRowStatus.Complete && status !== SessionRowStatus.Queued ? onClickDelete : undefined
                }
                onClickStart={onClickStart}
              />
            </div>
          )}
          {onJumpTo && (
            <Button
              onClick={onJumpTo}
              themeType="simple"
              iconOnly={true}
              iconName={"far fa-play-circle"}
              iconSize={20}
              iconColor={styleVariables.color_gray}
            />
          )}
        </div>
      </div>
      {expanded === true && status === SessionRowStatus.Complete && (
        <div className="session-row-expanded">
          <SessionRowDetails
            snapshots={snapshots}
            status={status}
            onClickScreenshot={onClickScreenshot}
            onClickDownloadProgram={onClickDownloadProgram}
            onClickUploadMeasurements={onClickUploadMeasurements}
            program={program}
            ncCodeId={ncCodeId!}
            parametersId={parametersId!}
            jobsId={jobsId!}
            material={material}
            onTextareaChange={onTextareaChange}
            notes={notes}
          />
        </div>
      )}
    </div>
  );
};

export { SessionRow };
