import axios from "axios";
import { IArcadiaRun, IMachineRun } from "@types";

const getAllSessionRuns = (sessionId: string) => {
  return axios.get(`/machineruns/${sessionId}`);
};

const getRunById = (sessionId: string, runId: string) => {
  return axios.get(`/machineruns/${sessionId}/${runId}`);
};

const createRun = (projectId: string, sessionId: string, data: IMachineRun) => {
  return axios.post(`/machineruns/${projectId}/${sessionId}`, data);
};

const createMultipleRuns = (projectId: string, sessionId: string, data: IMachineRun[]) => {
  return axios.post(`/machineruns/${projectId}/${sessionId}/bulkadd`, data);
};

const updateRun = (data: IMachineRun) => {
  return axios.put(`/machineruns/update`, data);
};

const updateMultipleRuns = (data: IMachineRun[]) => {
  return axios.put(`/machineruns/updatemany`, data);
};

const deleteRun = (runId: string) => {
  return axios.delete(`/machineruns/${runId}`);
};

const reorderRuns = (projectId: string, sessionId: string, data: IArcadiaRun[]) => {
  return axios.put(`/machineruns/${projectId}/${sessionId}/order`, data);
};

const startRun = (sessionId: string, runId: string) => {
  return axios.put(`/machineruns/${sessionId}/${runId}/start`);
};

const stopRun = (sessionId: string, runId: string) => {
  return axios.put(`/machineruns/${sessionId}/${runId}/stop`);
};

export default {
  getAllSessionRuns,
  getRunById,
  createRun,
  createMultipleRuns,
  updateRun,
  updateMultipleRuns,
  deleteRun,
  reorderRuns,
  startRun,
  stopRun,
};
