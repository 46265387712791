import axios from "axios";
import { IAddStatus } from "@types";

const get = () => {
  return axios.get(`/status/`);
};

const getById = (id: any) => {
  return axios.get(`/status/${id}`);
};

const create = (data: IAddStatus) => {
  return axios.post("/status", data);
};

const setActiveStation = (stationNumber: number, sessionId: string) => {
  return axios.put(`/status/setactivestation?station=${stationNumber}&id=${sessionId}`);
};

const update = (id: any, data: IAddStatus) => {
  return axios.put(`/status/${id}`, data);
};

const remove = (id: any) => {
  return axios.delete(`/status/${id}`);
};

export default {
  get,
  getById,
  create,
  update,
  remove,
  setActiveStation,
};
