import { IMachineRun } from "@types";
import { useState } from "react";
import MachineRunsService from "../../services/MachineRunsService";

const useMachineRuns = () => {
  const [isRunsLoading, setIsLoading] = useState(false);
  const [isRunsError, setIsError] = useState(false);

  const [runs, setRuns] = useState<IMachineRun[]>([]);

  const fetchMachineRuns = (sessionId: string) => {
    const fetchData = async () => {
      if (sessionId) {
        setIsError(false);
        setIsLoading(true);
        MachineRunsService.getAllSessionRuns(sessionId)
          .then((response: any) => {
            let result = response.data as IMachineRun[];
            result = result.filter((r) => r !== null);
            setRuns(result);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    fetchData();
  };

  return { runs, setRuns, isRunsLoading, isRunsError, fetchMachineRuns };
};

export default useMachineRuns;
