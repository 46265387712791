import React from "react";
import moment from "moment";

import { Typography, Color } from "../Common/Typography";

const ProgramInfoCard: React.FC<
  {
    dateCreated: string;
    createdBy: string;
    ncCodeDateUpdated: string;
    ncCodeUpdatedBy: string;
    parametersDateUpdated: string;
    parametersUpdatedBy: string;
    jobsDateUpdated: string;
    jobsUpdatedBy: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  dateCreated,
  createdBy,
  ncCodeDateUpdated,
  ncCodeUpdatedBy,
  parametersDateUpdated,
  parametersUpdatedBy,
  jobsDateUpdated,
  jobsUpdatedBy,
}): React.ReactElement => {
  const format = "MM/DD/YY hh:mm:ss A";
  return (
    <>
      <div className="program-info-card">
        <div className="program-info-card-row">
          <Typography color={Color.Secondary}>Created by</Typography>
          <Typography color={Color.OffWhite}>{createdBy}</Typography>
        </div>
        <div className="program-info-card-row">
          <Typography color={Color.Secondary}>NC Code Updated</Typography>
          <Typography color={Color.OffWhite}>{moment(ncCodeDateUpdated).format(format)}</Typography>
        </div>
        <div className="program-info-card-row-pull-right">
          <Typography color={Color.Secondary}>{`by ${ncCodeUpdatedBy}`}</Typography>
        </div>
        <div className="program-info-card-row">
          <Typography color={Color.Secondary}>Parameters Updated</Typography>
          <Typography color={Color.OffWhite}>{moment(parametersDateUpdated).format(format)}</Typography>
        </div>
        <div className="program-info-card-row-pull-right">
          <Typography color={Color.Secondary}>{`by ${parametersUpdatedBy}`}</Typography>
        </div>
        <div className="program-info-card-row">
          <Typography color={Color.Secondary}>Jobs Updated</Typography>
          <Typography color={Color.OffWhite}>{moment(jobsDateUpdated).format(format)}</Typography>
        </div>
        <div className="program-info-card-row-pull-right">
          <Typography color={Color.Secondary}>{`by ${jobsUpdatedBy}`}</Typography>
        </div>
      </div>
    </>
  );
};

export { ProgramInfoCard };
