import { useEffect, useState } from "react";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { authProvider } from "../../auth/authProvider";
import useNotification from "../../hooks/useNotification";

const useSessionHubConnection = () => {
  const [sessionHubConnection, setSessionHubConnection] = useState<HubConnection>();
  const notification = useNotification();

  useEffect(() => {
    if (sessionHubConnection) {
      sessionHubConnection.on("startSession", () => {
        notification("Live Session Started", "alert");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionHubConnection]);

  useEffect(() => {
    const createHubConnection = async () => {
      const token = await authProvider.acquireTokenSilent({
        scopes: ["api://d43c6832-c0b2-4234-b814-45d6050c4ff7/User.All"],
      });
      const hubConnect = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_BASE_API_URL}/hubs/SessionR`, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
          accessTokenFactory: () => {
            return token.accessToken;
          },
        })
        .build();
      try {
        await hubConnect.start();
      } catch (e) {
        console.log(e);
      }
      setSessionHubConnection(hubConnect);
    };

    createHubConnection();
  }, []);

  return { sessionHubConnection };
};

export default useSessionHubConnection;
