import React, { useState } from "react";

import { Typography, ElementType, Color } from "../Common/Typography";
import { IExecutionPackage } from "@types";
import { ProgramDetailsCard } from "../ProgramDetailsCard";
import { Button } from "../Button";
import { ModalUploadProgramPart } from "../ModalUploadProgramPart";
import { ProgramInfoCard } from "../ProgramInfoCard";
import DropdownMenu from "../DropdownMenu";
const ncCodeSvg = require("./ncCode.svg") as string;
const jobsSvg = require("./jobs.svg") as string;
const parametersSvg = require("./parameters.svg") as string;
import styleVariables from "@styles/export.scss";

const ProgramDetails: React.FC<
  {
    className?: string;
    selectedProgram?: IExecutionPackage;
    handleDownloadAllClick: () => void;
    handleDownloadNcCode: () => void;
    handleDownloadJobs: () => void;
    handleDownloadParameters: () => void;
    handleDeleteProgram: (programName: string) => void;
    handleCopyToNew: (programName: string) => void;
    onUploadNcCode: (newPart: FormData) => void;
    onUploadJobs: (newPart: FormData) => void;
    onUploadParameters: (newPart: FormData) => void;
    showUploadModalNcCode: boolean;
    setShowUploadModalNcCode: (value: boolean) => void;
    showUploadModalJobs: boolean;
    setShowUploadModalJobs: (value: boolean) => void;
    showUploadModalParameters: boolean;
    setShowUploadModalParameters: (value: boolean) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  selectedProgram,
  handleDownloadAllClick,
  handleDownloadJobs,
  handleDownloadNcCode,
  handleDownloadParameters,
  handleDeleteProgram,
  handleCopyToNew,
  onUploadNcCode,
  onUploadJobs,
  onUploadParameters,
  showUploadModalNcCode,
  setShowUploadModalNcCode,
  showUploadModalJobs,
  setShowUploadModalJobs,
  showUploadModalParameters,
  setShowUploadModalParameters,
}): React.ReactElement => {
  const [ncCodeFiles, setNcCodeFiles] = useState<File[] | null>(null);
  const [jobsFiles, setJobsFiles] = useState<File[] | null>(null);
  const [parametersFiles, setParametersFiles] = useState<File[] | null>(null);
  return (
    <main className={`program-details ${className}`}>
      <div className={"program-details-header"}>
        <Typography type={ElementType.h3} className="program-details-title" data-testid="program-details-title">
          {selectedProgram?.packageName}
        </Typography>
        <div className={"program-details-header-buttons"}>
          <DropdownMenu
            withButton={true}
            buttonSize="medium"
            buttons={[
              {
                icon: "fal fa-copy",
                name: "Copy to new program",
                onClick: () => handleCopyToNew(selectedProgram?.packageName!),
                color: styleVariables.color_white,
              },
              {
                icon: "fal fa-trash",
                name: "Delete program",
                color: styleVariables.color_red,
                textColor: Color.Error,
                onClick: () => handleDeleteProgram(selectedProgram?.packageName!),
              },
            ]}
            icon={"far fa-ellipsis-v"}
          />
          <Button
            onClick={handleDownloadAllClick}
            themeType="primary"
            title="Download all"
            iconLeftName="far fa-arrow-to-bottom"
          >
            Download All
          </Button>
        </div>
      </div>
      <div className="program-details-row-container">
        <div className="program-details-row">
          <ProgramDetailsCard
            programName={selectedProgram?.packageName!}
            title="Nc Code"
            versions={selectedProgram?.ncPrograms!}
            imageSrc={ncCodeSvg}
            onFileDrop={(files: File[]) => {
              setNcCodeFiles(files);
            }}
            onUpload={() => setShowUploadModalNcCode(true)}
            onDownload={handleDownloadNcCode}
          />
          <ProgramDetailsCard
            programName={selectedProgram?.packageName!}
            title="Jobs"
            versions={selectedProgram?.jobs!}
            imageSrc={jobsSvg}
            onFileDrop={(files: File[]) => {
              setJobsFiles(files);
            }}
            onUpload={() => setShowUploadModalJobs(true)}
            onDownload={handleDownloadJobs}
          />
        </div>
        <div className="program-details-row">
          <ProgramDetailsCard
            programName={selectedProgram?.packageName!}
            title="Parameters"
            versions={selectedProgram?.parameters!}
            imageSrc={parametersSvg}
            onFileDrop={(files: File[]) => {
              setParametersFiles(files);
            }}
            onUpload={() => setShowUploadModalParameters(true)}
            onDownload={handleDownloadParameters}
          />
          <ProgramInfoCard
            dateCreated={selectedProgram?.createdOn!}
            createdBy={selectedProgram?.createdBy!}
            ncCodeDateUpdated={selectedProgram?.ncProgramsLastUpdatedDate!}
            ncCodeUpdatedBy={selectedProgram?.ncProgramsLastUpdatedBy!}
            parametersDateUpdated={selectedProgram?.parametersLastUpdatedDate!}
            parametersUpdatedBy={selectedProgram?.parametersLastUpdatedBy!}
            jobsDateUpdated={selectedProgram?.jobsLastUpdatedDate!}
            jobsUpdatedBy={selectedProgram?.jobsLastUpdatedBy!}
          />
        </div>
      </div>
      {(showUploadModalNcCode || ncCodeFiles) && (
        <ModalUploadProgramPart
          programName={selectedProgram?.packageName!}
          title="Upload Nc Code"
          initialFiles={ncCodeFiles}
          onCloseModal={() => {
            setShowUploadModalNcCode(false);
            setNcCodeFiles(null);
          }}
          onDone={onUploadNcCode}
        />
      )}
      {(showUploadModalJobs || jobsFiles) && (
        <ModalUploadProgramPart
          programName={selectedProgram?.packageName!}
          title="Upload Jobs"
          initialFiles={jobsFiles}
          onCloseModal={() => {
            setShowUploadModalJobs(false);
            setJobsFiles(null);
          }}
          onDone={onUploadJobs}
        />
      )}
      {(showUploadModalParameters || parametersFiles) && (
        <ModalUploadProgramPart
          programName={selectedProgram?.packageName!}
          title="Upload Parameters"
          initialFiles={parametersFiles}
          onCloseModal={() => {
            setShowUploadModalParameters(false);
            setParametersFiles(null);
          }}
          onDone={onUploadParameters}
        />
      )}
    </main>
  );
};

export { ProgramDetails };
