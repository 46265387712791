import React, { FC, ReactElement } from "react";

const AuthContext = React.createContext<{
  data: any;
  login: () => void;
  logout: () => void;
  register: () => void;
}>({ data: {}, login: () => {}, logout: () => {}, register: () => {} });

const AuthProvider: FC<{
  children: JSX.Element;
  data: any;
}> = ({ children, data, ...other }): ReactElement => {
  // @TODO: Change with proper loading state
  if (data.isLoading) {
    return <div>Loading...</div>;
  }
  const login = () => {};
  const register = () => {};
  const logout = () => {};

  return (
    <AuthContext.Provider value={{ data, login, logout, register }} {...other}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
