import * as React from "react";
import TooltipTrigger, { ChildrenArg, TooltipArg } from "react-popper-tooltip";
import { default as cx } from "classnames";
import { Icon } from "../Icon";

export type ButtonThemeType =
  | "primary"
  | "secondary"
  | "destructive"
  | "outline"
  | "navigation"
  | "clear"
  | "simple"
  | "icon";
export type ButtonSize = "large" | "medium" | "small" | "xs";

const Button: React.FC<
  {
    size?: ButtonSize;
    themeType?: ButtonThemeType;
    iconLeftName?: string;
    iconRightName?: string;
    iconOnly?: boolean;
    navButton?: boolean;
    iconName?: string;
    iconSize?: number;
    className?: string;
    disabled?: boolean;
    iconColor?: string;
    tooltip?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  } & React.HTMLAttributes<HTMLButtonElement>
> = ({
  children,
  size = "medium",
  themeType = "primary",
  iconLeftName = null,
  iconRightName = null,
  iconOnly = false,
  navButton = false,
  iconName = null,
  iconSize,
  className = "",
  disabled = false,
  iconColor = "",
  tooltip = "",
  onClick = () => {},
  ...other
}): React.ReactElement => {
  const btnStyles = cx(
    {
      btn: true,
      [`btn-${size}`]: true,
      [`btn-${themeType}`]: true,
      ["btn-with-icon"]: iconLeftName || iconRightName,
      ["btn-icon-only"]: iconOnly === true,
    },
    className
  );

  const renderButton = () => (
    <button onClick={onClick} className={btnStyles} {...other} disabled={disabled}>
      {iconLeftName && (
        <div className="left-icon">
          <Icon name={iconLeftName} size={iconSize ? iconSize : 16} color={iconColor} />
        </div>
      )}
      {iconOnly !== true && children}
      {iconOnly === true && iconName && (
        <div className="center-icon">
          <Icon name={iconName} color={iconColor} size={iconSize ? iconSize : 16} />
        </div>
      )}
      {iconRightName && (
        <div className="right-icon">
          <Icon name={iconRightName} size={iconSize ? iconSize : 16} color={iconColor} />
        </div>
      )}
    </button>
  );
  const trigger = ({ getTriggerProps, triggerRef }: ChildrenArg) => (
    <span
      {...getTriggerProps({
        ref: triggerRef,
        className: "trigger",
      })}
    >
      {renderButton()}
    </span>
  );

  const renderTooltip = ({ tooltipRef, getTooltipProps }: TooltipArg) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "tooltip-container tooltip-container-button",
      })}
    >
      {tooltip}
    </div>
  );

  return tooltip ? (
    <TooltipTrigger placement={"top"} trigger={"hover"} tooltip={renderTooltip}>
      {trigger}
    </TooltipTrigger>
  ) : (
    renderButton()
  );
};

export { Button };
