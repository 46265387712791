import React, { useState } from "react";
import { default as cx } from "classnames";
import {
  calculateNewPlayHeadPosition,
  calculateLivePlayheadPosition,
  formatSecondsToHHMMSS,
  formatDate,
} from "../../CameraStreams/helpers";
import { Tooltip } from "../../Common/Tooltip";

const ProgressBar: React.FC<
  {
    className?: string;
    onSliderInput: (value: number) => void;
    onSliderChange: () => void;
    videoFrame: any;
    width?: number;
    showCurrentVideoTime?: boolean;
    progress: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  onSliderInput,
  onSliderChange,
  videoFrame,
  width,
  showCurrentVideoTime = false,
  progress,
}): React.ReactElement => {
  const classes = cx(
    {
      "progress-bar-container": true,
    },
    className
  );

  const tooltipRef = React.createRef<HTMLDivElement>();
  const [tooltipValue, setTooltipValue] = useState("");

  const calcSliderPos = (e: any) => {
    return (e.nativeEvent.offsetX / e.target.clientWidth) * parseInt(e.target.getAttribute("max"), 10);
  };

  return (
    <div className={classes}>
      <input
        className="progress-bar-slider"
        onInput={(ev) => onSliderInput(parseFloat((ev.target as HTMLInputElement).value))}
        onChange={onSliderChange}
        type="range"
        value={progress}
        min={0}
        max={1}
        step={0.001}
        onMouseMove={(e) => {
          const playheadPosition = calculateNewPlayHeadPosition(calcSliderPos(e).toFixed(3), videoFrame);
          let tooltipValue = "";
          if (showCurrentVideoTime === true) {
            tooltipValue = formatSecondsToHHMMSS(playheadPosition);
          } else {
            if (videoFrame.seekable.length === 0) {
              tooltipValue = "";
            } else {
              tooltipValue = formatDate(
                calculateLivePlayheadPosition(
                  playheadPosition,
                  videoFrame.seekable.end(videoFrame.seekable.length > 0 ? videoFrame.seekable.length - 1 : 0)
                )
              );
            }
          }
          setTooltipValue(tooltipValue);
          const x = e.clientX;
          const y = (e.target as HTMLDivElement).getBoundingClientRect().top;
          // @ts-ignore
          tooltipRef.current.style.top = `${y - 20}px`;
          // @ts-ignore
          tooltipRef.current.style.left = `${x - 25}px`;
        }}
      />
      <div className="progress-bar-tooltip" ref={tooltipRef}>
        <Tooltip type="dark" className="progress-bar-tooltip-value">
          {tooltipValue}
        </Tooltip>
      </div>
    </div>
  );
};

export { ProgressBar };
