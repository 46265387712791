import * as React from "react";
import { default as cx } from "classnames";

import { Typography, ElementType, Color } from "../Common/Typography";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { Textarea } from "../Common/Textarea";
import { Input } from "../Common/Input";
import styleVariables from "@styles/export.scss";
import { Modal } from "../Modal";
import { IArcadiaFileUpload, IArcadiaPartDetails } from "@types";
import openFullscreen from "../../helpers/fullscreen";
import { Tags } from "../Tags";
import { Tag } from "react-tag-autocomplete";
import DropdownMenu from "../DropdownMenu";
import { OptionType, Select } from "../Common/Select";
import { FilePaths } from "../Folders";
import { getFolderName } from "../FileUploads";
import { isRenderableImage } from "../../helpers/common";
const defaultFileImage = require("../../images/fileImage.svg") as string;

export interface Screenshot {
  id: number;
  imageUrl: string;
  bookmark: string;
  title: string;
  description: string;
}

const FileModal: React.FC<
  {
    className?: string;
    file: IArcadiaFileUpload;
    isFavorite: boolean;
    handleCloseClick: () => void;
    handlePreviousClick: (item: IArcadiaFileUpload) => void;
    handleNextClick: (item: IArcadiaFileUpload) => void;
    handleDoneClick: (item: IArcadiaFileUpload) => void;
    handleDeleteClick: () => void;
    handleFavoriteClick: (itemId: string) => void;
    parts: IArcadiaPartDetails[];
    //features: IArcadiaFeatureDetails[];
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  className = "",
  file,
  isFavorite,
  handleDoneClick,
  handleCloseClick,
  handlePreviousClick,
  handleNextClick,
  handleDeleteClick,
  handleFavoriteClick,
  parts,
  //features,
}): React.ReactElement => {
  const classes = cx("file-modal-container", className);
  const [item, setItem] = React.useState(file);

  const partOptions: OptionType[] =
    parts && parts.length > 0 ? parts.map((p) => ({ label: p.partName!, value: p.partId! })) : [];

  // const featureOptions: OptionType[] =
  //   features && features.length > 0 ? features.map((f) => ({ label: f.featureName!, value: f.featureId! })) : [];

  const folderOptions: OptionType[] = Object.values(FilePaths).map((fp) => ({
    label: getFolderName(fp),
    value: fp,
  }));

  React.useEffect(() => {
    setItem(file);
  }, [file]);

  const handleTitleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const element = item;
    const target = event.target as HTMLInputElement;
    const newElement = { ...element, fileName: target.value };
    setItem(newElement);
  };

  const handleDescriptionChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const element = item;
    const target = event.target as HTMLTextAreaElement;
    const newElement = { ...element, fileDescription: target.value };
    setItem(newElement);
  };

  const handlePartChange = (part: OptionType) => {
    const element = item;
    const newElement = { ...element, partId: part.value };
    setItem(newElement);
  };

  const handleFolderChange = (folder: OptionType) => {
    const element = item;
    const newElement = { ...element, userPath: folder.value };
    setItem(newElement);
  };

  // const handleFeatureChange = (feature: OptionType) => {
  //   const element = item;
  //   const newElement = { ...element, featureId: feature.value };
  //   setItem(newElement);
  // };

  const handleFullscreenClick = () => {
    const element = document.getElementsByClassName("files-carousel-image-container")[0];
    openFullscreen(element);
  };

  const handleDownloadClick = () => {
    const element = item;
    const link = document.createElement("a");
    link.href = element.fileUri!;
    link.setAttribute("download", item?.fileName!);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDelete = (i: number) => {
    const element = item;
    const updated = element!.tags!.slice(0);
    updated.splice(i, 1);

    const newElement = { ...element, tags: updated };
    setItem(newElement);
  };

  const onAddition = (tag: Tag) => {
    const element = item;
    const newTags = element.tags || [];
    const newElement = { ...element, tags: [...newTags, tag.name] };
    setItem(newElement);
  };

  const renderDefaultImage = (fileName: string) => {
    return <img className="default-file-image" src={defaultFileImage} alt={fileName} />;
  };

  return (
    <Modal className={"file-modal"} width={1383} onCloseModal={handleCloseClick}>
      <div className={classes}>
        <div className="file-modal-left">
          <div className="file-modal-left-top">
            <Typography className="file-modal-left-title" type={ElementType.h3}>
              File Viewer
            </Typography>
            <div className="file-modal-pull-right">
              <Button
                onClick={handleDownloadClick}
                themeType="secondary"
                size="medium"
                iconLeftName="fal fa-arrow-alt-to-bottom"
              >
                Download
              </Button>
              <Button
                iconOnly={true}
                iconColor={isFavorite ? styleVariables.color_orange : styleVariables.color_gray}
                onClick={() => handleFavoriteClick(item.fileUploadId!)}
                themeType="secondary"
                size="medium"
                iconName="fas fa-star"
              />
              <DropdownMenu
                buttons={[
                  {
                    icon: "far fa-expand",
                    name: "View Fillscreen",
                    onClick: handleFullscreenClick,
                    color: styleVariables.color_white,
                  },
                  {
                    icon: "fal fa-trash",
                    name: "Delete",
                    color: styleVariables.color_red,
                    textColor: Color.Error,
                    onClick: handleDeleteClick,
                  },
                ]}
                withButton={true}
                buttonSize="medium"
                icon={"far fa-ellipsis-v"}
              />
            </div>
          </div>
          <div className="carousel-root">
            <Button
              className="files-carousel-button files-carousel-button-left"
              themeType="secondary"
              onClick={() => handlePreviousClick(item)}
              iconOnly={true}
              iconName="fal fa-chevron-left"
            />
            <div className="files-carousel-image-container" key={item.fileUploadId}>
              {isRenderableImage(item!.fileUri!.split(".").pop()?.toLowerCase() ?? "") ? (
                <img src={item.imageUri_Large} alt={item.fileName} />
              ) : (
                renderDefaultImage(item.fileName!)
              )}
            </div>
            <Button
              className="files-carousel-button files-carousel-button-right"
              themeType="secondary"
              onClick={() => handleNextClick(item)}
              iconOnly={true}
              iconName="fal fa-chevron-right"
            />
          </div>

          {/* <Carousel
            renderArrowPrev={() => (
              <Button
                className="files-carousel-button files-carousel-button-left"
                themeType="secondary"
                onClick={() => handlePreviousClick(item)}
                iconOnly={true}
                iconName="fal fa-chevron-left"
              />
            )}
            renderArrowNext={() => (
              <Button
                className="files-carousel-button files-carousel-button-right"
                themeType="secondary"
                onClick={() => handleNextClick(item)}
                iconOnly={true}
                iconName="fal fa-chevron-right"
              />
            )}
            showStatus={false}
            showIndicators={false}
            autoPlay={false}
            thumbWidth={152}
            useKeyboardArrows={true}
          >
            {[item].map((i) => {
              const isImage = item!.fileUri!.split(".").pop() === "png";
              return (
                <div className="files-carousel-image-container" key={i.fileUploadId}>
                  {isImage ? <img src={i.imageUri_Large} alt={i.fileName} /> : renderDefaultImage(i.fileName!)}
                </div>
              );
            })}
          </Carousel> */}
        </div>
        <div className="file-modal-right">
          <div className="file-modal-pull-right">
            <Icon onClick={handleCloseClick} color={styleVariables.color_white} name="fal fa-times" size={24} />
          </div>
          <Input
            label="File Name"
            className="file-modal-input"
            value={item.fileName!}
            fontSize={24}
            onChange={handleTitleChange}
          />
          <Select
            label="Part assignment"
            className="file-modal-input"
            // @ts-ignore
            onChange={(selected) => handlePartChange(selected)}
            options={partOptions}
            placeholder={"Select Part"}
            // @ts-ignore
            value={item.partId ? partOptions.find((p) => p.value === item.partId) : null}
            width={"100%"}
          />
          {/* <Select
            label="Feature assignment"
            className="modal-new-project-form-input"
            // @ts-ignore
            onChange={(selected) => handleFeatureChange(selected)}
            options={featureOptions}
            placeholder={"Select Feature"}
            // @ts-ignore
            value={item.featureId ? featureOptions.find((f) => f.value === item.featureId) : null}
            width={"100%"}
          /> */}

          <Select
            label="Folder"
            className="file-modal-input"
            // @ts-ignore
            onChange={(selected) => handleFolderChange(selected)}
            options={folderOptions}
            placeholder={"Select Folder"}
            // @ts-ignore
            value={item.userPath ? folderOptions.find((p) => p.value === item.userPath) : null}
            width={"100%"}
          />

          <Tags
            className="file-modal-input"
            suggestions={[
              { id: 1, name: "Best Result" },
              { id: 2, name: "Review Later" },
            ]}
            tags={
              item && item.tags
                ? //@ts-ignore
                  item.tags.map((tag, index) => ({ id: index, name: tag! }))
                : []
            }
            onDelete={onDelete}
            onAddition={onAddition}
          />
          <Textarea
            height={"100%"}
            className="file-description file-modal-input"
            placeholder="Enter description"
            value={item.fileDescription}
            onChange={handleDescriptionChange}
          />

          <div className="file-modal-pull-right file-modal-pull-down">
            <Button onClick={() => handleDoneClick(item)} themeType="clear">
              Done
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { FileModal };
