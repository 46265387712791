import * as React from "react";
import { IArcadiaFileUpload } from "@types";
import moment from "moment";
import { CardBookmark } from "../CardBookmark";
import { BookmarkIcon } from "./playbackIcons";
import { sortingFunctionFileUploads } from "../../helpers/sorting";

const calculateBookmarkTimeOffset = (bookmark: string, videoStartTime: string) => {
  const result = moment
    .duration(moment(bookmark).diff(moment(videoStartTime)))
    .add(1, "second")
    .asSeconds();
  return result;
};

const Bookmarks: React.FC<
  {
    className?: string;
    onClickScreenshot: (snapshots: IArcadiaFileUpload[], index: string) => void;
    goToSnapshotTime: (timestamp: string) => void;
    snapshots: IArcadiaFileUpload[];
    videoStartTime: string;
    duration: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ onClickScreenshot, goToSnapshotTime, snapshots, videoStartTime, duration }): React.ReactElement => {
  const renderBookmarks = (
    snapshots: IArcadiaFileUpload[],
    onClickScreenshot: (snapshots: IArcadiaFileUpload[], index: string) => void,
    goToSnapshotTime: (timestamp: string) => void,
    videoStartTime: string,
    duration: number
  ) => {
    const offsetMin = 1.3;
    const result = [];
    snapshots.sort(sortingFunctionFileUploads);
    for (let index = 0; index < snapshots.length; index++) {
      const snapshot = snapshots[index];
      const offsetPercentage =
        (calculateBookmarkTimeOffset(snapshot.correlationTimestamp!, videoStartTime) / duration) * 100;
      let itemsInGroup = 1;
      if (index + 1 !== snapshots.length) {
        for (let j = index + 1; j < snapshots.length; j++) {
          const offsetNext =
            (calculateBookmarkTimeOffset(snapshots[j].correlationTimestamp!, videoStartTime) / duration) * 100;
          const diff = offsetNext - offsetPercentage;

          if (diff < offsetMin) {
            itemsInGroup++;
            continue;
          } else {
            if (itemsInGroup > 1) {
              result.push(
                <div
                  key={snapshot.fileUploadId}
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  onClick={() => onClickScreenshot(snapshots, snapshot.fileUploadId!)}
                  style={{ left: `${offsetPercentage}%` }}
                  className="bookmark-container"
                >
                  <div className="bookmark-group">{itemsInGroup}</div>
                </div>
              );
              index = j - 1;
              break;
            } else {
              result.push(
                <div
                  key={snapshot.fileUploadId}
                  style={{ left: `${offsetPercentage}%` }}
                  className="bookmark-container"
                >
                  <CardBookmark
                    onClickCard={() => onClickScreenshot(snapshots, snapshot.fileUploadId!)}
                    snapshot={snapshot}
                  />
                  <BookmarkIcon onClick={() => goToSnapshotTime(snapshot.correlationTimestamp!)} />
                </div>
              );
              break;
            }
          }
        }
      } else {
        result.push(
          <div key={snapshot.fileUploadId} style={{ left: `${offsetPercentage}%` }} className="bookmark-container">
            <CardBookmark
              onClickCard={() => onClickScreenshot(snapshots, snapshot.fileUploadId!)}
              snapshot={snapshot}
            />
            <BookmarkIcon onClick={() => goToSnapshotTime(snapshot.correlationTimestamp!)} />
          </div>
        );
      }
    }
    return result;
  };

  return (
    <div className="playback-line-bookmarks-container">
      {renderBookmarks(snapshots, onClickScreenshot, goToSnapshotTime, videoStartTime, duration)}
    </div>
  );
};

export { Bookmarks };
