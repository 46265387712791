import { Notification } from "@namespace/components/src";
import { NotificationType } from "@namespace/components/src/components/Notification";
import React, { FC, ReactElement } from "react";

const initialState: { isOpen: boolean; message: string; type: NotificationType } = {
  isOpen: false,
  message: "",
  type: "primary",
};

const notificationContext = React.createContext<{
  data: { isOpen: boolean; message: string; type: NotificationType };
  floatingNotification: (message: string, type: NotificationType) => void;
  globalNotification: (message: string, type: NotificationType) => void;
}>({
  data: initialState,
  floatingNotification: () => {},
  globalNotification: () => {},
});
const { Provider } = notificationContext;
const NotificationProvider: FC<{
  children: JSX.Element;
}> = ({ children }): ReactElement => {
  const [notificationState, setNotificationState] = React.useState(initialState);
  const [globalNotificationState, setGlobalNotificationState] = React.useState(initialState);

  const floatingNotification = (message: string, type: NotificationType) => {
    setNotificationState({
      isOpen: true,
      message,
      type: type,
    });
    setTimeout(() => setNotificationState(initialState), 3000);
  };

  const globalNotification = (message: string, type: NotificationType) => {
    setGlobalNotificationState({
      isOpen: true,
      message,
      type: type,
    });
    setTimeout(() => setGlobalNotificationState(initialState), 3000);
  };
  const close = () => {
    setNotificationState(initialState);
  };
  const closeGlobal = () => {
    setGlobalNotificationState(initialState);
  };
  return (
    <>
      <Provider value={{ data: notificationState, floatingNotification, globalNotification }}>{children}</Provider>
      <Notification {...notificationState} onClose={close} />
      <Notification isGlobal={true} {...globalNotificationState} onClose={closeGlobal} />
    </>
  );
};

export default NotificationProvider;
export { NotificationProvider, notificationContext };
