import * as React from "react";
import { default as cx } from "classnames";
import { Oval } from "../Oval";
import styleVariables from "@styles/export.scss";
import { Status } from "../../constants/enums/Status";

const Thumbnail: React.FC<
  {
    round?: true | false;
    title: string;
    backgroundImageUrl: string;
    backgroundColor?: string;
    clickHandler?: Function;
    boxShadow?: string;
    filter?: string;
    width: number;
    height: number;
    borderRadius?: number;
    className?: string;
    status?: Status;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  title = "Abstract painting",
  round = false,
  backgroundImageUrl,
  backgroundColor,
  clickHandler = () => {},
  boxShadow = null,
  filter = null,
  width = 76,
  height = 76,
  borderRadius = 8,
  className = "",
  status,
  ...other
}): React.ReactElement => {
  const thumbnailStyles = cx(
    {
      "image-container": true,
      ["thumbnail-round"]: round,
    },
    className
  );

  const containerStyles: {
    width: number;
    height: number;
    borderRadius?: number;
    backgroundImage: string;
    backgroundColor?: string;
    boxShadow?: string;
    filter?: string;
  } = {
    width: width,
    height: height,
    backgroundColor: backgroundColor,
    backgroundImage: `url(${backgroundImageUrl})`,
  };

  if (boxShadow) {
    containerStyles.boxShadow = boxShadow;
  }

  if (filter) {
    containerStyles.filter = filter;
  }

  if (borderRadius && !round) {
    containerStyles.borderRadius = borderRadius;
  }

  const resolveColor = (status: Status) => {
    let color = styleVariables.color_green;
    switch (status) {
      case Status.NotStarted:
        color = styleVariables.color_gray;
        break;
      case Status.Done:
        color = styleVariables.color_orange;
        break;
      case Status.InReview:
        color = styleVariables.color_white;
        break;
      case Status.InProgress:
        color = styleVariables.color_green;
        break;
      default:
        break;
    }
    return color;
  };

  return (
    <div
      onClick={() => clickHandler()}
      onKeyDown={() => clickHandler()}
      className={thumbnailStyles}
      style={containerStyles}
      {...other}
      role="presentation"
      aria-label={title}
    >
      {status && <Oval width={10} height={10} color={resolveColor(status)} borderColor={resolveColor(status)} />}
      {children}
    </div>
  );
};

export { Thumbnail };
