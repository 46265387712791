import React, { FormEvent, ReactElement, useState } from "react";
import { Icon } from "../Icon";
import { ElementType, Typography } from "../Common/Typography";
import { Input } from "../Common/Input";
import { useClickOutside } from "../../helpers/hooks";

const SessionTitle: React.FC<
  {
    sessionTitle: string;
    machineName?: string;
    editable?: boolean;
    className?: string;
    inverse?: boolean;
    onSessionNameChange?: (name: string) => void;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({
  sessionTitle = "",
  machineName = "",
  editable = false,
  className = "",
  inverse = false,
  onSessionNameChange,
}): ReactElement => {
  const [editMode, setEditMode] = useState<boolean>(false);

  // useEffect(() => {
  //   /**
  //    * Alert if clicked on outside of element
  //    */
  //   function handleClickOutside(event: any) {
  //    if (!event.target.classList.contains("custom-input")) {
  //       setEditMode(false);
  //     }
  //   }

  //   // Bind the event listener
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // Unbind the event listener on clean up
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [setEditMode]);

  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  const updateTitle = (e: FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (onSessionNameChange) {
      onSessionNameChange(target.value);
    }
  };

  const editInput = React.useRef(null);
  useClickOutside(editInput, toggleEdit);
  return (
    <div className={`session-title-container ${inverse ? "session-title-container-inverse" : ""} ${className}`}>
      {editMode ? (
        <Input
          autoFocus
          height={40}
          width={500}
          onChange={updateTitle}
          onBlur={() => setEditMode(false)}
          onKeyUp={(event) => {
            if (event.keyCode === 13) {
              updateTitle(event);
              setEditMode(false);
            }
          }}
          value={sessionTitle}
          fontSize={24}
        />
      ) : (
        <div
          tabIndex={0}
          role="button"
          className={`first-row${editable ? " with-hover" : ""}`}
          onKeyDown={editable ? toggleEdit : () => {}}
          onClick={editable ? toggleEdit : () => {}}
        >
          <Typography type={ElementType.h4}>{sessionTitle}</Typography>
          {editable && <Icon size={14} name="fal fa-edit" color="#8e8f8f" />}
        </div>
      )}
      <Typography className="machine-name" type={ElementType.eyebrow}>
        {machineName}
      </Typography>
    </div>
  );
};

export { SessionTitle };
