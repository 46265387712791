/* eslint-disable tsc/config */
import React, { FC, ReactElement, useState, useRef } from "react";
import { Typography, ElementType } from "../Common/Typography";
import { Status } from "../../constants/enums/Status";
import styleVariables from "@styles/export.scss";
import { StatusIcon } from "../StatusIcon";
import { Oval } from "../Oval";
import { useClickOutside } from "../../helpers/hooks";

//[ "NotStarted", "InProgress", "Complete", "Blocked", "OnHold", "Cancelled" ]

const mapArcadiaSubtaskStatusToStatus = (status: number) => {
  let result: Status = Status.NotStarted;
  switch (status) {
    case 1:
      result = Status.NotStarted;
      break;
    case 2:
      result = Status.Done;
      break;
    case 3:
      result = Status.InProgress;
      break;
    case 0:
    case 4:
    case 5:
      result = Status.Draft;
      break;
    default:
      break;
  }
  return result;
};

const StatusSelection: FC<{ onChangeStatus?: (status: number) => void; status?: number }> = ({
  onChangeStatus = () => {},
  status = 0,
}): ReactElement => {
  const [shown, setShown] = useState<boolean>(false);
  const statusName = (statusToGet: number): string => {
    switch (statusToGet) {
      case 0:
        return "Not Started";
      case 1:
        return "In Progress";
      case 2:
        return "Complete";
      case 3:
        return "Blocked";
      case 4:
        return "On Hold";
      case 5:
        return "Cancelled";
      default:
        return "";
    }
  };

  const toggleDropdown = (event: any) => {
    setShown(!shown);
  };

  const closeDropdown = () => {
    setShown(false);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, closeDropdown);

  return (
    <div className="status-selection" ref={wrapperRef}>
      <div
        className="status-selection-data"
        role="button"
        onKeyUp={toggleDropdown}
        onClick={toggleDropdown}
        tabIndex={0}
      >
        <StatusIcon className="status-selection-data-icon" status={mapArcadiaSubtaskStatusToStatus(status)} />
        <Typography type={ElementType.inline}>{statusName(status)}</Typography>
      </div>
      {shown && (
        <ul className="status-selection-dropdown">
          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(0);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(0);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_medium_gray}
                borderColor={styleVariables.color_medium_gray}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(0)}</Typography>
            </div>
          </li>
          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(1);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(1);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_white}
                borderColor={styleVariables.color_white}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(1)}</Typography>
            </div>
          </li>
          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(2);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(2);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_green}
                borderColor={styleVariables.color_green}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(2)}</Typography>
            </div>
          </li>

          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(3);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(3);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_orange}
                borderColor={styleVariables.color_orange}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(3)}</Typography>
            </div>
          </li>
          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(4);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(4);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_medium_gray}
                borderColor={styleVariables.color_medium_gray}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(4)}</Typography>
            </div>
          </li>
          <li className="status-selection-dropdown-option">
            <div
              role="button"
              onClick={() => {
                closeDropdown();
                onChangeStatus(5);
              }}
              onKeyPress={() => {
                closeDropdown();
                onChangeStatus(5);
              }}
              tabIndex={0}
            >
              <Oval
                width={16}
                height={16}
                color={styleVariables.color_medium_gray}
                borderColor={styleVariables.color_medium_gray}
              />{" "}
              <Typography type={ElementType.inline}>{statusName(5)}</Typography>
            </div>
          </li>
        </ul>
      )}
    </div>
  );
};

export default StatusSelection;
export { StatusSelection };
