import * as React from "react";

const Link: React.FC<
  {
    href: string;
    target?: "_self" | "_blank" | "_parent" | "_top";
    title?: string;
  } & React.HTMLAttributes<HTMLLinkElement>
> = ({ children, href = "/", target = "_self", title = "" }): React.ReactElement => {
  return (
    <a className={"custom-link"} href={href} target={target} title={title}>
      {children}
    </a>
  );
};

export { Link };
