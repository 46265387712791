import * as React from "react";
import { default as cx } from "classnames";
import { Checkbox } from "../Checkbox";
import uniqid from "uniqid";

const SelectionInput: React.FC<
  {
    className?: string;
    checked?: boolean;
    width?: number | string;
    height?: number | string;
    value?: string | string[] | number;
    text: string;
    handleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    name?: string;
  } & React.HTMLAttributes<HTMLInputElement> & { ref?: React.Ref<HTMLInputElement> }
> = React.forwardRef(
  (
    { width = 325, height = 48, checked = false, value = "", handleClick = () => {}, text, className, name = "" },
    ref
  ) => {
    const classes = cx(
      {
        "custom-input-wrapper": true,
      },
      className
    );

    const inputClasses = cx({
      "selection-input": true,
      "selection-input-checked": checked,
    });

    const uniq = uniqid();

    return (
      <div className={classes}>
        <Checkbox name={name} checked={checked} ref={ref} value={value} onClick={handleClick} id={uniq} />
        <label className={inputClasses} style={{ width: width, height: height }} htmlFor={uniq}>
          {text}
        </label>
      </div>
    );
  }
);

export { SelectionInput };
