const formatDate = (date: Date) => {
  const seconds = (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
  const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
  const hour = (date.getHours() < 10 ? "0" : "") + date.getHours();
  return hour + ":" + minutes + ":" + seconds;
};

// return and calculate playhead position as a date for livestreams which don't expose PDT timestamp
const calculateLivePlayheadPosition = (currentTime: number, seekableEnd: number) => {
  const toDeduct = seekableEnd - currentTime + 16;
  const date = new Date();
  date.setSeconds(date.getSeconds() - toDeduct);
  return date;
};

// format total seconds as HH:MM:SS
const formatSecondsToHHMMSS = (seconds: number) => {
  const hrs = Math.floor(seconds / 3600);
  const min = Math.floor((seconds - hrs * 3600) / 60);
  let sec = seconds - hrs * 3600 - min * 60;
  sec = Math.round(Math.round(sec * 100) / 100);
  let result = hrs == 0 ? "" : (hrs < 10 ? "0" + hrs : hrs) + ":";
  result += (min < 10 ? "0" + min : min) + ":";
  result += sec < 10 ? "0" + sec : sec;
  return result;
};

const calculateNewPlayHeadPosition = (value: string, videoFrame: any) => {
  if (videoFrame && videoFrame.seekable.length > 0) {
    const start = videoFrame.seekable.start(0);
    const end = videoFrame.seekable.end(videoFrame.seekable.length > 0 ? videoFrame.seekable.length - 1 : 0);
    const range = end - start;
    const playheadPosition = start + range * (parseFloat(value) / 1);
    return playheadPosition;
  } else {
    return 0;
  }
};

const calculateSliderValue = (videoFrame: any) => {
  if (videoFrame.seekable.length > 0) {
    const start = videoFrame.seekable.start(0);
    const end = videoFrame.seekable.end(videoFrame.seekable.length - 1);
    const range = end - start;
    const sliderValue = (videoFrame.currentTime - start) / range;
    return sliderValue;
  } else {
    return 0;
  }
};

export {
  formatDate,
  calculateLivePlayheadPosition,
  formatSecondsToHHMMSS,
  calculateNewPlayHeadPosition,
  calculateSliderValue,
};
