import * as React from "react";
import styleVariables from "@styles/export.scss";
import TooltipTrigger, { ChildrenArg, TooltipArg, Trigger } from "react-popper-tooltip";
import { Icon } from "../Icon";

const InfoIcon: React.FC<
  {
    className?: string;
    color?: string;
    size?: number | null;
    placement?:
      | "top-start"
      | "top-end"
      | "bottom-start"
      | "bottom-end"
      | "right-start"
      | "right-end"
      | "left-start"
      | "left-end"
      | "top"
      | "bottom"
      | "left"
      | "right";
    tooltipText: string;
    trigger?: Trigger;
  } & React.HTMLAttributes<HTMLElement>
> = ({
  className = "",
  color = styleVariables.color_white,
  size = null,
  placement = "top",
  tooltipText,
  trigger = "hover",
}): React.ReactElement => {
  const Tooltip = ({ tooltipRef, getTooltipProps }: TooltipArg) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "tooltip-container",
      })}
    >
      {tooltipText}
    </div>
  );

  const Trigger = ({ getTriggerProps, triggerRef }: ChildrenArg) => (
    <span
      {...getTriggerProps({
        ref: triggerRef,
        className: `trigger ${className}`,
      })}
    >
      <Icon
        name="far fa-info-circle"
        color={color}
        size={size || undefined}
        {...getTriggerProps({
          ref: triggerRef,
          className: "trigger",
        })}
      />
    </span>
  );

  return (
    <TooltipTrigger placement={placement} trigger={trigger} tooltip={Tooltip}>
      {Trigger}
    </TooltipTrigger>
  );
};

export { InfoIcon };
