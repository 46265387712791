import { IMachineRun, IArcadiaRun } from "@types";

const sortRuns = (runs: IMachineRun[], runsFromSession: IArcadiaRun[]) => {
  return runs.sort(function (a, b) {
    const map = runsFromSession.map((r) => r.runId);
    const firstIndex = map.indexOf(a.runId!);
    const secondIndex = map.indexOf(b.runId!);
    return firstIndex === secondIndex ? 0 : firstIndex > secondIndex ? 1 : -1;
  });
};

export default sortRuns;
