import axios from "axios";
import { IArcadiaMaterial } from "@types";
import { Operation } from "rfc6902";

const getAll = () => {
  return axios.get("/materials/getboostmaterial");
};

const getMaterialCategories = (customerId: string) => {
  return axios.get(`/materials/${customerId}/materialCategories`);
};

const getCustomerMaterials = () => {
  return axios.get(`/materials`);
};

const getById = (materialId: string) => {
  return axios.get(`/materials/${materialId}`);
};

const create = (data: IArcadiaMaterial) => {
  return axios.post("/materials", data);
};

const updateCustomerMaterial = (sku: string, data: Operation[]) => {
  return axios.patch(`/materials/customer?materialSku=${sku}`, data);
};

const updateBoostMaterial = (sku: string, data: Operation[]) => {
  return axios.patch(`/materials/boost?materialSku=${sku}`, data);
};

const remove = (materialId: any) => {
  return axios.delete(`/materials/${materialId}`);
};

const getMaterialTypes = () => {
  return axios.get(`/materials/materialtypes`);
};

const getUnitsOfMeasure = () => {
  return axios.get(`/materials/unitsofmeasure`);
};

const uploadCustomerMaterialFile = (sku: string, formData: FormData) => {
  return axios.post(`/materials/materialupload?sku=${sku}`, formData);
};

const uploadBoostMaterialFile = (sku: string, formData: FormData) => {
  return axios.post(`/materials/boostmaterialupload?sku=${sku}`, formData);
};

const uploadCustomerMaterialImage = (sku: string, formData: FormData) => {
  return axios.post(`/materials/materialimageupload?sku=${sku}`, formData);
};

const uploadBoostMaterialImage = (sku: string, formData: FormData) => {
  return axios.post(`/materials/boostmaterialimageupload?sku=${sku}`, formData);
};

const deleteMaterialImage = (sku: string, imageUrl: string) => {
  return axios.delete(`/materials/customermaterialimagedelete?sku=${sku}&imageurl=${imageUrl}`);
};

const transferMaterialToCustomer = (sku: string, quantity: number) => {
  return axios.put(`/materials/transfermaterialtocustomer/${sku}/${quantity}`);
};

export default {
  getAll,
  getMaterialCategories,
  getCustomerMaterials,
  getById,
  create,
  remove,
  updateCustomerMaterial,
  updateBoostMaterial,
  getMaterialTypes,
  getUnitsOfMeasure,
  deleteMaterialImage,
  uploadCustomerMaterialFile,
  uploadBoostMaterialFile,
  uploadCustomerMaterialImage,
  uploadBoostMaterialImage,
  transferMaterialToCustomer,
};
