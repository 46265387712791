/* eslint-disable tsc/config */
import React, { FC, ReactElement, useRef, useState } from "react";
import { useClickOutside } from "../../helpers/hooks";
import { IDropdownItem } from "@types";
import uniqid from "uniqid";

const DropdownSelection: FC<{
  renderedComponent: JSX.Element;
  items?: IDropdownItem[];
  lastOption?: IDropdownItem;
  disabled?: boolean;
  disableOptions?: boolean;
  size?: "small" | "medium" | "large";
}> = ({
  renderedComponent,
  items = [],
  lastOption,
  disabled = false,
  size = "medium",
  disableOptions,
}): ReactElement => {
  const [shown, setShown] = useState<boolean>(false);

  const toggleDropdown = (event: any) => {
    setShown(!shown);
  };

  const closeDropdown = () => {
    setShown(false);
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, closeDropdown);

  return (
    <span
      className={`dropdown-selection${disabled ? "-disabled" : ""} dropdown-${size} ${
        disableOptions ? "options-disabled" : ""
      }`}
      ref={wrapperRef}
    >
      <span
        role="button"
        onKeyUp={!disabled ? toggleDropdown : () => {}}
        onClick={!disabled ? toggleDropdown : () => {}}
        tabIndex={0}
      >
        {renderedComponent}
      </span>
      {shown && (
        <ul className="dropdown-selection-list">
          {items.map((item) => (
            <li className="dropdown-selection-list-option" key={uniqid()}>
              <div
                role="button"
                className="dropdown-selection-list-option-container"
                onClick={(ev) => {
                  setShown(false);
                  item.onClick(ev);
                }}
                onKeyPress={(ev) => {
                  setShown(false);
                  item.onClick(ev);
                }}
                tabIndex={0}
              >
                {item.name}
              </div>
            </li>
          ))}
          {lastOption && (
            <li className="dropdown-selection-list-option dropdown-selection-list-option-last">
              <div
                role="button"
                className="dropdown-selection-list-option-container"
                onClick={(ev) => {
                  setShown(false);
                  lastOption.onClick(ev);
                }}
                onKeyPress={(ev) => {
                  setShown(false);
                  lastOption.onClick(ev);
                }}
                tabIndex={0}
              >
                {lastOption.name}
              </div>
            </li>
          )}
        </ul>
      )}
    </span>
  );
};

export default DropdownSelection;
export { DropdownSelection };
