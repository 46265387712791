import { IArcadiaMaterial } from "@types";
import { useState } from "react";
import MaterialService from "../../services/MaterialService";

const useMaterials = () => {
  const [isMaterialsLoading, setIsMaterialsLoading] = useState(false);
  const [isMaterialsError, setIsMaterialsError] = useState(false);

  const [materials, setMaterials] = useState<IArcadiaMaterial[]>([]);
  const [fixtures, setFixtures] = useState<IArcadiaMaterial[]>([]);

  const fetchMaterials = () => {
    const fetchData = async () => {
      setIsMaterialsLoading(true);
      MaterialService.getAll()
        .then((response) => {
          const allBoostMaterials = response.data as IArcadiaMaterial[];
          const boostMaterials = allBoostMaterials.filter((m) => m.materialType === 1);
          const boostFixtures = allBoostMaterials.filter((m) => m.materialType === 3);
          MaterialService.getCustomerMaterials()
            .then((response) => {
              const allUserMaterials = response.data as IArcadiaMaterial[];
              const userMaterials = allUserMaterials.filter((m) => m.materialType === 1);
              const materialsFromDbIds = userMaterials.map((m) => m.sku!);
              userMaterials.forEach((um) => {
                um.isCustomerMaterial = true;
                if (um.ownerId === "BOOST") {
                  um.isCustomized = true;
                }
              });
              const filteredMaterials = boostMaterials.filter((m) => materialsFromDbIds.indexOf(m.sku!) < 0);
              setMaterials([...filteredMaterials, ...userMaterials]);
              const userFixtures = allUserMaterials.filter((m) => m.materialType === 3);
              const fixturesFromDbIds = userFixtures.map((m) => m.sku!);
              const filteredFixtures = boostFixtures.filter((m) => fixturesFromDbIds.indexOf(m.sku!) < 0);
              userFixtures.forEach((uf) => {
                uf.isCustomerMaterial = true;
                if (uf.ownerId === "BOOST") {
                  uf.isCustomized = true;
                }
              });
              setFixtures([...filteredFixtures, ...userFixtures]);
              setIsMaterialsLoading(false);
            })
            .catch((e) => {
              setIsMaterialsLoading(false);
              setIsMaterialsError(true);
              console.log(e);
            });
        })
        .catch((e) => {
          setIsMaterialsLoading(false);
          setIsMaterialsError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return { materials, fixtures, isMaterialsLoading, isMaterialsError, fetchMaterials };
};

export default useMaterials;
