import { IArcadiaPublicSession } from "@types";
import { useState } from "react";
import ProjectsService from "../../services/ProjectsService";

const usePublicSession = () => {
  const [isPublicSessionLoading, setIsLoading] = useState(false);
  const [isPublicSessionError, setIsErrorError] = useState(false);

  const [publicSession, setPublicSession] = useState<IArcadiaPublicSession>();

  const fetchPublicSession = (sessionId: string) => {
    const fetchData = async () => {
      setIsLoading(true);
      ProjectsService.getPublicSession(sessionId)
        .then((response) => {
          const publicSession = response.data as IArcadiaPublicSession;
          setPublicSession(publicSession);
        })
        .catch((e) => {
          setIsLoading(false);
          setIsErrorError(true);
          console.log(e);
        });
    };
    fetchData();
  };

  return { publicSession, isPublicSessionLoading, isPublicSessionError, fetchPublicSession };
};

export default usePublicSession;
