import React, { FC, FormEvent, ReactElement } from "react";
import { INewProjectDataPart } from "@types";
import { Input } from "../Common/Input";
import { OptionType, Select } from "../Common/Select";
import { Textarea } from "../Common/Textarea";
import { Color, ElementType, Typography } from "../Common/Typography";
import { DatePicker } from "../DatePicker";
import { CardAddPartAttribute } from "../CardAddPartAttribute";
import { Icon } from "../Icon";
import styleVariables from "@styles/export.scss";
import { FileUploader } from "../FileUploader";

const PartData: FC<{
  classNames: string;
  part: INewProjectDataPart;
  deletePart: () => void;
  showDelete: boolean;
  changePartName: (event: FormEvent<HTMLInputElement>) => void;
  changePartDescription: (event: FormEvent<HTMLTextAreaElement>) => void;
  changePartMaterial: (newMaterial: OptionType) => void;
  changePartParts: (event: FormEvent<HTMLInputElement>) => void;
  changeDeliveryDate: (newDate: Date) => void;
  changePartCycle: (event: FormEvent<HTMLInputElement>) => void;
  changePartSelection: (newSelection: string) => void;
  changePartDrawings: (files: File[]) => void;
  showCardAttributes: boolean;
  materialOptions: OptionType[];
  cycleOptions: OptionType[];
}> = ({
  classNames,
  deletePart,
  showDelete,
  part,
  changePartName,
  changePartDescription,
  changePartParts,
  changePartMaterial,
  changeDeliveryDate,
  changePartCycle,
  changePartSelection,
  changePartDrawings,
  showCardAttributes,
  materialOptions,
  cycleOptions,
}): ReactElement => {
  return (
    <div className={`modal-new-project-section modal-new-project-form ${classNames}`}>
      <div className="modal-new-project-form-title-row">
        <Typography className="modal-new-project-form-title" type={ElementType.subtitle}>
          Part Information
        </Typography>
        {showDelete && (
          <Icon
            className="pointer"
            color={styleVariables.color_gray}
            onClick={deletePart}
            onKeyDown={deletePart}
            name="fal fa-trash"
            size={18}
            tabIndex={0}
          />
        )}
      </div>
      <Input
        label={"Part Name *"}
        className="modal-new-project-form-input"
        placeholder="Part Name"
        onChange={changePartName}
        width={"100%"}
        value={part.name}
      />
      <Textarea
        label={"Part Description"}
        className="modal-new-project-form-input modal-new-project-form-input-separator"
        placeholder="Part Description"
        onChange={changePartDescription}
        width={"100%"}
        value={part.description}
      />
      <div className="relative">
        <Typography className="modal-new-project-form-title-2" type={ElementType.subtitle}>
          Part Attributes
        </Typography>
        {showCardAttributes && (
          <CardAddPartAttribute
            className="modal-new-project-attribute-later"
            title="Add Part Attributes Later"
            description="If your part attributes are not yet defined, enter what you know now and you can update this information later as the project goes on."
          />
        )}
      </div>
      <Typography className="modal-new-project-subtitle" type={ElementType.label}>
        Material
      </Typography>
      <Select
        className="modal-new-project-form-input"
        // @ts-ignore
        onChange={(selected) => changePartMaterial(selected)}
        options={materialOptions}
        placeholder={"Select a Material"}
        // @ts-ignore
        value={part.material}
        width={"100%"}
      />
      <Input
        label={"Number of parts"}
        className="modal-new-project-form-input"
        placeholder="1"
        onChange={changePartParts}
        type="number"
        width={"100%"}
        value={part.number}
      />
      <div className="custom-input modal-new-project-form-date">
        <DatePicker
          customInput={
            <Input
              label={"Delivery Date"}
              className="custom-input modal-new-project-form-input"
              data-testid="datepicker-input"
              onChange={() => {}}
              rightIconName="fas fa-calendar"
              value={part.deliveryDate ? part.deliveryDate.toISOString() : null}
              width="100%"
            />
          }
          handleChange={(newDate: Date) => changeDeliveryDate(newDate)}
          placeholderText="Select a date"
          popperPlacement="bottom"
          value={part.deliveryDate}
          width="100%"
        />
      </div>
      <Typography className="modal-new-project-cycle-label" type={ElementType.label}>
        Desired cycle time
      </Typography>
      <div className="modal-new-project-form-input modal-new-project-form-input-separator modal-new-project-form-input-cycle">
        <Input placeholder="Desired cycle time" onChange={changePartCycle} width={415} value={part.desiredCycle} />
        <Select
          // @ts-ignore
          onChange={(selected) => changePartSelection(selected)}
          options={cycleOptions}
          placeholder="sec"
          // @ts-ignore
          value={part.cycleSelection}
          width={"80%"}
        />
      </div>

      <Typography className="modal-new-project-form-title-3" type={ElementType.subtitle}>
        Drawing/Models
      </Typography>
      <Typography type={ElementType.body} color={Color.Secondary}>
        Upload Part Drawing / CAD Models.
      </Typography>
      <FileUploader files={part.drawing} setFiles={changePartDrawings} />
      <br />
    </div>
  );
};

export { PartData };
