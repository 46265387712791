import React, { ReactElement, useState } from "react";
import { default as cx } from "classnames";
import { Color, Typography } from "../Common/Typography";

const ShowMore: React.FC<
  {
    className?: string;
    width: number;
    text: string;
    characterCount: number;
  } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, className = "", width, text, characterCount }): ReactElement => {
  const [expanded, setExpanded] = useState(false);

  const classes = cx(
    {
      "show-more": true,
    },
    className
  );

  const toggleExpanded = () => {
    setExpanded((expanded) => !expanded);
  };

  const truncateString = (text: string, numberOfCharacters: number) => {
    if (text.length <= numberOfCharacters) {
      return text;
    }
    return text.slice(0, numberOfCharacters) + "...";
  };

  return (
    <div className={classes} style={{ width: width }}>
      {text.length <= characterCount ? (
        text
      ) : expanded ? (
        <div>
          {text}
          <Typography className="show-more-button" color={Color.Secondary} onClick={toggleExpanded}>
            {"Show less"}
          </Typography>
        </div>
      ) : (
        <div>
          {truncateString(text, characterCount)}
          <Typography className="show-more-button" color={Color.Secondary} onClick={toggleExpanded}>
            {"Show more"}
          </Typography>
        </div>
      )}
    </div>
  );
};

export { ShowMore };
