import React, { FC, HTMLAttributes, ReactElement } from "react";

import { IMachineRun, IArcadiaMaterial, IExecutionPackage, IArcadiaRun } from "@types";
import { ElementType, Typography } from "../Common/Typography";
import { Modal } from "../Modal";
import { useState } from "react";
import { Button } from "../Button";
import { ModalConfirm } from "../ModalConfirm";
import { EditRunPlanTable } from "../EditRunPlanTable";

const ModalEditRunPlan: FC<
  {
    className?: string;
    width: number;
    height?: number;
    machineRuns: IMachineRun[];
    materials: IArcadiaMaterial[];
    fixtures: IArcadiaMaterial[];
    executionPackages: IExecutionPackage[];
    fixtureId: string;
    sessionId: string;
    runOrder: IArcadiaRun[];
    onCancelClick: () => void;
    onDoneClick: (runs: IMachineRun[], fixtureId: string) => void;
  } & HTMLAttributes<HTMLDivElement>
> = ({
  machineRuns,
  materials,
  fixtures,
  executionPackages,
  fixtureId,
  sessionId,
  runOrder,
  onCancelClick,
  onDoneClick,
}): ReactElement => {
  const [isUpdated, setIsUpdated] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [runs, setRuns] = useState(machineRuns);

  return (
    <>
      <Modal
        className="modal-edit-run-plan with-overflow"
        width={"inherit"}
        onCloseModal={() => (isUpdated ? setShowConfirm(true) : onCancelClick())}
      >
        <div className="modal-edit-run-plan-header">
          <Typography type={ElementType.h1}>Edit Run Plan</Typography>
          <div className="modal-edit-run-plan-header-buttons">
            <Button
              onClick={() => (isUpdated ? setShowConfirm(true) : onCancelClick())}
              className="modal-edit-run-plan-header-buttons-cancel"
              themeType="secondary"
              size="large"
            >
              Cancel
            </Button>
            <Button onClick={() => onDoneClick(runs, fixtureId)} themeType="clear" size="large">
              Save Changes
            </Button>
          </div>
        </div>
        <EditRunPlanTable
          fixtures={fixtures}
          runs={runs}
          executionPackages={executionPackages}
          materials={materials}
          fixtureId={fixtureId}
          sessionId={sessionId}
          setIsUpdated={setIsUpdated}
          setRuns={setRuns}
        />
      </Modal>
      {showConfirm && (
        <ModalConfirm
          width={607}
          height={208}
          title="Discard Changes"
          text="Are you sure you want to discard all changes?"
          buttonCloseText="Cancel"
          buttonConfirmText="Discard Changes"
          onCloseModal={() => setShowConfirm(false)}
          onConfirm={onCancelClick}
        />
      )}
    </>
  );
};

export { ModalEditRunPlan };
